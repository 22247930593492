import { useTranslation } from "react-i18next";
import { Modal } from "../../common";
import { Button } from "../../App.styles";

const DescriptionModal = ({ onDismiss, show }) => {
  const { t } = useTranslation();

  return (
  <Modal onDismiss={onDismiss} show={show}>
    <div className="modal-header">
      <h4 className="modal-title">{t('Description')}</h4>
      <button
        type="button"
        className="close"
        data-dismiss="modal"
        aria-hidden="true"
        onClick={onDismiss}
      >
        ×
      </button>
    </div>
    <div className="modal-body">
      <p>
        {t(`When players search for or join the game, you can give them a
        personalized message to describe the game.`)}
      </p>
      <ul>
        <li>{t(`Only the game owner may set the game description.`)}</li>
        <li>{t(`The game description can be changed at any time.`)}</li>
        <li>
          {t(`When someone searches for your game, both the game name and game
          description are used.`)}
        </li>
        <li>
          {t(`If you are scheduling your game to start or finish at a certain time
          you should mention this in the description as challenges will be
          hidden for players who join early or late.`)}
        </li>
        <li>{t(`Supports Personalization.`)}</li>
      </ul>
    </div>
    <div className="modal-footer">
      <Button color="dark" data-dismiss="modal" onClick={onDismiss}>
        {t(`Close`)}
      </Button>
    </div>
  </Modal>
)
};

export default DescriptionModal;
