import { useTranslation } from "react-i18next";
import { Modal } from "../../common";
import { Button } from "../../App.styles";

const RewardsDescriptionModal = ({ onDismiss, show }) => {
  const { t } = useTranslation();

  return (
    <Modal onDismiss={onDismiss} show={show}>
      <div className="modal-header">
        <h4 className="modal-title">{t('Rewards and Clues')}</h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-hidden="true"
          onClick={onDismiss}
        >
          ×
        </button>
      </div>
      <div className="modal-body">
        <p>
          {t(`The description is shown when the player navigates to the full screen
          view of the reward or clue.`)}
        </p>
        <ul>
          <li>
            {t(`Descriptions don't have a set character limit, but depending on your
            device you may reach limits of visibility.`)}
          </li>
          <li>{t(`Supports Personalization.`)}</li>
        </ul>
      </div>
      <div className="modal-footer">
        <Button type="button" color="dark" data-dismiss="modal" onClick={onDismiss}>
          {t('Close')}
        </Button>
      </div>
    </Modal>
  )
};

export default RewardsDescriptionModal;
