export const addQueryString = function addQueryString(url, params) {
    if (params && typeof params === 'object') {
      let queryString = '';
  
      for (const paramName in params) {
        queryString += '&' + encodeURIComponent(paramName) + '=' + encodeURIComponent(params[paramName]);
      }
  
      if (!queryString) return url;
      url = url + (url.indexOf('?') !== -1 ? '&' : '?') + queryString.slice(1);
    }
  
    return url;
};