import { useTranslation } from "react-i18next";
import { Modal } from "../../common";
import { Button } from "../../App.styles";

const AugmentedRealityDelayModal = ({ onDismiss, show }) => {
  const { t } = useTranslation();

  return (
    <Modal onDismiss={onDismiss} show={show}>
      <div className="modal-header">
        <h4 className="modal-title">{t('Scale')}</h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-hidden="true"
          onClick={onDismiss}
        >
          ×
        </button>
      </div>
      <div className="modal-body">
        <p>
          {t(`Augmented reality models or files do not use standard scales. Due to
          this, you can adjust the scale to better match complimentary animations
          or contorl the default size they appear using.`)}
        </p>
        <ul>
          <li>
            {t(`A scale of 1 means display the animation using the scale specified in
            the model or file.`)}
          </li>
          <li>
            {t(`A scale of 0.02 means display the animation by default at 2% of the
            original size specified in the model or file.`)}
          </li>
          <li>
            {t(`A scale of 1.5 means display the animation by default at 150% of the
            original size specified in the model or file.`)}
          </li>
        </ul>
      </div>
      <div className="modal-footer">
        <Button type="button" color="dark" data-dismiss="modal" onClick={onDismiss}>
          {t('Close')}
        </Button>
      </div>
    </Modal>
  )
};

export default AugmentedRealityDelayModal;
