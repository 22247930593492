import { useTranslation } from "react-i18next";
import { Modal } from "../../common";
import { Button } from "../../App.styles";

const TitleModal = ({ onDismiss, show }) => {
  const { t } = useTranslation();

  return (
    <Modal onDismiss={onDismiss} show={show}>
      <div className="modal-header">
        <h4 className="modal-title">{t(`Reward Title`)}</h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-hidden="true"
          onClick={onDismiss}
        >
          ×
        </button>
      </div>
      <div className="modal-body">
        <p>{t(`This is the name for your reward or clue.`)}</p>
        <p dangerouslySetInnerHTML={{__html: t(`This supports personalization using the %&#123;key&#125; substitution
          syntax. For example, if you have this as your title "King of
          %&#123;castle&#125;" and have a key/value pair of castle="the World"
          setup in the Personalization area of the builder, then the reward title
          will be displayed as "King of the World" in the player apps.`)}} />
      </div>
      <div className="modal-footer">
        <Button color="dark" data-dismiss="modal" onClick={onDismiss}>
          {t('Close')}
        </Button>
      </div>
    </Modal>
  )
};

export default TitleModal;
