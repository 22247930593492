import styled, { css } from "styled-components";
import { darken } from "@material-ui/core";
import { Col } from "../App.styles";

export const StyledShimmer = styled.div`
${({ dark, theme }) => {
  const variants = {
    light: {
      bg: theme.placeholderColor,
      gradient: `linear-gradient(to right, ${theme.placeholderColor} 8%, ${darken(theme.placeholderColor, .1)} 18%, ${theme.placeholderColor} 33%)`,
    },
    dark: {
      bg: darken(theme.placeholderColor, .1),
      gradient: `linear-gradient(to right, ${darken(theme.placeholderColor, .1)} 8%, ${darken(theme.placeholderColor, .25)} 18%, ${darken(theme.placeholderColor, .1)} 33%)`,
    }
  }
  const rules = dark ? variants.dark : variants.light

  return css`
    animation-duration: 2.2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: shimmer;
    animation-timing-function: linear;
    background: ${rules.bg};
    background: ${rules.gradient};
    background-size: 1200px 100%;
    
    svg {
      color: #ddd;
    }
    
    @-webkit-keyframes shimmer {
      0% {
        background-position: -100% 0;
      }
      100% {
        background-position: 100% 0;
      }
    }
    
    @keyframes shimmer {
      0% {
        background-position: -1200px 0;
      }
      100% {
        background-position: 1200px 0;
      }
    }
    
    &.media {
      width: 75px;
      height: 75px;
    }

    &.media-full {
      width: 100%;
      padding-top: 50%;
    }
    
    &.title-line {
      width: 100%;
      height: 24px;
      margin-bottom: 12px;
      border-radius: 20px;
    }
    
    &.content-line {
      height: 8px;
      width: 100%;
      margin-bottom: 16px;
      border-radius: 8px;
    }
    
    &.cover-line {
      height: 100%;
      width: 100%;
      border-radius: 8px;
      object-fit: cover;
    }
    
    &.end {
      width: 40%;
    }  
  `;
}}
`;

export const LoadingCard = styled.div`
`;

export const LoadingCol = styled(Col)`
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start !important;
`;

export const Shimmer = ({ count = 1, type = 'content', end = false, media = false, dark = false, className = '' }) => {
  const typeClasses = {
    title: 'title-line',
    content: 'content-line',
    cover: 'cover-line',
  }

  const RenderShimmer = () => (
    <StyledShimmer dark={dark} className={`${className} ${media ? '' : typeClasses[type]} ${end ? 'end' : ''} ${media ? 'media' : ''}`}>
      &nbsp;
    </StyledShimmer>
  )

  return count <= 1
    ? <RenderShimmer/>
    : (
      <>
        {
          Array.from(
            Array(count).keys()
          ).map(key => (
            <RenderShimmer key={key} />
          ))
        }
      </>
    )
}
