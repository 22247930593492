import { useState, Children, isValidElement, cloneElement } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { Col } from "../../App.styles";
import { HelpButton, HelpCol, RowInfoContainer } from "./RowInfo.style";

const Child = ({ child, onDismiss, show }) => {
  if (isValidElement(child)) {
    return cloneElement(child, { show, onDismiss });
  }

  return child;
};

const RowInfo = ({ children, help = null, rowClassName = '', colClassName = '', hasLabel = true }) => {
  const [show, setShow] = useState(false);

  return (
    <RowInfoContainer className={rowClassName}>
      {
        children && help
          ? (
            <>
              <Col size="10" className={colClassName}>
                { children }
              </Col>
              <HelpCol size="2" className={`${hasLabel ? 'align-items-start': ''} col--stretch`}>
                <HelpButton type="button" onClick={() => setShow(true)}>
                  <FontAwesomeIcon icon={faQuestionCircle} />
                </HelpButton>
                {
                  Children.map(help, (child) => (
                    <Child 
                      child={child} 
                      show={show} 
                      onDismiss={() => setShow(false)} 
                    />
                  ))
                }
              </HelpCol>
            </>
          )
          : children
      }
    </RowInfoContainer>
  );
};

export default RowInfo;
