import { useTranslation } from "react-i18next";
import { NewImageUpload, Switch, RowInfo } from "../../common";
import { StickerModal, StickerStyleModal } from "../modals";

const StickerPhoto = ({ useQuery }) => {
  const { t } = useTranslation();

  return (
    <>
      <p>{t('Ask players to place a sticker on top of a photo.')}</p>
      <RowInfo help={<StickerModal />}>
        <NewImageUpload label={t("Sticker")} name="overlay" useQuery={useQuery} />
      </RowInfo>
      <RowInfo help={<StickerStyleModal />}>
        <Switch
            label={t("Fullscreen")}
            labels={{ on: t("Yes"), off: t("No") }}
            name="fullscreen"
            on={true}
            off={false}
            useQuery={useQuery}
          />
      </RowInfo>
    </>
  );
};

export default StickerPhoto;
