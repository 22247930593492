import styled from "styled-components";
import { useRecoilValue } from "recoil";
import { useEffect, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
} from "mdb-react-ui-kit";
import { errorState, useGameQuery } from "./Settings.state";
import { Alert, Refresher, SidebarLayout, withPlaceholder } from "../common";
import { Row } from "../App.styles";
import Mandatory from "./Mandatory";
import Optional from "./Optional";
import SettingsPlaceholder from "./SettingsPlaceholder";

const Tabs = styled(MDBTabs)`
  background-color: #eceff1;

  .nav-link {
    &.active {
      border-color: ${({ theme }) => theme.primaryColor};
      color: ${({ theme }) => theme.primaryColor};
    }
  }

  @media (max-width: ${({ theme }) => theme.maxMobileWidth}) {
    .nav-item {
      flex-grow: 1;
    }

    .nav-link {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      text-align: center;
    }
  }
`;

const TabsContent = styled(MDBTabsContent)`
  margin-bottom: 2.5rem;
`;

const AlertRow = styled.div`
  scroll-margin: 100px;
`;

const Settings = () => {
  const { t } = useTranslation();
  const { game_id } = useParams();
  const history = useHistory();
  const { hash } = useLocation();
  const alert = useRef(null);
  const [section, setSection] = useState("");
  const errors = useRecoilValue(errorState(game_id));
  const useQuery = () => useGameQuery(game_id);

  useEffect(() => setSection(hash.slice(1)), [hash]);
  useEffect(() => {
    if (history && section) {
      const newHash = `#${section}`;

      if (hash !== newHash) {
        history.push(newHash);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [section]);

  return (
    <>
      <AlertRow ref={alert}>
        <Alert color="danger">
          {!!errors.length && (
            <>
              {errors.map((error, id) => (
                <span key={id}>{error}</span>
              ))}
            </>
          )}
        </Alert>
      </AlertRow>
      <Row>
        <SidebarLayout message={t("These settings apply to your game You can change them anytime, even during the game.")}>
          <Tabs className="mb-3">
            <MDBTabsItem>
              <MDBTabsLink
                active={section !== "optional"}
                onClick={() => setSection("mandatory")}
              >
                Mandatory
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
              <MDBTabsLink
                active={section === "optional"}
                onClick={() => setSection("optional")}
              >
                Optional
              </MDBTabsLink>
            </MDBTabsItem>
          </Tabs>
          <TabsContent>
            <MDBTabsPane show={section !== "optional"}>
              <Mandatory useQuery={useQuery} />
            </MDBTabsPane>
            <MDBTabsPane show={section === "optional"}>
              <Optional useQuery={useQuery} />
            </MDBTabsPane>
          </TabsContent>
        </SidebarLayout>
      </Row>
      <Refresher subscriptions="ss.game.reel,ss.task.reel,ss.team.reel" />
    </>
  );
};

export default withPlaceholder(Settings, SettingsPlaceholder);
