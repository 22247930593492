import { Suspense, useState, useEffect, memo } from "react";
import { Switch, Route, Router, useLocation } from "react-router-dom";
import { createBrowserHistory } from "history";
import { ThemeProvider } from "styled-components";
import { RecoilRoot } from "recoil";
import { RecoilURLSyncJSON } from "recoil-sync";
import { QueryClient, QueryClientProvider } from "react-query";
import { AuthProvider } from "react-auth-kit";
import { ErrorBoundary, Provider, useRollbar } from "@rollbar/react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import FacebookPixel from "react-facebook-pixel";
import ReactGA from "react-ga";
import { OptimizelyProvider, createInstance, useDecision, setLogger } from "@optimizely/react-sdk";
import { useTranslation } from "react-i18next";
import { LoadScript } from "@react-google-maps/api";
import { Container, GlobalStyle, AppContainer, NavRow, MobileNav, NavCol } from "./App.styles";
import Dashboard from "./dashboard";
import Settings from "./settings";
import Organize from "./organize";
import Teams from "./teams";
import Monitor from "./monitor";
import AnswerShow from "./monitor/AnswerShow";
import GameStream from "./monitor/GameStream";
import Leaderboard from "./monitor/Leaderboard";
import ChallengeStream from "./monitor/ChallengeStream";
import TeamStream from "./monitor/TeamStream";
import Rewards from "./rewards";
import Exports from "./exports";
import Branding from "./branding";
import Slideshow from "./slideshow";
import Personalizations from "./personalizations";
import Reels from "./reels";
import SignIn from "./sign-in";
import SignUp from "./sign-up";
import ResetPassword from "./reset-password";
import Donation from "./donation";
import Analytics from "./analytics";
import Path from "./path";
import {
  Error,
  ErrorDisplay,
  SideNav,
  Header,
  Footer,
  Maintenance,
  ServerError,
} from "./common";
import theme from "./themes";
import PrivateRoute from "./common/PrivateRoute";
import ScrollToTop from "./common/ScrollToTop";
import PrivacyPolicy from "./common/PrivacyPolicy";
import TermsAndCondition from "./common/TermsAndCondition";
import Groups from "./groups";
import { useUserQuery } from "./monitor/User.state";
import useViewport from "./hooks/useViewport";
import "./lib/timeago";
import "./lib/i18n";
import "./style.scss";
import { LoadingDisplayIndicator } from "./common/LoadingDisplay";
import { getCurrentLanguage } from "./lib/lang";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (count, { response: { status } }) =>
        status !== 401 && status !== 403 && count < 3,
      retryDelay: attempt =>
        Math.min(attempt > 1 ? 2 ** attempt * 1000 : 1000, 30 * 1000),
      onError: error => {
        console.log('error', error)
      }
    },
  },
});

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const rollbarConfig = {
  accessToken: "52b44c7650074dac83310b2851b43f7a",
  environment: process.env.NODE_ENV,
};

const history = createBrowserHistory();

history.listen((location) => {
  ReactGA.set({
    page: `${location.pathname}${location.search}`
  });
  ReactGA.send("pageview");
  FacebookPixel.pageView();
});

setLogger(null);
const optimizelyClient = createInstance({
  sdkKey: process.env.REACT_APP_OPTIMIZELY_KEY,
});

const ExperimentContainer = ({ children }) => {
  const [, setIsClientReady] = useState(null);
  const user = useUserQuery();
  const hasUserId = user && user.id
  let userConfig = {}
  if (hasUserId) {
    userConfig = {
      id: String(user.id),
      attributes: {
        user_id: user.id,
        email: user.email,
        super: user.super_admin,
      }
    }
  }

  function isClientValid() {
    return optimizelyClient.getOptimizelyConfig() !== null;
  }

  optimizelyClient.onReady().then(() => {
    isClientValid() && setIsClientReady(true);
  });

  return (
    <OptimizelyProvider
      optimizely={optimizelyClient}
      user={userConfig}
    >
      {children}
    </OptimizelyProvider>
  )
}

const RollbackContainer = ({ children }) => {
  const location = useLocation();
  const rollbar = useRollbar()
  if (process.env.REACT_APP_OVERRIDE_CONSOLE === "true") {
    console.error = err => {
      const ignoreList = [
        'no-op',
        'status code',
        'OPTIMIZELY',
      ]
      ignoreList.find(key => key.match(err))
        ? console.error(err)
        : rollbar.error(err)
    }
  }

  return (
    <ErrorBoundary
      fallbackUI={(error, resetError) => {
        const errorObject = error?.error || error
        return (
          <ErrorDisplay error={errorObject} resetError={resetError} />
        )
      }}
      key={location.pathname}
    >
      {children}
    </ErrorBoundary>
  )
}

const LanguageContainer = ({ children }) => {
  const [decisionLang] = useDecision('language')
  const { i18n } = useTranslation()

  useEffect(() => {
    if (!decisionLang.enabled) {
      i18n.changeLanguage('disabled')
    }
  }, [decisionLang.enabled, i18n])
  return children
}

const RouterWrapper = () => {
  return (
    <Elements stripe={stripePromise}>
      <ScrollToTop />
      <Switch>
        <PrivateRoute
          exact
          path="/"
          loginPath="/sign-in"
          component={Dashboard}
        />
        <PrivateRoute
          path="/dashboard"
          loginPath="/sign-in"
          component={Dashboard}
        />
        <PrivateRoute
          path="/games/edit/:game_id"
          loginPath="/sign-in"
          component={Settings}
        />
        <PrivateRoute
          path="/tasks/organize/:game_id"
          loginPath="/sign-in"
          component={Organize}
        />
        <PrivateRoute
          path="/games/path/:game_id"
          loginPath="/sign-in"
          component={Path}
        />
        <PrivateRoute
          path="/teams/organize/:game_id"
          loginPath="/sign-in"
          component={Teams}
        />
        <PrivateRoute
          path="/groups/organize/:game_id"
          loginPath="/sign-in"
          component={Groups}
        />
        <PrivateRoute
          path="/games/rewards/:game_id"
          loginPath="/sign-in"
          component={Rewards}
        />
        <PrivateRoute
          path="/games/exports/:game_id"
          loginPath="/sign-in"
          component={Exports}
        />
        <PrivateRoute
          path="/answers/monitor/:game_id"
          loginPath="/sign-in"
          component={Monitor}
        />
        <PrivateRoute
          path="/games/grey/:game_id"
          loginPath="/sign-in"
          component={Branding}
        />
        <PrivateRoute
          path="/games/reels/:game_id"
          loginPath="/sign-in"
          component={Reels}
        />
        <PrivateRoute
          path="/games/stream/:game_id"
          loginPath="/sign-in"
          component={GameStream}
        />
        <PrivateRoute
          path="/tasks/stream/:game_id/:challenge_id"
          loginPath="/sign-in"
          component={ChallengeStream}
        />
        <PrivateRoute
          path="/teams/stream/:game_id/:team_id"
          loginPath="/sign-in"
          component={TeamStream}
        />
        <PrivateRoute
          path="/teams/leaderboard/:game_id"
          loginPath="/sign-in"
          component={Leaderboard}
        />
        <PrivateRoute
          path="/answers/show/:game_id/:id"
          loginPath="/sign-in"
          component={AnswerShow}
        />
        <PrivateRoute
          path="/games/slideshow/:game_id"
          loginPath="/sign-in"
          component={Slideshow}
        />
        <PrivateRoute
          path="/games/personalizations/:game_id"
          loginPath="/sign-in"
          component={Personalizations}
        />
        <PrivateRoute
          path="/games/analytics/:game_id"
          loginPath="/sign-in"
          component={Analytics}
        />
        <Route path="/games/donate/:game_id">
          <Donation />
        </Route>
        <Route path="/sign-in">
          <SignIn />
        </Route>
        <Route path="/sign-up">
          <SignUp />
        </Route>
        <Route path="/reset-password">
          <ResetPassword />
        </Route>
        <Route path="/maintenance">
          <Maintenance />
        </Route>
        <Route path="/server-error">
          <ServerError />
        </Route>
        <Route path="/privacy">
          <PrivacyPolicy />
        </Route>
        <Route path="/terms">
          <TermsAndCondition />
        </Route>
        <Route path="*">
          <Error
            title="Page Not Found!"
            message="We're unable to find the page you're looking for."
          />
        </Route>
      </Switch>
    </Elements>
  );
};

const ResponsiveNav = memo(({ nav, children }) => {
  const { width } = useViewport();
  const breakpoint = 600;

  return width < breakpoint
    ? (
      <MobileNav>
        {nav}
        {children}
      </MobileNav>
    )
    : (
      <>
        <NavRow>
          <NavCol className="col--stretch nav-wrapper">{nav}</NavCol>
          <NavCol className="col--stretch">{children}</NavCol>
        </NavRow>
      </>
    )
})

const MapLoader = () => null;
const App = () => {
  FacebookPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID);
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
  const language = getCurrentLanguage();
  return (
    <Provider config={rollbarConfig}>
      <Router history={history}>
        <ThemeProvider theme={theme}>
          <RollbackContainer>
            <AuthProvider authType="localstorage" authName="_auth">
              <RecoilRoot>
                  <RecoilURLSyncJSON
                    location={{
                      part: "queryParams",
                    }}
                  >
                    <QueryClientProvider client={queryClient}>
                      <Suspense fallback={<LoadingDisplayIndicator />}>
                        <ExperimentContainer>
                          <LanguageContainer>
                            <LoadScript
                              id ="google-map-script"
                              googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                              language={language}
                              loadingElement={<MapLoader />}
                            >
                              <GlobalStyle />
                              <Header />
                              <AppContainer className="d-flex" id="app-container">
                                <ResponsiveNav nav={<SideNav />}>
                                  <Container>
                                    <Suspense fallback={null}>
                                      <RouterWrapper />
                                    </Suspense>
                                    <Footer />
                                  </Container>
                                </ResponsiveNav>
                              </AppContainer>
                            </LoadScript>
                          </LanguageContainer>
                        </ExperimentContainer>
                      </Suspense>
                    </QueryClientProvider>
                  </RecoilURLSyncJSON>
              </RecoilRoot>
            </AuthProvider>
          </RollbackContainer>
        </ThemeProvider>
      </Router>
    </Provider>
  );
};

export default App;