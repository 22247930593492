import styled from "styled-components";
import { useState } from "react";
import { HelpText } from "../App.styles";

const Wrapper = styled.div`
  position: relative;
`;

const Textarea = ({ help, label, labelClassName = '', name, useQuery, ...rest }) => {
  const [collection, setCollection] = useQuery();
  const [value, setValue] = useState(collection[name]);
  const [focus, setFocus] = useState(false);

  const commit = () => {
    if (value) {
      setFocus(false);
      setCollection({ [name]: value });
    }
  };

  const changes = ({ target }) => {
    if (name === "multiplechoice") {
      const choices = target.value.split(",");

      if (choices.length > 5) {
        let str = target.value.slice(0, -1);
        setValue(str);
      } else {
        setValue(target.value);
      }
    } else {
      setValue(target.value);
    }
  };

  return (
    <Wrapper className="form-outline">
      <textarea
        className="form-control active"
        rows={5}
        value={value}
        onChange={changes}
        onBlur={commit}
        onFocus={() => setFocus(true)}
        {...rest}
      />
      <label className={`form-label ${labelClassName}`}>{label}</label>
      {help && <HelpText focus={focus}>{help}</HelpText>}
    </Wrapper>
  );
};

export default Textarea;
