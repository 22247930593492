import { useParams } from "react-router-dom";
import { Row, Col, HelpText } from "../../App.styles";
import { TagsModal } from "../modals";
import { TagList, RowInfo } from "../../common";
import { useAutocompleteQuery } from "../../library/Library.state";
import {
  useAddTagMutation,
  useChallengeTagsQuery,
  useRemoveTagMutation,
} from "../Organize.state";

const TagsSection = ({ id }) => {
  const { game_id } = useParams();
  const contents = useAutocompleteQuery(game_id);
  const tags = useChallengeTagsQuery(id);
  const { addTag } = useAddTagMutation(id);
  const { removeTag } = useRemoveTagMutation(id);

  return (
    <>
      <RowInfo help={<TagsModal />} hasLabel={false}>
        <TagList
            freeSolo={true}
            value={tags}
            onAdd={addTag}
            onRemove={removeTag}
            options={contents}
          />
      </RowInfo>
      <Row>
        <Col size="10">
          <HelpText className="position-relative top-0 mt-4 d-block" focus>
          Tags are used to add search labels to challenges. Tags are the basis for the premium library.

          <p className="mt-2">After typing a few characters, wait for the autocomplete to suggest tags already in use.
          Tagging is currently limited to administrators.</p>
          </HelpText>
        </Col>
      </Row>
    </>
  );
};

export default TagsSection;
