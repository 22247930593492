import { MDBBtnGroup, MDBBtn } from "mdb-react-ui-kit"
import styled from "styled-components"

const PrimaryButton = styled(MDBBtn)`
    &.btn-primary {
        transition: none;
        background-color: ${({ theme }) => theme.darkColor};
        border-color: ${({ theme }) => theme.darkColor};

        &:hover,
        &.active {
            background-color: ${({ theme }) => theme.accent2Color}
        }
    }
`

const PageTabs = ({ tabs }) => {
    return (
        <MDBBtnGroup>
            {
                tabs.map((tab, tabId) => {
                    const action = tab.href
                        ? 'href'
                        : 'onClick'
                    const actionProp = {
                        [action]: tab[action]
                    }
                    return (
                        <PrimaryButton 
                            {...actionProp}
                            key={tabId} 
                            className={`${tab.active ? 'active' : ''}`}
                            data-testid={`page-tab${tab.active ? '-active' : ''}`}
                        >
                            { tab.label }
                        </PrimaryButton>
                    )
                })
            }
        </MDBBtnGroup>
    )
}

export default PageTabs