import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "../../common";
import { FormSelect } from "../../common/FormSelect";
import { Button, Row } from "../../App.styles";

const AcceptModal = ({ onConfirm, onDismiss, currentPoints, defaultPoints, show }) => {
  const { t } = useTranslation();
  const [points, setPoints] = useState(currentPoints);
  const [message, setMessage] = useState("");
  const set =
    (setter) =>
    ({ target }) =>
      setter(target.value);

  return (
    <Modal onDismiss={onDismiss} show={show}>
      <div className="modal-header">
        <h4 className="modal-title">{t('Accept')}</h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-hidden="true"
          onClick={onDismiss}
        >
          ×
        </button>
      </div>
      <div className="modal-body">
        <Row>
          <FormSelect label={t("Points")} value={points} setValue={setPoints}>
            {Array(501)
              .fill()
              .map((_, i) => (
                <option key={i} value={i}>
                  {i} {i === defaultPoints && "*"}
                </option>
              ))}
          </FormSelect>
        </Row>
        <Row>
          <textarea
            placeholder={t("Message to player")}
            rows={5}
            value={message}
            onChange={set(setMessage)}
          />
        </Row>
      </div>
      <div className="modal-footer">
        <Button color="dark" data-dismiss="modal" onClick={onDismiss}>
          {t('Cancel')}
        </Button>
        <Button color="primary" onClick={() => onConfirm(points, message)}>
          {t('Accept')}
        </Button>
      </div>
    </Modal>
  );
};

export default AcceptModal;
