import { MDBCard, MDBCardHeader, MDBCardBody, MDBCardTitle } from "mdb-react-ui-kit";
import styled from "styled-components";
import { HeaderRow, Row, Col, Divider, CardBody } from "../App.styles";
import { SidebarLayout } from "../common";
import RewardList from "./RewardList";
import { Shimmer } from "../common/Loading.styles";

const RewardHeader = styled.div`
  display: flex;
  justify-content: flex-end;

  input {
    width: 33%;
  }

  @media (max-width: ${({ theme }) => theme.maxMobileWidth}) {
    margin-bottom: 1rem;
  }
`;

export const RewardLi = styled.li`
  scroll-margin-top: ${({ theme }) => theme.headerPadding};
`;

export const RewardCard = styled(MDBCard)`
  margin-bottom: 1.25rem;
`;

const RewardView = () => {
  return (
    <MDBCard className="mb-3">
      <MDBCardHeader>
        <MDBCardTitle>
            <Shimmer type="title"/>
        </MDBCardTitle>
      </MDBCardHeader>
      <MDBCardBody>
        <Row>
          <Col size="5" md="2">
            <Shimmer media/>
          </Col>
          <Col size="7" md="10">
            <div className="flex-grow-1">
              <Shimmer type="title" count={3}/>
            </div>
          </Col>
        </Row>
        <Divider />
        <Row className="flex-grow-1 d-flex justify-content-end">
          <Col md={3}>
            <Shimmer type="title"/>
          </Col>
        </Row>
      </MDBCardBody>
    </MDBCard>
  );
};

const Panel = () => (
  <>
    <CardBody className="card p-4">
        <Shimmer dark type="title"/>
        <Shimmer dark />
    </CardBody>
  </>
);

const RewardsPlaceholder = () => {
  return (
    <>
      <HeaderRow>
        <RewardHeader className="col-12 col-lg-9">
            <Row className="flex-grow-1 d-flex justify-content-end">
              <Col md={3}>
                <Shimmer dark type="title"/>
              </Col>
            </Row>
        </RewardHeader>
      </HeaderRow>
      <Row>
        <SidebarLayout
          panel={<Panel />}
        >
          <RewardList>
            {
                Array.from(
                    Array(3).keys()
                ).map(key => (
                    <RewardView key={key} />
                ))
            }
          </RewardList>
        </SidebarLayout>
      </Row>
    </>
  );
};

export default RewardsPlaceholder;
