import { useTranslation } from "react-i18next";
import { Modal } from "../../common";
import { Button } from "../../App.styles";

const MessagesModal = ({ onDismiss, show }) => {
  const { t } = useTranslation();

  return (
    <Modal onDismiss={onDismiss} show={show}>
      <div className="modal-header">
        <h4 className="modal-title">{t('Messages')}</h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-hidden="true"
          onClick={onDismiss}
        >
          ×
        </button>
      </div>
      <div className="modal-body">
        <p>
          {t(`When a reward or clue is granted, you may optionally send them a message
          via push notification.`)}
        </p>
        <ul>
          <li>{t('Limit of 255 characters, text only.')}</li>
          <li>{t('Supports Personalization.')}</li>
        </ul>
      </div>
      <div className="modal-footer">
        <Button color="dark" data-dismiss="modal" onClick={onDismiss}>
          {t('Close')}
        </Button>
      </div>
    </Modal>
  )
};

export default MessagesModal;
