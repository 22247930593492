import { Modal } from "../common";
import { Button } from "../App.styles";
import useUnsuspendedTranslation from "../hooks/useUnsuspendedTranslation";

const ErrorModal = ({ onConfirm, onDismiss, message, show, title }) => {
  const { t } = useUnsuspendedTranslation()
  return (
    <Modal onDismiss={onDismiss} show={show}>
      <div className="modal-header">
        <h4 className="modal-title">{title}</h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-hidden="true"
          onClick={onDismiss}
        >
          ×
        </button>
      </div>
      <div className="modal-body">{message}</div>
      <div className="modal-footer">
        <Button color="link" data-dismiss="modal" onClick={onDismiss}>
          {t(`Dismiss`)}
        </Button>
        <Button color="primary" onClick={onConfirm}>
          {t(`Retry`)}
        </Button>
      </div>
    </Modal>
  )
}

export default ErrorModal;
