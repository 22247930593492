import styled from "styled-components";
import { useState } from "react";
import { MDBCardHeader, MDBCardBody, MDBCardTitle } from "mdb-react-ui-kit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ActionButtonWrapper, Button, Col, Divider, Row } from "../App.styles";
import { useTeamQuery, useTeamPhotoThumbQuery } from "./Teams.state";
import PlayerList from "./PlayerList";

const TeamThumb = styled.div`
  background-color: ${({ theme }) => theme.placeholderColor};
  height: 75px;
  margin-right: 1rem;
  width: 75px;

  img {
    height: 75px;
    width: 75px;
    object-fit: cover;
    object-position: center;
  }

  ${({ error }) =>
    error &&
    `
    border: 2px solid red;

    img {
      display: none;
    }
  `};
`;

const TeamView = ({ id, onEdit }) => {
  const { t } = useTranslation();
  const { game_id } = useParams();
  const [error, setError] = useState(false);
  const [{ name, users }] = useTeamQuery(game_id, id);
  const photo_thumb = useTeamPhotoThumbQuery(id);

  return (
    <>
      <MDBCardHeader>
        <Row>
          <Col>
            <MDBCardTitle>{name}</MDBCardTitle>
          </Col>
        </Row>
      </MDBCardHeader>
      <MDBCardBody>
        <Row>
          <Col size="5" md="2">
            <TeamThumb error={error}>
              {photo_thumb && (
                <img
                  alt={name}
                  src={photo_thumb}
                  onError={() => setError(true)}
                />
              )}
            </TeamThumb>
          </Col>
          <Col size="7" md="10">
            <PlayerList users={users} />
          </Col>
        </Row>
        <Divider />
        <ActionButtonWrapper>
          <Button color="primary" className="btn-raised" onClick={onEdit}>
            <FontAwesomeIcon icon={faPencilAlt} /> {t('Edit')}
          </Button>
        </ActionButtonWrapper>
      </MDBCardBody>
    </>
  );
};

export default TeamView;
