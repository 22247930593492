import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
    faImage,
    faVideo,
    faQuestion,
    faListOl,
    faLaugh,
    faFont,
    faQrcode,
    faVrCardboard,
    faPhotoVideo,
    faWaveSquare,
 } from "@fortawesome/free-solid-svg-icons";
 import { IconWrapper } from "./ChallengeIcon.style";

const taskIcons = [
    { value: 1, label: "Photo", icon: <FontAwesomeIcon icon={faImage} /> },
    { value: 2, label: "Video", icon: <FontAwesomeIcon icon={faVideo} /> },
    { value: 5, label: "Trivia", icon: <FontAwesomeIcon icon={faQuestion} /> },
    { value: 6, label: "Multiple Choice", icon: <FontAwesomeIcon icon={faListOl} /> },
    { value: 3, label: "Sticker + Photo", icon: <FontAwesomeIcon icon={faLaugh} /> },
    { value: 0, label: "Text", icon: <FontAwesomeIcon icon={faFont} /> },
    { value: 4, label: "QR Code", icon: <FontAwesomeIcon icon={faQrcode} /> },
    { value: 7, label: "Augmented Reality + Photo", icon: <FontAwesomeIcon icon={faVrCardboard} /> },
    { value: 8, label: "Augmented Reality + Video", icon: <FontAwesomeIcon icon={faVrCardboard} /> },
    { value: 9, label: "Background + Photo", icon: <FontAwesomeIcon icon={faPhotoVideo} /> },
    { value: 10, label: "Background + Video", icon: <FontAwesomeIcon icon={faPhotoVideo} /> },
    { value: 11, label: "Split Path", icon: <FontAwesomeIcon icon={faWaveSquare} /> },
]

const ChallengeIcon = ({ id }) => {
    const icon = taskIcons.find(({ value }) => String(id) === String(value))
    return icon
        ? (
            <IconWrapper className="text-info">
                {icon.icon}
                <span>{icon.label}</span>
            </IconWrapper>
        )
        : null
}

export default ChallengeIcon