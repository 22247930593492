import { useTranslation } from "react-i18next";
import { Modal } from "../../common";
import { Button } from "../../App.styles";

const AugmentedRealityStyleModal = ({ onDismiss, show }) => {
  const { t } = useTranslation();

  return (
    <Modal onDismiss={onDismiss} show={show}>
      <div className="modal-header">
        <h4 className="modal-title">{t('Style')}</h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-hidden="true"
          onClick={onDismiss}
        >
          ×
        </button>
      </div>
      <div className="modal-body">
        <p>
          {t(`There are a few different ways you can embed an animation into the
          experience.`)}
        </p>
        <ul>
          <li dangerouslySetInnerHTML={{__html: t(`Use 'loop' to your animation endlessly.`)}} />
          <li dangerouslySetInnerHTML={{__html: t(`Use 'stop' to play once then stop animating until tapped.`)}} />
          <li dangerouslySetInnerHTML={{__html: t(`Use 'static' if you do not want animation.`)}} />
          <li dangerouslySetInnerHTML={{__html: t(`Use 'chain-loop' or 'chain-stop' to create sequences of animations
            that play one after another, either looping at end or stopping to wait
            for a tap.`)}} />
        </ul>
      </div>
      <div className="modal-footer">
        <Button type="button" color="dark" data-dismiss="modal" onClick={onDismiss}>
          {t('Close')}
        </Button>
      </div>
    </Modal>
  )
};

export default AugmentedRealityStyleModal;
