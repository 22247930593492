import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const useRouteChange = ({ onRouteChange }) => {
  const location = useLocation();

  useEffect(() => {
    onRouteChange && onRouteChange(location)
  }, [location, onRouteChange]);
};

export default useRouteChange