import { useTranslation } from "react-i18next";
import { FormSelect, RowInfo } from "../common";
import { BonusPointsModal } from "./modals";

const PointsSection = ({ useQuery }) => {
  const { t } = useTranslation();

  return (
    <>
      <RowInfo help={<BonusPointsModal />}>
        <FormSelect label={t("Bonus Points")} name="points" useQuery={useQuery}>
            <option value={0}>0</option>
            <option value={1}>1</option>
            <option value={2}>2</option>
            <option value={3}>3</option>
            <option value={4}>4</option>
            <option value={5}>5</option>
            <option value={6}>6</option>
            <option value={7}>7</option>
            <option value={8}>8</option>
            <option value={9}>9</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
            <option value={40}>40</option>
            <option value={50}>50</option>
            <option value={60}>60</option>
            <option value={70}>70</option>
            <option value={80}>80</option>
            <option value={90}>90</option>
            <option value={99}>99</option>
          </FormSelect>
      </RowInfo>
    </>
  );
};

export default PointsSection;
