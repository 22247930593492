import { useState } from "react";
import { DatalistCard } from "./Datalist.style";
import HighlightList from "../common/HighlightList";

const DatalistItem = ({ scrollId, cards, ...props }) => {
  const [edit, setEdit] = useState(false);
  const [toggles, setToggles] = useState({});
  const { edit: editCard, view: viewCard } = cards
  const onClose = () => setEdit(false)
  const onEdit = () => setEdit(true)
  const onToggle = key => setToggles({ ...toggles, [key]: !toggles?.[key] })
  const DatalistEdit = () => editCard({ onClose, onEdit, onToggle, toggles, ...props })
  const DatalistView = () => viewCard({ onClose, onEdit, onToggle, toggles, ...props })

  return (
    <HighlightList scrollId={scrollId} id={props.id}>
      <DatalistCard>
        {
          edit
            ? <DatalistEdit />
            : <DatalistView />
        }
      </DatalistCard>
    </HighlightList>
  );
};

export default DatalistItem;
