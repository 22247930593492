import { Row, CardBody } from "../App.styles";
import { SidebarLayout } from "../common";
import { Shimmer } from "../common/Loading.styles";
import ChallengeList from "./ChallengeList";
import ChallengePlaceholder from "./ChallengePlaceholder";

const Panel = () => (
  <CardBody className="card p-4">
    <Shimmer dark type="title"/>
    <Shimmer dark />
  </CardBody>
);

const OrganizePlaceholder = () => {
  return (
    <>
      <Row>
        <SidebarLayout
          panel={<Panel />}
        >
          <div>
            <ChallengeList>
              <div>
                <ChallengePlaceholder />
                <ChallengePlaceholder />
                <ChallengePlaceholder />
                <ChallengePlaceholder />
                <ChallengePlaceholder />
              </div>
            </ChallengeList>
          </div>
        </SidebarLayout>
      </Row>
    </>
  );
};

export default OrganizePlaceholder;
