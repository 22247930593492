import { useTranslation } from "react-i18next";
import { Modal } from "../../common";
import { Button } from "../../App.styles";

const CategoryModal = ({ onDismiss, show }) => {
  const { t } = useTranslation();

  return (
    <Modal onDismiss={onDismiss} show={show}>
      <div className="modal-header">
        <h4 className="modal-title">{t('Category')}</h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-hidden="true"
          onClick={onDismiss}
        >
          ×
        </button>
      </div>
      <div className="modal-body">
        <p dangerouslySetInnerHTML={{__html: t(`There are four categories of games. Games are grouped together by
          category on your dashboard. Categories do not impact Promotion or Search
          settings. For companies that manage many games, categories provide basic
          workflow. A game might start in DRAFT as it's being authored. Next, it
          might become ACTIVE as its run for the first time. If the game was a
          hit, it might then be made into a TEMPLATE for future games. Copies of
          the template might be played once, then moved into ARCHIVE state.`)}} />
      </div>
      <div className="modal-footer">
        <Button color="dark" data-dismiss="modal" onClick={onDismiss}>
          {t('Close')}
        </Button>
      </div>
    </Modal>
  )
};

export default CategoryModal;
