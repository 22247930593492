import { useTranslation } from "react-i18next";

const VideoHighlightReels = () => {
  const { t } = useTranslation();

  return (
    <div className="card">
      <div className="card-header bg-dark text-light">
        <span className="card-title" dangerouslySetInnerHTML={{__html: t('Video Highlight Reels')}} />
      </div>
      <div className="card-body">
        <p>
          <b>{t('Game Reels made with the Human Touch')}</b>
          <br />
          <span dangerouslySetInnerHTML={{__html: t(`Do you want to tie your entire experience together with a hilarious hand
          crafted, human curated, video of the best moments? You have access to
          all of the content (DIY) but many of our clients simply don’t have the
          time. We do! Just reach out and give us the details and we’ll get you a
          price based on your game size and timing. Take a peek at a`)}} />{" "}
          <a href="https://video214.com/play/hURGvLao7cwz9NHy11XSCw/s/dark">
            {t('Sample Reel')}
          </a>
          .
        </p>
        <p>
          <b>{t('Game, Challenge, and Team Reels Automated')}</b>
          <br />
          {t(`Unlock the ultimate highlight reels experience where our system
          constantly refreshes personalized highlight reels for every team, every
          challenge and for the game itself. As players submit responses to your
          challenges, reels are refreshed and circulated. Reels are shareable and
          provide players with a beloved souvenir of the event, from their own
          perspective. Unlock this add-on via the Reels section of your builder.
          Want a preview? See an`)}{" "}
          <a href="https://ss-production.s3.amazonaws.com/reels/g_5368_t_43689_s.mp4">
            {t('example')}
          </a>
          .
        </p>
      </div>
    </div>
  )
};

export default VideoHighlightReels;
