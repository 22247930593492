import { useTranslation } from "react-i18next";
import { Modal } from "../common";
import { Button } from "../App.styles";

const DeleteModal = ({ onConfirm, onDismiss, show }) => {
  const { t } = useTranslation();

  return (
    <Modal onDismiss={onDismiss} show={show}>
      <div className="modal-header">
        <h4 className="modal-title" dangerouslySetInnerHTML={{__html: t(`Confirm Deletion`)}} />
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-hidden="true"
          onClick={onDismiss}
        >
          ×
        </button>
      </div>
      <div className="modal-body">
        <span dangerouslySetInnerHTML={{__html: t(`Are you sure you wish to delete this reward permanently?`)}} />
      </div>
      <div className="modal-footer">
        <Button color="dark" data-dismiss="modal" onClick={onDismiss}>
          {t(`Cancel`)}
        </Button>
        <Button color="primary" onClick={onConfirm}>
          {t(`Delete`)}
        </Button>
      </div>
    </Modal>
  )
}

export default DeleteModal;
