import { useTranslation } from "react-i18next";
import { useDecision } from "@optimizely/react-sdk";
import { Col, HelpText } from "../../App.styles";
import {
  LockByModal,
  LockedVisibilityModal,
  LockByChallengeModal,
} from "../modals";
import { Switch, RowInfo, FormAutocomplete, ChainSelect } from "../../common";
import { Shimmer } from "../../common/Loading.styles";
import { useContext, useMemo } from "react";
import { OrganizeContext } from "../Organize.context";
import { radiusOptions } from "../../consts/settings";

const LocksSection = ({ useQuery }) => {
  const { t } = useTranslation();
  const [{ id, latitude, longitude, unlock_by_task_id }] = useQuery();
  const { challenges: challengesContext } = useContext(OrganizeContext);
  const { tasks: challenges = [], isLoading } = challengesContext || {};
  const [decisionAdventures] = useDecision('adventures')

  const filteredChallenges = [{
    id: '0',
    name: '',
  }].concat(
    challenges.filter(
      (challenge) => challenge.id !== id
    )
  );

  const disabled = !longitude && !latitude;
  const chainKeys = useMemo(() => {
    return btoa(filteredChallenges.map(c => {
      return `${c?.name || ''}-${c?.task_type || ''}`
    }).join('/'))
  }, [filteredChallenges])
  return (
    <>
      <RowInfo>
        <Col size="10">
          <HelpText className="position-relative top-0 d-block mb-4" focus>
            <span dangerouslySetInnerHTML={{__html: t('You can lock a challenge, and hide it too.')}} />
            <ul>
              <li><span dangerouslySetInnerHTML={{__html: t('You can lock a challenge such that it requires another challenge.')}} /></li>
              <li><span dangerouslySetInnerHTML={{__html: t('You can lock it to require being within a small radius of the location you set (Must select a location before using this feature).')}} /></li>
              <li><span dangerouslySetInnerHTML={{__html: t('While not listed here, you can lock it to require being within a start and end time as well.')}} /></li>
              <li><span dangerouslySetInnerHTML={{__html: t('While not listed here, QR code challenges are another way of locking completion to a particular location(s).')}} /></li>
            </ul>
          </HelpText>
        </Col>
      </RowInfo>
      <RowInfo help={<LockByChallengeModal />}>
        {
          isLoading
            ? <Shimmer type="title" />
            : decisionAdventures?.enabled
              ? (
                <ChainSelect
                  help={t('Locking is optional.')}
                  label={t('Unlock by Completing Challenge')}
                  name="unlock_by_task_ids"
                  backwardCompatibleValue={unlock_by_task_id}
                  useQuery={useQuery}
                  options={filteredChallenges}
                  size="small"
                  childKeys={{ 5: 'trivia', 6: 'multiplechoice', 11: 'multiplechoice' }}
                  key={chainKeys}
                />
              )
              : (
                <FormAutocomplete
                  help={t('Locking is optional.')}
                  label={t('Unlock by Completing Challenge')}
                  name="unlock_by_task_id"
                  useQuery={useQuery}
                  options={filteredChallenges}
                  size="small"
                />
              )
        }
      </RowInfo>
      <RowInfo help={<LockByModal />}>
        <FormAutocomplete
          desc={
            disabled
              ? t('Location must be enabled to use this feature')
              : null
          }
          label={t('Unlock when closer than:')}
          name="unlock_by_location_radius"
          useQuery={useQuery}
          disabled={disabled}
          options={radiusOptions}
          size="small"
        />
      </RowInfo>
      <RowInfo help={<LockedVisibilityModal />}>
        <Switch
            label={t('If locked, show in list')}
            labels={{ on: t("Show"), off: t("Hide") }}
            name="hide_locked"
            on={false}
            off={true}
            useQuery={useQuery}
          />
      </RowInfo>
    </>
  );
};

export default LocksSection;
