import { useCallback, useEffect } from "react";
import { useMemo } from "react";
import styled from "styled-components";
import FormAutocomplete from "../common/FormAutocomplete";
import { DEFAULT_TIMEZONE } from "../consts/timezone";
import { backwardCompatibleTimezone } from "./mapTimezones";
import { useTimezoneDataQuery } from "./Settings.state";

const StyledAutocomplete = styled(FormAutocomplete)`
  .PrivateNotchedOutline-root-1,
  .MuiOutlinedInput-notchedOutline {
    top: 2px;
  }
`

const ActiveLabel = styled.label`
  font-size: 0.8rem
`;

export const getTzOffsetDisplay = offset => {
  const minsPerHour = 60
  let offsetDisplay = offset/minsPerHour
  if(offsetDisplay > 0) {
    offsetDisplay = `+${offsetDisplay}`
  }
  return offsetDisplay.toString()
}

const TimezoneSelect = ({
  date,
  label,
  value,
  onBlur,
  onChange,
  labelStyle = "original",
  ...props
}) => {
  const timezones = useTimezoneDataQuery()
  const handleChange = useCallback((e, tz) => {
    onChange && onChange(tz?.id);
  }, [onChange]);
  const options = timezones.map(([name, id]) => ({
    id,
    name,
  }))

  // Add backward compatible value
  const currentValue = useMemo(() => {
    return options.find(backwardCompatibleTimezone(value))?.id
  }, [options, value])

  useEffect(() => {
    if (!currentValue) {
      handleChange(null, { id: DEFAULT_TIMEZONE })
    }
  }, [currentValue, handleChange])

  return (
    <div className="form-outline">
      <ActiveLabel className="form-label">{label}</ActiveLabel>
      <StyledAutocomplete
        options={options}
        size="small"
        disableClearable={true}
        onChange={handleChange}
        stateful={false}
        value={currentValue}
        {...props}
      />
    </div>
  );
};

export default TimezoneSelect;
