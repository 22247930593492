import styled from "styled-components";
import {
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBDropdownLink,
} from "mdb-react-ui-kit";
import { useTranslation } from "react-i18next";

export const CategoryDropdownWrapper = styled(MDBDropdown)`
  margin-right: 0;
  button {
    position: relative;
    text-align: left;
    width: 160px;

    &:after {
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  @media (max-width: ${({ theme }) => theme.maxMobileWidth}) {
    width: 65%;
  }
`;

const CategoryDropdown = ({ category, className, onSelect }) => {
  const { t } = useTranslation()
  const defaultCategory = t("All Categories")

  return (
    <CategoryDropdownWrapper className={className}>
      <MDBDropdownToggle color="dark">{category ?? defaultCategory}</MDBDropdownToggle>
      <MDBDropdownMenu>
        <MDBDropdownItem onClick={() => onSelect(null)}>
          <MDBDropdownLink>{defaultCategory}</MDBDropdownLink>
        </MDBDropdownItem>
        <MDBDropdownItem onClick={() => onSelect("Template")}>
          <MDBDropdownLink>{t('Template')}</MDBDropdownLink>
        </MDBDropdownItem>
        <MDBDropdownItem onClick={() => onSelect("Draft")}>
          <MDBDropdownLink>{t('Draft')}</MDBDropdownLink>
        </MDBDropdownItem>
        <MDBDropdownItem onClick={() => onSelect("Active")}>
          <MDBDropdownLink>{t('Active')}</MDBDropdownLink>
        </MDBDropdownItem>
        <MDBDropdownItem onClick={() => onSelect("Archive")}>
          <MDBDropdownLink>{t('Archive')}</MDBDropdownLink>
        </MDBDropdownItem>
      </MDBDropdownMenu>
    </CategoryDropdownWrapper>
  );
};

export default CategoryDropdown;
