import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import {
  useInvalidateSlideshowMutation,
  notificationsState,
} from "./Slideshow.state";
import { Alert, Spinner, withPlaceholder } from "../common";

const AlertWrapper = styled.div`
  padding: 1.5rem;
  width: 25vw;
  z-index: 999999;
`;

const SlideshowLoader = () => {
  const [showingNotification, setShowingNotification] = useState(false);
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [notification, setNotification] = useState("");
  const [error] = useState(false);
  const intervalRef = useRef();
  const refresh = useInvalidateSlideshowMutation();

  useEffect(() => {
    if (!showingNotification && notifications.length) {
      setShowingNotification(true);
      setNotification(notifications[0]);
      setNotifications(notifications.slice(1));
      setTimeout(() => setShowingNotification(false), 5000);
    }
  }, [notifications, setNotifications, showingNotification]);

  useEffect(() => {
    if (error) {
      intervalRef.current = setInterval(refresh, 30000);
    } else {
      clearInterval(intervalRef.current);
    }

    return () => clearInterval(intervalRef.current);
  }, [error, refresh]);

  return showingNotification ? (
    <AlertWrapper>
      <Alert color="info">{notification}</Alert>
    </AlertWrapper>
  ) : null;
};

export default withPlaceholder(SlideshowLoader, Spinner);
