import styled from "styled-components";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  MDBCard,
  MDBCardHeader,
  MDBCardTitle,
  MDBCardBody,
} from "mdb-react-ui-kit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCheckCircle,
  faClock,
  faStar,
  faTimes,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { Button, Row, Col } from "../App.styles";
import { Alert } from "../common";
import { AcceptModal, RejectModal } from "./modals";
import {
  useAcceptMutation,
  useRejectMutation,
  useFeatureMutation,
} from "./Monitor.state";
import { axios } from "../lib/request";
import AnswerMedia from "./AnswerMedia";
import Timeago from "../common/Timeago";

const Card = styled(MDBCard)`
  margin: 1rem 0;
`;

const CardBody = styled(MDBCardBody)`
  svg {
    margin-left: 0.625rem;
  }
`;

const AnswerCol = styled(Col)`
  flex-direction: column;
  align-items: stretch;

  @media (max-width: ${({ theme }) => theme.maxMobileWidth}) {
    font-size: 0.875rem;
    margin-top: 1rem;

    a {
      color: ${({ theme }) => theme.primaryColor};
    }

    p {
      margin-right: 0;
    }
  }
`;

const AnswerMediaProcessing = styled.div`
  background-color: #f5f5f5;
  padding: 8rem 4rem;
  flex-grow: 1;
  text-align: center;
`;

const ButtonCol = styled(Col)`
  ${({ theme }) => `
    margin-top: 12px;

    ${theme.breakpoints.down('md')} {
      ${Button} {
        font-size: 0.875rem;
        line-height: 1;
        margin-right: 2px;
        padding: 0.5rem 1rem;

        span {
          display: none;
        }
      }
    }

    ${theme.breakpoints.up('sm')} {
      justify-content: flex-end;
    }

    ${theme.breakpoints.up('lg')} {
      margin-top: 0;

      ${Button} {
        svg {
          display: none;
        }
      }
    }
  `}
`;

const MediaCol = styled(Col)`
  justify-content: center;
`;

const RegenerateButton = styled(Button)`
  font-size: 1rem;
  font-weight: normal;
  padding: 0;
  text-transform: none;

  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
  }
`;

export const UpdatedAt = styled.abbr`
  cursor: help;
  margin-left: 0.625rem;
`;

export const P = styled.p`
  margin-top: 1.5rem;
`;

const isProcessing = (type, subtype, photo, video, sticker) => {
  switch (type) {
    case 1:
    case 7:
    case 9:
      return !photo || !photo.url;
    case 2:
    case 8:
    case 10:
      if (subtype === 2) {
        return false;
      }

      return !video || (!video.urls.mp4 && !video.urls.webm && !video.urls.gif);
    case 3:
      return !sticker || !sticker.url;
    default:
      return false;
  }
};

const Answer = ({
  id,
  onFullscreen,
  onLoad,
  address,
  assets,
  type,
  sub_type,
  duplicate,
  created_at,
  approved_at,
  featured_at,
  rejected_at,
  comment,
  game,
  late,
  latitude,
  longitude,
  points,
  regenerate,
  review_key,
  share_location,
  task,
  team,
  trivia,
  user_id,
}) => {
  const { t } = useTranslation();
  const [status, setStatus] = useState("");
  const { game_id } = useParams();
  const { accept } = useAcceptMutation(id, review_key);
  const { reject } = useRejectMutation(id, review_key);
  const { feature } = useFeatureMutation(id, review_key);

  const onAccept = async (points, message) => {
    setStatus("");
    await accept({ message, points });
  };

  const onReject = async (points, message) => {
    setStatus("");
    await reject({ message, points });
  };

  const onFeature = async () => {
    setStatus("");
    await feature();
  };

  const onRegenerate = async () => {
    await axios.put(
      `${process.env.REACT_APP_API_REGENERATE_URL}`,
      { id },
    );
  };

  const audio = assets?.audios?.find((audio) => audio.name === "standard");
  const photo =
    type === 9
      ? assets?.photos?.find(
          (photo) => photo.name === "background-merged" && photo.url
        )
      : assets?.photos?.find((photo) => photo.name === "standard" && photo.url);
  const sticker = assets?.photos?.find(
    (photo) => photo.name === "background-sticker" && photo.url
  );
  const video =
    type === 10
      ? assets?.videos?.find((video) => video.name === "background-merged")
      : assets?.videos?.find((video) => video.name === "standard");
  const processing = isProcessing(type, sub_type, photo, video, sticker);

  return (
    <>
      <Card>
        <MDBCardHeader>
          <MDBCardTitle>
            <Row>
              <Col sm="4" lg="6">{task?.name}</Col>
              <ButtonCol sm="8" lg="6">
                {user_id && (
                  <>
                    <Button
                      color={approved_at ? "dark" : "light"}
                      onClick={() => setStatus("accept")}
                    >
                      <span>{rejected_at ? t("Approve") : t("Update")}</span>
                      <FontAwesomeIcon icon={faCheck} />
                    </Button>
                    <Button
                      color={rejected_at ? "dark" : "light"}
                      onClick={() => setStatus("reject")}
                    >
                      <span>{t('Reject')}</span>
                      <FontAwesomeIcon icon={faTimes} />
                    </Button>
                    <Button
                      color={featured_at ? "dark" : "light"}
                      disabled={!!featured_at}
                      onClick={onFeature}
                    >
                      <span>{t('Feature')}</span>
                      <FontAwesomeIcon icon={faStar} />
                    </Button>
                  </>
                )}
              </ButtonCol>
            </Row>
          </MDBCardTitle>
        </MDBCardHeader>
        <CardBody>
          <Row>
            <MediaCol size="12" lg="6">
              {processing ? (
                <AnswerMediaProcessing>
                  {t('Media is processing')}
                </AnswerMediaProcessing>
              ) : (
                <AnswerMedia
                  onLoad={onLoad}
                  audio={audio}
                  photo={photo}
                  video={video}
                />
              )}
            </MediaCol>
            <Col size="12" lg="6">
              {!!latitude && share_location !== "hide" && (
                <iframe
                  title={address}
                  width="100%"
                  height="100%"
                  frameBorder="0"
                  scrolling="no"
                  marginHeight="0"
                  marginWidth="0"
                  onLoad={onLoad}
                  style={{ minHeight: "100px" }}
                  src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_API_KEY}&q=${latitude},${longitude}`}
                />
              )}
            </Col>
          </Row>
          <Row>
            <AnswerCol>
              {comment && (
                <Alert color="primary">
                  <span>
                    {type === 4
                      ? t("QR Code scanned successfully.")
                      : `"${comment}"`}
                    {trivia === "correct" && (
                      <FontAwesomeIcon icon={faCheckCircle} />
                    )}
                    {trivia === "incorrect" && (
                      <FontAwesomeIcon icon={faTimesCircle} />
                    )}
                  </span>
                </Alert>
              )}
              <P dangerouslySetInnerHTML={{ __html: task.description }} />
            </AnswerCol>
            <AnswerCol>
              <p>
                {team.id ? (
                  <Link to={`/teams/stream/${game.id}/${team.id}`}>
                    {team.name}
                  </Link>
                ) : (
                  t("Anonymous")
                )}
                {game.name && (
                  <>
                    {t(" played ")}
                    &nbsp;
                    {task && (
                      <Link to={`/tasks/stream/${game.id}/${task.id}`}>
                        {task.name}
                      </Link>
                    )}
                    &nbsp;
                    {t(" challenge in ")}
                    &nbsp;
                    <Link to={`/games/stream/${game.id}`}>{game.name}</Link>
                    &nbsp;
                  </>
                )}
                {points === 1 ? t(" for 1 point.") : `${t(' for')} ${points} ${t('points')}.`}
                <br />
                <FontAwesomeIcon icon={faClock} />
                <UpdatedAt title={created_at}>
                  <Timeago time={created_at} />
                </UpdatedAt>
                {late === 2 && (
                  <span className="late text-danger">{t('Late Submission!')}</span>
                )}
                {late === 3 && (
                  <span className="late text-danger">{t('Early Submission!')}</span>
                )}
                <br />
                <Link
                  title={t("Share directly via this URL.")}
                  to={`/answers/show/${game_id}/${id}`}
                >
                  url
                </Link>{" "}
                {!!duplicate && "(duplicate)"}
                {regenerate && (
                  <>
                    <br />
                    <RegenerateButton
                      color="link"
                      onClick={onRegenerate}
                      title={t("Attempt to regenerate the media for this answer.")}
                    >
                      {t('Regenerate')}
                    </RegenerateButton>
                  </>
                )}
                <br />
                {audio?.url && (
                  <a
                    href={audio.url}
                    className="tools"
                    title={t("Download this video.")}
                  >
                    {t('Download')}
                  </a>
                )}
                {parseInt(type) === 2 && parseInt(sub_type) === 1 ? (
                  <a
                    href={video.urls.gif}
                    className="tools"
                    title={t("Download this video.")}
                  >
                    {t('Download')}
                  </a>
                ) : (
                  video?.urls.mp4 && (
                    <a
                      href={video.urls.mp4}
                      className="tools"
                      title={t("Download this video.")}
                    >
                      {t('Download')}
                    </a>
                  )
                )}
                {sticker?.url && (
                  <>
                    <a
                      href={sticker.url}
                      className="tools"
                      title={t("Download this sticker.")}
                    >
                      {t('Sticker')}
                    </a>
                  </>
                )}
              </p>
            </AnswerCol>
          </Row>
        </CardBody>
      </Card>
      {user_id && (
        <>
          <AcceptModal
            show={status === "accept"}
            onConfirm={onAccept}
            onDismiss={() => setStatus("")}
            currentPoints={points}
            defaultPoints={task?.points}
          />
          <RejectModal
            show={status === "reject"}
            onConfirm={onReject}
            onDismiss={() => setStatus("")}
          />
        </>
      )}
    </>
  );
};

export default Answer;
