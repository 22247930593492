import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import {
  MDBCard,
  MDBCardHeader,
  MDBCardTitle,
  MDBCardBody,
} from "mdb-react-ui-kit";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGameQuery } from "../settings/Settings.state";
import { axios } from "../lib/request";
import { Button, Row, Col } from "../App.styles";
import { Alert, RowInfo } from "../common";
import { FormSelect } from "../common/FormSelect";
import { SoundtrackModal } from "../settings/modals";

const ReelRow = styled(Row)`
  margin-top: 1rem;
`;

const ReelCol = styled(Col)`
  display: block;

  p {
    margin: 1rem 0;
  }

  ${Button} {
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: 1rem;
  }

  br + ${Button} {
    margin-left: 0;
    margin-top: 1rem;
  }

  video {
    width: 100%;
    height: 528px;
  }
`;

export const Soundtrack = styled.audio`
  min-width: 17.5rem;
`;

const ReelsForm = () => {
  const { t } = useTranslation();
  const { game_id } = useParams();
  const [game, setGame] = useGameQuery(game_id);
  const [alert, setAlert] = useState("");
  const audioRef = useRef();
  const { reel, soundtrack, task_reels, team_reels, user } = game;
  const [gameReelGenerated, setGameReelGenerated] = useState(false);
  const [taskReelGenerated, setTaskReelGenerated] = useState(false);
  const [teamReelGenerated, setTeamReelGenerated] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const generateReels = (url) => {
    axios.patch(`${process.env.REACT_APP_API_GENERATE_REELS_URL}/${url}`);
    setAlert(t("Reels have been queued for this game."));
  };

  useEffect(() => {
    if (isVideoPlaying) {
      localStorage.setItem("reels_playing", String(isVideoPlaying));
    } else {
      if (localStorage.getItem("reels_playing")) {
        localStorage.removeItem("reels_playing");
      }
    }
  }, [isVideoPlaying]);

  const setSoundtrack = async (soundtrack) => {
    setGame({ soundtrack });
    axios.put(`${process.env.REACT_APP_API_GAME_UPDATE_URL}/${game_id}.json`, {
      soundtrack,
    });

    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.load();
    }
  };

  return (
    <>
      <Row>
        <Alert color="info">{alert}</Alert>
      </Row>
      <Row>
        <Col className="col--stretch">
          <MDBCard className="flex-grow-1">
            <MDBCardHeader>
              <MDBCardTitle>{t('Automated Highlight Reels')}</MDBCardTitle>
            </MDBCardHeader>
            <MDBCardBody>
              <RowInfo help={<SoundtrackModal />}>
                <FormSelect
                    defaultValue=""
                    help={t("Select the soundtrack you would like used for your highlight reels.")}
                    helpClass="realign"
                    name="soundtrack"
                    label={t("Highlight Reels Soundtrack")}
                    value={soundtrack}
                    setValue={setSoundtrack}
                  >
                    <option value="">{t('No Soundtrack')}</option>
                    <option value="John_Bartmann_-_09_-_Happy_Clappy.m4a">
                      Happy Clappy {t('by')} John Bartmann
                    </option>
                    <option value="Scott_Holmes_-_04_-_Happy_Ukulele.m4a">
                      Happy Ukulele {t('by')} Scott Holmes
                    </option>
                    <option value="Scott_Holmes_-_07_-_Positive_and_Fun.m4a">
                      Positive and Fun {t('by')} Scott Holmes
                    </option>
                    <option value="Scott_Holmes_-_11_-_Happy_Ending.m4a">
                      Happy Ending {t('by')} Scott Holmes
                    </option>
                    <option value="Scott_Holmes_-_12_-_Feeling_Sunny.m4a">
                      Feeling Sunny {t('by')} Scott Holmes
                    </option>
                    <option
                      value="Scott_Holmes_-_01_-_Our_Big_Adventure.m4a"
                      selected="selected"
                    >
                      Our Big Adventure {t('by')} Scott Holmes
                    </option>
                  </FormSelect>
                  {!!soundtrack && (
                    <Soundtrack controls ref={audioRef}>
                      <source
                        type="audio/mp4"
                        src={`https://ss-video-support.s3.amazonaws.com/reels/${soundtrack}`}
                      />
                    </Soundtrack>
                  )}
              </RowInfo>
              <ReelRow>
                <ReelCol size="12">
                  <strong>{t('Game')}</strong>
                  <p>
                    {!!reel ? (
                      <>
                        <video
                          controls
                          preload="auto"
                          onEnded={() => setIsVideoPlaying(false)}
                          onPause={() => setIsVideoPlaying(false)}
                          onPlay={() => setIsVideoPlaying(true)}
                          id="reels-video"
                        >
                          <source src={reel} type="video/mp4" />
                        </video>
                        {user.super_admin && (
                          <Button
                            color="success"
                            disabled={gameReelGenerated}
                            onClick={() => {
                              setGameReelGenerated(true);
                              generateReels(`${game_id}/generate_reels.json`);
                            }}
                          >
                            {t('Regenerate')}
                          </Button>
                        )}
                      </>
                    ) : (
                      <>
                        {t('There is no game reel available. There must be at least 3')}{" "}
                        <Link to={`/answers/monitor/${game_id}`}>{t('featured')}</Link>{" "}
                        {t('submissions')}.
                      </>
                    )}
                  </p>
                </ReelCol>
                <ReelCol size="6">
                  <strong>{t('Teams')}</strong>
                  <p>
                    {team_reels.length ? (
                      <>
                        {team_reels.map(({ id, name, url }) => (
                          <>
                            <a key={id} href={url}>
                              {name}
                            </a>
                            <br />
                          </>
                        ))}
                        {user.super_admin && (
                          <Button
                            color="success"
                            disabled={teamReelGenerated}
                            onClick={() => {
                              setTeamReelGenerated(true);
                              generateReels(
                                `${game_id}/generate_reels.json?reel_type=teams`
                              );
                            }}
                          >
                            {t('Regenerate')}
                          </Button>
                        )}
                      </>
                    ) : (
                      <>{t('There are no team reels available.')}</>
                    )}
                  </p>
                </ReelCol>
                <ReelCol size="6">
                  <strong>{t('Challenges')}</strong>
                  <p>
                    {task_reels.length ? (
                      <>
                        {task_reels.map(({ id, name, url }) => (
                          <>
                            <a key={id} href={url}>
                              {name}
                            </a>
                            <br />
                          </>
                        ))}
                        {user.super_admin && (
                          <Button
                            color="success"
                            disabled={taskReelGenerated}
                            onClick={() => {
                              setTaskReelGenerated(true);
                              generateReels(
                                `${game_id}/generate_reels.json?reel_type=challenges`
                              );
                            }}
                          >
                            {t('Regenerate')}
                          </Button>
                        )}
                      </>
                    ) : (
                      <>{t('There are no challenge reels available.')}</>
                    )}
                  </p>
                </ReelCol>
              </ReelRow>
            </MDBCardBody>
          </MDBCard>
        </Col>
      </Row>
    </>
  );
};

export default ReelsForm;
