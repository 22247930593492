import React, { useState } from "react";
import styled from "styled-components";
import { Autocomplete } from "@material-ui/lab";
import { TextField, Checkbox } from "@material-ui/core";
import statefulField from "./StatefulField";
import { DescText } from "../App.styles";

const FieldWrapper = styled.div`
  ${({ theme }) => `
    position: relative;
    width: 100%;

    > .form-label {
      margin-bottom: .3rem;
    }

    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] {
      padding: 5px 6px;
    }

    .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot {
      background: ${theme.white};
      border: 1px solid ${theme.darkGreyColor}70;

      .MuiOutlinedInput-notchedOutline {
        border-color: transparent;
      }
    }
  `}
`;

const StyledAutocomplete = styled(Autocomplete)`
  [class*=PrivateNotchedOutline-root-],
  [class*=MuiOutlinedInput-notchedOutline] {
    top: 2px;
  }

  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-outlined.Mui-focused,
  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-outlined.MuiFormLabel-filled {
    padding: 4px;
    background: #fff;
  }
`

const BaseAutoComplete = ({
  help,
  desc,
  label,
  placeholder,
  options,
  setValue,
  onChange,
  value,
  mapValue = 'id',
  mapLabel = 'name',
  defaultText,
  multiple,
  mode = 'outline',
  ...autocompleteProps
}) => {
  const defaultOption = {
    [mapValue]: null,
    [mapLabel]: defaultText
  }
  const validOption = option => {
    const invalid = [null, undefined]
    return (
      invalid.includes(option?.[mapValue]) &&
      option?.[mapLabel] === defaultText
    ) || 
      (
        !invalid.includes(option?.[mapValue]) && 
        !invalid.includes(option?.[mapLabel])
      )
  }
  
  const getOptionSelected = (option, value) => {
    return String(option?.[mapValue]) === String(value?.[mapValue]) ||
        String(option?.[mapValue]) === String(value) ||
        String(value?.[mapValue]) === ""
  }
  
  const getOptionDisabled = (option) => {
    return option?.disabled
  }

  const optionsWithDefault = [
    defaultOption,
    ...options,
  ]
  
  const multipleValues = multiple ? (value || []).map(v => v?.[mapValue]) : []
  const currentValue = multiple
    ? options.filter(opt => multipleValues.includes(opt?.[mapValue]))
    : options.find(opt => getOptionSelected(opt, value))
  const fallbackValue = defaultText ? optionsWithDefault[0] : null
  const defaultValueProps = {
    options: (defaultText ? optionsWithDefault : options).filter(validOption),
    defaultValue: ![null, undefined].includes(currentValue)
      ? currentValue
      : fallbackValue
  }
  const [inputValue, setInputValue] = useState('')
  const conditionalRender = (options.length > 0 && multiple) || !multiple

  return conditionalRender
    ? (
      <FieldWrapper className={`form-${mode}`}>
          {
            mode === 'outline'
              ? null
              : (
                <label className="form-label">{label}</label>
              )
          }
          <StyledAutocomplete
              {...autocompleteProps}
              {...defaultValueProps}
              onChange={(e, v) => {
                const stateUpdate = setValue ? () => setValue(v?.[mapValue]) : null
                const callback = onChange || stateUpdate
                callback && callback(e, v)
              }}
              clearOnBlur={false}
              disableClearable={true}
              getOptionLabel={(option) => {
                  return option?.[mapLabel] || defaultText || ''
              }}
              getOptionSelected={getOptionSelected}
              getOptionDisabled={getOptionDisabled}
              renderInput={(params) => (
                  <TextField 
                      {...params} 
                      defaultValue={defaultText}
                      label={mode === 'outline' ? label : undefined}
                      placeholder={placeholder}
                      variant="outlined" 
                  />
              )}
              multiple={multiple}
              data-testid={`form-autocomplete${multiple ? '-multiple' : ''}`}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              onBlur={() => {
                if (!multiple && inputValue === '' && (defaultValueProps?.defaultValue?.[mapLabel] || defaultText)) {
                  setInputValue(defaultValueProps?.defaultValue?.[mapLabel] || defaultText)
                }
              }}
          />
          {
            desc
              ? <DescText>{desc}</DescText>
              : null
          }
      </FieldWrapper>
    )
    : null;
}

export const FormAutocomplete = ({ stateful = true, ...baseProps }) => {
  return stateful
    ? statefulField(BaseAutoComplete)(baseProps)
    : <BaseAutoComplete {...baseProps} />
};

export const FormAutocompleteMultiple = ({
  ...autocompleteProps
}) => {
  const { mapLabel = 'name' } = autocompleteProps

  return (
    <FormAutocomplete
      {...autocompleteProps}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox
            checked={selected}
          />
          {option[mapLabel]}
        </React.Fragment>
      )}
      multiple
    />
  )
}

export default FormAutocomplete;
