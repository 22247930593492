import styled from "styled-components";

const Overlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  z-index: 2500;
  justify-content: center;
  align-items: center;

  img,
  video {
    max-width: 100vw;
    max-height: 100vh;
    width: auto;
    height: auto;
  }
`;

const MediaOverlay = ({ onClick, photo_url, mp4_url, webm_url }) => {
  if (mp4_url) {
    return (
      <Overlay onClick={onClick}>
        <video
          className="video-js vjs-default-skin"
          controls
          preload="none"
          poster={photo_url}
        >
          <source src={mp4_url} type="video/mp4" />
          {webm_url && <source src={webm_url} type="video/webm" />}
        </video>
      </Overlay>
    );
  }

  return (
    <Overlay onClick={onClick}>
      <img alt="" src={photo_url} />
    </Overlay>
  );
};

export default MediaOverlay;
