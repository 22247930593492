import Error from "./Error";
import LinkButton from "./LinkButton";

const ServerError = () => {
  return (
    <Error
      title="Internal server error"
      message="The builder is currently having problem serving your request. Hit the button below to refresh."
      button={
        <LinkButton color="primary" to="/">
          Refresh
        </LinkButton>
      }
    />
  );
};

export default ServerError;
