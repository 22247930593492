import { useState } from "react";
import styled from "styled-components";
import { MDBCard } from "mdb-react-ui-kit";
import TeamEdit from "./TeamEdit";
import TeamView from "./TeamView";
import HighlightList from "../common/HighlightList";

export const TeamCard = styled(MDBCard)`
  margin-bottom: 1.25rem;
`;

const Team = ({ scrollId, ...props }) => {
  const [edit, setEdit] = useState(false);

  return (
    <HighlightList scrollId={scrollId} id={props.id}>
      <TeamCard>
        {edit ? (
          <TeamEdit onClose={() => setEdit(false)} {...props} />
        ) : (
          <TeamView onEdit={() => setEdit(true)} {...props} />
        )}
      </TeamCard>
    </HighlightList>
  );
};

export default Team;
