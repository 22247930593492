import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { useCreateMutation } from "./Settings.state";
import { useUserQuery } from "../monitor/User.state";
import Timezone from "./Timezone";
import {
  FormSelect,
  Input,
  RadioGroup,
  Spinner,
  TimingSection,
  RowInfo,
} from "../common";
import { Button, ButtonWrapper, Col, Row } from "../App.styles";
import {
  CategoryModal,
  ChallengeListModal,
  ChatModal,
  GameModeModal,
  LateSubmissionsModal,
  LeaderboardModal,
  ModerationModal,
  PlayerExportsModal,
  PromotionModal,
  SearchModal,
  SharingLocationsModal,
  TeamsModal,
  TimeToCompleteModal,
  TimezonesModal,
  UnlockStyleModal,
} from "./modals";
import axios from "axios";
import WebhookModal from "./modals/WebhookModal";
import { useMemo } from "react";

const TimingWrapper = styled.div`
  height: auto;
  max-height: ${({ show }) => (show ? "400px" : "0")};
  transition: max-height linear 0.5s;
  overflow: hidden;
`;

const Optional = ({ useQuery }) => {
  const { t } = useTranslation()
  const { game_id } = useParams();
  const history = useHistory();
  const { isCreating, create } = useCreateMutation();
  const { super_admin } = useUserQuery();

  const [{ finish_at, start_anytime, start_at, tz }] = useQuery();
  const date =
    start_at || finish_at ? new Date(start_at || finish_at) : new Date();

  const onCreate = async () => {
    const { data } = await create();
    history.push(`/tasks/organize/${data.id}`);
  };

  function validURL(str) {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" +
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
        "((\\d{1,3}\\.){3}\\d{1,3}))" +
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
        "(\\?[;&a-z\\d%_.~+=-]*)?" +
        "(\\#[-a-z\\d_]*)?$",
      "i"
    );
    return !!pattern.test(str);
  }

  const validateWebhook = (e) => {
    const promise = new Promise((resolve, reject) => {
      if (!validURL(e)) {
        resolve({ error: "Webhook URL is not a valid URL." });
      } else if (e.split(":")[0] !== "https") {
        resolve({ error: "Webhook URL must use HTTPS." });
      } else {
        axios
          .post(e, {})
          .then(() => {
            resolve({ success: "Updated Webhook" });
          })
          .catch(() => {
            resolve({
              error: "Webhook is throwing errors to our post requests.",
            });
          });
      }
    });

    return promise;
  };

  const showTimingWrapper = useMemo(() => {
    return start_anytime === null || parseInt(start_anytime) === 0
  }, [start_anytime])

  return (
    <>
      <RowInfo help={<GameModeModal />}>
        <RadioGroup
            defaultValue={1}
            label="Game Mode"
            name="start_anytime"
            options={[
              { value: 0, label: t('Scheduled') },
              { value: 2, label: t('Stopped') },
            ]}
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo rowClassName={showTimingWrapper ? '' : 'd-none'}>
        <TimingWrapper
          show={showTimingWrapper}
        >
          <TimingSection
            startHelp={t('If you set a start date, challenges will be hidden until then.')}
            startLabel={t('Start Date and Time')}
            finishHelp={t('If you set a finish date, challenges will be hidden afterwards.')}
            finishLabel={t('Finish Date and Time')}
            startName="start_at"
            finishName="finish_at"
            timezone={tz}
            useQuery={useQuery}
          />
        </TimingWrapper>
      </RowInfo>
      <RowInfo rowClassName="force-vspace" help={<TimezonesModal />}>
        <Timezone
            date={date}
            label="Timezone"
            name="tz"
            offsetName="time_zone"
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<TimeToCompleteModal />}>
        <Input
            help={t('This allows you to cap the length of a game from when a team starts playing.')}
            label={t('Time to Complete')}
            model="games"
            name="time_to_play"
            useQuery={useQuery}
          />
      </RowInfo>
      {super_admin && (
        <RowInfo help={<PromotionModal />}>
          <RadioGroup
              defaultValue={1}
              label="Promotion"
              name="game_type"
              options={[
                { value: 1, label: "Standard" },
                { value: 2, label: "Featured (no password required)" },
                { value: 3, label: "Featured (require password)" },
              ]}
              useQuery={useQuery}
            />
        </RowInfo>
      )}
      <RowInfo help={<SearchModal />}>
        <RadioGroup
            defaultValue={true}
            label="Search"
            name="searchability"
            options={[
              { value: true, label: "Standard" },
              { value: false, label: "Hidden" },
            ]}
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<TeamsModal />}>
        <RadioGroup
            defaultValue={false}
            label="Teams"
            name="join_existing_team"
            options={[
              { value: false, label: "Single Device" },
              { value: true, label: "Multiple Devices" },
            ]}
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<LeaderboardModal />}>
        <RadioGroup
            defaultValue={true}
            label="Leaderboard"
            name="leaderboard_visible"
            options={[
              { value: true, label: "Visible" },
              { value: false, label: "Hidden" },
            ]}
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<ChatModal />}>
        <RadioGroup
            defaultValue={true}
            label="Chat"
            name="notifications_bidirectional"
            options={[
              { value: true, label: "Enabled" },
              { value: false, label: "Disabled" },
            ]}
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<CategoryModal />}>
        <RadioGroup
            label={t('Category')}
            name="category"
            options={[
              { value: "Active", label: "Active" },
              { value: "Draft", label: "Draft" },
              { value: "Template", label: "Template" },
              { value: "Archive", label: "Archive" },
            ]}
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<UnlockStyleModal />}>
        <RadioGroup
            defaultValue={false}
            label="Unlock Style"
            name="unlock_requires_network"
            options={[
              { value: false, label: "Offline" },
              { value: true, label: "Online" },
            ]}
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<ChallengeListModal />}>
        <RadioGroup
            defaultValue={false}
            label="Challenge List"
            name="shuffled"
            options={[
              { value: false, label: "Ordered" },
              { value: true, label: "Shuffled" },
            ]}
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<ModerationModal />}>
        <FormSelect
            defaultValue="post"
            help="Moderation affects how submissions are released to the stream for other players to see."
            name="review"
            label="Moderation"
            useQuery={useQuery}
          >
            <option value="pre">Pre-stream</option>
            <option value="post">Post-stream</option>
          </FormSelect>
      </RowInfo>
      <RowInfo help={<LateSubmissionsModal />}>
        <FormSelect
            help={t('Applicable only if your game or challenge is restricted by time.')}
            name="late"
            label="Late Submissions"
            useQuery={useQuery}
          >
            <option value=""></option>
            <option value="reject">Reject</option>
            <option value="approve">Approve</option>
          </FormSelect>
      </RowInfo>
      <RowInfo help={<PlayerExportsModal />}>
        <FormSelect
            help={t('A popular option for those wanting their players to be able to take home their photography.')}
            name="export_player_enabled"
            label="Player Exports"
            useQuery={useQuery}
          >
            <option value={false}>No, Administrators only</option>
            <option value={true}>Yes</option>
          </FormSelect>
      </RowInfo>
      <RowInfo help={<SharingLocationsModal />}>
        <FormSelect
            help={t('Fine tune the privacy of locations.')}
            label={t('Share player locations')}
            name="share_location"
            useQuery={useQuery}
          >
            <option value={false}>No, withold player locations.</option>
            <option value={true}>Yes, include maps.</option>
          </FormSelect>
      </RowInfo>
      <RowInfo help={<WebhookModal />}>
        <Input
            help={t('You can have all our platform game events sent to your own server and application')}
            label="Webhook URL"
            model="games"
            name="webhook"
            maxLength={1024}
            validation={(e) => validateWebhook(e)}
            useQuery={useQuery}
          />
      </RowInfo>
      <Row>
        <Col>
          <ButtonWrapper>
            {game_id === "new" && (
              <Button color="primary" onClick={onCreate}>
                Save{" "}
                {isCreating ? (
                  <Spinner size="0.875rem" />
                ) : (
                  <FontAwesomeIcon icon={faAngleDoubleRight} />
                )}
              </Button>
            )}
          </ButtonWrapper>
        </Col>
      </Row>
    </>
  );
};

export default Optional;
