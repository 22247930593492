import { useCallback, useState } from "react";
import { useRecoilState } from "recoil";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, HeaderRow, Row, Col } from "../App.styles";
import { Pagination, SidebarLayout, Spinner } from "../common";
import { Alert, Refresher } from "../common";
import Datalist from "./Datalist";
import DatalistItem from "./DatalistItem";
import { DatalistHeader } from "./Datalist.style";

const DatalistItems = ({ paginated = false, sidebar, partials, states, mutations, queries, cards, errors, refresher, dataId }) => {
  const { t } = useTranslation();
  const { game_id } = useParams();
  const { isCreating, create } = mutations.create;
  const { isDeleting, destroy } = mutations.destroy;
  const { isFetching } = queries.fetching;
  const listData = queries.list;
  const pages = queries.pages;
  const [{ color, message }] = useRecoilState(states.notification);
  const [, setDeleteId] = useRecoilState(states.deleteId);
  const [page, setPage] = useRecoilState(states.page);
  const [pageSize, setPageSizeState] = useRecoilState(states.pageSize);
  const [scrollId, setScrollId] = useState(0);
  const setPageSize = pageSize => {
    setPageSizeState(Number(pageSize))
  }
  const onCreate = useCallback(async () => {
    const updatePage = (currentPage) => {
      if (Number(currentPage) !== Number(page)) {
        setPage(currentPage)
      }
    }
    try {
      const { data } = await create();
      if (data?.meta?.page) updatePage(data.meta.page)
      if (data[dataId]?.id) setScrollId(data[dataId].id);
    } catch (e) {
      errors?.onCreate && errors.onCreate(e)
    }
  }, [create, page, setPage, dataId, errors]);

  return (
    <>
      <Row>
        <Col size="12">
          <Alert color={color}>{message}</Alert>
        </Col>
      </Row>
      <HeaderRow>
        <DatalistHeader className="col-12 col-xl-9">
          <Button color="primary" onClick={onCreate} disabled={isCreating}>
            {t('Add New')} {isCreating && <Spinner size="0.875rem" />}
          </Button>
        </DatalistHeader>
      </HeaderRow>
      <Row>
        <SidebarLayout
          message={sidebar.message}
          panel={sidebar.panel}
        >
          <Datalist>
            {
              listData.sort((a, b) => {
                const first = (a.name || a.label || '').toLocaleLowerCase();
                const second = (b.name || b.label || '').toLocaleLowerCase();
      
                if (first === second) {
                  return 0;
                }
      
                if (first < second) {
                  return -1;
                }
      
                return 1;
              }).map((row, index) => (
                <DatalistItem
                  index={index}
                  key={row.id}
                  {...row}
                  deleteSection={
                    partials?.delete
                      ? partials.delete({
                        confirmAction: async () => {
                          await setDeleteId(row.id)
                          destroy()
                        },
                        isLoading: isDeleting,
                        t,
                      })
                      : null
                  }
                  game_id={game_id}
                  scrollId={scrollId}
                  cards={cards}
                  mutations={mutations}
                  queries={queries}
                />
              ))
            }
          </Datalist>
        </SidebarLayout>
      </Row>
      {
        paginated
          ? (
            <Row>
              <Pagination
                loading={isFetching}
                page={page}
                pageSize={pageSize}
                pages={pages}
                setPage={setPage}
                setPageSize={setPageSize}
              />
            </Row>
          )
          : null
      }
      <Refresher subscriptions={refresher.subscriptions} />
    </>
  );
};

export default DatalistItems;
