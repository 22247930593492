import { atom, useRecoilValue, useRecoilState } from "recoil";
import { urlSyncEffect } from "recoil-sync";
import { number } from "@recoiljs/refine";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { axios } from "../lib/request";
import { groupsQueryKey } from "../lib/refresh";
import { MAX_PER_PAGE } from "../consts/request";

export const deleteIdState = atom({
  key: "groups/deleteId",
  default: 0,
});

export const notificationState = atom({
  key: "groupsNotification",
  default: {},
});

export const pageState = atom({
  key: "groups/page",
  default: 1,
  effects: [
    urlSyncEffect({
      refine: number(),
      history: 'push'
    })
  ],
});

export const pageSizeState = atom({
  key: "groups/pageSize",
  default: MAX_PER_PAGE,
  effects: [
    urlSyncEffect({
      refine: number(),
      history: 'push'
    })
  ],
});

export const fetchGroups = async (game_id, page, per_page) => {
  if (game_id === "new") {
    return { groups: [] };
  }

  const response = await axios({
    url: process.env.REACT_APP_API_GROUPS_RETRIEVE_URL,
    params: { game_id, page, per_page },
  });
  if (response?.data?.groups) {
    response.data.groups = response.data.groups.map(g => {
      if (g?.teams?.length) {
        g.teams = g.teams.sort(teamByName)
      }
      return g
    })
  }
  return response.data;
};

export const useGroupsDataQuery = (game_id) => {
  const page = useRecoilValue(pageState);
  const pageSize = useRecoilValue(pageSizeState);
  const queryKey = useQueryKey(game_id);

  return useQuery(queryKey, () => fetchGroups(game_id, page, pageSize), {
    suspense: true,
  });
};

const useQueryKey = (game_id) => {
  const page = useRecoilValue(pageState);
  const pageSize = useRecoilValue(pageSizeState);
  return groupsQueryKey(game_id, page, pageSize);
};

export const useGroupsQuery = () => {
  const { game_id } = useParams();
  const { data } = useGroupsDataQuery(game_id);
  return data.groups;
};

export const useGroupsFetchingQuery = () => {
  const { game_id } = useParams();
  return useGroupsDataQuery(game_id);
};

export const useGroupsPagesQuery = () => {
  const { game_id } = useParams();
  const { data } = useGroupsDataQuery(game_id);
  return data.pages;
};

export const useCreateMutation = () => {
  const { game_id } = useParams();
  const queryClient = useQueryClient();
  const queryKey = useQueryKey(game_id);
  const { data } = useGroupsDataQuery(game_id);
  const pageSize = useRecoilValue(pageSizeState);

  const { isLoading, mutateAsync } = useMutation(
    () => axios.post(process.env.REACT_APP_API_GROUPS_CREATE_URL, { game_id, per_page: pageSize }),
    {
      onSuccess: ({ data: group }) => {
        const groups = [group.group, ...data.groups];
        const uri = groupsQueryKey(game_id);

        queryClient.invalidateQueries(uri);
        queryClient.setQueryData(queryKey, {
          ...data,
          groups,
        });
      },
    }
  );

  return { isCreating: isLoading, create: mutateAsync };
};

export const useDestroyMutation = () => {
  const { game_id } = useParams();
  const [deleteId, setDeleteId] = useRecoilState(deleteIdState);
  const queryClient = useQueryClient();
  const queryKey = useQueryKey(game_id);

  const { isLoading, mutate } = useMutation(
    ({ groupId, ...payload } = {}) => {
      const actionId = ![null, undefined].includes(groupId)
        ? groupId
        : deleteId
      return axios.delete(
        `${process.env.REACT_APP_API_GROUPS_DELETE_URL}/${actionId}.json`,
        { data: { ...payload, game_id } },
      )
    },
    {
      onSettled: () => setDeleteId(0),
      onSuccess: () => queryClient.invalidateQueries(groupsQueryKey(game_id)),
      onMutate: ({ team_id } = {}) => {
        const data = queryClient.getQueryData(queryKey);
        const groups = data.groups.filter((group) => {
          return (group.teams || []).length > 0
            ? true
            : group.id !== deleteId
        }).map(group => {
          if (team_id) {
            group.teams = group.teams.filter(({ id }) => id !== team_id)
          }
          return group
        });

        queryClient.setQueryData(queryKey, { ...data, groups });
      },
    }
  );

  return { isDeleting: isLoading, destroy: mutate };
};

export const teamByName = (a, b) => {
  const aName = (a.name || '').toLowerCase();
  const bName = (b.name || '').toLowerCase();

  if (aName === bName) {
    return 0;
  }

  return aName < bName ? -1 : 1;
}

export const useUpdateMutation = () => {
  const { game_id } = useParams();
  const queryClient = useQueryClient();
  const queryKey = useQueryKey(game_id);
  const { data } = useGroupsDataQuery(game_id);
  const updateGroup = ({ groupId, ...payload }) => {
    return data.groups.map(group => {
      if (group.id === groupId) {
        for (const key in payload) {
          group[key] = payload?.[key]
        }
      }
      group.teams = group.teams.sort(teamByName);
      return group
    });
  }

  const { isLoading, mutateAsync } = useMutation(
    ({ groupId, ...payload }) => axios.put(`${process.env.REACT_APP_API_GROUPS_UPDATE_URL}/${groupId}.json`, { 
      game_id, ...payload,
    }),
    {
      onMutate: async group => {    
        const previousGroups = queryClient.getQueryData(queryKey)
        const groups = updateGroup(group);
        queryClient.setQueryData(queryKey, {
          ...data,
          groups,
        });
        return { previousGroups, group }
      },
      onError: (err, group, context) => {
        queryClient.setQueryData(
          queryKey,
          context.previousGroups,
        )
      },
      onSuccess: (_, { groupId, ...payload }) => {
        const groups = updateGroup({ groupId, ...payload });
        const uri = groupsQueryKey(game_id);

        queryClient.invalidateQueries(uri);
        queryClient.setQueryData(queryKey, {
          ...data,
          groups,
        });
      },
    }
  );

  return { isUpdating: isLoading, update: mutateAsync };
};