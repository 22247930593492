import styled from "styled-components";
import React, { Suspense, useState } from "react";
import { useQueryClient, useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { useAuthUser } from "react-auth-kit";
import { useTranslation } from "react-i18next";
import { useInfiniteChallenges } from "../organize/Organize.state";
import { useInfinitePlayers } from "../teams/Teams.state";
import { useGameQuery } from "../settings/Settings.state";
import {
  pageState,
  pageSizeState,
  challengeIdState,
  monitorQueueState,
  teamIdState,
  useAnswersQuery,
  useAnswersFetchingQuery,
  useAnswersLoadingQuery,
  useMonitorRefresh,
  usePagesQuery,
  useStartGameMutation,
  useStopGameMutation,
} from "./Monitor.state";
import { useUserQuery } from "./User.state";
import { Button, Row, Col } from "../App.styles";
import {
  Alert,
  Loadable,
  Pagination,
  Refresher,
  SidebarLayout,
  Spinner,
} from "../common";
import { FormAutocomplete } from "../common/FormAutocomplete";
import MediaOverlay from "./MediaOverlay";
import { axios } from "../lib/request";
import { leaderboardQueryKey } from "../lib/refresh";
import Answer from "./Answer";
import LeaderboardWidget from "./LeaderboardWidget";
import MonitorPlaceholder from "./MonitorPlaceholder";
import { ConfirmationModal, withPlaceholder } from "../common";
import { Shimmer } from "../common/Loading.styles";

const HeaderRow = styled(Row)`
  ${({ theme }) => `
    margin-bottom: 1rem;

    .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-outlined.Mui-focused, 
    .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-outlined.MuiFormLabel-filled {
      background-color: ${theme.lightColor};
    }
  `}
`;

const DeleteButton = styled(Button)`
  min-width: 8rem;
`;

const GameButton = styled(Button)`
  min-width: 8rem;
`;

const AlertWrapper = styled.span`
  ${Button} {
    margin-right: 1rem;
  }
`;

const ChallengeSelect = ({ setValue, value }) => {
  const { t } = useTranslation();
  const { tasks: challenges, isLoading } = useInfiniteChallenges();

  return isLoading
    ? (
      <Shimmer type="title" />
    )
    : (
      <FormAutocomplete
        options={challenges}
        disableClearable={true}
        size="small"
        defaultText={t("All Challenges")}
        value={value}
        onChange={(e, v) => setValue(v?.id)}
        stateful={false}
        label={t("Challenges")}
      />
    );
};

const TeamSelect = ({ setValue, value }) => {
  const { t } = useTranslation();
  const { teams = [], isLoading } = useInfinitePlayers();

  return isLoading
    ? (
      <Shimmer type="title" />
    )
    : (
      <FormAutocomplete
        options={
          teams
            .slice()
            .sort(({ name }) => name)
        }
        disableClearable={true}
        size="small"
        defaultText={t("All Teams")}
        value={value}
        onChange={(e, v) => setValue(v?.id)}
        stateful={false}
        label={t("Teams")}
      />
    );
};

const Monitor = () => {
  const { t } = useTranslation();
  const { game_id } = useParams();
  const { user_id, user_type } = useAuthUser()();
  const answers = useAnswersQuery(game_id);
  const [overlayId, setOverlayId] = useState();
  const pages = usePagesQuery(game_id);
  const [page, setPage] = useRecoilState(pageState);
  const [pageSize, setPageSizeState] = useRecoilState(pageSizeState);
  const [challengeId, setChallengeId] = useRecoilState(challengeIdState);
  const [teamId, setTeamId] = useRecoilState(teamIdState);
  const [game] = useGameQuery(game_id);
  const { game: gameRole } = useUserQuery(game_id);
  const { administrator, coordinator, super_admin } = gameRole;
  const queryClient = useQueryClient();
  const { startGame, isStarting } = useStartGameMutation();
  const { stopGame, isStopping } = useStopGameMutation();
  const isFetching = useAnswersFetchingQuery(game_id);
  const isLoading = useAnswersLoadingQuery(game_id);
  const monitorQueue = useRecoilValue(monitorQueueState(game_id));
  const refresh = useMonitorRefresh(game_id);

  const setPageSize = pageSize => {
    setPageSizeState(Number(pageSize))
  }

  const { isLoading: isDeletingAll, mutateAsync: deleteAll } = useMutation(
    () =>
      axios.delete(
        `${process.env.REACT_APP_API_EMPTY_URL}/${game_id}/empty.json`
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(leaderboardQueryKey(game_id));
        queryClient.invalidateQueries(["monitor"]);
        queryClient.invalidateQueries(["stream"]);
      },
    }
  )

  const set = (setter) => (value) => {
    setter(value);
    setPage(1);
  };

  return (
    <>
      {!isFetching && !!monitorQueue && (
        <Row>
          <Alert color="success">
            <AlertWrapper>
              <Button color="success" onClick={refresh}>
                {t('Refresh')}
              </Button>
              {(() => {
                switch (monitorQueue) {
                  case 1:
                    return t("This is 1 new submission in the queue.");
                  default:
                    return `${t('There are')} ${monitorQueue} ${t('new submissions in the queue')}.`;
                }
              })()}
            </AlertWrapper>
          </Alert>
        </Row>
      )}
      <Row>
        <SidebarLayout
          message={t("If you are moderating your game you will need to approve, reject or feature submissions.")}
          panel={
            <Suspense fallback={<Spinner />}>
              <LeaderboardWidget showVisibilityToggle={true} />
            </Suspense>
          }
        >
          <HeaderRow>
            <Col size="12" lg="6" className="col--stretch">
              <Row className="flex-grow-1">
                <Col size="6" className="col--stretch">
                  <ChallengeSelect
                    value={challengeId}
                    setValue={set(setChallengeId)}
                  />
                </Col>
                <Col size="6" className="col--stretch">
                  <TeamSelect value={teamId} setValue={set(setTeamId)} />
                </Col>
              </Row>
            </Col>
            <Col size="12" lg="6" className="col--stretch">
              <Row className="flex-grow-1 mt-2 mt-lg-0">
                <Col size="6" sm="4" md="6" className="col--stretch">
                  {game?.start_anytime === 2 ? (
                    <GameButton className="w-100" color="success" onClick={startGame}>
                      {t('Start Game')} {isStarting && <Spinner size="0.875rem" />}
                    </GameButton>
                  ) : (
                    <ConfirmationModal
                      title={t("Stop Game" )}
                      body={t("Are you sure you would like to stop this game? <br>Once this is done, challenges will be unavailable and hidden from players.")}
                      confirmLabel={t("Yes, stop game")}
                      confirmAction={stopGame}
                      isLoading={isStopping}
                    >
                      <GameButton className="w-100" color="danger">
                        {t('Stop Game')} {isStopping && <Spinner size="0.875rem" />}
                      </GameButton>
                    </ConfirmationModal>
                  )}
                </Col>
                <Col size="6" sm="4" md="6" className="col--stretch">
                  {game?.user?.super_admin && (
                    <ConfirmationModal 
                      title={t("Delete All" )}
                      body={t("Are you sure you would like to permanently delete all submissions in this game? <br>Once this is done, you will not be able to get them back.")}
                      confirmLabel={t("Yes, delete all")}
                      confirmAction={deleteAll}
                      isLoading={isDeletingAll}
                    >
                      <DeleteButton className="w-100" color="danger">
                        {t('Delete All')}
                      </DeleteButton>
                    </ConfirmationModal>
                  )}
                </Col>
              </Row>
            </Col>
          </HeaderRow>
          <Row>
            {pages > 0 && (
              <Pagination
                loading={isLoading}
                page={page}
                pageSize={pageSize}
                pages={pages}
                setPage={setPage}
                setPageSize={setPageSize}
              />
            )}
          </Row>
          {answers.length ? (
            answers.map((answer) => (
              <Row key={answer.id}>
                <Answer
                  {...answer}
                  onFullscreen={setOverlayId}
                  user_id={user_id}
                  user_type={user_type}
                  game_id={game_id}
                  regenerate={
                    (super_admin || administrator || coordinator)
                  }
                />
              </Row>
            ))
          ) : (
            <Loadable loading={isLoading}>
              <Alert color="warning">{t('There are no submissions to review.')}</Alert>
            </Loadable>
          )
          }
        </SidebarLayout>
      </Row>
      <Row>
        {pages > 0 && (
          <Pagination
            loading={isLoading}
            page={page}
            pageSize={pageSize}
            pages={pages}
            setPage={setPage}
            setPageSize={setPageSize}
          />
        )}
      </Row>
      {overlayId && (
        <MediaOverlay
          {...answers.find((answer) => answer.id === overlayId)}
          onClick={() => setOverlayId(undefined)}
        />
      )}
      <Refresher />
    </>
  );
};

export default withPlaceholder(Monitor, MonitorPlaceholder);
