import { useQuery } from "react-query"
import { useParams } from "react-router-dom"
import { analyticsQueryKey } from "../lib/refresh"
import { axios } from "../lib/request"


export const fetchTeamAnalytics = async (game_id) => {
    const response = await axios({
        url: process.env.REACT_APP_API_ANALYTICS_TEAMS_URL,
        params: { game_id },
    })
    return response?.data
}

export const useAnalyticsQuery = () => {
    const { game_id } = useParams();
    return useQuery(
        analyticsQueryKey(game_id),
        () => fetchTeamAnalytics(game_id),
    )
}