import { useTranslation } from "react-i18next";
import { Modal } from "../../common";
import { Button } from "../../App.styles";

const ModerationModal = ({ onDismiss, show }) => {
  const { t } = useTranslation();

  return (
    <Modal onDismiss={onDismiss} show={show}>
      <div className="modal-header">
        <h4 className="modal-title">{t(`Moderation`)}</h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-hidden="true"
          onClick={onDismiss}
        >
          ×
        </button>
      </div>
      <div className="modal-body">
        <p>
          {t(`Challenges may be reviewed via email, or the game builder, before they
          go out on the public stream, after they go out, or not at all.`)}
        </p>
        <ul>
          <li>
            {t(`Pre-stream means a moderator must manually approve a submission before
            points are granted and it is displayed in the streams.`)}
          </li>
          <li>
            {t(`Post-stream means by default submissions are accepted and only a
            manual rejection will remove them from the streams and remove points.`)}
          </li>
          <li>
            {t(`Submissions are always received by the system, moderation gives you
            fine control over dealing with them as they arrive.`)}
          </li>
        </ul>
      </div>
      <div className="modal-footer">
        <Button color="dark" data-dismiss="modal" onClick={onDismiss}>
          {t(`Close`)}
        </Button>
      </div>
    </Modal>
  )
};

export default ModerationModal;
