import { axios } from "../lib/request";

export const verifyUser = async email => {
    let returnVerification;
    try {
        const response = await axios({
            url: process.env.REACT_APP_API_VERIFY_USER_URL,
            params: {
                email,
            }
        })
        returnVerification = response.data
    } catch (e) {
        returnVerification = null
    }
    return returnVerification;
}

export const createUser = params => {
    return axios.post("https://app.socialscavenger.com/v1/users.json", params)
}