import styled from "styled-components";

export const FlowContainer = styled.div`
    ${({ theme }) => `
        width: 100%;
        height: calc(100vh - 233px);

        ${theme.breakpoints.up('sm')} {
            height: calc(100vh - 153px);
        }

        g.react-flow__edge-textwrapper {
            opacity: 0;
            fill: #fff;

            > rect {
                fill: #ADADAD;
            }
        }

        .react-flow__edge:hover > g.react-flow__edge-textwrapper {
            opacity: 1;
        }
    `}
`