import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { useTranslation } from "react-i18next";
import Spinner from "./Spinner";

const Wrapper = styled.div`
  ${({ theme }) => `
    white-space: nowrap;
    position: relative;
    display: flex;
    align-items: center;
    flex-grow: 1;

    .svg-inline--fa {
      position: absolute;
      top: 50%;
      left: 12px;
      transform: translate3d(0, -50%, 0);
      pointer-events: none;
    }

    input {
      margin-right: 0.75rem;
      width: 100% !important;
      padding-left: 36px;
    }

    .spinner-border {
      position: absolute;
      left: 100%;
    }

    .form-control--flat {
      height: 44px;
      border-radius: ${theme.rounded.sm}
    }

    .form-control--flat-md {
      border-radius: ${theme.rounded.md}
    }
  `}
`;

const Search = ({ className, isLoading, onChange, value }) => {
  const { t } = useTranslation();
  const [input, setInput] = useState("");

  const handler = useDebouncedCallback((value) => {
    if (value?.length < 3 && value?.length !== 0) {
      return
    }
    return onChange(value)
  }, 250);

  const setter = ({ target }) => {
    setInput(target.value);
    handler(target.value);
  };

  useEffect(() => setInput(value), [value]);

  return (
    <Wrapper>
      <FontAwesomeIcon icon={faSearch} />
      <input
        className={`form-control ${className}`}
        type="text"
        placeholder={t('Search')}
        aria-label="Search"
        onChange={setter}
        value={input}
      />
      {isLoading && <Spinner size="0.875rem" />}
    </Wrapper>
  );
};

export default Search;
