import { useTranslation } from "react-i18next";
import { useDecision } from "@optimizely/react-sdk";
import { FormSelect, RowInfo } from "../../common";
import TextTransformationModal from "../modals/TextTransformationModal";

const Text = ({ useQuery }) => {
    const { t } = useTranslation();
    const [decisionAi] = useDecision('ai');

    return (
        <>
            <p dangerouslySetInnerHTML={{ __html: t('Require the player to type words.') }} />
            {
                decisionAi?.enabled
                    ? (
                        <>
                            <RowInfo help={<TextTransformationModal />}>
                                <FormSelect
                                    label={t("Transformations")}
                                    name="task_subtype"
                                    useQuery={useQuery}
                                >
                                    <option value="0">{t('None')}</option>
                                    <option value="1">{t('Generate image from submitted text using AI robot artist')}</option>
                                </FormSelect>
                            </RowInfo>
                        </>
                    )
                    : null
            }
        </>
    )
};

export default Text;
