import { useTranslation } from "react-i18next";
import { Modal } from "../../common";
import { Button } from "../../App.styles";

const AugmentedRealityDelayModal = ({ onDismiss, show }) => {
  const { t } = useTranslation();

  return (
    <Modal onDismiss={onDismiss} show={show}>
      <div className="modal-header">
        <h4 className="modal-title">{t('Delay')}</h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-hidden="true"
          onClick={onDismiss}
        >
          ×
        </button>
      </div>
      <div className="modal-body">
        <p>{t('Offset when an animation starts playing by adding a delay.')}</p>
        <ul>
          <li>
            {t(`A delay of 0.5 means wait half a second after all the AR models are
            loaded to begin animating this model.`)}
          </li>
          <li>
            {t(`If you wish to chain together animations into a sequence you should
            use the chain parameter instead of the delay attribute. Only set the
            delay attribute for the first animiation in a chain.`)}
          </li>
        </ul>
      </div>
      <div className="modal-footer">
        <Button type="button" color="dark" data-dismiss="modal" onClick={onDismiss}>
          {t('Close')}
        </Button>
      </div>
    </Modal>
  )
};

export default AugmentedRealityDelayModal;
