import styled from "styled-components";
import { useState } from "react";
import {
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBDropdownLink,
} from "mdb-react-ui-kit";
import { useTranslation } from "react-i18next";

export const HistoryDropdownWrapper = styled(MDBDropdown)`
  button {
    position: relative;
    text-align: left;
    width: 220px;

    &:after {
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  @media (max-width: ${({ theme }) => theme.maxMobileWidth}) {
    width: 65%;
  }
`;

const HistoryDropdown = ({ className, onSelect }) => {
  const { t } = useTranslation();
  const [history, setHistory] = useState(3650);

  const dropdownLabel = () => {
    switch (history) {
      case 7:
        return t('View Week');
      case 30:
        return t('View Month');
      case 365:
        return t('View Year');
      case 3650:
        return t('View All');
      default:
        return t('Time');
    }
  };

  const selectItem = (prop) => {
    setHistory(prop);
    onSelect(prop);
  };

  return (
    <HistoryDropdownWrapper className={className}>
      <MDBDropdownToggle color="dark">{dropdownLabel()}</MDBDropdownToggle>
      <MDBDropdownMenu>
        <MDBDropdownItem onClick={() => selectItem(7)}>
          <MDBDropdownLink dangerouslySetInnerHTML={{__html: t('View Week')}} />
        </MDBDropdownItem>
        <MDBDropdownItem onClick={() => selectItem(30)}>
          <MDBDropdownLink dangerouslySetInnerHTML={{__html: t('View Month')}} />
        </MDBDropdownItem>
        <MDBDropdownItem onClick={() => selectItem(365)}>
          <MDBDropdownLink dangerouslySetInnerHTML={{__html: t('View Year')}} />
        </MDBDropdownItem>
        <MDBDropdownItem onClick={() => selectItem(3600)}>
          <MDBDropdownLink dangerouslySetInnerHTML={{__html: t('View All')}} />
        </MDBDropdownItem>
      </MDBDropdownMenu>
    </HistoryDropdownWrapper>
  );
};

export default HistoryDropdown;
