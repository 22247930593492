import { Modal, Spinner } from "../common";
import { Button } from "../App.styles";

const DeleteModal = ({ isLoading, onConfirm, onDismiss, show }) => (
  <Modal onDismiss={onDismiss} show={show}>
    <div className="modal-header">
      <h4 className="modal-title">Confirm Deletion</h4>
      <button
        type="button"
        className="close"
        data-dismiss="modal"
        aria-hidden="true"
        onClick={onDismiss}
      >
        ×
      </button>
    </div>
    <div className="modal-body">Are you sure you want to delete this?</div>
    <div className="modal-footer">
      <Button color="dark" data-dismiss="modal" onClick={onDismiss}>
        Cancel
      </Button>
      <Button color="primary" onClick={onConfirm}>
        Delete {isLoading && <Spinner size="0.875rem" />}
      </Button>
    </div>
  </Modal>
);

export default DeleteModal;
