import { useTranslation } from "react-i18next";
import { Modal } from "../../common";
import { Button } from "../../App.styles";

const QualifyModal = ({ onDismiss, show }) => {
  const { t } = useTranslation();
  
  return (
    <Modal onDismiss={onDismiss} show={show}>
      <div className="modal-header">
        <h4 className="modal-title">{t('Qualify')}</h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-hidden="true"
          onClick={onDismiss}
        >
          ×
        </button>
      </div>
      <div className="modal-body">
        <p>
          {t(`Players will be granted this reward or clue only if they satisfy this
          criterion.`)}
        </p>
        <ul>
          <li>{t('Submissions will count every type of challenge.')}</li>
          <li>
            {t(`Eg. 10 Photo means only grant this reward or clue after the player has
            completed 10 challenges of the Photo type.`)}
          </li>
        </ul>
      </div>
      <div className="modal-footer">
        <Button color="dark" data-dismiss="modal" onClick={onDismiss}>
          {t('Close')}
        </Button>
      </div>
    </Modal>
  )
};

export default QualifyModal;
