import { useTranslation } from "react-i18next";
import { FormAutocompleteMultiple } from "../../common/FormAutocomplete";
import {
  useAddBanMutation,
  useRemoveBanMutation,
} from "../Teams.state";
import { CardBody } from "../../App.styles";
import { Shimmer } from "../../common/Loading.styles";

const Banned = props => {
  const { options: players, selected: banned, loading } = props
  const { t } = useTranslation();
  const { addBan } = useAddBanMutation();
  const { removeBan } = useRemoveBanMutation();

  const handleChange = async ({ target }) => {
    const { value } = target;

    for (let player of value.filter(
      (v) => !banned.find((b) => b.id === v.id)
    )) {
      await addBan(player);
    }

    for (let player of banned.filter(
      (b) => !value.find((v) => v.id === b.id)
    )) {
      await removeBan(player);
    }
  };

  return (
    <CardBody className="card">
      <div className="card-header bg-dark text-light">
        <span className="card-title">{t('Banned')}</span>
      </div>
      <div className="card-body">
        <p dangerouslySetInnerHTML={{__html: t('Anyone breaking the rules? Add them here to toss them from the game.')}} />
        {
          loading
          ? (
            <>
              <Shimmer dark type="title" />
              <Shimmer dark />
            </>
          )
          : (
            <FormAutocompleteMultiple
              options={players}
              mapLabel="email"
              disableClearable={true}
              size="small"
              placeholder={t("Select people...")}
              onChange={(e, value) => {
                handleChange({
                  target: {
                    value,
                  }
                })
              }}
              value={banned}
              stateful={false}
            />
          )
        }
      </div>
    </CardBody>
  );
};

export default Banned;
