import { useAuthUser } from "react-auth-kit";

const usePreviewButton = ({ code, id }) => {
    const auth = useAuthUser()();
    const { token } = auth;
    // link with id is a challenge
    const suffix = id ? `/challenge/${id}` : ''
    return {
        onClick: () => {
            window.open(
                `${process.env.REACT_APP_PREVIEW_URL
                }/#/preview/${encodeURIComponent(encodeURIComponent(code))}${suffix}?token=${token}`,
                '_blank'
            )
        }
    }
}

export default usePreviewButton