import React, { useState } from "react";
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import { ContentTabWrapper } from "./ContentTab.style";

export default function ContentTab({ tabs }) {
  const [active, setActive] = useState('tab0');

  const handleClick = (value) => {
    if (value === active) {
      return;
    }

    setActive(value);
  };

  return (
    <ContentTabWrapper>
      <MDBRow className="g-0">
        <MDBCol size="12" md="3">
          <MDBTabs className="flex-md-column text-center" pills>
            {
                tabs.map((tab, index) => {
                    return (
                        <MDBTabsItem key={index}>
                            <MDBTabsLink onClick={() => handleClick(`tab${index}`)} active={active === `tab${index}`}>
                                {tab.title}
                            </MDBTabsLink>
                        </MDBTabsItem>
                    )
                })
            }
          </MDBTabs>
        </MDBCol>
        <MDBCol size="12" md="9" className="d-md-flex">
          <MDBTabsContent className="flex-grow-1">
            {
                // content tabs are rendered dynamically to reduce
                // API loads for unopened tabs.
                tabs.map((tab, index) => {
                    return active === `tab${index}`
                      ? (
                        <MDBTabsPane show={true} key={index}>
                            {tab.content}
                        </MDBTabsPane>
                      )
                      : null
                })
            }
          </MDBTabsContent>
        </MDBCol>
      </MDBRow>
    </ContentTabWrapper>
  );
}