import { useTranslation } from "react-i18next";
import { Modal } from "../../common";
import { Button } from "../../App.styles";

const LocationModal = ({ onDismiss, show }) => {
  const { t } = useTranslation();
  return (
    <Modal onDismiss={onDismiss} show={show}>
      <div className="modal-header">
        <h4 className="modal-title">{t('Location')}</h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-hidden="true"
          onClick={onDismiss}
        >
          ×
        </button>
      </div>
      <div className="modal-body">
        <p>
          {t(`Locations can be informative or restrictive. If you do not lock to the
          location, the player can use the map for guidance but may submit from
          anywhere.`)}
        </p>
        <ul>
          <li>{t('Locations are saved as latitude and longitude coordinates.')}</li>
          <li>
            {t(`Unless entering latitude and longitude directly, Google will be used
            to geocode the address entered.`)}
          </li>
          <li dangerouslySetInnerHTML={{
            __html: t(`Enter coordinates like this '51.959662, -0.8399444', otherwise enter
            the street address as you would normally do in Google Maps.`)
          }} />
        </ul>
      </div>
      <div className="modal-footer">
        <Button type="button" color="dark" data-dismiss="modal" onClick={onDismiss}>
          {t('Close')}
        </Button>
      </div>
    </Modal>
  )
};

export default LocationModal;
