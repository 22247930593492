import { atom, useRecoilValue } from "recoil";
import { urlSyncEffect } from "recoil-sync";
import { string, number, nullable } from "@recoiljs/refine";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { axios } from "../lib/request";
import { dashboardQueryKey } from "../lib/refresh";
import { MAX_PER_PAGE } from "../consts/request";

export const alertState = atom({
  key: "games/my/alert",
  default: { type: "none", message: "" },
});

export const categoryState = atom({
  key: "games/my/category",
  default: null,
  effects: [
    urlSyncEffect({
      refine: nullable(string()),
      history: 'push'
    })
  ],
});

export const historyState = atom({
  key: "games/my/history",
  default: 3650,
});

export const pageState = atom({
  key: "games/my/page",
  default: 1,
  effects: [
    urlSyncEffect({
      refine: number(),
      history: 'push'
    })
  ],
});

export const pageSizeState = atom({
  key: "games/my/pageSize",
  default: MAX_PER_PAGE,
  effects: [
    urlSyncEffect({
      refine: number(),
      history: 'push'
    })
  ],
});

export const termState = atom({
  key: "games/my/term",
  default: "",
  effects: [
    urlSyncEffect({
      refine: string(),
      history: 'push'
    })
  ],
});

export const fetchGames = async (category, history, page, per_page, term) => {
  const url = process.env.REACT_APP_API_GAMES_URL;
  const { data } = await axios.get(url, {
    params: { category, history, page, per_page, term },
  });

  return data;
};

export const useGamesDataQuery = () => {
  const category = useRecoilValue(categoryState);
  const history = useRecoilValue(historyState);
  const page = useRecoilValue(pageState);
  const pageSize = useRecoilValue(pageSizeState);
  const term = useRecoilValue(termState);

  return useQuery(
    dashboardQueryKey(category, history, page, pageSize, term),
    () => fetchGames(category, history, page, pageSize, term),
    { keepPreviousData: true, suspense: true }
  );
};

export const useGamesQuery = () => {
  const { data } = useGamesDataQuery();
  return data.games;
};

export const useGamesFetchingQuery = () => {
  const { isFetching } = useGamesDataQuery();
  return isFetching;
};

export const usePagesQuery = () => {
  const { data } = useGamesDataQuery();
  return data.meta.pages;
};

export const useLiberateMutation = (game_id) => {
  const queryClient = useQueryClient();

  const { isLoading, mutateAsync } = useMutation(
    (config) =>
      axios.patch(
        `https://app.socialscavenger.com/v1/games/${game_id}/liberate.json`,
        null,
        config,
      ),
    {
      onError: (error) => console.log("[error]", error),
      onSuccess: () => queryClient.invalidateQueries(dashboardQueryKey()),
    }
  );

  return { isLiberating: isLoading, liberate: mutateAsync };
};
