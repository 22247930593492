import styled from "styled-components";

export const ChallengeHeader = styled.div`
  display: flex;
  justify-content: space-between;

  input {
    width: 33%;
  }
`;
