import styled from "styled-components";
import { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { Link, useParams } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import {
  MDBCard,
  MDBCardHeader,
  MDBCardTitle,
  MDBCardBody,
  MDBTable,
  MDBTableBody,
  MDBInput,
} from "mdb-react-ui-kit";
import { useTranslation } from "react-i18next";
import { LinkButton } from "../common";
import { queryState, useLeaderboardQuery } from "./Leaderboard.state";
import LeaderboardToggle from "./LeaderboardToggle";

const Input = styled(MDBInput)`
  margin-bottom: 1rem;
`;

const LeaderboardTableBody = styled(MDBTableBody)`
  th,
  td {
    padding: 0.3rem;
  }
`;

const LeaderboardButton = styled(LinkButton)`
  border: 1px solid rgba(0, 0, 0, 0.87);
  white-space: normal;

  a {
    color: rgba(0, 0, 0, 0.87);
    white-space: normal;
  }
`;

const PhotoCell = styled.div`
  width: 75px;
  height: 75px;
  background-color: ${({ theme }) => theme.placeholderColor};
`;

const LeaderboardRow = ({
  game_id,
  id,
  name,
  photo,
  points,
  points_place,
  completed,
}) => {
  const { t } = useTranslation();

  return (
    <tr>
      <th>{points_place}</th>
      <td>
        <PhotoCell>
          {photo && (
            <img alt="" src={photo.url} width={photo.width} height={photo.height} />
          )}
        </PhotoCell>
      </td>
      <td>
        <Link to={`/teams/stream/${game_id}/${id}`}>{name}</Link>
        <br />
        {points} {t('Points')}
        <br />
        {completed} {t('Challenges')}
      </td>
    </tr>
  )
};

const LeaderboardWidget = ({ showVisibilityToggle }) => {
  const { t } = useTranslation();
  const { game_id } = useParams();
  const leaderboard = useLeaderboardQuery(game_id);
  const [query, setQuery] = useRecoilState(queryState);
  const [value, setValue] = useState("");
  const callback = useDebouncedCallback(setQuery, 500);
  const handler = ({ target }) => {
    const { value } = target;
    setValue(value);
    callback(value);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setValue(query), []);

  if (leaderboard.length === 0) {
    return null;
  }

  return (
    <MDBCard>
      <MDBCardHeader>
        <MDBCardTitle>
          {t('Leaderboard')}
          {showVisibilityToggle && (
            <>
              <br />
              <LeaderboardToggle />
            </>
          )}
        </MDBCardTitle>
      </MDBCardHeader>
      <MDBCardBody>
        <Input label={t("Search")} value={value} onChange={handler} />
        <MDBTable striped>
          <LeaderboardTableBody>
            {leaderboard.slice(0, 10).map((table, index) => (
              <LeaderboardRow
                key={index}
                {...table}
                game_id={game_id}
                photo={table.assets.photos.find(
                  (photo) => photo.name === "thumb"
                )}
              />
            ))}
          </LeaderboardTableBody>
        </MDBTable>
        <LeaderboardButton color="outline" to={`/teams/leaderboard/${game_id}`}>
          {t('Full leaderboard')}
        </LeaderboardButton>
      </MDBCardBody>
    </MDBCard>
  );
};

export default LeaderboardWidget;
