import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { axios } from "../lib/request";
import { answerQueryKey } from "../lib/refresh";

export const fetchAnswer = async (id, game_id) => {
  const url = `${process.env.REACT_APP_API_ANSWER_RETRIEVE_URL}/${id}.json?game_id=${game_id}`;
  const { data } = await axios.get(url);
  return data;
};

export const useAnswerDataQuery = (id) => {
  const { game_id } = useParams();
  return useQuery(answerQueryKey(id), () => fetchAnswer(id, game_id), {
    suspense: true,
  });
};

export const useAnswerQuery = (id) => {
  const { data } = useAnswerDataQuery(id);
  return data.answer;
};
