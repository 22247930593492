import { useState } from "react";
import { Tooltip } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClipboard,
  faClipboardCheck,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { Button } from "../App.styles";
import { List, TooltipParagraph } from "./ButtonCopy.style";

const ButtonCopyTooltip = ({ help, copied, onCopy }) => {
  const { t } = useTranslation();

  return (
    <TooltipParagraph>
      { help }
      <FontAwesomeIcon
        icon={copied ? faClipboardCheck : faClipboard}
        onClick={onCopy}
        title={copied ? t("Copied to clipboard") : t("Copy to clipboard")}
      />
    </TooltipParagraph>
  );
};

const Copyable = ({ children, title }) => {
  return (
    <Tooltip
      arrow
      interactive
      title={title}
      enterTouchDelay={0}
    >
      {children}
    </Tooltip>
  );
};

const ButtonCopy = ({ list }) => {
  const ListItem = ({ text, help }) => {
    const [copied, setCopied] = useState(false);
    const copyText = (text) => {
      navigator.clipboard.readText();
      navigator.clipboard.writeText(text);
      setCopied(true);
    };

    return (
      <Copyable
        title={
          <ButtonCopyTooltip 
            text={text} 
            help={help} 
            onCopy={() => copyText(text)}
            copied={copied}
          />
        }
      >
        <li>
          <Button color="light" onClick={() => copyText(text)}>
            {text}
          </Button>
        </li>
      </Copyable>
    )
  }
  return (
    <List className="list-unstyled list-inline">
      {
        list.map(({ text, help }, key) => {
          return <ListItem key={key} text={text} help={help} />
        })
      }
    </List>
  );
};

export default ButtonCopy;
