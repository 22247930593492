import styled from "styled-components";
import { Button, Col, Row } from "../../App.styles";

export const RowInfoContainer = styled(Row)`
  ${({ theme }) => `
    flex-grow: 1;
    flex-wrap: nowrap;

    ${theme.breakpoints.up('lg')} {
      flex-wrap: wrap;
    }
    
    .form-outline .form-control:focus~.form-label, 
    .form-outline .form-control.active~.form-label {
      transform: scale(0.8);
      line-height: 1;
      padding-top: 0;
    }

    & + & {
      margin-top: 2rem;
    }

    .card-body & + & .form-outline,
    .tab-content & + & .form-outline {
      margin-top: 0;
    }

    .datetime-picker-col {
      position: relative;
      flex-direction: column;
      align-items: flex-start;

      .form-label {
        font-size: 0.8rem;
      }
    }

    .tag-col {
      flex-direction: column;
      align-items: flex-start;
      justify-content: stretch;
      margin: 0 0 3rem;
    }
  `}
`;

export const HelpCol = styled(Col)`
    ${({ theme }) => `
      &&:not(.justify-start) {
        flex-grow: 1;
        min-width: 16.6666666667%;
        width: auto;
      }

      ${theme.breakpoints.up('lg')} {
        flex-grow: 0;
        width: 16.6666666667%;
      }
    `}
`;

export const HelpButton = styled(Button)`
  ${({ theme }) => `
    border-radius: 50%;
    font-size: 1rem;
    display: flex;
    margin-right: 0;
    padding: 0;
    width: 16px;
    height: 16px;

    &,
    &:active,
    &:focus {
      background-color: transparent !important;
      box-shadow: none;
      color: rgb(33, 37, 41);
    }

    &:hover {
      color: ${theme.primaryColor};
      background-color: rgba(153, 153, 153, 0.2);
      border-color: rgba(153, 153, 153, 0.2);
      box-shadow: none;
    }

    ${theme.breakpoints.down('sm')} {
      margin-top: 0;
      padding-left: 0;
      padding-right: 0;

      .card-header & {
        margin-top: 0;
      }
    }
  `}
`;