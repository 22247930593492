import { useTranslation } from "react-i18next";
import { FormAutocomplete } from "../../common";
import { Row, Col, HelpText } from "../../App.styles";
import { RowInfo } from "../../common";
import { QuantityModal } from "../modals";
import { SectionContainer } from "./QuantitySection.style";

const QuantitySection = ({ useQuery }) => {
  const { t } = useTranslation();

  return (
    <SectionContainer>
      <RowInfo help={<QuantityModal />}>
        <FormAutocomplete 
          defaultValue="0"
          options={[
            ...[{
              id: 0,
              name: t('Unlimited'),
            }],

            ...Array.from(Array(500).keys()).map(i => ({
              id: i + 1,
              name: String(i + 1),
            })),
          
          ]}
          size="small"
          name="quantity"
          useQuery={useQuery}
        />
      </RowInfo>
      <Row>
        <Col size="10">
          <HelpText className="position-relative top-0 mt-4 d-block" focus>
            <ul className="mx-3 p-0">
              <li>{t('If set to Unlimited, this challenge has no limit to its availability. ')}</li>
              <li>{t('If set to 8, only 8 teams are allowed to complete it. ')}</li>
              <li>{t('After which, the challenge is removed from the list and any additional submissions received are rejected.')}</li>
            </ul>
          </HelpText>
        </Col>
      </Row>
    </SectionContainer>
  );
};

export default QuantitySection;
