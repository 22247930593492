import React from "react";
import { useTranslation } from "react-i18next";
import { FormAutocomplete, NewImageUpload, NewVideoUpload, RowInfo } from "../../common";
import { radiusOptions } from "../../consts/settings";
import { ArrivalRadiusModal, MediaArrivalModal } from "../modals";

const ArrivalSection = ({ useQuery }) => {
    const { t } = useTranslation();
    const [{ longitude, latitude }] = useQuery();
    const disabled = !longitude && !latitude;

    return (
        /**
         * Using key here to re-render when the location has changed,
         * e.g.: when the user clears the location, it will not retain
         * the arrival radius value
         */
        <React.Fragment key={`${latitude}-${longitude}`}>
            <RowInfo help={<ArrivalRadiusModal />}>
                <FormAutocomplete
                    desc={
                        disabled
                            ? t('Location must be enabled to use this feature')
                            : null
                    }
                    label={t('Display arrival media when closer than:')}
                    name="arrival_location_radius"
                    useQuery={useQuery}
                    disabled={disabled}
                    options={radiusOptions}
                    size="small"
                />
            </RowInfo>
            <RowInfo help={<MediaArrivalModal />}>
                <NewImageUpload
                    label={`${t('Image given upon arrival')}`}
                    name="arrival_image"
                    useQuery={useQuery}
                />
            </RowInfo>
            <RowInfo help={<MediaArrivalModal />}>
                <NewVideoUpload
                    label={`${t('Video given upon arrival')}`}
                    name="arrival_video"
                    useQuery={useQuery}
                />
            </RowInfo>
        </React.Fragment>
    );
};

export default ArrivalSection;
