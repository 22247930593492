import { useTranslation } from "react-i18next";
import { MDBBadge, MDBCard, MDBCardBody, MDBCardHeader, MDBCardTitle, MDBTypography } from "mdb-react-ui-kit";
import { Refresher, SidebarLayout, withPlaceholder } from "../common";
import AnalyticsPlaceholder from "./AnalyticsPlaceholder";
import { Col, Row } from "../App.styles";
import { InfoItem, InfoWrapper } from "./Analytics.style";
import { useAnalyticsQuery } from "./Analytics.state";
import { Shimmer } from "../common/Loading.styles";
import React from "react";

const Analytics = () => {
    const { t } = useTranslation();
    const { data, isFetching } = useAnalyticsQuery()
    const Loadable = ({ children }) => {
        return isFetching
            ? <Shimmer type="title" />
            : children
    }

    return (
        <React.Fragment>
            <Row>
                <SidebarLayout
                    message={t(`Data analytics of the game`)}
                    panel={null}
                >
                    <Row>
                        <Col lg="6">
                            <MDBCard className="flex-grow-1">
                                <MDBCardHeader>
                                    <MDBCardTitle>
                                        {t(`Teams and Players`)}
                                    </MDBCardTitle>
                                </MDBCardHeader>
                                <MDBCardBody>
                                    <InfoWrapper>
                                        <InfoItem>
                                            <Loadable>
                                                <MDBTypography variant="h3">
                                                    { data?.analytics?.teams }
                                                </MDBTypography>
                                            </Loadable>
                                            <MDBBadge color='info' light>
                                                {t(`Teams`)}
                                            </MDBBadge>
                                        </InfoItem>
                                        <InfoItem>
                                            <Loadable>
                                                <MDBTypography variant="h3">
                                                    { data?.analytics?.players }
                                                </MDBTypography>
                                            </Loadable>
                                            <MDBBadge color='info' light>
                                                {t(`Players`)}
                                            </MDBBadge>
                                        </InfoItem>
                                        <InfoItem>
                                            <Loadable>
                                                <MDBTypography variant="h3">
                                                    { data?.analytics?.players_per_team }
                                                </MDBTypography>
                                            </Loadable>
                                            <MDBBadge color='info' light>
                                                {t(`Player per Team`)}
                                            </MDBBadge>
                                        </InfoItem>
                                    </InfoWrapper>
                                </MDBCardBody>
                            </MDBCard>
                        </Col>
                    </Row>
                </SidebarLayout>
            </Row>
            <Refresher />
        </React.Fragment>
    )
}

export default withPlaceholder(Analytics, AnalyticsPlaceholder)