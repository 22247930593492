import { useCallback, useEffect, useReducer } from "react";
import { useIsomorphicLayoutEffect } from "react-use";
import { useVirtual } from "react-virtual";

const rectReducer = (state, action) => {
  const rect = action.rect;
  if (!state || state.height !== rect.height || state.width !== rect.width) {
    return rect;
  }
  return state;
};

const useWindowRect = (windowObj, nodeRef) => {
  const [rect, dispatch] = useReducer(rectReducer, {});

  useIsomorphicLayoutEffect(() => {
    dispatch({
      rect: {
        height: windowObj.innerHeight,
        width: windowObj.innerWidth,
      },
    });
  }, [windowObj]);

  useEffect(() => {
    const resizeHandler = () => {
      dispatch({
        rect: {
          height: windowObj.innerHeight,
          width: windowObj.innerWidth,
        },
      });
    };
    resizeHandler();
    windowObj.addEventListener("resize", resizeHandler);
    return () => {
      windowObj.removeEventListener("resize", resizeHandler);
    };
  }, [nodeRef, windowObj]);

  return rect;
};

export const useVirtualWindow = ({
  windowRef,
  scrollToFn,
  horizontal,
  parentRef,
  ...rest
}) => {
  const scrollKey = horizontal ? "scrollX" : "scrollY";
  const defaultScrollToFn = useCallback(
    (offset) => {
      if (windowRef.current) {
        windowRef.current[scrollKey] = offset;
      }
    },
    [scrollKey, windowRef]
  );

  return useVirtual({
    ...rest,
    horizontal,
    parentRef,
    scrollToFn: scrollToFn || defaultScrollToFn,
    onScrollElement: windowRef,
    scrollOffsetFn() {
      const bounds = parentRef.current
        ? parentRef.current.getBoundingClientRect()
        : { left: 0, top: 192 };
      return horizontal ? bounds.left * -1 : bounds.top * -1;
    },
    useObserver: () => useWindowRect(windowRef.current),
  });
};
