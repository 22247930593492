import styled from "styled-components";
import { darken } from "@material-ui/core";

export const TeamWrapper = styled.div`
    ${({ theme, loading }) => `
        background-color: ${darken(theme.lightColor, .05)};
        display: inline-flex;
        padding: 0 25px 0 5px;
        border-radius: 5px;
        min-height: 40px;
        align-items: center;
        gap: 20px;
        position: relative;
        ${
            loading
                ? `
                    opacity: .5;
                    pointer-events: none;
                    cursor: not-allowed;
                `
                : ''
        }
    `}
`;

export const TeamThumbnail = styled.img`
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 5px;
`;

export const CloseIcon = styled.span`
    font-size: 10px;
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: #ff4d6d;
    color: white;
    padding: 2px 6px 0 6px;
    border-radius: 20px;
    cursor: pointer;
`;