import { useTranslation } from "react-i18next";
import { RowInfo } from "../../common";
import { SplitPathAnswersModal } from "../modals";
import Textarea from "../Textarea";

const SplitPath = ({ useQuery }) => {
  const { t } = useTranslation();

  return (
    <>
      <p dangerouslySetInnerHTML={{__html: t(`Present buttons with answers and ask players to select one.`)}} />
      <RowInfo help={<SplitPathAnswersModal />}>
        <Textarea
            label={t("Answers (list all answers, separated by commas, maximum of 5)")}
            labelClassName="no-ellipsis"
            name="multiplechoice"
            useQuery={useQuery}
            onKeyDown={e => {
              // Prevent user from entering colons/semicolons into paths/choices.
              // These delimiters are being used to map parent-child paths
              if ([';', ':'].includes(e.key)) {
                e.preventDefault()
              }
            }}
          />
      </RowInfo>
    </>
  )
};

export default SplitPath;
