import { useEffect, useState } from "react";
import ChallengeEdit from "./ChallengeEdit";
import ChallengeView from "./ChallengeView";
import { ChallengeCard } from "./Challenge.styles";
import { useRecoilState } from "recoil";
import { openTabState } from "./Organize.state";

const Challenge = ({ clearOpenId, openId, ...props }) => {
  const [edit, setEdit] = useState(false);
  const [openIds, setOpenIds] = useRecoilState(openTabState);
  const onClose = () => {
    setEdit(false)
    setOpenIds(
      openIds
        .filter(id => id !== props.id)
    )
  }

  const onEdit = () => {
    setEdit(true)
    setOpenIds(
      [
        ...openIds,
        props.id,
      ]
        .filter((id, index, self) => self && self.indexOf(id) === index)
    )
  }

  useEffect(() => {
    if (openIds.includes(props.id) || openId === props.id) {
      setEdit(true);
      clearOpenId();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openId]);

  return (
    <ChallengeCard>
      {edit ? (
        <ChallengeEdit
          onClose={onClose}
          {...props}
        />
      ) : (
        <ChallengeView
          onEdit={onEdit}
          {...props}
        />
      )}
    </ChallengeCard>
  );
};

export default Challenge;
