import { useCallback } from "react";
import { useTranslation } from "react-i18next";

const useUnsuspendedTranslation = () => {
    const { t: translate, ready } = useTranslation('default', { useSuspense: false });
    const t = useCallback((str) => {
        // when the translations are ready, show translated text;
        // otherwise show original version of the text
        return ready ? translate(str) : str
    }, [ready, translate])
    return { t }
}

export default useUnsuspendedTranslation