import { Card } from "@material-ui/core";
import { MDBCardBody, MDBCardHeader, MDBCardTitle } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const PrivacyPolicy = () => {
  const { t } = useTranslation()

  return (
    <Card>
      <MDBCardHeader>
        <MDBCardTitle>
          <span dangerouslySetInnerHTML={{__html: t('Privacy Policy')}} />
        </MDBCardTitle>
      </MDBCardHeader>
      <MDBCardBody>
        <p dangerouslySetInnerHTML={{__html: t(`This Privacy Policy applies to the websites and mobile applications
          owned and operated by Mandown Group Inc. (doing business as and
          referred to herein as "Social Scavenger"). The websites and mobile
          applications owned and operated by Social Scavenger are referred to
          herein collectively as the "Service". Social Scavenger is a Canadian
          company with a registered office at 147 Liberty Street, Toronto, ON,
          M6K 3G3, Canada. When used herein the terms "we", "us", and "our"
          refer to Social Scavenger.`)}} />
        <p>
          <span dangerouslySetInnerHTML={{__html: t(`By accessing and using the Service you agree to this Privacy Policy.
          Specific rules may apply to certain sections or elements of the
          Service, such as a specific mobile application, and you hereby agree
          to be bound by such rules in addition to this Privacy Policy. Your
          access and use of the Service is also subject to our`)}} />{" "}
          <Link to="/terms"><span dangerouslySetInnerHTML={{__html: t(`Terms of Use.`)}} /></Link>
        </p>

        <p dangerouslySetInnerHTML={{__html: t(`We reserve the right, at any time, to modify, alter, or update this
          Privacy Policy. We will notify you of material changes by a prominent
          notice on socialscavenger.com prior to such material change becoming
          effective. Your continued use of the Service following such
          notification shall constitute your acceptance of the revised Privacy
          Policy. If you do not agree to any such changes you must immediately
          cease using the Service. We encourage you to periodically review this
          page for the latest information on our practices.`)}} />

        <h5 dangerouslySetInnerHTML={{__html: t(`Personal Information We Collect`)}} />

        <p  dangerouslySetInnerHTML={{__html: t(`We collect personal information in a number of ways in connection with
          your use of the Service. The personal information we may collect
          includes:`)}}/>

        <ul style={{ listStyle: "disc" }}>
          <li dangerouslySetInnerHTML={{__html: t(`user account information provided to us by Facebook or Twitter when
            you register with our Service through those sites;`)}} />
          <li dangerouslySetInnerHTML={{__html: t(`your geographical location as provided to us by your mobile phone
            only once you opt in to location services;`)}} />
          <li dangerouslySetInnerHTML={{__html: t(`the actions you take and challenges you complete in the games and
            applications offered through the Service;`)}} />
          <li dangerouslySetInnerHTML={{__html: t(`the content you supply through the Service, such as your comments,
            photos and videos.`)}} />
        </ul>

        <h5 dangerouslySetInnerHTML={{__html: t(`Use of Personal Information`)}} />

        <p dangerouslySetInnerHTML={{__html: t(`We generally use your personal information for the following purposes,
          and otherwise with your consent or as permitted or required by
          applicable law:`)}} />

        <ul style={{ listStyle: "disc" }}>
          <li dangerouslySetInnerHTML={{__html: t(`communicating with you and providing you with information the use of
            our Service;`)}} />
          <li dangerouslySetInnerHTML={{__html: t(`sending you notifications about game details or in game
            informations;`)}} />
          <li dangerouslySetInnerHTML={{__html: t(`improving the performance and utility of our Service;`)}} />
          <li dangerouslySetInnerHTML={{__html: t(`generally managing and administering our business;`)}} />
          <li dangerouslySetInnerHTML={{__html: t(`preventing and prosecuting fraud; and`)}} />
          <li dangerouslySetInnerHTML={{__html: t(`meeting legal, regulatory, insurance and security requirements.`)}} />
        </ul>

        <h5 dangerouslySetInnerHTML={{__html: t(`Disclosure of Your Personal Information`)}} />

        <p dangerouslySetInnerHTML={{__html: t(`We may disclose your personal information in the following
          circumstances, and otherwise with your consent or as permitted or
          required by applicable law:`)}} />

        <ul style={{ listStyle: "disc" }}>
          <li dangerouslySetInnerHTML={{__html: t(`when you submit content in connection with challenges or otherwise
            complete a challenge in a game, your response and location is stored
            on the map for that game, but only if you have provided permission
            on your mobile phone. If you are using a public game, this
            information is made publically available. If you are using a private
            game, only the users with access to that game may see this
            information;`)}} />
          <li dangerouslySetInnerHTML={{__html: t(`we may disclose your submissions by publicly displaying them,
            incorporating them into marketing materials, advertisements and
            allowing other users to distribute the same in connection with their
            own websites, media platforms, and applications;`)}} />
          <li dangerouslySetInnerHTML={{__html: t(`to third parties as we deem necessary or appropriate to prevent,
            limit or otherwise prosecute fraud; and`)}} />
          <li dangerouslySetInnerHTML={{__html: t(`as necessary to meet legal, regulatory, insurance, audit and
            security requirements.`)}} />
        </ul>

        <h5 dangerouslySetInnerHTML={{__html: t(`Cookies and Tracking Devices`)}} />

        <p dangerouslySetInnerHTML={{__html: t(`A cookie is a small text file that is stored on a user's computer for
          record-keeping purposes. We use both session ID cookies and persistent
          cookies on our websites and applications. We use session cookies to
          make it easier for you to navigate our site. A session ID cookie
          expires when you close your browser. We set a persistent cookie to
          only store your username, so you don't have to enter it more than
          once. Persistent cookies also enable us to track and target the
          interests of our users to enhance their experience. A persistent
          cookie remains on your hard drive for an extended period of time. You
          can remove persistent cookies by following directions provided in your
          Internet browser's "help" file. If you reject cookies, you may still
          use the Service, but your ability to use some areas, such as contests
          or surveys, will be limited.`)}} />

        <p dangerouslySetInnerHTML={{__html: t(`The use of cookies by our partners, affiliates, tracking utility
          company and service providers is not covered by our privacy policy. We
          do not have access or control over these cookies. Our partners,
          affiliates, tracking utility company and service providers may use
          session ID cookies to make it easier for you to navigate or sign-in to
          the Service.`)}} />

        <p dangerouslySetInnerHTML={{__html: t(`We and our third party web analytics providers also employ a software
          technology called clear gifs (also known as web beacons or web bugs),
          that help us better manage content on the Service by informing us what
          content is effective. Clear gifs are tiny graphics with a unique
          identifier, similar in function to cookies, and are used to track the
          online movements of users. In contrast to cookies, which are stored on
          a user's computer hard drive, clear gifs are embedded invisibly on web
          pages and are about the size of the period at the end of this
          sentence. We do not tie the information gathered by clear gifs to our
          customers' personal information. We use clear gifs in our HTML-based
          emails to let us know which emails have been opened by recipients.
          This allows us to gauge the effectiveness of certain communications
          and the effectiveness of our marketing campaigns.`)}} />

        <h5 dangerouslySetInnerHTML={{__html: t(`Service Providers`)}} />

        <p dangerouslySetInnerHTML={{__html: t(`We use the services of various third party companies and contractors
          (referred to here as "service providers") in the conduct of our
          business. These service providers provide us with information
          technology services for our software applications, marketing and
          survey services and other essential services. These service providers
          may, from time to time, collect, use, store, disclose and process
          personal information on our behalf. These service providers are
          located in Canada and the United States and, as a result, your
          personal information may be stored, processed or transferred into or
          out of these countries. We take all reasonable measures and
          precautions to protect your personal information when it is being
          handled by our service providers. If you wish to obtain more
          information on our use of service providers and our policies and
          practices regarding such service providers, please contact us as set
          forth below.`)}} />

        <h5 dangerouslySetInnerHTML={{__html: t(`Business Transactions`)}} />

        <p dangerouslySetInnerHTML={{__html: t(`We may use and disclose your personal information in connection with
          the proposed or actual financing, sale or other business transaction
          involving part or all of our business or assets. Such use and
          disclosure would be for the purpose of allowing third parties to
          determine whether to proceed with the proposed transaction, and if the
          transaction proceeds, for the purpose of completing the transaction.
          Assignees or successors to our business or assets may use and disclose
          your personal information for the purposes described in this policy.`)}} />

        <h5 dangerouslySetInnerHTML={{__html: t(`Your Consent`)}} />

        <p dangerouslySetInnerHTML={{__html: t(`By providing us with personal information, we will assume that you
          consent to our collection, use and disclosure of such information for
          the purposes identified in this policy or otherwise at the time of
          collection. You may withdraw your consent to our collection, use or
          disclosure at any time, provided that if you withdraw your consent to
          certain uses, we may no longer be able to provide our services to you.`)}} />

        <h5 dangerouslySetInnerHTML={{__html: t(`Sweepstakes, Contests, Promotions and Surveys`)}} />

        <p dangerouslySetInnerHTML={{__html: t(`From time to time, we may offer you the opportunity to participate in
          sweepstakes, contests, surveys and other promotions. Any information
          submitted in connection with such activities will be treated in
          accordance with this policy, except as specifically set forth in the
          rules, terms or conditions for those sweepstakes, contests, surveys or
          promotions.`)}} />

        <h5 dangerouslySetInnerHTML={{__html: t(`Security`)}} />

        <p dangerouslySetInnerHTML={{__html: t(`Personal information transmitted to our website is through 128-bit
          Secure Sockets Layer (SSL) encryption, which creates a private
          conversation between your computer and our site. No method of
          transmission over the Internet is 100% secure and as such we cannot
          guarantee absolute security for the transmission of your personal
          information.`)}} />

        <h5 dangerouslySetInnerHTML={{__html: t(`Access and Contacting Us`)}} />

        <p dangerouslySetInnerHTML={{__html: t(`If you wish to request access to your personal information or have any
          other questions, concerns or complaints regarding our personal
          information policies and practices, please contact us as follows:`)}} />

        <h5 dangerouslySetInnerHTML={{__html: t(`Data Privacy Officer`)}} />

        <p>
          Email: info@socialscavenger.com
          <br />
          147 Liberty Street, <br />
          Toronto, ON, M6K 3G3
          <br />
          Canada
          <br />
          <em dangerouslySetInnerHTML={{__html: t(`This Privacy Policy was last updated on May 27th, 2018.`)}} />
        </p>
      </MDBCardBody>
    </Card>
  );
};

export default PrivacyPolicy;
