import { useTranslation } from "react-i18next";
import { Modal } from "../../common";
import { Button } from "../../App.styles";

const AugmentedRealityChainModal = ({ onDismiss, show }) => {
  const { t } = useTranslation();

  return (
    <Modal onDismiss={onDismiss} show={show}>
      <div className="modal-header">
        <h4 className="modal-title">{t('Chain')}</h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-hidden="true"
          onClick={onDismiss}
        >
          ×
        </button>
      </div>
      <div className="modal-body">
        <p dangerouslySetInnerHTML={{__html: t(`If you set the style to be 'chain' you can now use this attribute to
          specify which chain the model is part of and which place in the order it
          should be linked.`)}} />
        <ul>
          <li>
            {t(`A value of 2.0 means that this model is in chain 2 and in position 0.
            Zero is the first position.`)}
          </li>
          <li>
            {t('A value of 1.8 means that this model is in chain 1 and in position 8')}
          </li>
          <li>
            {t(`Your first chain should be chain 1 and you should use sequential
            numbers for positions starting with 0.`)}
          </li>
        </ul>
      </div>
      <div className="modal-footer">
        <Button type="button" color="dark" data-dismiss="modal" onClick={onDismiss}>
          {t('Close')}
        </Button>
      </div>
    </Modal>
  )
};

export default AugmentedRealityChainModal;
