import { useTranslation } from "react-i18next";
import { Modal } from "../../common";
import { Button } from "../../App.styles";

const MediaArrivalModal = ({ onDismiss, show }) => {
  const { t } = useTranslation();
  
  return (
    <Modal onDismiss={onDismiss} show={show}>
      <div className="modal-header">
        <h4 className="modal-title">
          {t('Images and videos to be given upon arrival ')}
        </h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-hidden="true"
          onClick={onDismiss}
        >
          ×
        </button>
      </div>
      <div className="modal-body">
        <p>
          {t(`For each challenges, you can provide an image or video to be shown upon arrival`)}
        </p>
        <ul>
          <li>
            {t(`For images, you can use any graphics application you want that allows
            you to export JPEG, GIF or PNG files.`)}
          </li>
          <li>
            {t(`Submit the largest (resolution) size image you can and we will resize
            it as required.`)}
          </li>
          <li>{t('Always orient your image for portrait mode.')}</li>
          <li>{t('Encode your vidoes as MP4 H.264.')}</li>
          <li>
            {t(`Immediately after upload, the thumbnail will be grey as it waits for
            the video to be processed. Once processed, a frame from the video will
            appear.`)}
          </li>
        </ul>
      </div>
      <div className="modal-footer">
        <Button type="button" color="dark" data-dismiss="modal" onClick={onDismiss}>
          {t('Close')}
        </Button>
      </div>
    </Modal>
  )
};

export default MediaArrivalModal;
