import { useTranslation } from "react-i18next";
import { Modal } from "../../common";
import { Button } from "../../App.styles";

const LateSubmissionsModal = ({ onDismiss, show }) => {
  const { t } = useTranslation();

  return (
    <Modal onDismiss={onDismiss} show={show}>
      <div className="modal-header">
        <h4 className="modal-title">{t(`Late Submissions`)}</h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-hidden="true"
          onClick={onDismiss}
        >
          ×
        </button>
      </div>
      <div className="modal-body">
        <p>
          {t(`Challenges may be determined to be late if they are submitted after the
          fixed game finish time, after their time to complete the game has
          expired (varies as timer is based on when they join the game), or after
          the fixed challenge end time. You can choose to have them marked as
          rejected or approved automatically. If you use this setting along with
          pre or post stream moderation, you have fine tune control of your
          submissions.`)}
        </p>
      </div>
      <div className="modal-footer">
        <Button color="dark" data-dismiss="modal" onClick={onDismiss}>
          {t(`Close`)}
        </Button>
      </div>
    </Modal>
  )
};

export default LateSubmissionsModal;
