import styled from "styled-components";
import { FieldWrapper } from "./NumberInput";

export const SortableListWrapper = styled.div`
    ${FieldWrapper} {
        width: auto;
        min-width: 75px;
    }

    & + & {
        margin-top: 2rem;
    }
`;