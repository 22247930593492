export const defaults = function defaults(obj) {
    const arr = [];
    const each = arr.forEach;
    const slice = arr.slice;
    each.call(slice.call(arguments, 1), function (source) {
      if (source) {
        for (const prop in source) {
          if (obj[prop] === undefined) obj[prop] = source[prop];
        }
      }
    });
    return obj;
}