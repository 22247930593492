import { useTranslation } from "react-i18next";
import { Modal } from "../../common";
import { Button } from "../../App.styles";

const UnlockStyleModal = ({ onDismiss, show }) => {
  const { t } = useTranslation();

  return (
    <Modal onDismiss={onDismiss} show={show}>
      <div className="modal-header">
        <h4 className="modal-title">{t(`Unlock Style`)}</h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-hidden="true"
          onClick={onDismiss}
        >
          ×
        </button>
      </div>
      <div className="modal-body">
        <p>
          {t(`Challenges that reside in games that are set to use an OFFLINE unlock
          style will unlock even when the player is not connected to the network.
          This means that players can submit non valid responses in order to get
          to the next challenge. If you do not want to open the door to unlock
          cheating, you should select ONLINE. The ONLINE mode will only unlock
          challenges that have been approved.`)}
        </p>
        <ul>
          <li>
            {t(`OFFLINE mode is least secure but most accommodating as players can
            advance even when not connected to the Internet.`)}
          </li>
          <li>
            {t(`ONLINE mode in conjunction with pre-stream moderation can make
            unlocking cheat proof.`)}
          </li>
          <li>{t(`When in doubt, go OFFLINE.`)}</li>
        </ul>
      </div>
      <div className="modal-footer">
        <Button color="dark" data-dismiss="modal" onClick={onDismiss}>
          {t(`Close`)}
        </Button>
      </div>
    </Modal>
  )
};

export default UnlockStyleModal;
