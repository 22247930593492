import { useTranslation } from "react-i18next";
import { Modal, Spinner } from "../common";
import { Button } from "../App.styles";

const DeleteModal = ({ loading, onConfirm, onDismiss, show }) => {
  const { t } = useTranslation();

  return (
    <Modal onDismiss={onDismiss} show={show}>
      <div className="modal-header">
        <h4 className="modal-title">{t('Confirm Deletion')}</h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-hidden="true"
          onClick={onDismiss}
        >
          ×
        </button>
      </div>
      <div className="modal-body">
        {t(`Are you sure you wish to delete this team? If you have not removed the
        players from the team, the players will be removed as well.`)}
      </div>
      <div className="modal-footer">
        <Button color="dark" data-dismiss="modal" onClick={onDismiss}>
          {t('Cancel')}
        </Button>
        <Button color="primary" onClick={onConfirm}>
          {t('Delete')} {loading && <Spinner size="0.875rem" />}
        </Button>
      </div>
    </Modal>
  )
};

export default DeleteModal;
