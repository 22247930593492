import { useTranslation } from "react-i18next";
import { Modal } from "../../common";
import { Button } from "../../App.styles";

const ColorModal = ({ onDismiss, show, type }) => {
  const { t } = useTranslation();

  const TopNavigationBackgroundColor = () => (
    <ul>
      <li dangerouslySetInnerHTML={{__html: t(`Select or enter an HTML hexadecimal color code.`)}} />
      <li dangerouslySetInnerHTML={{__html: t(`This color is used in the player apps as the background color of the top and bottom navigation bars.`)}} />
      <li dangerouslySetInnerHTML={{__html: t(`This color should contrast with the Top Navigation Text Color in order for navigation bar text to be highly visible and easily readable.`)}} />
    </ul>
  )

  const TopNavigationTextColor = () => (
    <ul>
      <li dangerouslySetInnerHTML={{__html: t(`Select or enter an HTML hexadecimal color code.`)}} />
      <li dangerouslySetInnerHTML={{__html: t(`This color is used for text color in the Social Scavenger player app top and bottom navigation bars.`)}} />
    </ul>
  )

  const TopNavigationTintColor = () => (
    <ul>
      <li dangerouslySetInnerHTML={{__html: t(`Select or enter an HTML hexadecimal color code.`)}} />
      <li dangerouslySetInnerHTML={{__html: t(`This color is used in the player apps to re-tint icons and images that appear in the top navigation bar.`)}} />
    </ul>
  )

  const BodyHighlightColor = () => (
    <ul>
      <li dangerouslySetInnerHTML={{__html: t(`Select or enter an HTML hexadecimal color code.`)}} />
      <li dangerouslySetInnerHTML={{__html: t(`This color is used in the player apps for secondary/byline headings in various features.`)}} />
      <li dangerouslySetInnerHTML={{__html: t(`The Body Highlight Color is also used in various player app views to highlight specific view features, such as submission dates in the LiveStream.`)}} />
    </ul>
  )

  const BodyTextColor = () => (
    <ul>
      <li dangerouslySetInnerHTML={{__html: t(`Select or enter an HTML hexadecimal color code.`)}} />
      <li dangerouslySetInnerHTML={{__html: t(`The Body Text Color is the default font color for primary/headline headings and body text. color used in the player apps for the general body and headline text color.`)}} />
    </ul>
  )

  const BodyBackgroundColor = () => (
    <ul>
      <li dangerouslySetInnerHTML={{__html: t(`Select or enter an HTML hexadecimal color code.`)}} />
      <li dangerouslySetInnerHTML={{__html: t(`This color is used in the player apps for the main body background color (the area between the top and bottom navigation bars).`)}} />
    </ul>
  )

  const BodyTintColor = () => (
    <ul>
      <li dangerouslySetInnerHTML={{__html: t(`Select or enter an HTML hexadecimal color code.`)}} />
      <li dangerouslySetInnerHTML={{__html: t(`This color is used in the player apps to re-tint icons and indicators that appear in the main view body.`)}} />
      <li dangerouslySetInnerHTML={{__html: t(`The Body Tint Color is also used in the player apps color is also used in the player apps to provide contrast for tabs in features that utilize them, contrast for selected multiple choice challenge answers, and positive action dialog button background color.`)}} />
    </ul>
  )

  const renderHelp = {
    top_nav_background_color: <TopNavigationBackgroundColor />,
    top_nav_txt_color: <TopNavigationTextColor />,
    top_nav_tint_color: <TopNavigationTintColor />,
    body_high_color: <BodyHighlightColor />,
    body_txt_color: <BodyTextColor />,
    body_background_color: <BodyBackgroundColor />,
    body_tint_color: <BodyTintColor />,
  }

  return (
    <Modal onDismiss={onDismiss} show={show}>
      <div className="modal-header">
        <h4 className="modal-title">{t(`Colors`)}</h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-hidden="true"
          onClick={onDismiss}
        >
          ×
        </button>
      </div>
      <div className="modal-body">
        {renderHelp[type]}
      </div>
      <div className="modal-footer">
        <Button color="dark" data-dismiss="modal" onClick={onDismiss}>
          {t('Close')}
        </Button>
      </div>
    </Modal>
  );
}

export default ColorModal;
