import { useTranslation } from "react-i18next";
import { Modal } from "../../common";
import { Button } from "../../App.styles";

const NameModal = ({ onDismiss, show }) => {
  const { t } = useTranslation();

  return (
    <Modal onDismiss={onDismiss} show={show}>
      <div className="modal-header">
        <h4 className="modal-title">{t(`Name`)}</h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-hidden="true"
          onClick={onDismiss}
        >
          ×
        </button>
      </div>
      <div className="modal-body">
        <p>{t(`Give a name to your game.`)}</p>
        <ul>
          <li>{t(`Public game names are published to our game directory.`)}</li>
          <li>{t(`The best game names are short and descriptive.`)}</li>
          <li>
            {t(`When someone searches for your game, both the game name and game
            description are used.`)}
          </li>
          <li>{t(`Supports Personalization.`)}</li>
        </ul>
      </div>
      <div className="modal-footer">
        <Button color="dark" data-dismiss="modal" onClick={onDismiss}>
          {t(`Close`)}
        </Button>
      </div>
    </Modal>
  )
};

export default NameModal;
