import { useIsAuthenticated } from "react-auth-kit";
import { Redirect } from "react-router-dom";

const WithDashboardRedirect = Component => {
    return function Comp(props) {
        const isAuthenticated = useIsAuthenticated()
        const isLoggedIn = isAuthenticated()
        return isLoggedIn
            ? <Redirect to={'/dashboard'}/>
            : <Component {...props}/>
    }
}

export default WithDashboardRedirect