import { Row, Col } from "../App.styles";
import { Shimmer } from "../common/Loading.styles";

const SlideshowPlaceholder = () => {
  return (
    <>
        <Row className="mb-3">
            <Col size="12" lg="3">
                <Shimmer media className="media-full mb-3" />
            </Col>
            <Col size="12" lg="3">
                <Shimmer media className="media-full mb-3" />
            </Col>
            <Col size="12" lg="3">
                <Shimmer media className="media-full mb-3" />
            </Col>
            <Col size="12" lg="3">
                <Shimmer media className="media-full mb-3" />
            </Col>
        </Row>
        <Row className="mb-3">
            <Col size="12" lg="3">
                <Shimmer media className="media-full mb-3" />
            </Col>
            <Col size="12" lg="3">
                <Shimmer media className="media-full mb-3" />
            </Col>
            <Col size="12" lg="3">
                <Shimmer media className="media-full mb-3" />
            </Col>
            <Col size="12" lg="3">
                <Shimmer media className="media-full mb-3" />
            </Col>
        </Row>
    </>
  );
};

export default SlideshowPlaceholder;
