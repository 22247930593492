import styled from "styled-components";
import {
  arc,
  format,
  pie,
  pointer,
  scaleOrdinal,
  schemeCategory10,
  select,
} from "d3";

const SVG = styled.svg`
  position: relative;

  g {
    width: 100%;
    height: 100%;
  }

  .chart-tooltip {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    pointer-events: none;

    .tooltip-type {
      font-size: 0.75rem;
      font-weight: 700;
    }

    .tooltip-count {
      font-size: 0.625rem;
    }

    rect {
      height: 36px;
      opacity: 0.75;
      width: 8rem;
    }
  }
`;

const LegendBox = styled.g`
  transform: translate(1.5rem, ${({ y }) => y}px);
`;

const LegendRect = styled.rect`
  width: 0.75rem;
  height: 0.75rem;
  fill: ${({ color }) => color};
  transform: translate(-1.5rem, -0.625rem);
`;

const LegendText = styled.text`
  font-size: 0.75rem;
  line-height: 1rem;
`;

const Legend = ({ colors, data, width, y }) => {
  return (
    <LegendBox y={y}>
      {data.map(({ data }, index) => (
        <g key={data.label}>
          <LegendRect
            color={colors(index)}
            key={`${data.label}-rect`}
            y={index * 16}
          />
          <LegendText key={`${data.label}-text`} width={width} y={index * 16}>
            {data.label}
          </LegendText>
        </g>
      ))}
    </LegendBox>
  );
};

const Arc = ({
  createArc,
  colors,
  data,
  description,
  index,
  property,
  total,
}) => {
  const className = `.${property}-tooltip`;

  const mouseover = (d) => {
    const mouse = pointer(d);
    const percent = format(".2%")(data.data[property] / total);
    const value = data.data[property];

    select(className).style("opacity", 1);
    select(`${className} .tooltip-type`).text(data.data.label);
    select(`${className} .tooltip-count`).text(
      `${value} ${description} (${percent})`
    );
    select(className).style(
      "transform",
      `translate(${mouse[0]}px, ${mouse[1]}px)`
    );
  };

  const mouseout = () => select(className).style("opacity", 0);
  const arc = createArc(data);

  return (
    <g
      className="arc"
      key={index}
      onMouseOver={mouseover}
      onMouseOut={mouseout}
    >
      <path className="arc" d={arc} fill={colors(index)} />
    </g>
  );
};

const Pie = ({ data, innerRadius, property, label, outerRadius }) => {
  const createPie = pie()
    .value((d) => d[property])
    .sort(null);
  const createArc = arc().innerRadius(innerRadius).outerRadius(outerRadius);
  const colors = scaleOrdinal(schemeCategory10);
  const chartData = createPie(data);

  const total = data.reduce(
    (total, current) => (total += current[property]),
    0
  );

  return (
    <>
      <SVG
        preserveAspectRatio="xMidYMid meet"
        viewBox={`0 0 ${outerRadius * 2} ${
          outerRadius * 2 + (chartData.length + 4) * 16
        }`}
      >
        <g transform={`translate(${outerRadius} ${outerRadius})`}>
          {chartData.map((d, i) => (
            <Arc
              key={i}
              data={d}
              description={label.toLocaleLowerCase()}
              index={i}
              property={property}
              createArc={createArc}
              colors={colors}
              total={total}
            />
          ))}
          <Legend
            colors={colors}
            data={chartData}
            width={outerRadius - 20}
            y={outerRadius + 20}
          />
          <g className={`${property}-tooltip chart-tooltip`}>
            <rect rx="5"></rect>
            <text className="tooltip-type" x="8" y="16" fill="white"></text>
            <text className="tooltip-count" x="8" y="30" fill="white"></text>
          </g>
          <text
            fill="black"
            transform={`translate(-${outerRadius} ${outerRadius + 20})`}
            x="16"
          >
            {label}: {total}
          </text>
        </g>
      </SVG>
    </>
  );
};

export default Pie;
