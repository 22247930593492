import { useTranslation } from "react-i18next";
import { Modal } from "../../common";
import { Button } from "../../App.styles";

const HintMessagesModal = ({ onDismiss, show }) => {
  const { t } = useTranslation();
  return (
    <Modal onDismiss={onDismiss} show={show}>
      <div className="modal-header">
        <h4 className="modal-title">{t('Hint Messages')}</h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-hidden="true"
          onClick={onDismiss}
        >
          ×
        </button>
      </div>
      <div className="modal-body">
        <p>
          {t(`Hint messages can be anything - location suggestions, memory joggers,
          puzzles and riddles. The harder your challenge, the more hints you might
          want to add.`)}
        </p>
        <ul>
          <li>{t('Messages are limited to 255 characters.')}</li>
          <li>
            {t(`Shorter is better, hints are dismissed and can't be seen multiple
            times.`)}
          </li>
          <li>{t('Supports Personalization.')}</li>
        </ul>
      </div>
      <div className="modal-footer">
        <Button type="button" color="dark" data-dismiss="modal" onClick={onDismiss}>
          {t('Close')}
        </Button>
      </div>
    </Modal>
  )
};

export default HintMessagesModal;
