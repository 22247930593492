import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  MDBCard,
  MDBCardHeader,
  MDBCardTitle,
  MDBCardBody,
} from "mdb-react-ui-kit";
import { Row, Col } from "../App.styles";
import { useAnswerQuery } from "./Answer.state";
import Answer from "./Answer";

const AnswerCol = styled(Col)`
  flex-direction: column;
  align-items: stretch;
`;

const SidebarCol = styled(Col)`
  align-items: stretch;
  flex-direction: column;
`;

const AnswerShow = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const answer = useAnswerQuery(id);
  return (
    <Row>
      <AnswerCol size="12" lg="9">
        <Answer {...answer} />
      </AnswerCol>
      <SidebarCol size="12" lg="3">
        <MDBCard>
          <MDBCardHeader>
            <MDBCardTitle>Submissions</MDBCardTitle>
            <MDBCardBody>
              <p dangerouslySetInnerHTML={{__html: t(`Your completed challenges are submitted instantly to a live
                stream and categorized for easy viewing. The live stream is
                available on Web and inside the app.`)}} />
            </MDBCardBody>
          </MDBCardHeader>
        </MDBCard>
      </SidebarCol>
    </Row>
  );
};

export default AnswerShow;
