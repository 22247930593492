import { useState } from "react";
import styled from "styled-components";
import { MDBCard } from "mdb-react-ui-kit";
import RewardEdit from "./RewardEdit";
import RewardView from "./RewardView";
import HighlightList from "../common/HighlightList";

export const RewardCard = styled(MDBCard)`
  margin-bottom: 1.25rem;
`;

const Reward = ({ scrollId, ...props }) => {
  const [edit, setEdit] = useState(false);

  return (
    <HighlightList scrollId={scrollId} id={props.id}>
      <RewardCard>
        {edit ? (
          <RewardEdit onClose={() => setEdit(false)} {...props} />
        ) : (
          <RewardView onEdit={() => setEdit(true)} {...props} />
        )}
      </RewardCard>
    </HighlightList>
  );
};

export default Reward;
