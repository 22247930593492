import { useTranslation } from "react-i18next";
import { Modal } from "../../common";
import { Button } from "../../App.styles";

const SearchModal = ({ onDismiss, show }) => {
  const { t } = useTranslation();

  return (
    <Modal onDismiss={onDismiss} show={show}>
      <div className="modal-header">
        <h4 className="modal-title">{t(`Search`)}</h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-hidden="true"
          onClick={onDismiss}
        >
          ×
        </button>
      </div>
      <div className="modal-body">
        <p>
          {t(`There are two types of games. Games that are HIDDEN do not show up when
          they search in the app.`)}
        </p>
      </div>
      <div className="modal-footer">
        <Button color="dark" data-dismiss="modal" onClick={onDismiss}>
          {t(`Close`)}
        </Button>
      </div>
    </Modal>
  )
};

export default SearchModal;
