import { Link } from "react-router-dom";
import { Button } from "../App.styles";

const LinkButton = (props) => {
  const { children, ...btnProps } = props
  return (
    <Link to={props.to}>
      <Button {...btnProps}>
        <div dangerouslySetInnerHTML={{__html: children}} />  
      </Button>
    </Link>
  )
};

export default LinkButton;
