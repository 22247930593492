import { createTheme } from "@material-ui/core";

const theme = createTheme({
  contactURL: "https://www.socialscavenger.com/contact.html",
  dangerColor: "#DF20CC",
  headerPadding: "24px",
  infoColor: "#39c0ed",
  linkButtonColor: "rgba(0, 0, 0, 0.87)",
  maxMobileWidth: "767px",
  minDesktopWidth: "768px",
  navbarColor: "#950e0b",
  placeholderColor: "#f5f5f5",
  playerUrl: "https://m.app.outbackteambuilding.com/",
  primaryColor: "#950e0b",
  successColor: "#8bc34a",
  warningColor: "#ffa900",
  headlinesColor: "#313131",
  accent1Color: "#DF20CC",
  accent2Color: "#950e0b",
  accent3Color: "#21DE93",
  accent4Color: "#FF961B",
  lightColor: "#F8F9FF",
  darkGreyColor: "#4F4F4F",
  darkColor: "#660100",
  cardColor: "#E8F5FE",
  disabledColor: "#c8c8c8",
  white: "#FFF",
  rounded: {
    xs: "4px",
    sm: "7px",
    md: "14px",
    lg: "20px",
  },
  breakpoints: {
    values: {
      xs: 281,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

export default theme;
