import { useTranslation } from "react-i18next";
import { Modal } from "../../common";
import { Button } from "../../App.styles";

const TimezonesModal = ({ onDismiss, show }) => {
  const { t } = useTranslation();
  return (
    <Modal onDismiss={onDismiss} show={show}>
      <div className="modal-header">
        <h4 className="modal-title">{t('Timezones')}</h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-hidden="true"
          onClick={onDismiss}
        >
          ×
        </button>
      </div>
      <div className="modal-body">
        <p dangerouslySetInnerHTML={{__html: t(`Select the timezone where the game will be played. If the game is being
          played across multiple timezones, simply pick the timezone that you will
          use to define the game start and end times.`)}} />
        <ul>
          <li dangerouslySetInnerHTML={{__html: t(`The games platform will adapt automatically to the different timezones
            being used by the players.`)}} />
          <li dangerouslySetInnerHTML={{__html: t(`When times are listed in the stream, they will be relative and
            accurate to the visitor's timezone.`)}} />
          <li dangerouslySetInnerHTML={{__html: t(`Take care with daylight savings, EST and EDT are two different
            settings for example. Use the most appropriate one for the date in
            question.`)}} />
        </ul>
      </div>
      <div className="modal-footer">
        <Button color="dark" data-dismiss="modal" onClick={onDismiss}>
          {t('Close')}
        </Button>
      </div>
    </Modal>
  )
};

export default TimezonesModal;
