import { MDBCardHeader, MDBCardBody } from "mdb-react-ui-kit";
import { Row, Col, Divider } from "../App.styles";
import { ChallengeCard } from "./Challenge.styles";
import { LoadingCol, Shimmer } from "../common/Loading.styles";

const ChallengePlaceholder = () => {
  return (
    <>
      <ChallengeCard>
        <MDBCardHeader>
          <Row>
            <Col size="7" lg="9">
              <Shimmer dark type="title" />
            </Col>
            <Col size="5" lg="3">
              <Shimmer dark type="title" />
              <Shimmer dark type="title" end />
            </Col>
          </Row>
        </MDBCardHeader>
        <MDBCardBody>
          <Row>
            <LoadingCol size="2">
              <Shimmer dark media />
            </LoadingCol>
            <LoadingCol size="10">
              <Shimmer dark type="content" count={10} />
            </LoadingCol>
          </Row>
          <Divider />
          <Row>
            <Col size="9"></Col>
            <Col size="3">
              <Shimmer dark type="title" count={2} end />
              <Shimmer dark type="title" />
            </Col>
          </Row>
        </MDBCardBody>
      </ChallengeCard>
    </>
  );
};

export default ChallengePlaceholder;
