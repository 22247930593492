import { useTranslation } from "react-i18next";
import { Modal } from "../../common";
import { Button } from "../../App.styles";

const TourModal = ({ onDismiss, show }) => {
const { t } = useTranslation();

return (
  <Modal onDismiss={onDismiss} show={show}>
    <div className="modal-header">
      <h4 className="modal-title">{t(`Tour Images`)}</h4>
      <button
        type="button"
        className="close"
        data-dismiss="modal"
        aria-hidden="true"
        onClick={onDismiss}
      >
        ×
      </button>
    </div>
    <div className="modal-body">
      <p>
        {t(`Add 1, 2, 3 or 4 tour images to be displayed to the player after they
        have successfully joined a game. If more than 1, they will display in a
        carousel. The images will fill the screen on mobile and be centered both
        horizantally and vertically on desktop or other devices with different
        aspect ratios. For best results, upload a transparent PNG with no
        background.`)}
      </p>
    </div>
    <div className="modal-footer">
      <Button color="dark" data-dismiss="modal" onClick={onDismiss}>
        {t('Close')}
      </Button>
    </div>
  </Modal>
);
}

export default TourModal;
