import styled from "styled-components";

const AlertWrapper = styled.div`
  display: ${({ active }) => (active === "true" ? "flex" : "none")};
  margin-bottom: 1.5rem;
  opacity: ${({ active }) => (active === "true" ? "1" : "0")};
  transition: opacity 0.5s;
  flex-wrap: wrap;

  @media (max-width: ${({ theme }) => theme.maxMobileWidth}) {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
`;

const Alert = ({ children, color }) => {
    const RenderAlertChildren = ({ content = null }) => {
        if (Array.isArray(content)) {
            return content.map(c => {
                // empty node needs to be rendered as space
                if (c.trim() === '') {
                    return '&nbsp;'
                }
                return c
            }).map((c, id) => (
                <RenderAlertChildren content={c} key={id} />
            ))
        }
        // If the content is a plain string, render as is;
        // otherwise render as HTML (e.g. translated strings that contains foreign characters)
        return content?.props?.children
            ? content
            : <div dangerouslySetInnerHTML={{__html: content}} />
    }
    return (
        <AlertWrapper
            active={String(!!children)}
            className={`alert alert-${color}`}
            role="alert"
            data-mdb-color={color}
        >
            <RenderAlertChildren content={children} />
        </AlertWrapper>
    )
};

export default Alert;
