import styled from "styled-components";
import {
  MDBCard,
  MDBCardHeader,
  MDBCardTitle,
  MDBCardBody,
} from "mdb-react-ui-kit";
import { Button, Row, Col } from "../App.styles";
import { Shimmer } from "../common/Loading.styles";

const ReelRow = styled(Row)`
  margin-top: 1rem;
`;

const ReelCol = styled(Col)`
  display: block;

  p {
    margin: 1rem 0;
  }

  ${Button} {
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: 1rem;
  }

  br + ${Button} {
    margin-left: 0;
    margin-top: 1rem;
  }

  video {
    width: 100%;
    height: 528px;
  }
`;

const ReelsPlaceholder = () => {
  return (
    <>
      <Row>
        <MDBCard>
          <MDBCardHeader>
            <MDBCardTitle>
                <Row>
                  <Col lg={6}>
                    <Shimmer type="title" />
                  </Col>
                </Row>
            </MDBCardTitle>
          </MDBCardHeader>
          <MDBCardBody>
            <Row>
              <Col>
                <Shimmer count={2} type="title" />
              </Col>
            </Row>
            <ReelRow>
              <ReelCol size="12">
                <Shimmer type="title" />
                <Shimmer count={3} />
              </ReelCol>
              <ReelCol size="6">
                <Shimmer type="title" />
                <Shimmer count={3} />
              </ReelCol>
              <ReelCol size="6">
              <Shimmer type="title" />
                <Shimmer count={3} />
              </ReelCol>
            </ReelRow>
          </MDBCardBody>
        </MDBCard>
      </Row>
    </>
  );
};

export default ReelsPlaceholder;
