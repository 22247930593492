import { useHistory } from "react-router-dom";
import { useDecision } from "@optimizely/react-sdk";
import { useTranslation } from "react-i18next";
import {
  DropdownToggle,
  DropdownItem,
  Dropdown,
  DropdownLink,
  DropdownMenu,
} from "./LanguagePicker.styles";
import { getCurrentLanguage } from "../lib/lang";
import { useMemo } from "react";

const LanguagePicker = () => {
    const { i18n, t } = useTranslation()
    const language = getCurrentLanguage()
    const [decisionLang] = useDecision('language')
    const history = useHistory()
    const languages = useMemo(() => ({
        "af": {
            "label": "af",
            "fullname": t("Afrikaans")
        },
        "am": {
            "label": "am",
            "fullname": t("Amharic")
        },
        "ar": {
            "label": "ar",
            "fullname": t("Arabic")
        },
        "az": {
            "label": "az",
            "fullname": t("Azerbaijani")
        },
        "be": {
            "label": "be",
            "fullname": t("Belarusian")
        },
        "bg": {
            "label": "bg",
            "fullname": t("Bulgarian")
        },
        "bn": {
            "label": "bn",
            "fullname": t("Bengali")
        },
        "bs": {
            "label": "bs",
            "fullname": t("Bosnian")
        },
        "ca": {
            "label": "ca",
            "fullname": t("Catalan")
        },
        "ceb": {
            "label": "ceb",
            "fullname": t("Cebuano")
        },
        "co": {
            "label": "co",
            "fullname": t("Corsican")
        },
        "cs": {
            "label": "cs",
            "fullname": t("Czech")
        },
        "cy": {
            "label": "cy",
            "fullname": t("Welsh")
        },
        "da": {
            "label": "da",
            "fullname": t("Danish")
        },
        "de": {
            "label": "de",
            "fullname": t("German")
        },
        "el": {
            "label": "el",
            "fullname": t("Greek")
        },
        "en": {
            "label": "en",
            "fullname": t("English")
        },
        "eo": {
            "label": "eo",
            "fullname": t("Esperanto")
        },
        "es": {
            "label": "es",
            "fullname": t("Spanish")
        },
        "et": {
            "label": "et",
            "fullname": t("Estonian")
        },
        "eu": {
            "label": "eu",
            "fullname": t("Basque")
        },
        "fa": {
            "label": "fa",
            "fullname": t("Persian")
        },
        "fi": {
            "label": "fi",
            "fullname": t("Finnish")
        },
        "fr": {
            "label": "fr",
            "fullname": t("French")
        },
        "fy": {
            "label": "fy",
            "fullname": t("Frisian")
        },
        "ga": {
            "label": "ga",
            "fullname": t("Irish")
        },
        "gd": {
            "label": "gd",
            "fullname": t("Scots Gaelic")
        },
        "gl": {
            "label": "gl",
            "fullname": t("Galician")
        },
        "gu": {
            "label": "gu",
            "fullname": t("Gujarati")
        },
        "ha": {
            "label": "ha",
            "fullname": t("Hausa")
        },
        "haw": {
            "label": "haw",
            "fullname": t("Hawaiian")
        },
        "he": {
            "label": "he",
            "fullname": t("Hebrew")
        },
        "hi": {
            "label": "hi",
            "fullname": t("Hindi")
        },
        "hmn": {
            "label": "hmn",
            "fullname": t("Hmong")
        },
        "hr": {
            "label": "hr",
            "fullname": t("Croatian")
        },
        "ht": {
            "label": "ht",
            "fullname": t("Haitian Creole")
        },
        "hu": {
            "label": "hu",
            "fullname": t("Hungarian")
        },
        "hy": {
            "label": "hy",
            "fullname": t("Armenian")
        },
        "id": {
            "label": "id",
            "fullname": t("Indonesian")
        },
        "ig": {
            "label": "ig",
            "fullname": t("Igbo")
        },
        "is": {
            "label": "is",
            "fullname": t("Icelandic")
        },
        "it": {
            "label": "it",
            "fullname": t("Italian")
        },
        "ja": {
            "label": "ja",
            "fullname": t("Japanese")
        },
        "jv": {
            "label": "jv",
            "fullname": t("Javanese")
        },
        "ka": {
            "label": "ka",
            "fullname": t("Georgian")
        },
        "kk": {
            "label": "kk",
            "fullname": t("Kazakh")
        },
        "km": {
            "label": "km",
            "fullname": t("Khmer")
        },
        "kn": {
            "label": "kn",
            "fullname": t("Kannada")
        },
        "ko": {
            "label": "ko",
            "fullname": t("Korean")
        },
        "ku": {
            "label": "ku",
            "fullname": t("Kurdish")
        },
        "ky": {
            "label": "ky",
            "fullname": t("Kyrgyz")
        },
        "la": {
            "label": "la",
            "fullname": t("Latin")
        },
        "lb": {
            "label": "lb",
            "fullname": t("Luxembourgish")
        },
        "lo": {
            "label": "lo",
            "fullname": t("Lao")
        },
        "lt": {
            "label": "lt",
            "fullname": t("Lithuanian")
        },
        "lv": {
            "label": "lv",
            "fullname": t("Latvian")
        },
        "mg": {
            "label": "mg",
            "fullname": t("Malagasy")
        },
        "mi": {
            "label": "mi",
            "fullname": t("Maori")
        },
        "mk": {
            "label": "mk",
            "fullname": t("Macedonian")
        },
        "ml": {
            "label": "ml",
            "fullname": t("Malayalam")
        },
        "mn": {
            "label": "mn",
            "fullname": t("Mongolian")
        },
        "mr": {
            "label": "mr",
            "fullname": t("Marathi")
        },
        "ms": {
            "label": "ms",
            "fullname": t("Malay")
        },
        "mt": {
            "label": "mt",
            "fullname": t("Maltese")
        },
        "my": {
            "label": "my",
            "fullname": t("Myanmar (Burmese)")
        },
        "ne": {
            "label": "ne",
            "fullname": t("Nepali")
        },
        "nl": {
            "label": "nl",
            "fullname": t("Dutch")
        },
        "no": {
            "label": "no",
            "fullname": t("Norwegian")
        },
        "ny": {
            "label": "ny",
            "fullname": t("Nyanja (Chichewa)")
        },
        "or": {
            "label": "or",
            "fullname": t("Odia (Oriya)")
        },
        "pa": {
            "label": "pa",
            "fullname": t("Punjabi")
        },
        "pl": {
            "label": "pl",
            "fullname": t("Polish")
        },
        "ps": {
            "label": "ps",
            "fullname": t("Pashto")
        },
        "pt": {
            "label": "pt",
            "fullname": t("Portuguese (Portugal, Brazil)")
        },
        "ro": {
            "label": "ro",
            "fullname": t("Romanian")
        },
        "ru": {
            "label": "ru",
            "fullname": t("Russian")
        },
        "rw": {
            "label": "rw",
            "fullname": t("Kinyarwanda")
        },
        "sd": {
            "label": "sd",
            "fullname": t("Sindhi")
        },
        "si": {
            "label": "si",
            "fullname": t("Sinhala (Sinhalese)")
        },
        "sk": {
            "label": "sk",
            "fullname": t("Slovak")
        },
        "sl": {
            "label": "sl",
            "fullname": t("Slovenian")
        },
        "sm": {
            "label": "sm",
            "fullname": t("Samoan")
        },
        "sn": {
            "label": "sn",
            "fullname": t("Shona")
        },
        "so": {
            "label": "so",
            "fullname": t("Somali")
        },
        "sq": {
            "label": "sq",
            "fullname": t("Albanian")
        },
        "sr": {
            "label": "sr",
            "fullname": t("Serbian")
        },
        "st": {
            "label": "st",
            "fullname": t("Sesotho")
        },
        "su": {
            "label": "su",
            "fullname": t("Sundanese")
        },
        "sv": {
            "label": "sv",
            "fullname": t("Swedish")
        },
        "sw": {
            "label": "sw",
            "fullname": t("Swahili")
        },
        "ta": {
            "label": "ta",
            "fullname": t("Tamil")
        },
        "te": {
            "label": "te",
            "fullname": t("Telugu")
        },
        "tg": {
            "label": "tg",
            "fullname": t("Tajik")
        },
        "th": {
            "label": "th",
            "fullname": t("Thai")
        },
        "tk": {
            "label": "tk",
            "fullname": t("Turkmen")
        },
        "tl": {
            "label": "tl",
            "fullname": t("Tagalog (Filipino)")
        },
        "tr": {
            "label": "tr",
            "fullname": t("Turkish")
        },
        "tt": {
            "label": "tt",
            "fullname": t("Tatar")
        },
        "ug": {
            "label": "ug",
            "fullname": t("Uyghur")
        },
        "uk": {
            "label": "uk",
            "fullname": t("Ukrainian")
        },
        "ur": {
            "label": "ur",
            "fullname": t("Urdu")
        },
        "uz": {
            "label": "uz",
            "fullname": t("Uzbek")
        },
        "vi": {
            "label": "vi",
            "fullname": t("Vietnamese")
        },
        "xh": {
            "label": "xh",
            "fullname": t("Xhosa")
        },
        "yi": {
            "label": "yi",
            "fullname": t("Yiddish")
        },
        "yo": {
            "label": "yo",
            "fullname": t("Yoruba")
        },
        "zh-CN": {
            "label": "zh-CN",
            "fullname": t("Chinese (Simplified)")
        },
        "zh-TW": {
            "label": "zh-TW",
            "fullname": t("Chinese (Traditional)")
        },
        "zu": {
            "label": "zu",
            "fullname": t("Zulu")
        }
    }), [t])
    const languagesList = useMemo(() => {
        const langByName = (a, b) => {
            const [, { fullname: aFullname = '' }] = a
            const [, { fullname: bFullname = '' }] = b
            const aName = aFullname.toLowerCase()
            const bName = bFullname.toLowerCase()
            
            if (aName === bName) {
                return 0;
            }
            
            return aName < bName ? -1 : 1;
        }
        return Object.entries(languages).sort(langByName) 
    }, [languages])

    const onChangeLanguage = lang => {
        i18n.changeLanguage(lang)
        localStorage.setItem('i18nextLng', lang)

        // page needs to refresh after language change;
        // this will make sure the new selected language is freshly loaded components like Google Maps,
        // https://developers.google.com/maps/documentation/javascript/localization
        history.go(0)
    }

    return decisionLang?.enabled
        ? (
            <Dropdown className="language-picker">
                <DropdownToggle tag="a">
                    <DropdownLink tag="span">
                        {languages[language]?.label}
                    </DropdownLink>
                </DropdownToggle>
                <DropdownMenu>
                    {
                        languagesList.map(([lang, { fullname, icon }]) => (
                            <DropdownItem key={lang}>
                                <DropdownLink tag="span" onClick={() => onChangeLanguage(lang)}>
                                    {icon} {fullname}
                                </DropdownLink>
                            </DropdownItem>
                        ))
                    }
                </DropdownMenu>
            </Dropdown>
        )
        : null
}

export default LanguagePicker