import { useTranslation } from "react-i18next";
import { FormSelect, Input, ModelUpload, RowInfo } from "../../common";
import {
  AugmentedRealityAnimationModal,
  AugmentedRealityAssetModal,
  AugmentedRealityChainModal,
  AugmentedRealityDelayModal,
  AugmentedRealityDurationModal,
  AugmentedRealityScaleModal,
  AugmentedRealityStyleModal,
} from "../modals";

const AugmentedRealityCard = ({ useQuery }) => {
  const { t } = useTranslation()
  
  return (
    <>
      <RowInfo help={<AugmentedRealityAssetModal />}>
        <ModelUpload label={t("File")} useQuery={useQuery} />
      </RowInfo>
      <RowInfo help={<AugmentedRealityAnimationModal />}>
        <Input
            label={t("Animation")}
            name="animation_name"
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<AugmentedRealityDurationModal />}>
        <Input
            defaultValue="0.0"
            label="Duration"
            min="0.00"
            name="duration_seconds"
            step="0.01"
            useQuery={useQuery}
            type="number"
          />
      </RowInfo>
      <RowInfo help={<AugmentedRealityScaleModal />}>
        <Input
            defaultValue="0.0"
            label="Scale"
            name="scale"
            min="0.00"
            step="0.01"
            useQuery={useQuery}
            type="number"
          />
      </RowInfo>
      <RowInfo help={<AugmentedRealityDelayModal />}>
        <Input
            defaultValue="0.0"
            label="Delay"
            min="0.00"
            name="delay_seconds"
            step="0.01"
            useQuery={useQuery}
            type="number"
          />
      </RowInfo>
      <RowInfo help={<AugmentedRealityStyleModal />}>
        <FormSelect
            label={t("Style")}
            name="style"
            useQuery={useQuery}
          >
            <option>loop</option>
            <option>stop</option>
            <option>static</option>
            <option>chain-loop</option>
            <option>chain-stop</option>
          </FormSelect>
      </RowInfo>
      <RowInfo help={<AugmentedRealityChainModal />}>
        <Input
            defaultValue="0.0"
            label="Chain"
            min="0.00"
            name="chain"
            step="0.01"
            useQuery={useQuery}
            type="number"
          />
      </RowInfo>
    </>
  );
};

export default AugmentedRealityCard;
