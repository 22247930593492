import styled from "styled-components";

export const SectionContainer = styled.div`
    ${({ theme }) => `
        .form-outline {
            width: 100%;
        }
        ${theme.breakpoints.up('lg')} {
            .form-outline {
                width: 200px;
            }
        }
    `}
`;