import { useTranslation } from "react-i18next";
import { Modal } from "../../common";
import { Button } from "../../App.styles";

const TimingEndModal = ({ onDismiss, show }) => {
  const { t } = useTranslation();

  return (
    <Modal onDismiss={onDismiss} show={show}>
      <div className="modal-header">
        <h4 className="modal-title">{t('End')}</h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-hidden="true"
          onClick={onDismiss}
        >
          ×
        </button>
      </div>
      <div className="modal-body">
        <p>
          {t('Specify when you would like the challenge to stop appearing to players.')}
        </p>
        <ul>
          <li>
            {t(`Challenges that are submitted after the end time will be considered
            late and points will not be awarded.`)}
          </li>
          <li>
            {t(`If you change your timezone, double check these values as they will
            automatically adjust.`)}
          </li>
        </ul>
      </div>
      <div className="modal-footer">
        <Button type="button" color="dark" data-dismiss="modal" onClick={onDismiss}>
          {t('Close')}
        </Button>
      </div>
    </Modal>
  )
};

export default TimingEndModal;
