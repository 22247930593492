import styled from "styled-components";
import { Button as GlobalButton } from "../App.styles";

export const Button = styled(GlobalButton)`
    ${({ theme }) => `
        white-space: normal;
        max-width: 50%;
    
        ${theme.breakpoints.up('xs')} {
            white-space: nowrap;
            max-width: none;
        }

        + ${GlobalButton} {
            margin-left: 8px;
        }
    `}
`;