import styled from "styled-components";

export const FieldWrapper = styled.div`
  ${({ theme }) => `
    position: relative;
    width: 100%;

    &.input--strong {
      .form-outline .form-control ~ .form-notch {
        div {
          border-color: ${theme.darkGreyColor};
        }

        .form-notch-leading {
          border-radius: ${theme.rounded.sm} 0 0 ${theme.rounded.sm};
        }

        .form-notch-trailing {
          border-radius: 0 ${theme.rounded.sm} ${theme.rounded.sm} 0;
        }
      }

      .form-label {
        color: ${theme.darkGreyColor};
      }
    }

    .form-notch-middle {
      align-items: center;
      display: flex;

      label {
        font-size: 1rem;
        margin: 0;
        transform: translateY(0);
        transition: font-size linear 0.2s, transform linear 0.2s;
      }
    }

    .active + .form-notch .form-notch-middle label {
      font-size: 0.8rem;
      margin: 0 2px;
      transform: translateY(-1.15rem) translateY(0.1rem);
    }

    .form-label {
      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;

      ${theme.breakpoints.up('lg')} {
        white-space: normal;
        max-width: none;
      }
    }

    .form-clean {
      .form-label {
        font-size: .8rem;
        margin: .1rem;
      }

      .form-control {
        min-height: 39px;
      }
    }
  `}
`;

export const FieldContainer = styled.div`
    display: flex;
    align-items: center;
`;