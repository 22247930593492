import { useTranslation } from "react-i18next";
import theme from "../../themes";

const PurchaseAGame = () => {
  const { t } = useTranslation();

  return (
    <div className="card">
      <div className="card-header bg-dark text-light">
        <span className="card-title" dangerouslySetInnerHTML={{__html: t('Purchase a Game')}} />
      </div>
      <div className="card-body">
        <p>
          <span dangerouslySetInnerHTML={{__html: t(`Are you looking for help coming up with challenge ideas or an entire
          game? Social Scavenger and our game partners can work with you to build
          a fully custom game for your event (or get you set up with a ready to go
          game in your city). Indoors or Outdoors.`)}} />{" "}
          <a href={theme.contactURL}>{t('Tell us more about your needs')}</a>.
        </p>
      </div>
    </div>
  )
};

export default PurchaseAGame;
