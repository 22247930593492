import styled from "styled-components";
import TimezoneSelect from "./TimezoneSelect";

const TimezoneWrapper = styled.div`
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  .css-2b097c-container {
    width: 100%;
  }
`;

export const getTzOffset = offset => {
  const minsPerHour = 60
  let offsetDisplay = offset/minsPerHour
  return String(offsetDisplay.toFixed(1))
}

export const Timezone = ({ date, name, useQuery }) => {
  const [collection, setCollection] = useQuery();
  const value = collection[name];
  const timezone = value

  return (
    <TimezoneWrapper>
      <TimezoneSelect
        date={date}
        label="Timezone"
        value={timezone}
        onChange={(value) => {
          setCollection({
            [name]: value,
          });
        }}
      />
    </TimezoneWrapper>
  );
};

export default Timezone;
