import styled from "styled-components";
import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useRecoilState, useSetRecoilState } from "recoil";
import { MDBTable, MDBTableHead, MDBTableBody } from "mdb-react-ui-kit";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MyGames from "./MyGames";
import CategoryDropdown from "./CategoryDropdown";
import HistoryDropdown from "./HistoryDropdown";
import { Col, Row } from "../App.styles";
import {
  alertState,
  categoryState,
  historyState,
  pageState,
  pageSizeState,
  termState,
  useGamesQuery,
  useGamesFetchingQuery,
  usePagesQuery,
} from "./Dashboard.state";
import { useUserQuery } from "../monitor/User.state";
import { DashboardControls, Th, NewButton } from "./Dashboard.styles";
import { sortByAccess } from "./Dashboard.utilities";
import { Alert, Pagination } from "../common";
import DeleteModal from "./DeleteModal";
import { Refresher, Search } from "../common";
import { axios } from "../lib/request";
import { dashboardQueryKey } from "../lib/refresh";
import DashboardPlaceholder from "./DashboardPlaceholder";
import withPlaceholder from "../common/WithPlaceholder";

const TableWrapper = styled.div`
  max-width: 100vw;
  overflow: scroll;
`;

const Table = styled(MDBTable)`
  width: 100%;
`;

const DashboardCol = styled(Col)`
  flex-wrap: wrap;
`;

const CategoryCol = styled(Col)`
  ${({ theme }) => `
    ${theme.breakpoints.up('tablet')} {
      justify-content: flex-end;
    }
  `}
`;

const DashboardAction = styled.div`
    ${({ theme }) => `
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      > * {
        margin: 0 .5rem .5rem 0;
      }

      ${theme.breakpoints.up('lg')} {
        > * {
          margin: 0 .5rem 0 0;
        }
      }
    `}
`;

const DashboardSearch = styled.div`
    ${({ theme }) => `
      input {
        margin: 1rem 0;
        max-width: 354px;

        ${theme.breakpoints.up('sm')} {
          margin: 0;
        }

        ${theme.breakpoints.up('lg')} {
          max-width: 215px;
        }
      }
    `}
`;

const Dashboard = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const historyHook = useHistory();
  const match = location.search.match(/history=(?<history>\d+)/);
  const history = match ? match.groups.history : 3650;
  const queryClient = useQueryClient();
  const { super_admin } = useUserQuery();

  const setHistoryState = useSetRecoilState(historyState);
  const [page, setPage] = useRecoilState(pageState);
  const [pageSize, _setPageSize] = useRecoilState(pageSizeState);
  const [term, setTerm] = useRecoilState(termState);
  const [category, setCategory] = useRecoilState(categoryState);
  const games = useGamesQuery();
  const pages = usePagesQuery();
  const [deleteId, setDeleteId] = useState(0);
  const [{ type, message }, setAlert] = useRecoilState(alertState);
  const setHistory = (days) =>
    historyHook.push({ ...location, search: `?history=${days}` });
  const isFetching = useGamesFetchingQuery();
  const setPageSize = size => {
    _setPageSize(Number(size))
  }

  useEffect(() => setHistoryState(history), [history, setHistoryState]);

  const { isLoading, mutate } = useMutation(
    (deleteId) =>
      axios.delete(
        `${process.env.REACT_APP_API_GAME_DELETE_URL}/${deleteId}.json`
      ),
    {
      onSettled: () => setDeleteId(0),
      onSuccess: () => {
        queryClient.invalidateQueries(dashboardQueryKey());
        setAlert({ type: "info", message: "Your game has been deleted." });
      },
    }
  );

  const destroy = async () => {
    if (deleteId) {
      mutate(deleteId);
    }
  };

  const search = (term) => {
    setPage(1);
    setTerm(term);
  };

  const changeCategory = (category) => {
    setPage(1);
    setCategory(category);
  };

  const rows = games.slice().sort(sortByAccess);

  return (
    <>
      <Alert color={type}>{message}</Alert>
      <Row>
        <DashboardControls>
          <DashboardCol size="12" md="8">
            <DashboardAction>
              {super_admin && (
                <NewButton to="/games/edit/new">
                  {t('New Game')}
                </NewButton>
              )}
              <HistoryDropdown onSelect={setHistory} />
            </DashboardAction>
            <DashboardSearch>
              <Search
                onChange={search}
                isLoading={isFetching}
                value={term}
              />
            </DashboardSearch>
          </DashboardCol>
          <CategoryCol size="12" md="4">
            <label>{t('Filters')}:</label>
            <CategoryDropdown category={category} onSelect={changeCategory} />
          </CategoryCol>
        </DashboardControls>
        <TableWrapper>
          <Table striped={true}>
            <MDBTableHead dark>
              <tr>
                <Th>#</Th>
                <Th dangerouslySetInnerHTML={{__html: t('Name')}} />
                <Th dangerouslySetInnerHTML={{__html: t('Entry Code')}} />
                <Th dangerouslySetInnerHTML={{__html: t('Updated')}} />
                <Th dangerouslySetInnerHTML={{__html: t('Before')}} />
                <Th dangerouslySetInnerHTML={{__html: t('During')}} />
                <Th dangerouslySetInnerHTML={{__html: t('After')}} />
                <Th dangerouslySetInnerHTML={{__html: t('Access')}} />
                <Th></Th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              <MyGames rows={rows} onDelete={setDeleteId} />
            </MDBTableBody>
          </Table>
        </TableWrapper>
      </Row>
      <Row>
        <Pagination
          loading={isFetching}
          page={page}
          pageSize={pageSize}
          pages={pages}
          setPage={setPage}
          setPageSize={setPageSize}
        />
      </Row>
      <DeleteModal
        isLoading={isLoading}
        show={!!deleteId}
        onConfirm={destroy}
        onDismiss={() => setDeleteId(0)}
      />
      <Refresher
        refresh="ss.admin.create,ss.admin.delete,ss.coordinator.create,ss.coordinator.delete,ss.game.delete"
        subscriptions="ss.admin.create,ss.admin.delete,ss.coordinator.create,ss.coordinator.delete,ss.game.delete"
      />
    </>
  );
};

export default withPlaceholder(Dashboard, DashboardPlaceholder);
