import { useState } from "react";
import useUnsuspendedTranslation from "../hooks/useUnsuspendedTranslation";
import Error from "./Error";
import ErrorModal from "./ErrorModal";

const ErrorDisplay = ({ error }) => {
  const { t } = useUnsuspendedTranslation();
  const [show, setShow] = useState(true);
  switch (error?.response?.status) {
    case 401:
      window.location = "/sign-in?expired";
      return null;
    case 403:
      return (
        <Error
          title={t("Not Authorized")}
          message={t("You need to be a game administrator in order to take that action.")}
        />
      );
    case 404:
      return (
        <Error
          title={t("Not Found")}
          message={t("You need to be a game administrator in order to take that action.")}
        />
      );
    case 406:
    case 500:
    case 501:
    case 503:
      const description = {
        406: t(`<strong>Error 406</strong>: <em>The browser has requested a response using Accept- headers that the server cannot fulfill.</em>`),
        500: t(`<strong>Error 500</strong>: <em>The server encountered an unexpected condition that prevented it from fulfilling the request.</em>`),
        501: t(`<strong>Error 501</strong>: <em>The server does not support the functionality required to fulfill the request.</em>`),
        503: t(`<strong>Error 503</strong>: <em>The server is not ready to handle the request.</em>`),
      }
      const title = {
        501: t(`Server error`),
        503: t(`Server error`),
      }
      return (
        <ErrorModal
          title={title[error?.response?.status] || t("Lost connection")}
          message={(
            <>
              {t(`There has been a problem connecting with our platform. Please click retry to try again.`)}
              <p dangerouslySetInnerHTML={{__html: description[error?.response?.status]}} className="mt-4" />
            </>
          )}
          onConfirm={() => window.location.reload(true)}
          show={show}
          onDismiss={() => setShow(false)}
        />
      );
    default:
      return <pre>{error.message}</pre>;
  }
};

export default ErrorDisplay;
