import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "./Modal";
import Spinner from "./Spinner";
import { Button } from "./ConfirmationModal.style";

const ConfirmationModal = ({ 
    title = 'Confirm Action', 
    body = 'Are you sure you want to do this?', 
    confirmLabel = 'Confirm',
    confirmAction,
    isLoading, 
    children = null,
 }) => {
    const { t } = useTranslation();
    const [show, setIsShow] = useState(false)
    const onDismiss = () => setIsShow(false)
    const onShow = () => setIsShow(true)
    const onConfirm = async () => {
        try {
            confirmAction && await confirmAction()
        } catch (e) {
            console.error(e)
        }
        onDismiss()
    }

    return (
        <>
            <Modal onDismiss={onDismiss} show={show}>
              <div className="modal-header">
              <h4 className="modal-title" data-testid="modal-title">{title}</h4>
              <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                  onClick={onDismiss}
              >
                  &times;
              </button>
              </div>
              <div className="modal-body" data-testid="modal-body" dangerouslySetInnerHTML={{__html: body}} />
              <div className="modal-footer">
                <Button className="btn--narrow" color="dark" data-dismiss="modal" onClick={onDismiss}>
                    {t('Cancel')}
                </Button>
                <Button className="btn--narrow" color="primary" onClick={onConfirm}>
                    {confirmLabel} {isLoading && <Spinner size="0.875rem" />}
                </Button>
              </div>
            </Modal>
            {
                children
                    ? React.cloneElement(children, {
                        onClick: onShow,
                    })
                    : null
            }
        </>
    )
 };

export default ConfirmationModal;
