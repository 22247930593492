import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { MDBCheckbox } from "mdb-react-ui-kit"
import { useCallback, useEffect, useMemo, useState } from "react"
import equal from "fast-deep-equal"
import { Button } from "../App.styles"
import { uuidv4 } from "../lib/uuid"
import { ChainChild, ChainChildLabel, ChainParent, ChainRow, ChainWrapper } from "./ChainSelect.style"
import FormAutocomplete from "./FormAutocomplete"
import statefulField from "./StatefulField"

const ChainSelect = (props) => {
    const { 
        value: defaultValue, 
        setValue,
        backwardCompatibleValue,
        options = [], 
        help, 
        label, 
        valueKey = 'id',
        childKeys: childKeysMap = {},
        ...selectProps
    } = props
    const [parentValues, setParentValues] = useState(null)
    const [childValues, setChildValues] = useState(null)
    const taskTypesWithOptions = useMemo(() => {
        return Object.keys(childKeysMap).map(k => Number(k))
    }, [childKeysMap])

    // get tasks (parent) to only those containing valid task object
    // and filter out new additions to avoid race-condition 
    // when comparing parents with parentValues
    const getTasksContent = useCallback((tasks) => {
        return (tasks || []).filter(task => !!task.id).map(task => {
            const { task_type, trivia, multiplechoice } = task
            return {
                task_type,
                trivia,
                multiplechoice,
            }
        })
    }, [])

    // get choices (child) to only those containing valid options
    // and filter out newly added ones to avoid race-condition 
    // when comparing child with childValues
    const getChoicesContent = useCallback((choices) => {
        return (choices || []).filter(choice => choice?.length > 0).map(choice => {
            return choice.flat().join(',')
        })
    }, [])
    const onChange = useCallback(mode => ({ value, index, evt, parentIndex, childIndex } = {}) => {
        let newParentValues = [...parentValues]
        let newChildValues = [...childValues]
        if (mode === 'add') {
            // adding with a value is used in backward compatibility
            // to insert the existing unlock_by_task_id to the new unlock_by_task_ids property
            const newItem = value || { key: uuidv4() }
            newParentValues = [...parentValues, newItem]
            newChildValues = [...childValues, []]
        } else if (mode === 'choose') {
            let child = []
            if (taskTypesWithOptions.includes(value.task_type)) {
                const subOptions = value?.[childKeysMap[value?.task_type]] || ''
                if (subOptions && (child || []).length === 0) {
                    child = subOptions.split(',')
                }
            }
            newParentValues.splice(index, 1, value)
            newChildValues.splice(index, 1, child?.length > 0 
                // if a challenge is of type multiple choice, trivia, or split path
                // it will map the trivia/multiplechoice into a set of sub options;
                // otherwise it will be an empty array
                ? child.map(() => null) 
                : []
            )
        } else if (mode === 'check') {
            const { checked, value: checkValue } = evt.target
            const childValue = newChildValues[parentIndex] || []
            if (checked && checkValue) {
                childValue.splice(childIndex, 1, checkValue.trim())
            } else {
                childValue.splice(childIndex, 1, null)
            }
            newChildValues.splice(parentIndex, 1, childValue)
        } else if (mode === 'remove') {
            newParentValues.splice(index, 1)
            newChildValues.splice(index, 1)
        }

        /**
         * New attribute is unlock_by_task_ids and format is id_1;a_1:a_2,id_2;a_3:a_4
         * A few examples of how this attribute should look depending on the following tasks:

            - task 1 is a multiple choice task with correct answer A and incorrect answers B, C, D
            - task 2 is a trivia task with correct answers E, F, G
            - task 3 is a photo task
            - task 4 is a text task
            - task 5 is a video task

         * let's say we want to lock task 5 to task 1 (A + C answers), task 2 (E + F + G), task 3 and task 4
         * the unlock_by_task_ids value would be

            1;A:C, 2;E:F:G, 3, 4

         * let's say we then change
         * task 2 is a trivia task with correct answers blah, bla, bleh
         * the unlock_by_task_ids value would then be

            1;A:C, 2;blah:bla:bleh, 3, 4
         */

        const updatedValue = newParentValues.filter(p => p?.[valueKey] && p[valueKey] !== '0').map((v, index) => {
            const childPaths = (newChildValues[index] || []).filter(c => ![null, undefined].includes(c))
            if (childPaths.length > 0) {
                return `${v[valueKey]};${childPaths.join(':')}`
            }
            return v[valueKey]
        }).join(',')

        setParentValues(newParentValues)
        setChildValues(newChildValues)
        setValue(updatedValue)
    }, [parentValues, childValues, setValue, valueKey, childKeysMap, taskTypesWithOptions])

    const onAdd = () => {
        onChange('add')()
    }
    const onRemove = (index) => {
        onChange('remove')({ index })
    }
    const onChoose = (value, index) => {
        onChange('choose')({ value, index })
    }
    const onCheck = (evt, parentIndex, childIndex) => {
        onChange('check')({ evt, parentIndex, childIndex })
    }

    useEffect(() => {
        if (options.filter(opt => opt.id !== '0').length > 0) {
            if (!defaultValue) {
                if (parentValues === null && childValues === null) {
                    setParentValues([{ key: uuidv4() }])
                    setChildValues([[]])
                }
            } else {
                /**
                 * Map default value from this format:
                 * 1;A:C, 2;blah:bla:bleh, 3, 4
                 * 
                 * to this format:
                 * parentValues => [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }]
                 * childValues => [['A', null, 'C'], ['blah', 'bla', 'bleh'], [], []]
                 */
                const parents = []
                const child = []
                defaultValue.split(',').forEach(v => {
                    const [p, paths = ''] = v.split(';')
                    const c = paths.split(':')
                    const context = options.find(opt => String(opt[valueKey]) === String(p))
                    const childContext = []
                    if (taskTypesWithOptions.includes(context?.task_type)) {
                        const subOptions = (context?.[childKeysMap?.[context?.task_type]] || '').split(',').map(subOpt => subOpt.trim())
                        if (subOptions && (childContext || []).length === 0) {
                            subOptions.forEach(subOption => {
                                childContext.push(c.includes(subOption) ? subOption : null)
                            })
                        }
                    }
                    parents.push(context)
                    child.push(childContext)
                })

                // insert newly added parents that 
                // are not containing selected challenge yet
                if (Array.isArray(parentValues)) {
                    parentValues.filter(p => p.key && !p.id).forEach(p => {
                        parents.push(p)
                    })
                }

                if (
                    !equal(getTasksContent(parents), getTasksContent(parentValues)) || 
                    !equal(getChoicesContent(child), getChoicesContent(childValues))
                ) {
                    setParentValues(parents)
                    setChildValues(child)
                }
            }
        }
    }, [defaultValue, childKeysMap, childValues, parentValues, valueKey, options, taskTypesWithOptions, getTasksContent, getChoicesContent])

    useEffect(() => {
        const existingValue = options.find(opt => String(opt?.[valueKey]) === String(backwardCompatibleValue))
        if (
            parentValues && !parentValues.find(p => String(p?.id) === String(backwardCompatibleValue)) && 
            defaultValue && !defaultValue.includes(String(backwardCompatibleValue)) && existingValue
        ) {
            onChange('add')({ value:  existingValue})
        }
    }, [parentValues, defaultValue, backwardCompatibleValue, options, onChange, valueKey])
    return parentValues && childValues
        ? (
            <ChainWrapper>
                { parentValues.map((v, index) => {
                    const key = `${v?.key || v?.[valueKey]}_${index}_${v?.task_type}`
                    const firstRow = index === 0
                    const child = (v?.[childKeysMap?.[v?.task_type]] || '').split(',').map(subOpt => subOpt.trim())
                    return (
                        <ChainRow key={key}>
                            <ChainParent>
                                <FormAutocomplete
                                    help={firstRow ? help : null}
                                    label={firstRow ? label : null}
                                    options={options}
                                    stateful={false}
                                    onChange={(e, v) => onChoose(v, index)}
                                    value={parentValues[index]}
                                    {...selectProps}
                                />
                                {
                                    firstRow
                                        ? (
                                            <Button color="link" size="lg" onClick={onAdd}>
                                                <FontAwesomeIcon icon={faPlusCircle} />
                                            </Button>
                                        )
                                        : (
                                            <Button color="link" size="lg" onClick={() => onRemove(index)}>
                                                <FontAwesomeIcon icon={faMinusCircle} />
                                            </Button>
                                        )
                                }
                            </ChainParent>
                            {
                                child?.length > 0 && taskTypesWithOptions.includes(v?.task_type)
                                    ? (
                                        <ChainChild>
                                            {
                                                child.map((c, cIndex) => {
                                                    return (
                                                        <ChainChildLabel htmlFor={`check-${index}-${cIndex}`} key={`check-${index}-${cIndex}`}>
                                                            <span>{c}</span>
                                                            <MDBCheckbox defaultChecked={!!c && childValues[index].includes(c)} onChange={(e) => onCheck(e, index, cIndex)} value={c} id={`check-${index}-${cIndex}`} />
                                                        </ChainChildLabel>
                                                    )
                                                })
                                            }
                                        </ChainChild>
                                    )
                                    : null
                            }
                        </ChainRow>
                    )
                })}
            </ChainWrapper>
        )
        : null
}

export default statefulField(ChainSelect)