import i18n from "../lib/i18n";

export const getTaskTypeName = (task_type) => {
  switch (task_type) {
    case 0:
      return i18n.t("Text");
    case 1:
      return i18n.t("Photo");
    case 2:
      return i18n.t("Video");
    case 3:
      return i18n.t("Sticker + Photo");
    case 4:
      return i18n.t("QR Code");
    case 5:
      return i18n.t("Trivia");
    case 6:
      return i18n.t("Multiple Choice");
    case 7:
      return i18n.t("Augmented Reality + Photo");
    case 8:
      return i18n.t("Augmented Reality + Video");
    case 9:
      return i18n.t("Background + Photo");
    case 10:
      return i18n.t("Background + Video");
    default:
      return null;
  }
};
