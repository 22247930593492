import { Suspense } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useIsAuthenticated } from "react-auth-kit";
import { useResetRecoilState } from "recoil";
import {
  MDBNavbarItem,
  MDBDropdownLink,
} from "mdb-react-ui-kit";
import { 
  faStream,
  faGamepad,
  faSignOutAlt,
  faCog,
  faTasks,
  faUser,
  faUsers,
  faThumbsUp,
  faPlay,
  faFilm,
  faDownload,
  faGift,
  faFire,
  faUserEdit,
  faMobile,
  faTimesCircle,
  faChartBar,
  faWaveSquare,
} from "@fortawesome/free-solid-svg-icons";
import { useSignOut } from "react-auth-kit";
import { useDecision } from "@optimizely/react-sdk";
import { useTranslation } from "react-i18next";
import Spinner from "./Spinner";
import { useGameQuery } from "../settings/Settings.state";
import { teamIdState } from "../monitor/Monitor.state";
import { challengeIdState } from "../monitor/Monitor.state";
import {
  SideNavContainer,
  SideNavSpacer,
  SideNavGroup,
  BuilderNav,
  NavbarBrand,
  FontAwesomeIcon,
  CloseNavButton,
  SignOutWrapper,
} from "./SideNav.styles";
import LanguagePicker from "./LanguagePicker";
import logo from "../images/logo.png";
import theme from "../themes";
import useRouteChange from "../lib/useRouteChange";
import usePreviewButton from "../hooks/usePreviewButton";

const ListMenu = ({ game_id }) => {
  const { t } = useTranslation();
  const [game] = useGameQuery(game_id);
  const { user, code } = game || {}
  const [decisionGroups] = useDecision('groups')
  const [decisionAnalytics] = useDecision('analytics')
  const previewBtnProps = usePreviewButton({ code })
  const [decisionAdventures] = useDecision('adventures')

  if (user?.access === "Joined") {
    return (
      <MDBDropdownLink href={`/games/slideshow/${game_id}`}>
        <FontAwesomeIcon icon={faPlay}/> <span dangerouslySetInnerHTML={{__html: t('Slideshow')}} />
      </MDBDropdownLink>
    );
  }

  return (
    <>
      <NavLink to={`/games/edit/${game_id}`}>
        <MDBDropdownLink tag="span">
          <FontAwesomeIcon icon={faCog} /> <span dangerouslySetInnerHTML={{__html: t('Settings')}} />
        </MDBDropdownLink>
      </NavLink>
      <NavLink to={`/tasks/organize/${game_id}`}>
        <MDBDropdownLink tag="span">
          <FontAwesomeIcon icon={faTasks}/> <span dangerouslySetInnerHTML={{__html: t('Challenges')}} />
        </MDBDropdownLink>
      </NavLink>
      {
        decisionAdventures?.enabled
          ? (
            <NavLink to={`/games/path/${game_id}`}>
              <MDBDropdownLink tag="span">
                <FontAwesomeIcon icon={faWaveSquare} /> <span dangerouslySetInnerHTML={{__html: t('Path')}} />
              </MDBDropdownLink>
            </NavLink>
          )
          : null
      }
      <NavLink to={`/teams/organize/${game_id}`}>
        <MDBDropdownLink tag="span">
          <FontAwesomeIcon icon={faUser} /> <span dangerouslySetInnerHTML={{__html: t('Teams')}} />
        </MDBDropdownLink>
      </NavLink>
      {
        decisionAnalytics?.enabled
          ? (
            <NavLink to={`/games/analytics/${game_id}`}>
              <MDBDropdownLink tag="span">
                <FontAwesomeIcon icon={faChartBar} /> <span dangerouslySetInnerHTML={{__html: t('Analytics')}} />
              </MDBDropdownLink>
            </NavLink>
          )
          : null
      }
      {
        decisionGroups?.enabled
          ? (
            <NavLink to={`/groups/organize/${game_id}`}>
              <MDBDropdownLink tag="span">
                <FontAwesomeIcon icon={faUsers} /> <span dangerouslySetInnerHTML={{__html: 'Groups'}} />
              </MDBDropdownLink>
            </NavLink>
          )
          : null
      }
      <NavLink to={`/answers/monitor/${game_id}`}>
        <MDBDropdownLink tag="span">
          <FontAwesomeIcon icon={faThumbsUp} /> <span dangerouslySetInnerHTML={{__html: t('Monitor')}} />
        </MDBDropdownLink>
      </NavLink>
      <NavLink to={`/games/slideshow/${game_id}`}>
        <MDBDropdownLink tag="span">
          <FontAwesomeIcon icon={faPlay} /> <span dangerouslySetInnerHTML={{__html: t('Slideshow')}} />
        </MDBDropdownLink>
      </NavLink>
      <NavLink to={`/games/reels/${game_id}`}>
        <MDBDropdownLink tag="span">
          <FontAwesomeIcon icon={faFilm} /> <span dangerouslySetInnerHTML={{__html: t('Reels')}} />
        </MDBDropdownLink>
      </NavLink>
      <NavLink to={`/games/exports/${game_id}`}>
        <MDBDropdownLink tag="span">
          <FontAwesomeIcon icon={faDownload} /> <span dangerouslySetInnerHTML={{__html: t('Exports')}} />
        </MDBDropdownLink>
      </NavLink>
      <NavLink to={`/games/rewards/${game_id}`}>
        <MDBDropdownLink tag="span">
          <FontAwesomeIcon icon={faGift} /> <span dangerouslySetInnerHTML={{__html: t('Rewards')}} />
        </MDBDropdownLink>
      </NavLink>
      <NavLink to={`/games/personalizations/${game_id}`}>
        <MDBDropdownLink tag="span">
          <FontAwesomeIcon icon={faUserEdit} /> <span dangerouslySetInnerHTML={{__html: t('Personalizations')}} />
        </MDBDropdownLink>
      </NavLink>
      <NavLink to={`/games/grey/${game_id}`}>
        <MDBDropdownLink tag="span">
          <FontAwesomeIcon icon={faFire} /> <span dangerouslySetInnerHTML={{__html: t('Branding')}} />
        </MDBDropdownLink>
      </NavLink>
      <MDBDropdownLink
        {...previewBtnProps}
      >
        <FontAwesomeIcon icon={faMobile} /> <span dangerouslySetInnerHTML={{__html: t('Preview')}} />
      </MDBDropdownLink>
    </>
  );
};

const SideNav = () => {
  const { t } = useTranslation();
  const history = useLocation();
  const signOut = useSignOut();
  const resetTeamState = useResetRecoilState(teamIdState);
  const resetChallengeState = useResetRecoilState(challengeIdState);
  const [, , , game_id] = history.pathname.split("/");
  const isAuthenticated = useIsAuthenticated();
  const showBuilderSections =
    isAuthenticated() &&
    !!game_id &&
    game_id !== "new";

  const RenderIfAuth = ({ children }) => {
    return isAuthenticated()
      ? children
      : null
  }

  const hideNavbar = () => {
    document.body.classList.remove('navbar-active')
  }

  const onRouteChange = () => {
    hideNavbar()
    resetTeamState()
    resetChallengeState()
  }

  useRouteChange({ onRouteChange })

  return (
    <>
      <SideNavSpacer />
      <SideNavContainer>
        <SideNavGroup>
          <NavbarBrand tag="div">
            <a href="/">
              <img src={logo} alt="Social Scavenger" />
            </a>
            <CloseNavButton color="link" onClick={hideNavbar}>
              <FontAwesomeIcon icon={faTimesCircle} />
            </CloseNavButton>
          </NavbarBrand>
          <BuilderNav
            data-test="navbar-nav"
            className="navbar-nav justify-content-end"
          >
            <MDBNavbarItem>
              <RenderIfAuth>
                <NavLink to="/dashboard">
                  <MDBDropdownLink tag="span">
                    <FontAwesomeIcon icon={faStream}/> <span dangerouslySetInnerHTML={{__html: t('Dashboard')}} />
                  </MDBDropdownLink>
                </NavLink>
              </RenderIfAuth>
              {showBuilderSections && (
                <Suspense
                  fallback={
                    <MDBDropdownLink tag="div">
                      <Spinner size="0.875rem" />
                    </MDBDropdownLink>
                  }
                >
                  <ListMenu game_id={game_id} />
                </Suspense>
              )}
              <MDBDropdownLink href={theme.playerUrl} target="_blank">
                <FontAwesomeIcon icon={faGamepad}/> <span dangerouslySetInnerHTML={{__html: t('Play')}} />
              </MDBDropdownLink>
            </MDBNavbarItem>
          </BuilderNav>
        </SideNavGroup>
        <SignOutWrapper>
          <RenderIfAuth>
            <MDBDropdownLink href="#" onClick={signOut}>
              <FontAwesomeIcon icon={faSignOutAlt}/> <span dangerouslySetInnerHTML={{__html: t('Sign out')}} />
            </MDBDropdownLink>
          </RenderIfAuth>
          <RenderIfAuth>
            <LanguagePicker />
          </RenderIfAuth>
        </SignOutWrapper>
      </SideNavContainer>
    </>
  );
};

export default SideNav;
