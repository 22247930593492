import React, { memo, useMemo } from "react";
import { MDBBadge } from "mdb-react-ui-kit";
import { Handle, Position } from "reactflow";
import { Chips, NodeBody } from "./PathNode.style";
import ChallengeIcon from "../organize/ChallengeIcon";
import ContinuedText from "../common/ContinuedText";

const PathNode = ({ data, isConnectable }) => {
    const taskType = useMemo(() => String(data.task_type), [data.task_type])
    const hasAnswers = ['5', '6', '11'].includes(taskType)
    const paths = useMemo(() => {
        if (hasAnswers) {
            const keys = {
                '5': 'trivia',
                '6': 'multiplechoice',
                '11': 'multiplechoice',
            }
            return (data[keys[taskType]] || '').split(',')
        }
        return []
    }, [data, taskType, hasAnswers])
    return (
        <>
            <Handle
                type="target"
                position={Position.Top}
                style={{ background: '#555' }}
                isConnectable={isConnectable}
            />
            <NodeBody>
                {data.name}
                <ChallengeIcon id={data.task_type} />
                <ContinuedText text={data.description} />
                {
                    paths.length > 0
                        ? (
                            <Chips>
                                {paths.map((p, id) => (
                                    <MDBBadge color={id > 0 && taskType === '6' ? 'danger':'success'} key={p}>
                                        {p.trim()}
                                    </MDBBadge>
                                ))}
                            </Chips>
                        )
                        : null
                }
            </NodeBody>
            <Handle
                type="source"
                position={Position.Bottom}
                style={{ background: '#555' }}
                isConnectable={isConnectable}
            />
        </>
    );
}

export default memo(PathNode);