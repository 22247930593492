import { useTranslation } from "react-i18next";
import { Row, Col, HelpText } from "../../App.styles";
import { VisibilityModal } from "../modals";
import { Switch, RowInfo } from "../../common";

const VisibilitySection = ({ useQuery }) => {
  const { t } = useTranslation();

  return (
    <>
      <RowInfo help={<VisibilityModal />}>
        <Switch
            label={t("Show submissions in streams")}
            labels={{ on: t("Show"), off: t("Hide") }}
            name="hide_answers"
            on={false}
            off={true}
            useQuery={useQuery}
          />
      </RowInfo>
      <Row>
        <Col size="10">
          <HelpText className="position-relative top-0 mt-4 d-block" focus>
          You can toggle whether or not you want submissions to be shared in the stream.

          <p className="mt-2">Some challenge submissions aren't very exciting to see such as QR codes. They can be hidden.
          Some submissions might give away an answer or a location. You can hide them.
          You can change this setting before, during and after the game. Hide sensitive submissions during the action but then open them up afterwards. </p>
          </HelpText>
        </Col>
      </Row>
    </>
  )
};

export default VisibilitySection;
