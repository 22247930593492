import { useTranslation } from "react-i18next";
import {
  ColorPicker,
  FormSelect,
  ImageUpload,
  Input,
  RadioGroup,
  RichEditor,
  RowInfo,
  FormAutocomplete,
} from "../common";
import { Col } from "../App.styles";
import {
  BoldFontModal,
  ChallengesEnabledModal,
  ChallengesVisibilityModal,
  ColorModal,
  CustomEnabledModal,
  CustomURLModal,
  CustomVisibilityModal,
  FontSizeModal,
  HelpEnabledModal,
  HelpVisibilityModal,
  JoinEnabledModal,
  JoinVisibilityModal,
  LeaderboardEnabledModal,
  LeaderboardVisibilityModal,
  LogoModal,
  NotificationsEnabledModal,
  NotificationsVisibilityModal,
  ReelsEnabledModal,
  ReelsVisibilityModal,
  RegularFontModal,
  RewardsEnabledModal,
  RewardsVisibilityModal,
  StreamEnabledModal,
  StreamVisibilityModal,
  SubtitleModal,
  TitleModal,
  TourModal,
  ThemeModal,
} from "./modals";
import { useDefaultHelpTextQuery } from "./Branding.state";
import BrandingSortMenu from "./BrandingSortMenu";
import WatermarkModal from "./modals/WatermarkModal";
import MenuOrderModal from "./modals/MenuOrderModal";

export const themes = [{
  id: 0,
  name: 'Light'
}, {
  id: 1,
  name: 'Dark'
}]

const BrandingForm = ({ useQuery }) => {
  const { t } = useTranslation();
  const defaultText = useDefaultHelpTextQuery();

  return (
    <>
      <RowInfo help={<ColorModal type="top_nav_background_color" />}>
        <ColorPicker
            defaultValue="#831312"
            label={t("Top Navigation Background Color")}
            name="top_nav_background_color"
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<ColorModal type="top_nav_txt_color" />}>
        <ColorPicker
            defaultValue="#FFFFFF"
            label={t("Top Navigation Text Color")}
            name="top_nav_txt_color"
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<ColorModal type="top_nav_tint_color" />}>
        <ColorPicker
            defaultValue="#FFFFFF"
            label={t("Top Navigation Tint Color")}
            name="top_nav_tint_color"
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<ColorModal type="body_high_color" />}>
        <ColorPicker
            defaultValue="#000000"
            label={t("Body Highlight Color")}
            name="body_high_color"
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<ColorModal type="body_txt_color" />}>
        <ColorPicker
            defaultValue="#000000"
            label={t("Body Text Color")}
            name="body_txt_color"
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<ColorModal type="body_background_color" />}>
        <ColorPicker
            defaultValue="#FFFFFF"
            label={t("Body Background Color (Android only)")}
            name="body_background_color"
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<ColorModal type="body_tint_color" />}>
        <ColorPicker
            defaultValue="#1A5594"
            label={t("Body Tint Color")}
            name="body_tint_color"
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<FontSizeModal />}>
        <FormSelect
            defaultValue={16}
            help={t("Select a font size for the players app.")}
            label={t("Body Font Size (Android and Web)")}
            name="font_size"
            useQuery={useQuery}
          >
            <option value={8}>8</option>
            <option value={10}>10</option>
            <option value={12}>12</option>
            <option value={16}>16</option>
            <option value={20}>20</option>
          </FormSelect>
      </RowInfo>
      <RowInfo help={<RegularFontModal />}>
        <FormSelect
            defaultValue="Default"
            help={t("Select a font for the players app.")}
            label={t("Regular Font (Android and Web)")}
            name="font_regular"
            useQuery={useQuery}
          >
            <option value="Lato-Regular">Lato</option>
            <option value="OpenSans-Regular">OpenSans</option>
            <option value="Caveat-Regular">Caveat</option>
            <option value="Default">Default</option>
          </FormSelect>
      </RowInfo>
      <RowInfo help={<BoldFontModal />}>
        <FormSelect
            defaultValue="Default"
            help={t(`Select a font for the players app.`)}
            label={t(`Bold Font (Android and Web)`)}
            name="font_bold"
            useQuery={useQuery}
          >
            <option value="Lato-Bold">Lato</option>
            <option value="OpenSans-Bold">OpenSans</option>
            <option value="Caveat-Bold">Caveat</option>
            <option value="Default">Default</option>
          </FormSelect>
      </RowInfo>
      <RowInfo help={<LogoModal />}>
        <ImageUpload
            label={t(`Logo`)}
            name="logo"
            useQuery={useQuery}
            width={150}
          />
      </RowInfo>
      <RowInfo help={<ChallengesEnabledModal />}>
        <ImageUpload
            label={t(`Challenges`)}
            name="challengesenabled"
            type="icon"
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<TitleModal />}>
        <Input
            help={t(`The headline on the main menu.`)}
            label={t(`Challenges Title`)}
            name="challenges_title"
            maxLength={30}
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<SubtitleModal />}>
        <Input
            help={t(`The subheading on the main menu.`)}
            label={t(`Challenges Subtitle`)}
            name="challenges_subtitle"
            maxLength={30}
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<ChallengesVisibilityModal />}>
        <RadioGroup
            defaultValue={true}
            label={t(`Challenges Visibility`)}
            name="challenges_visible"
            options={[
              { value: false, label: t(`Hidden`) },
              { value: true, label: t(`Visible`) },
            ]}
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<StreamEnabledModal />}>
        <ImageUpload
            label={t(`Stream`)}
            name="streamenabled"
            type="icon"
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<TitleModal />}>
        <Input
            help={t(`The headline on the main menu.`)}
            label={t(`Stream Title`)}
            name="stream_title"
            maxLength={30}
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<SubtitleModal />}>
        <Input
            help={t(`The subheading on the main menu.`)}
            label={t(`Stream Subtitle`)}
            name="stream_subtitle"
            maxLength={30}
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<StreamVisibilityModal />}>
        <RadioGroup
            defaultValue={true}
            label={t(`Stream Visibility`)}
            name="stream_visible"
            options={[
              { value: false, label: t(`Hidden`) },
              { value: true, label: t(`Visible`) },
            ]}
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<LeaderboardEnabledModal />}>
        <ImageUpload
            label={t(`Leaderboard`)}
            name="leaderboardenabled"
            type="icon"
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<TitleModal />}>
        <Input
            help={t(`The headline on the main menu.`)}
            label={t(`Leaderboard Title`)}
            name="leaderboard_title"
            maxLength={30}
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<SubtitleModal />}>
        <Input
            help={t(`The subheading on the main menu.`)}
            label={t(`Leaderboard Subtitle`)}
            name="leaderboard_subtitle"
            maxLength={30}
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<LeaderboardVisibilityModal />}>
        <RadioGroup
            defaultValue={true}
            label={t(`Leaderboard Visibility`)}
            name="leaderboard_visible"
            options={[
              { value: false, label: t(`Hidden`) },
              { value: true, label: t(`Visible`) },
            ]}
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<ReelsEnabledModal />}>
        <ImageUpload
            label={t(`Reels`)}
            name="reelsenabled"
            type="icon"
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<TitleModal />}>
        <Input
            help={t(`The headline on the main menu.`)}
            label={t(`Reels Title`)}
            name="reels_title"
            maxLength={30}
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<SubtitleModal />}>
        <Input
            help={t(`The subheading on the main menu.`)}
            label={t(`Reels Subtitle`)}
            name="reels_subtitle"
            maxLength={30}
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<ReelsVisibilityModal />}>
        <RadioGroup
            defaultValue={true}
            label={t(`Reels Visibility`)}
            name="reels_visible"
            options={[
              { value: false, label: t(`Hidden`) },
              { value: true, label: t(`Visible`) },
            ]}
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<RewardsEnabledModal />}>
        <ImageUpload
            label={t(`Rewards`)}
            name="rewardsenabled"
            type="icon"
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<TitleModal />}>
        <Input
            help={t(`The headline on the main menu.`)}
            label={t(`Rewards Title`)}
            name="rewards_title"
            maxLength={30}
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<SubtitleModal />}>
        <Input
            help={t(`The subheading on the main menu.`)}
            label={t(`Rewards Subtitle`)}
            name="rewards_subtitle"
            maxLength={30}
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<RewardsVisibilityModal />}>
        <RadioGroup
            defaultValue={true}
            label={t(`Rewards Visibility`)}
            name="rewards_visible"
            options={[
              { value: false, label: t(`Hidden`) },
              { value: true, label: t(`Visible`) },
            ]}
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<JoinEnabledModal />}>
        <ImageUpload
            label={t(`Join`)}
            name="joinenabled"
            type="icon"
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<TitleModal />}>
        <Input
            help={t(`The headline on the main menu.`)}
            label={t(`Join Title`)}
            name="join_title"
            maxLength={30}
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<SubtitleModal />}>
        <Input
            help={t(`The subheading on the main menu.`)}
            label={t(`Join Subtitle`)}
            name="join_subtitle"
            maxLength={30}
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<JoinVisibilityModal />}>
        <RadioGroup
            defaultValue={true}
            label={t(`Join Visibility`)}
            name="join_visible"
            options={[
              { value: false, label: t(`Hidden`) },
              { value: true, label: t(`Visible`) },
            ]}
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<NotificationsEnabledModal />}>
        <ImageUpload
            label={t(`Chat`)}
            model="games"
            name="notificationsenabled"
            type="icon"
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<TitleModal />}>
        <Input
            help={t(`The headline on the main menu.`)}
            label={t(`Chat Title`)}
            name="notifications_title"
            maxLength={30}
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<SubtitleModal />}>
        <Input
            help={t(`The subheading on the main menu.`)}
            label={t(`Chat Subtitle`)}
            name="notifications_subtitle"
            maxLength={30}
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<NotificationsVisibilityModal />}>
        <RadioGroup
            defaultValue={true}
            label={t(`Chat Visibility`)}
            name="notifications_visible"
            options={[
              { value: false, label: t(`Hidden`) },
              { value: true, label: t(`Visible`) },
            ]}
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<HelpEnabledModal />}>
        <ImageUpload
            label={t(`Help`)}
            name="helpenabled"
            type="icon"
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<TitleModal />}>
        <Input
            help={t(`The headline on the main menu.`)}
            label={t(`Help Title`)}
            name="help_title"
            maxLength={30}
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<SubtitleModal />}>
        <Input
            help={t(`The subheading on the main menu.`)}
            label={t(`Help Subtitle`)}
            name="help_subtitle"
            maxLength={30}
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<HelpVisibilityModal />}>
        <RadioGroup
            defaultValue={true}
            label={t(`Help Visibility`)}
            name="help_visible"
            options={[
              { value: false, label: t(`Hidden`) },
              { value: true, label: t(`Visible`) },
            ]}
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo>
        <Col size="12">
          <RichEditor
            defaultValue={defaultText}
            label={t(`Help`)}
            name="help"
            useQuery={useQuery}
          />
        </Col>
      </RowInfo>
      <RowInfo help={<CustomEnabledModal />}>
        <ImageUpload
            label={t(`Custom`)}
            name="customenabled"
            type="icon"
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<TitleModal />}>
        <Input
            help={t(`The headline on the main menu.`)}
            label={t(`Custom Title`)}
            name="custom_title"
            maxLength={30}
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<SubtitleModal />}>
        <Input
            help={t(`The subheading on the main menu.`)}
            label={t(`Custom Subtitle`)}
            name="custom_subtitle"
            maxLength={30}
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<CustomURLModal />}>
        <Input
            help={t(`The url to load for this custom menu item.`)}
            label={t(`Custom URL`)}
            name="custom_url"
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<CustomVisibilityModal />}>
        <RadioGroup
            defaultValue={false}
            label={t(`Custom Visibility`)}
            name="custom_visible"
            options={[
              { value: false, label: t(`Hidden`) },
              { value: true, label: t(`Visible`) },
            ]}
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<TourModal />}>
        <ImageUpload
            label={t(`Tour Image 1`)}
            name="tour_1"
            type="tour"
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<TourModal />}>
        <ImageUpload
            label={t(`Tour Image 2`)}
            name="tour_2"
            type="tour"
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<TourModal />}>
        <ImageUpload
            label={t(`Tour Image 3`)}
            name="tour_3"
            type="tour"
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<TourModal />}>
        <ImageUpload
            label={t(`Tour Image 4`)}
            name="tour_4"
            type="tour"
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<ThemeModal />}>
        <FormAutocomplete
          label={t(`Theme`)}
          name="theme"
          useQuery={useQuery}
          options={themes}
          size="small"
        />
      </RowInfo>
      <RowInfo help={<MenuOrderModal />}>
        <Col size="10">
          <BrandingSortMenu useQuery={useQuery} />
        </Col>
      </RowInfo>
      <RowInfo help={<WatermarkModal />}>
        <ImageUpload
            label={t(`Watermark`)}
            name="watermark"
            type="watermark"
            useQuery={useQuery}
          />
      </RowInfo>
    </>
  );
};

export default BrandingForm;
