import { useTranslation } from "react-i18next";
import theme from "../../themes";

const CustomStickers = () => {
  const { t } = useTranslation();

  return (
    <div className="card">
      <div className="card-header bg-dark text-light">
        <span className="card-title" dangerouslySetInnerHTML={{__html: t('Custom Stickers')}} />
      </div>
      <div className="card-body">
        <p>
          <span dangerouslySetInnerHTML={{__html: t(`Are you using any stickers in your game? If not, why not?! Want to turn
          a friend / co-worker / boss into a sticker and put them into your game?
          We can turn your sticker dreams into reality (or help you dream some
          up). Just`)}} />{" "}
          <a href={theme.contactURL}>{t('tell us what you are looking for')}</a> {t(`and
          we’ll take it from there.`)}
        </p>
      </div>
    </div>
  )
};

export default CustomStickers;
