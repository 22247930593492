import { useTranslation } from "react-i18next";
import { FormAutocompleteMultiple } from "../../common/FormAutocomplete";
import {
  useAddCoordinatorMutation,
  useRemoveCoordinatorMutation,
} from "../Teams.state";
import { CardBody } from "../../App.styles";
import { Shimmer } from "../../common/Loading.styles";

const Coordinators = props => {
  const { options: players, selected: coordinators, loading } = props
  const { t } = useTranslation();
  const { addCoordinator } = useAddCoordinatorMutation();
  const { removeCoordinator } = useRemoveCoordinatorMutation();

  const handleChange = async ({ target }) => {
    const { value } = target;

    for (let player of value.filter(
      (v) => !coordinators.find((c) => c.id === v.id)
    )) {
      await addCoordinator(player);
    }

    for (let player of coordinators.filter(
      (c) => !value.find((v) => c.id === v.id)
    )) {
      await removeCoordinator(player);
    }
  };

  return (
    <CardBody className="card">
      <div className="card-header bg-dark text-light">
        <span className="card-title">{t('Coordinators')}</span>
      </div>
      <div className="card-body">
        <p dangerouslySetInnerHTML={{__html: t(`Want someone else to coordinate the event? Add them here to give them
          light weight administrative access.`)}} />
        {
          loading
          ? (
            <>
              <Shimmer dark type="title" />
              <Shimmer dark />
            </>
          )
          : (
            <FormAutocompleteMultiple
              options={players}
              mapLabel="email"
              disableClearable={true}
              size="small"
              placeholder={t("Select people...")}
              onChange={(e, value) => {
                handleChange({
                  target: {
                    value,
                  }
                })
              }}
              value={coordinators}
              stateful={false}
            />
          )
        }
      </div>
    </CardBody>
  );
};

export default Coordinators;
