import { createContext } from "react";
import { useInfiniteChallenges } from "./Organize.state";

export const OrganizeContext = createContext();
export const OrganizeContextProvider = ({ children }) => {
    const challenges = useInfiniteChallenges();

    return (
        <OrganizeContext.Provider value={{ challenges }}>
            { children }
        </OrganizeContext.Provider>
    )
}