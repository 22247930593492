import styled from "styled-components";
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBContainer,
  MDBBtn,
} from "mdb-react-ui-kit";

export const Navbar = styled(MDBNavbar)`
  ${({ theme }) => `
    background-color: ${theme.navbarColor};

    ${theme.breakpoints.up('md')} {
        display: none;
    }
  `}
`;

export const NavbarBrand = styled(MDBNavbarBrand)`
  padding: 12px 1rem;
  display: flex;
  align-items: center;

  img {
    width: auto;
    height: 40px;
  }
`;

export const Container = styled(MDBContainer)`
  ${({ theme }) => `
    color: #fff;
    
    ${theme.breakpoints.up('sm')} {
      max-width: 96vw;
    }

    ${theme.breakpoints.up('md')} {
      max-width: calc(100vw - 245px);
    }
  `}
`;

export const Button = styled(MDBBtn)`
  font-size: 1.75rem;
  color: #fff;
  padding: .625rem 1rem .5rem 1rem;

  &:hover,
  &:focus,
  &:active {
    color: #fff;
    background-color: transparent;
  }
`;

export const BrandWrapper = styled.div`
  display: flex;
  align-items: center;
`;