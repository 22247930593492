import { useState } from "react";
import { MDBCardBody } from "mdb-react-ui-kit";
import { useTranslation } from "react-i18next";
import ChallengeNav from "./ChallengeNav";
import { 
  ConfirmationModal,
  Input,
  NewImageUpload,
  RichEditor,
} from "../common";
import {
  PointsSection,
  TypeSection,
} from "./sections";
import { Button, Row, Col, ChallengeActionButtonWrapper } from "../App.styles";
import { highlightIdState, useChallengeQuery, useQueryInvalidateInfinite, useQueryNoInvalidate } from "./Organize.state";
import { useGameQuery } from "../settings/Settings.state";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { useRecoilState } from "recoil";
import usePreviewButton from "../hooks/usePreviewButton";

const ChallengeEdit = ({ id, onClose, onDelete: destroy, isDeleting }) => {
  const { t } = useTranslation();
  const useQuery = (config) => useChallengeQuery(id, config);
  const useQueryNoInvalidate_ = () => useQueryNoInvalidate(id)
  const useQueryInvalidateInfinite_ = () => useQueryInvalidateInfinite(id)
  const [{ code }] = useGameQuery();
  const [{ task_type }] = useQuery();
  const [section, setSection] = useState("reels");
  const [, setHighlightId] = useRecoilState(highlightIdState);
  const onSave = async () => {
    await onClose()
    setHighlightId(id)
  }
  const previewBtnProps = usePreviewButton({ code, id })

  const ActionDelete = () => (
    <ConfirmationModal
      title={t("Confirm Deletion")}
      body={t("Are you sure you wish to delete?")}
      confirmLabel={t("Delete")}
      confirmAction={() => {
        onClose && onClose()
        destroy(id)
      }}
      isLoading={isDeleting}
    >
      <Button disabled={isDeleting} className="btn--flat" color="warning">
        <FontAwesomeIcon icon={faTrash} /> {t('Delete')}
      </Button>
    </ConfirmationModal>
  )

  return (
    <>
      <MDBCardBody>
        <Row>
          <Col size="12" lg="3" className="align-items-start mb-4 col--stretch">
            <NewImageUpload name="photo" label={t("Image")} useQuery={useQuery} />
          </Col>
          <Col size="12" lg="5" className="align-items-start mb-4 col--stretch">
            <Input
              data-testid="challenge__input-title"
              help={t("This is the title or headline for your challenge.")}
              name="name"
              label={t("Headline")}
              useQuery={useQueryInvalidateInfinite_}
              mode="clean"
            />
          </Col>
          <Col size="12" lg="4" className="align-items-start col--stretch">
            <PointsSection useQuery={useQuery} id={id} />
          </Col>
        </Row>
        <div className="mt-4 mt-lg-0">
          <RichEditor
            help={t("This is the main text for your challenge.")}
            label={t("Description")}
            name="description"
            useQuery={useQueryNoInvalidate_}
          />
        </div>
        <TypeSection useQuery={useQuery} id={id} />
        <ChallengeNav
          task_type={task_type}
          section={section}
          setSection={setSection}
          useQuery={useQueryNoInvalidate_}
          challengeId={id}
        />
        <ChallengeActionButtonWrapper className="justify-content-between d-flex mb-md-2 g-2">
          <Col size="6" lg="4" className="col--stretch">
            <ActionDelete />
          </Col>
          <Col size="6" lg="4" className="col--stretch justify-content-center">
            <Button 
              className="btn--flat" 
              rel="noreferrer"
              outline
              {...previewBtnProps}
            >
              {t('Preview')}
            </Button>
          </Col>
          <Col size="6" lg="4" className="col--stretch justify-content-end">
            <Button data-testid="challenge__button-save" color="primary" className="btn--flat" onClick={onSave}>
              {t('Save')}
            </Button>
          </Col>
        </ChallengeActionButtonWrapper>
      </MDBCardBody>
    </>
  );
};

export default ChallengeEdit;