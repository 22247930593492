import { useTranslation } from "react-i18next";
import { Modal } from "../../common";
import { Button } from "../../App.styles";

const GrantsModal = ({ onDismiss, show }) => {
  const { t } = useTranslation();

  return (
    <Modal onDismiss={onDismiss} show={show}>
      <div className="modal-header">
        <h4 className="modal-title">{t('Grants/Awards')}</h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-hidden="true"
          onClick={onDismiss}
        >
          ×
        </button>
      </div>
      <div className="modal-body">
        <p>{t('You can limit how many players will receive this reward or clue.')}</p>
        <ul>
          <li>{t('Unlimited means every player will be eligible.')}</li>
          <li>
            {t(`If you select a particular numeric value, then only the first X will
            receive the reward or clue. Eg. Select 10 to grant only to the first
            10.`)}
          </li>
        </ul>
      </div>
      <div className="modal-footer">
        <Button color="dark" data-dismiss="modal" onClick={onDismiss}>
          {t('Close')}
        </Button>
      </div>
    </Modal>
  )
};

export default GrantsModal;
