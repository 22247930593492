import { useTranslation } from "react-i18next";
import { Modal } from "../../common";
import { Button } from "../../App.styles";

const QRDownloadModal = ({ onDismiss, show }) => {
  const { t } = useTranslation();

  return (
    <Modal onDismiss={onDismiss} show={show}>
      <div className="modal-header">
        <h4 className="modal-title">{t('QR Download')}</h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-hidden="true"
          onClick={onDismiss}
        >
          ×
        </button>
      </div>
      <div className="modal-body">
        <p>{t('This QR image will work only for this challenge.')}</p>
        <ul>
          <li>
            {t('Cloning games? QR challenges continue to work after copying your game.')}
          </li>
          <li>
            {t(`Be careful when testing these off a screen as the scanner is sensitive
            to what background color surrounds the QR image.`)}
          </li>
          <li>
            {t(`When printing, size doesn't matter but we recommend bigger than an
            inch.`)}
          </li>
          <li>
            {t(`Looking to move people around? Posters with QR codes and challenges
            locked to GPS locations are the most popular methods.`)}
          </li>
        </ul>
      </div>
      <div className="modal-footer">
        <Button type="button" color="dark" data-dismiss="modal" onClick={onDismiss}>
          {t('Close')}
        </Button>
      </div>
    </Modal>
  )
};

export default QRDownloadModal;
