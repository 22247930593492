import styled from "styled-components";

export const ContentTabWrapper = styled.div`
    ${({ theme }) => `
        background: ${theme.white};
        margin: 24px 0;
        border-radius: ${theme.rounded.sm};

        .nav-pills {
            margin-left: 0;

            .nav-item {
                width: 50%;
            }

            .nav-link {
                margin: 0;
                background: transparent;
                text-transform: none;
                font-size: .9rem;
                padding: 14px;

                &.active {
                    color: ${theme.lightColor};
                    background: ${theme.warningColor};
                    box-shadow: none;
                }
            }
        }

        .tab-content {
            padding: 24px 12px;
            margin-top: 24px;
            border-top: 1px solid ${theme.darkGreyColor}30;
        }

        ${theme.breakpoints.up('sm')} {
            .nav-pills {
                text-align: left!important;

                .nav-item {
                    width: 100%;
                }

                .nav-link {
                    padding: 14px 24px;
                }
            }

            .tab-content {
                margin-top: 0;
                border-top: none;
                border-left: 1px solid ${theme.darkGreyColor}30;
            }
        }
    `}
`;