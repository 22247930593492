import { useTranslation } from "react-i18next";
import { Modal } from "../../common";
import { Button } from "../../App.styles";

const SoundtrackModal = ({ onDismiss, show }) => {
  const { t } = useTranslation();

  return (
    <Modal onDismiss={onDismiss} show={show}>
      <div className="modal-header">
        <h4 className="modal-title">{t('Soundtrack')}</h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-hidden="true"
          onClick={onDismiss}
        >
          ×
        </button>
      </div>
      <div className="modal-body">
        <p>
          {t(`Highlight reels are created automatically for your game if you have
          unlocked this option via purchase.`)}
        </p>
        <ul>
          <li>{t('The soundtrack you select will be added to your slideshow.')}</li>
          <li dangerouslySetInnerHTML={{__html: t(`You may opt to have no soundtrack applied by selecting 'No Soundtrack'`)}} />
          <li>
            {t(`If you wish to have your own custom sountrack added, please talk to
            your Social Scavenger representative.`)}
          </li>
        </ul>
      </div>
      <div className="modal-footer">
        <Button color="dark" data-dismiss="modal" onClick={onDismiss}>
          {t('Close')}
        </Button>
      </div>
    </Modal>
  )
};

export default SoundtrackModal;
