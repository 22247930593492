import TeamDefaultThumbnail from "../images/team_default.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { TeamWrapper, TeamThumbnail, CloseIcon } from "./TeamChip.style";

const TeamChip = ({ loading, thumbnail, name, editMode, onDelete }) => {
  return (
    <TeamWrapper loading={loading}>
      {editMode ? (
        <CloseIcon onClick={onDelete}>
          <FontAwesomeIcon icon={faTimes} />
        </CloseIcon>
      ) : (
        ""
      )}
      <TeamThumbnail
        alt="group-thumbnail"
        src={thumbnail ? thumbnail : TeamDefaultThumbnail}
      />
      <p style={{ margin: 0, fontWeight: 500 }}>{name}</p>
    </TeamWrapper>
  );
};

export default TeamChip;
