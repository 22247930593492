import { useTranslation } from "react-i18next";
import { Input } from "../../common";
import { Switch, FormSelect, RowInfo } from "../../common";
import {
  HintsModal,
  HintMessagesModal,
  HintSubtractionPointsModal,
} from "../modals";

const HintSelect = (props) => (
  <FormSelect {...props}>
    {Array(100)
      .fill()
      .map((_, i) => (
        <option key={i} value={i}>
          {i}
        </option>
      ))}
    <option value={9999}>9999</option>
  </FormSelect>
);

const HintsSection = ({ useQuery }) => {
  const { t } = useTranslation();
  return (
    <>
      <RowInfo help={<HintsModal />}>
        <Switch
            label={t('Hints')}
            name="hints"
            labels={{ on: "On", off: "Off" }}
            on={true}
            off={false}
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<HintMessagesModal />}>
        <Input
            help={t('This is your message to the player.')}
            label={t('Hint 1 Message')}
            name="hint_1"
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<HintSubtractionPointsModal />}>
        <HintSelect
            label={t('Subtract after hint 1')}
            name="hint_1_points"
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<HintMessagesModal />}>
        <Input
            help={t('This is your message to the player.')}
            label={t('Hint 2 Message')}
            name="hint_2"
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<HintSubtractionPointsModal />}>
        <HintSelect
            label={t('Subtract after hint 2')}
            name="hint_2_points"
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<HintMessagesModal />}>
        <Input
            help={t('This is your message to the player.')}
            label={t('Hint 3 Message')}
            name="hint_3"
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<HintSubtractionPointsModal />}>
        <HintSelect
            label={t('Subtract after hint 3')}
            name="hint_3_points"
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<HintMessagesModal />}>
        <Input
            help={t('This is your message to the player.')}
            label={t('Hint 4 Message')}
            name="hint_4"
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<HintSubtractionPointsModal />}>
        <HintSelect
            label={t('Subtract after hint 4')}
            name="hint_4_points"
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<HintMessagesModal />}>
        <Input
            help={t('This is your message to the player.')}
            label={t('Hint 5 Message')}
            name="hint_5"
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<HintSubtractionPointsModal />}>
        <HintSelect
            label={t('Subtract after hint 5')}
            name="hint_5_points"
            useQuery={useQuery}
          />
      </RowInfo>
    </>
  )
};

export default HintsSection;
