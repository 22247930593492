import { useTranslation } from "react-i18next";
import { Modal } from "../../common";
import { Button } from "../../App.styles";

const StickerStyleModal = ({ onDismiss, show }) => {
  const { t } = useTranslation();

  return (
    <Modal onDismiss={onDismiss} show={show}>
      <div className="modal-header">
        <h4 className="modal-title">{t('Style')}</h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-hidden="true"
          onClick={onDismiss}
        >
          ×
        </button>
      </div>
      <div className="modal-body">
        <p>{t('A sticker can be fullscreen or floating.')}</p>
        <ul>
          <li>
            {t(`Fullscreen means the sticker will be locked in place and the player
            will not be able to move it, rotate it or resize it.`)}
          </li>
          <li>
            {t('These are great for frames or things you want in a fixed position.')}
          </li>
          <li>
            {t('Floating means the sticker will be rotate, resize and move enabled.')}
          </li>
        </ul>
      </div>
      <div className="modal-footer">
        <Button type="button" color="dark" data-dismiss="modal" onClick={onDismiss}>
          {t('Close')}
        </Button>
      </div>
    </Modal>
  )
};

export default StickerStyleModal;
