import { useHistory, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { useCreateMutation } from "./Settings.state";
import {
  Checkbox,
  ImageUpload,
  Input,
  RichEditor,
  RowInfo,
  Spinner,
} from "../common";
import { Button, ButtonWrapper, Col, Row } from "../App.styles";
import {
  DescriptionModal,
  EntryCodeModal,
  GameImageModal,
  LegalModal,
  NameModal,
  PrimaryEmailModal,
} from "./modals";
import CheatCodeModal from "./modals/CheatCodeModal";
import CheatCodes from "./CheatCodes";

const Mandatory = ({ useQuery }) => {
  const { t } = useTranslation();
  const { game_id } = useParams();
  const [ game ] = useQuery();
  const history = useHistory();
  const { isCreating, create } = useCreateMutation();

  const onCreate = async () => {
    const { data } = await create();
    history.push(`/tasks/organize/${data.id}`);
  };

  return (
    <>
      <RowInfo help={<NameModal />}>
        <Input
            help={t('Give your game a name. This will be visible to the public via search.')}
            label={t('Name')}
            name="name"
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<DescriptionModal />}>
        <RichEditor
            help={t('This is the main message to players at the entry gate to your game.')}
            label={t('Description')}
            model="games"
            name="description"
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<GameImageModal />}>
        <ImageUpload
            label={t('Game Image')}
            model="games"
            name="photo"
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<EntryCodeModal />}>
        <Input
            help={t('This is the secret or password each player is required to provide before joining a game.')}
            label={t('Game Entry Code')}
            name="code"
            useQuery={useQuery}
            minLength={3} 
          />
      </RowInfo>
      {
        (game?.cheats || []).length
          ? (
            <RowInfo help={<CheatCodeModal />}>
              <CheatCodes cheats={game?.cheats} />
            </RowInfo>
          )
          : null
      }
      <RowInfo help={<PrimaryEmailModal />}>
        <Input
            help={t('If we need to reach someone, we will contact this address.')}
            label={t('Primary Email')}
            name="primary_email"
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<LegalModal />}>
        <Checkbox
            disabled={game_id !== "new"}
            label={t('Legal')}
            name="signed_legal"
            useQuery={useQuery}
            text={
              <>
                {t('I agree to the Social Scavenger')}{" "}
                <a href="/terms" dangerouslySetInnerHTML={{__html: t('Terms of Use')}} />
              </>
            }
          />
      </RowInfo>
      <Row>
        <Col>
          <ButtonWrapper>
            {game_id === "new" && (
              <Button color="primary" onClick={onCreate}>
                {t('Save')}{" "}
                {isCreating ? (
                  <Spinner size="0.875rem" />
                ) : (
                  <FontAwesomeIcon icon={faAngleDoubleRight} />
                )}
              </Button>
            )}
          </ButtonWrapper>
        </Col>
      </Row>
    </>
  );
};

export default Mandatory;
