import { useTranslation } from "react-i18next";
import { Modal } from "../../common";
import { Button } from "../../App.styles";

const LockedVisibilityModal = ({ onDismiss, show }) => {
  const { t } = useTranslation();

  return (
    <Modal onDismiss={onDismiss} show={show}>
      <div className="modal-header">
        <h4 className="modal-title">{t('Locked Visibility')}</h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-hidden="true"
          onClick={onDismiss}
        >
          ×
        </button>
      </div>
      <div className="modal-body">
        <p>
          {t(`You can select whether or not players should see challenges that are
          locked in the challenges list.`)}
        </p>
        <ul>
          <li>
            {t(`When you show locked challenges it can provide motivation or
            anticipation.`)}
          </li>
          <li>{t('When you hide locked challenges it can provide surprise.')}</li>
        </ul>
      </div>
      <div className="modal-footer">
        <Button type="button" color="dark" data-dismiss="modal" onClick={onDismiss}>
          {t('Close')}
        </Button>
      </div>
    </Modal>
  )
};

export default LockedVisibilityModal;
