import { useTranslation } from "react-i18next";
import { Modal } from "../../common";
import { Button } from "../../App.styles";

const LogoModal = ({ onDismiss, show }) => {
const { t } = useTranslation();

return (
  <Modal onDismiss={onDismiss} show={show}>
    <div className="modal-header">
      <h4 className="modal-title">{t(`Logo`)}</h4>
      <button
        type="button"
        className="close"
        data-dismiss="modal"
        aria-hidden="true"
        onClick={onDismiss}
      >
        ×
      </button>
    </div>
    <div className="modal-body">
      <p>
        {t(`Give your grey label a logo. Click the thumbnail to upload a new image.`)}
      </p>
      <ul>
        <li>
          {t(`Logo images are in the top navigation bar on their mobile device.`)}
        </li>
        <li>
          {t(`The best logo images are optimized for display on the phone at 600w by
          200h pixels in size.`)}
        </li>
        <li>
          {t(`Be sure the image is a PNG and that the background is transparent.`)}
        </li>
      </ul>
    </div>
    <div className="modal-footer">
      <Button color="dark" data-dismiss="modal" onClick={onDismiss}>
        {t('Close')}
      </Button>
    </div>
  </Modal>
);
}

export default LogoModal;
