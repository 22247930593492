import styled from "styled-components";
import { Row } from "../App.styles";

export const ChallengeThumb = styled.div`
  ${({ theme, hasImage, error }) => `
    ${hasImage ? `background-color: ${theme.white};` : `background-color: ${theme.placeholderColor};`}
    height: 75px;
    width: 75px;
    margin-right: 0;
    border-radius: ${theme.rounded.xs};

    img {
      height: 75px;
      width: 75px;
      object-fit: contain;
      object-position: contain;
      border-radius: ${theme.rounded.xs};
    }

    ${
      error &&
      `
      border: 2px solid red;

      img {
        display: none;
      }
      `
    };
  `}
`;

export const ChallengeViewRow = styled(Row)`
  ${({ theme }) => `
    align-items: flex-start;
    font-size: 0.875rem;
  
    *:not(.modal-dialog) {
      max-width: 100%;
    }

    .input--strong {
      ${theme.breakpoints.up('sm')} {
        margin-bottom: 48px;
      }
    }
  `}
`;

export const BodyRow = styled(Row)`
    max-width: none;

    .align-items-unset {
      align-items: unset;
    }
`;

export const ActionWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

export const ChallengeFooter = styled(Row)`
    ${({ theme }) => `
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      margin-top: 12px;

      ${theme.breakpoints.up('md')} {
        margin-top: 0;
      }
    `}
`;