import { useState } from "react";
import { useMutation } from "react-query";
import styled from "styled-components";
import externalAxios from "axios";
import Spinner from "./Spinner";
import { axios } from "../lib/request";
import { Button, ButtonRow } from "../App.styles";

const PreviewImage = styled.div`
  background-color: ${({ theme }) => theme.placeholderColor};
  display: flex;
  height: 75px;
  margin-right: 1rem;
  width: ${({ width }) => width}px;
  align-items: center;
  justify-content: center;

  img {
    width: ${({ width }) => width}px;
    height: 75px;
    object-fit: contain;
  }

  ${({ error }) =>
    error &&
    `
    border: 2px solid red;

    img {
      display: none;
    }
  `}
`;

const ImageUploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  .form-label {
    font-size: 0.8rem;
  }

  ${ButtonRow} {
    margin-top: 0.75rem;
    margin-bottom: 0.25rem;

    ${Button} {
      margin-right: 0.25rem;
      max-width: 200px;
      text-overflow: ellipsis;
    }
  }
`;

const FileWrapper = styled.div`
  margin-right: 0.5rem;

  input[type="file"] {
    display: none;
  }

  .form-control {
    font-size: 0.8rem;
  }
`;

const findThumbURL = (assets, key) => {
  const asset = assets.videos.find(
    (asset) => asset.name === key && asset.urls?.png
  );

  if (asset) {
    return asset.urls.png;
  }

  return null;
};

const translateKey = (key) => {
  switch (key) {
    case "ya":
      return "correct";
    case "naw":
      return "incorrect";
    default:
      return key;
  }
};

const VideoUpload = ({ label, name, useQuery, width = 75 }) => {
  const [{ assets, placeholders }, setCollection] = useQuery();
  const key = translateKey(name);
  const thumb = findThumbURL(assets, key);
  const [placeholder, setPlaceholder] = useState(!thumb);
  const [error, setError] = useState(false);
  const [preview, setPreview] = useState(!placeholder);

  const { isLoading, mutateAsync } = useMutation(async (file) => {
    const [ext] = file.name.split(".").reverse();

    const url = `${
      process.env.REACT_APP_API_SIGN_URL
    }/${ext.toLocaleLowerCase()}`;
    const { data } = await axios.get(url);
    const options = { headers: { "Content-Type": file.type } };

    await externalAxios.put(data.signature, file, options);

    const [uuid, extension] = data.name.split(".");

    return await setCollection({
      placeholders: [...(placeholders || []), { key, uuid }],
      [`${name}_media_uuid`]: uuid,
      [`${name}_media_extension`]: extension.toLocaleLowerCase(),
    });
  });

  const upload = async (file) => {
    if (file) {
      try {
        setPlaceholder(false);
        setPreview(true);
        setError(false);
        await mutateAsync(file);
      } catch (e) {
        console.error(e)
      }
    }
  };

  const clear = () => {
    const media = assets.videos.filter((asset) => asset.name !== key);

    setCollection({
      assets: {
        ...assets,
        videos: [...media, { name: key, urls: {} }],
      },
      [`${name}_media_uuid`]: null,
      [`${name}_media_extension`]: null,
    });

    setPlaceholder(true);
    setPreview(false);
    setError(false);
  };

  return preview ? (
    <ImageUploadWrapper className="form-outline">
      {label && <label className="form-label">{label}</label>}
      <PreviewImage
        error={error}
        onClick={() => setPreview(false)}
        width={width}
      >
        {isLoading ? (
          <Spinner />
        ) : (
          thumb && (
            <img src={thumb} alt={label} onError={() => setError(true)} />
          )
        )}
      </PreviewImage>
    </ImageUploadWrapper>
  ) : (
    <ImageUploadWrapper className="form-outline">
      <FileWrapper>
        {label && <label className="form-label">{label}</label>}
        <input
          accept=".avi,.mkv,.mov,.mp4,.webm"
          className="form-control"
          id={`${name}-video-upload`}
          type="file"
          onChange={({ target }) => upload(target.files[0])}
        />
      </FileWrapper>
      <ButtonRow>
        <Button className="btn--flat" color="info">
          <label htmlFor={`${name}-video-upload`}>Select File</label>
        </Button>
        <Button className="btn--flat" color="warning" onClick={() => setPreview(true)}>
          Cancel
        </Button>
        <Button className="btn--flat" color="danger" disabled={!!placeholder} onClick={clear}>
          Delete Video
        </Button>
      </ButtonRow>
    </ImageUploadWrapper>
  );
};

export default VideoUpload;
