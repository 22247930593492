import styled, { keyframes, css } from "styled-components";
import { MDBCardBody } from "mdb-react-ui-kit";

export const LoadingBody = styled(MDBCardBody)(({ theme }) => `
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${theme.darkColor};
  width: 100vw;
  height: 100vh;

  .spinner-border {
    color: rgba(0, 0, 0, 0.5);
    font-size: 10rem;
    margin: 5rem;
    opacity: 0.5;
  }
`);

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`

export const LoadingText = styled.div(({ theme }) => css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  font-size: 2.5rem;
  font-weight: bold;
  color: ${theme.white};
  padding: 1.5rem;
  max-width: 400px;
  text-align: center;
  line-height: 1.2;

  .joke {
    &__question {
      display: block;
      opacity: 0;
      font-size: 2rem;
      animation: ${fadeIn} 1.5s .1s forwards;
    }

    &__answer {
      display: block;
      opacity: 0;
      font-size: 1.5rem;
      color: ${theme.accent2Color};
      margin-top: 1rem;
      animation: ${fadeIn} 1.5s 2.5s forwards;
    }
  }
`)