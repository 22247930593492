import { useState } from "react";
import { useTranslation } from "react-i18next";
import { HelpText } from "../../App.styles";
import { Switch, Input, RowInfo } from "../../common";
import { ToggleReelsModal, ReelsIntroductionModal } from "../modals";

const ReelsSection = ({ useQuery }) => {
  const { t } = useTranslation();
  const [collection] = useQuery();
  const [showIntroField, setShowIntroField] = useState(collection.reel_enabled);

  return (
    <>
      <RowInfo help={<ToggleReelsModal />}>
        <Switch
            label={t("Add Reels")}
            labels={{ on: t("Yes"), off: t("No") }}
            name="reel_enabled"
            on={true}
            off={false}
            useQuery={useQuery}
            onChange={(e) => setShowIntroField(e)}
          />
      </RowInfo>
      {
        showIntroField
          ? (
            <RowInfo help={<ReelsIntroductionModal />}>
              <div>
                  <Input
                    help={t("This title will be used to introduce submissions")}
                    label={t("Introduction slide")}
                    name="reel_intro"
                    useQuery={useQuery}
                  />
                  <HelpText className="position-relative top-0 d-block mt-4" focus>
                    {t('By default, the title will be used to introduce submissions in the various generated reels. If you provide alternative text, it will be used instead.')}
                  </HelpText>
                </div>
            </RowInfo>
          )
          : null
      }
    </>
  );
};

export default ReelsSection;
