import { useTranslation } from "react-i18next";
import { Modal } from "../../common";
import { Button } from "../../App.styles";

const BonusPointsModal = ({ onDismiss, show }) => {
  const { t } = useTranslation();

  return (
    <Modal onDismiss={onDismiss} show={show}>
      <div className="modal-header">
        <h4 className="modal-title">{t('Bonus Points')}</h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-hidden="true"
          onClick={onDismiss}
        >
          ×
        </button>
      </div>
      <div className="modal-body">
        <p>
          {t(`You can add a bonus to their submission when the reward or clue is
          granted.`)}
        </p>
        <ul>
          <li>{t('Bonuses are added automatically upon receipt of the submission.')}</li>
          <li>
            {t(`Administrators and moderators may override the bonus points after
            submission via the Monitor panel.`)}
          </li>
          <li dangerouslySetInnerHTML={{__html: t(`Don't want to give anything? Set it to 0.`)}} />
        </ul>
      </div>
      <div className="modal-footer">
        <Button color="dark" data-dismiss="modal" onClick={onDismiss}>
          {t('Close')}
        </Button>
      </div>
    </Modal>
  )
};

export default BonusPointsModal;
