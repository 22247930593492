import { atom, atomFamily, useSetRecoilState, useRecoilValue } from "recoil";
import { urlSyncEffect } from "recoil-sync";
import { number, nullable } from "@recoiljs/refine";
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { axios } from "../lib/request";
import {
  challengeStreamQueryKey,
  challengeStreamUri,
  gameStreamQueryKey,
  gameStreamUri,
  gameUri,
  globalMonitorQueryKey,
  leaderboardQueryKey,
  monitorQueryKey,
  monitorStreamQueryKey,
  teamStreamQueryKey,
  teamStreamUri,
} from "../lib/refresh";
import { MAX_PER_PAGE } from "../consts/request";

export const pageState = atom({
  key: "answers/page",
  default: 1,
  effects: [
    urlSyncEffect({
      refine: number(),
      history: 'push'
    })
  ],
});

export const pageSizeState = atom({
  key: "answers/pageSize",
  default: MAX_PER_PAGE,
  effects: [
    urlSyncEffect({
      refine: number(),
      history: 'push'
    })
  ],
});

export const challengeIdState = atom({
  key: "answers/challengeId",
  default: null,
  effects: [
    urlSyncEffect({
      refine: nullable(number()),
      history: 'push'
    })
  ],
});

export const teamIdState = atom({
  key: "answers/teamId",
  default: null,
  effects: [
    urlSyncEffect({
      refine: nullable(number()),
      history: 'push'
    })
  ],
});

export const unlockCodeState = atom({
  key: "answers/stream/code",
  default: "",
});

export const monitorQueueState = atomFamily({
  key: "answers/stream/queue",
  default: 0,
});

export const fetchAnswers = async (
  game_id,
  task_id,
  team_id,
  page,
  per_page,
  signal
) => {
  const url = `${process.env.REACT_APP_API_MONITOR_URL}/${game_id}.json`;

  const { data } = await axios({
    url,
    params: { page, per_page, task_id, team_id },
    signal,
  });

  return data;
};

const useQueryKey = (game_id) => {
  const task_id = useRecoilValue(challengeIdState);
  const team_id = useRecoilValue(teamIdState);
  const page = useRecoilValue(pageState);
  const per_page = useRecoilValue(pageSizeState);
  return monitorQueryKey(game_id, task_id, team_id, page, per_page);
};

const useStreamQueryKey = (game_id) => {
  const task_id = useRecoilValue(challengeIdState);
  const team_id = useRecoilValue(teamIdState);
  const page = useRecoilValue(pageState);
  const per_page = useRecoilValue(pageSizeState);
  return monitorStreamQueryKey(game_id, task_id, team_id, page, per_page);
};

const fetchAllGameStreams = ({ pageParam }, game_id, unlock_code) => {
  return fetchGameStream(game_id, unlock_code, pageParam);
}

export const useInfiniteGameStream = () => {
  const { game_id } = useParams();
  const unlock_code = useRecoilValue(unlockCodeState);

  return useInfiniteQuery(gameStreamQueryKey(game_id), config => fetchAllGameStreams(config, game_id, unlock_code), {
    getNextPageParam: (lastPage, pages) => {
      const ids = lastPage.answers.map(({ id }) => parseInt(id));
      const { count } = lastPage;
      const loaded = pages.reduce((sum, page) => (sum += page.length), 0);

      if (lastPage.answers.length === 0 || count <= loaded) {
        return undefined;
      }

      return Math.min(...ids);
    },
  });
}

const fetchAllChallengeStream = ({ pageParam }, game_id, challenge_id, unlock_code) =>
  fetchChallengeStream(game_id, challenge_id, unlock_code, pageParam);

export const useInfinityChallengeStream = () => {
  const { game_id, challenge_id } = useParams();
  const unlock_code = useRecoilValue(unlockCodeState);
  return useInfiniteQuery(challengeStreamQueryKey(game_id, challenge_id), config => fetchAllChallengeStream(config, game_id, challenge_id, unlock_code), {
    getNextPageParam: (lastPage, pages) => {
      const ids = lastPage.answers.map(({ id }) => parseInt(id));
      const { count } = lastPage;
      const loaded = pages.reduce(
        (sum, page) => (sum += page.answers.length),
        0
      );

      if (lastPage.answers.length === 0 || count <= loaded) {
        return undefined;
      }

      return Math.min(...ids);
    },
  })
}

const fetchAllTeamStream = ({ pageParam }, game_id, team_id, unlock_code) =>
  fetchTeamStream(game_id, team_id, unlock_code, pageParam);

export const useInfinityTeamStream = () => {
  const { game_id, team_id } = useParams();
  const unlock_code = useRecoilValue(unlockCodeState);
  return useInfiniteQuery(teamStreamQueryKey(game_id, team_id), config => fetchAllTeamStream(config, game_id, team_id, unlock_code), {
    getNextPageParam: (lastPage, pages) => {
      const ids = lastPage.answers.map(({ id }) => parseInt(id));
      const { count } = lastPage;
      const loaded = pages.reduce((sum, page) => (sum += page.length), 0);

      if (lastPage.answers.length === 0 || count <= loaded) {
        return undefined;
      }

      return Math.min(...ids);
    },
  })
}

export const useAnswersDataQuery = (game_id) => {
  const page = useRecoilValue(pageState);
  const pageSize = useRecoilValue(pageSizeState);
  const task_id = useRecoilValue(challengeIdState);
  const team_id = useRecoilValue(teamIdState);
  const key = useQueryKey(game_id);

  return useQuery(
    key,
    ({ signal }) =>
      fetchAnswers(game_id, task_id, team_id, page, pageSize, signal),
    {
      suspense: false,
    }
  );
};

export const useAnswersStreamQuery = (game_id) => {
  const page = useRecoilValue(pageState);
  const pageSize = useRecoilValue(pageSizeState);
  const task_id = useRecoilValue(challengeIdState);
  const team_id = useRecoilValue(teamIdState);
  const key = useStreamQueryKey(game_id);

  return useQuery(
    key,
    ({ signal }) =>
      fetchAnswers(game_id, task_id, team_id, page, pageSize, signal),
    {
      suspense: true,
    }
  );
};

export const useAnswersQuery = (game_id) => {
  const response = useAnswersDataQuery(game_id);
  return response?.data?.answers || [];
};

export const useAnswersFetchingQuery = (game_id) => {
  const { isFetching } = useAnswersDataQuery(game_id);
  return isFetching;
};

export const useAnswersLoadingQuery = (game_id) => {
  const { isLoading } = useAnswersDataQuery(game_id);
  return isLoading;
};

export const usePagesQuery = (game_id) => {
  const { data } = useAnswersDataQuery(game_id);
  return data?.meta?.pages || [];
};

export const fetchChallengeStream = async (
  game_id,
  challenge_id,
  code,
  token,
  per_page = 50,
  signal
) => {
  const params = { code, game_id, per_page, token };
  const { data } = await axios.get(challengeStreamUri(challenge_id), {
    params,
    signal,
  });
  return data;
};

export const fetchGameStream = async (game_id, unlock_code, token, signal) => {
  try {
    const params = { token, per_page: 50, unlock_code };
    const { data } = await axios.get(gameStreamUri(game_id), { params, signal });
    return data;
  } catch (e) {
    console.error(e)
  }
};

export const fetchTeamStream = async (
  game_id,
  team_id,
  unlock_code,
  token,
  signal
) => {
  const params = { game_id, token, per_page: 50, unlock_code };
  const { data } = await axios.get(teamStreamUri(team_id), { params, signal });
  return data;
};

const accept = async (id, review_key, params) => {
  const url = `${process.env.REACT_APP_API_ANSWERS_ACCEPT_URL}/${id}/${review_key}.json`;
  return await axios.patch(url, params);
};

const reject = async (id, review_key, params) => {
  const url = `${process.env.REACT_APP_API_ANSWERS_REJECT_URL}/${id}/${review_key}.json`;
  return await axios.patch(url, params);
};

const feature = async (id, review_key) => {
  const url = `${process.env.REACT_APP_API_ANSWERS_FEATURE_URL}/${id}/${review_key}.json`;
  return await axios.patch(url);
};

export const useAcceptMutation = (id, review_key) => {
  const { game_id } = useParams();
  const queryKey = useQueryKey(game_id);
  const queryClient = useQueryClient();

  const { isLoading, mutateAsync } = useMutation(
    (params) => accept(id, review_key, params),
    {
      onMutate: () => {
        const data = queryClient.getQueryData(queryKey);
        const index = data.answers.findIndex((answer) => answer.id === id);

        queryClient.cancelQueries(queryKey);
        const answers = [
          ...data.answers.slice(0, index),
          {
            ...data.answers[index],
            approved_at: Date.now(),
            rejected_at: null,
          },
          ...data.answers.slice(index + 1),
        ];

        queryClient.setQueryData(queryKey, { ...data, answers });
      },
      onSuccess: () => {
        queryClient.invalidateQueries(leaderboardQueryKey(game_id));
        queryClient.invalidateQueries(globalMonitorQueryKey(game_id));
      },
    }
  );

  return { accept: mutateAsync, isAccepting: isLoading };
};

export const useRejectMutation = (id, review_key) => {
  const { game_id } = useParams();
  const queryKey = useQueryKey(game_id);
  const queryClient = useQueryClient();

  const { isLoading, mutateAsync } = useMutation(
    (params) => reject(id, review_key, params),
    {
      onMutate: () => {
        const data = queryClient.getQueryData(queryKey);
        const index = data.answers.findIndex((answer) => answer.id === id);

        queryClient.cancelQueries(queryKey);
        queryClient.setQueryData(queryKey, {
          ...data,
          answers: [
            ...data.answers.slice(0, index),
            {
              ...data.answers[index],
              approved_at: null,
              featured_at: null,
              rejected_at: Date.now(),
            },
            ...data.answers.slice(index + 1),
          ],
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries(leaderboardQueryKey(game_id));
        queryClient.invalidateQueries(globalMonitorQueryKey(game_id));
      },
    }
  );

  return { reject: mutateAsync, isRejecting: isLoading };
};

export const useFeatureMutation = (id, review_key) => {
  const { game_id } = useParams();
  const queryKey = useQueryKey(game_id);
  const queryClient = useQueryClient();

  const { isLoading, mutateAsync } = useMutation(
    () => feature(id, review_key),
    {
      onMutate: () => {
        const data = queryClient.getQueryData(queryKey) || { answers: [] };
        const index = data.answers.findIndex((answer) => answer.id === id);

        queryClient.cancelQueries(queryKey);
        queryClient.setQueryData(queryKey, {
          ...data,
          answers: [
            ...data.answers.slice(0, index),
            {
              ...data.answers[index],
              featured_at: Date.now(),
            },
            ...data.answers.slice(index + 1),
          ],
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries(leaderboardQueryKey(game_id));
        queryClient.invalidateQueries(globalMonitorQueryKey(game_id));
      },
    }
  );

  return { feature: mutateAsync, isFeaturing: isLoading };
};

export const useStartGameMutation = () => {
  const { game_id } = useParams();
  const queryClient = useQueryClient();

  const { isLoading, mutateAsync } = useMutation(
    () =>
      axios.put(
        `${process.env.REACT_APP_API_GAME_UPDATE_URL}/${game_id}.json`,
        { start_anytime: 1 }
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["stream"]);
        queryClient.invalidateQueries(gameUri(game_id));
      },
    }
  );

  return { startGame: mutateAsync, isStarting: isLoading };
};

export const useStopGameMutation = () => {
  const { game_id } = useParams();
  const queryClient = useQueryClient();

  const { isLoading, mutateAsync } = useMutation(
    () =>
      axios.put(
        `${process.env.REACT_APP_API_GAME_UPDATE_URL}/${game_id}.json`,
        { start_anytime: 2 }
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["stream"]);
        queryClient.invalidateQueries(gameUri(game_id));
      },
    }
  );

  return { stopGame: mutateAsync, isStopping: isLoading };
};

export const useMonitorQueue = (game_id) => {
  const setMonitorQueue = useSetRecoilState(monitorQueueState(game_id));

  const { mutate } = useMutation(() => {
    setMonitorQueue((monitorQueue) => monitorQueue + 1);
  });

  return mutate;
};

export const useMonitorRefresh = (game_id) => {
  const queryClient = useQueryClient();
  const queryKey = useQueryKey(game_id);
  const streamQueryKey = useStreamQueryKey(game_id);
  const setMonitorQueue = useSetRecoilState(monitorQueueState(game_id));

  const { mutate } = useMutation(() => {
    if (queryClient.isFetching(streamQueryKey)) {
      queryClient.invalidateQueries(queryKey);
    } else {
      queryClient.setQueryData(
        queryKey,
        queryClient.getQueryData(streamQueryKey)
      );
    }
    setMonitorQueue(0);
  });

  return mutate;
};
