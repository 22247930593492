import { useTranslation } from "react-i18next";
import { Modal } from "../../common";
import { Button } from "../../App.styles";

const PointsModal = ({ trivia, onDismiss, show }) => {
  const { t } = useTranslation();
  return (
    <Modal onDismiss={onDismiss} show={show}>
      <div className="modal-header">
        <h4 className="modal-title">{t('Points')}</h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-hidden="true"
          onClick={onDismiss}
        >
          ×
        </button>
      </div>
      <div className="modal-body">
        <p>
          {t(`Set the points you want awarded by default for completion of this
          challenge.`)}
        </p>
        <ul>
          <li>
            {t(`Game administrators or judges can review submissions as they come in
            via the Monitor section. They are able to override the points granted
            for the submission.`)}
          </li>
          <li>
            {t(`In many cases, the default points is used as the maximum points and
            administrators apply penalties to make the score granted less.`)}
          </li>
          <li>
            {t(`Alternatively, default points can be used as the standard award with
            judges granting bonus points on top.`)}
          </li>
          <li>
            {t(`If the challenge has a timer, it can only award points for a single submission.`)}
          </li>
          {
            trivia
              ? (
                <li>
                  {t('When you choose "Use Unlimited" you are telling the system to skip to the unlimited guessing points value.   ')}     
                </li>
              )
              : null
          }
        </ul>
      </div>
      <div className="modal-footer">
        <Button type="button" color="dark" data-dismiss="modal" onClick={onDismiss}>
          {t('Close')}
        </Button>
      </div>
    </Modal>
  )
};

export default PointsModal;