import { useTranslation } from "react-i18next";
import { Row, Col, HelpText } from "../../App.styles";
import { FormSelect, RowInfo } from "../../common";
import { TimersModal } from "../modals";

const TimerSelect = (props) => (
  <FormSelect {...props}>
    {Array(100)
      .fill()
      .map((_, i) => (
        <option key={i} value={i}>
          {i}
        </option>
      ))}
  </FormSelect>
);

const TimersSection = ({ useQuery }) => {
  const { t } = useTranslation();
  
  return (
    <>
      <RowInfo help={<TimersModal />}>
        <TimerSelect
            help={t('If you want to add some pressure, set this above 0.')}
            label={t('Timer in seconds')}
            name="timer"
            useQuery={useQuery}
          />
      </RowInfo>
      <Row>
        <Col size="10">
          <HelpText className="position-relative top-0 mt-4 d-block" focus>
            Timers force the player to submit within the number of seconds specified.

            <p className="mt-2">
            0 indicates no timer.
            Players cannot use the back button to escape a timer.
            If a timer expires and the player hasn't submitted, a submission will automatically occur and the player will not get any points.
            </p>
          </HelpText>
        </Col>
      </Row>
    </>
  )
};

export default TimersSection;
