import styled from "styled-components";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  MDBCard,
  MDBCardHeader,
  MDBCardTitle,
  MDBCardBody,
} from "mdb-react-ui-kit";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useTranslation } from "react-i18next";
import { Alert, ExternalLinkButton, Spinner } from "../common";
import { useGameQuery, useReelPayMutation } from "../settings/Settings.state";
import { Button, Row, Col } from "../App.styles";
import theme from "../themes";
import usePayment from "../lib/usePayment";

const PaymentForm = styled.form`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  .col-lg-4 {
    justify-content: flex-end;
  }

  .StripeElement {
    width: 100%;
  }

  @media (max-width: ${({ theme }) => theme.maxMobileWidth}) {
    flex-direction: column;
    margin-top: 1rem;
  }

  ${Col} {
    margin-bottom: 1.5rem;
  }
`;

const PaymentCol = styled(Col)`
  border-bottom: 1px solid #bdbdbd;
  margin-right: 0.75rem;
  padding-bottom: 0.75rem;
`;

const stripeStyles = {
  style: {
    base: {
      fontSize: "16px",
    },
  },
};

const ReelsCol = styled(Col)`
  flex-direction: column;
  margin: 0 15px;
  padding: 30px;

  h2 {
    margin-bottom: 0.5rem;
  }

  p {
    margin-bottom: 1rem;
  }

  button,
  a.btn {
    margin-left: 1rem;
  }

  video {
    margin: 0 0 30px;
    max-width: 100%;
  }

  img + p {
    margin-top: 1rem;
  }
`;

const ReelsPreview = () => {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const { game_id } = useParams();
  const [game] = useGameQuery(game_id);
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(game.reels_enabled);
  const [paid, setPaid] = useState(false);
  const { isPaying, pay } = useReelPayMutation();
  const [errorMessage, setErrorMessage] = useState("");
  const { doPayment } = usePayment();

  useEffect(() => setSuccess(game.reels_enabled), [game.reels_enabled]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      setErrorMessage(error.message);
      console.error("[Reels] Payment Error: ", error);
      window.scrollTo(0, 0);
      return;
    }

    const { id: stripe_id } = paymentMethod;
    const params = { stripe_id, cost: 299 };
    doPayment({
      action: () => pay(params),
      onSuccess: () => setPaid(true),
      onError: setErrorMessage,
    })
  };

  return (
    <>
      <Row>
        <Alert color="danger">{errorMessage}</Alert>
      </Row>
      <Row>
        <Col className="col--stretch">
          <MDBCard className="flex-grow-1">
            <MDBCardHeader>
              <MDBCardTitle>{t('Automated Highlight Reels')}</MDBCardTitle>
            </MDBCardHeader>
            <MDBCardBody>
              <Row>
                <ReelsCol>
                  <video controls preload="auto">
                    <source
                      src="https://ss-production.s3.amazonaws.com/reels/g_5368_t_43689_s.mp4"
                      type="video/mp4"
                    />
                  </video>
                  <p dangerouslySetInnerHTML={{__html: t(`Unlock the ultimate highlight reels experience where our
                    system constantly refreshes personalized highlight reels for
                    every team, every challenge and for the game itself. As
                    players submit responses to your challenges, reels are
                    refreshed and circulated. Reels are shareable and provide
                    players with a beloved souvenir of the event, from their own
                    perspective.`)}} />
                  <p dangerouslySetInnerHTML={{__html: t(`Soundtrack music is configurable in your game settings under
                    the optional tab. Need us to add your own corporate theme
                    song? Anything is possible.`)}} />
                  <p>
                    $ 299 USD
                    {success ? (
                      <ExternalLinkButton
                        color="success"
                        href={theme.contactURL}
                        target="_blank"
                      >
                        {t('Thank You')}
                      </ExternalLinkButton>
                    ) : (
                      !show && (
                        <Button color="danger" onClick={() => setShow(true)}>
                          {t('Pay Now')} {isPaying && <Spinner size="0.875rem" />}
                        </Button>
                      )
                    )}
                  </p>
                </ReelsCol>
              </Row>
              <Row>
                {show && !success && (
                  <PaymentForm onSubmit={handleSubmit}>
                    <PaymentCol size="12" md="7">
                      <CardElement options={stripeStyles} />
                    </PaymentCol>
                    <Col size="12" md="4">
                      <Button type="submit" color="danger">
                        {t('Pay Now')}
                      </Button>
                    </Col>
                  </PaymentForm>
                )}
              </Row>
            </MDBCardBody>
          </MDBCard>
          {paid ? (
            <img
              alt=""
              src={`${process.env.REACT_APP_CONVERSION_PAY}&value=29900&currency_code=${process.env.REACT_APP_CURRENCY_CODE}`}
              style={{ width: "1px", height: "1px" }}
            />
          ) : (
            <img
              alt=""
              src={process.env.REACT_APP_CONVERSION_BUILD}
              style={{ width: "1px", height: "1px" }}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default ReelsPreview;