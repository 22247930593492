import { useTranslation } from "react-i18next";
import { Modal } from "../../common";
import { Button } from "../../App.styles";

const MultipleChoiceAnswersModal = ({ onDismiss, show }) => {
  const { t } = useTranslation();

  return (
    <Modal onDismiss={onDismiss} show={show}>
      <div className="modal-header">
        <h4 className="modal-title">{t('Multiple Choice Answers')}</h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-hidden="true"
          onClick={onDismiss}
        >
          ×
        </button>
      </div>
      <div className="modal-body">
        <p>
          {t(`Only the first answer listed here will be indicated as correct and
          awarded points.`)}
        </p>
        <ul>
          <li>
            {t('The button order will always be shuffled so as to keep them guessing.')}
          </li>
          <li>{t('You have 255 characters for all your answers.')}</li>
          <li>{t('You can have maximum of 5 choices.')}</li>
          <li>
            {t(`Individual answers are truncated to 25 characters to fit nicely inside
            the button.`)}
          </li>
          <li>{t('Supports Personalization.')}</li>
        </ul>
      </div>
      <div className="modal-footer">
        <Button type="button" color="dark" data-dismiss="modal" onClick={onDismiss}>
          {t('Close')}
        </Button>
      </div>
    </Modal>
  )
};

export default MultipleChoiceAnswersModal;
