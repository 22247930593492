import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Modal } from "../common";
import { Button } from "../App.styles";

const ReelsNotificationModal = ({ game, onDismiss, show, task, team }) => {
  const { t } = useTranslation();
  const type = task ? "task" : team ? "team" : "game";
  const name = task ? task.name : team ? team.name : game?.name;

  return (
    <Modal onDismiss={onDismiss} show={show}>
      <div className="modal-header">
        <h4 className="modal-title" dangerouslySetInnerHTML={{__html: t(`Reel Complete`)}} />
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-hidden="true"
          onClick={onDismiss}
        >
          ×
        </button>
      </div>
      <div className="modal-body">
        <p>
          {t(`The`)} {type} {t(`reel for`)} {type}{" "}
          <Link onClick={onDismiss} to={`/games/reels/${game?.id}`}>
            {name}
          </Link>{" "}
          {t(`has completed.`)}
        </p>
      </div>
      <div className="modal-footer">
        <Button color="dark" data-dismiss="modal" onClick={onDismiss}>
          {t(`Close`)}
        </Button>
      </div>
    </Modal>
  );
};

export default ReelsNotificationModal;
