import { useTranslation } from "react-i18next";
import { Modal } from "../../common";
import { Button } from "../../App.styles";

const CheatCodeModal = ({ onDismiss, show }) => {
  const { t } = useTranslation();

  return (
    <Modal onDismiss={onDismiss} show={show}>
      <div className="modal-header">
        <h4 className="modal-title">{t(`Cheat Codes`)}</h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-hidden="true"
          onClick={onDismiss}
        >
          ×
        </button>
      </div>
      <div className="modal-body">
        <p>
          {t(`Enter these codes when joining a game and apply the special power as you join. 
          If you are already joined to the game, then go through the join process again to apply 
          the code. As an admin, only you know these secret codes so only give them to others you 
          want to have the power.`)}
        </p>
        <ul>
          <li>{t(`Gain game admin powers.`)}</li>
          <li>{t(`Change the game start or end time earlier or later.`)}</li>
          <li>{t(`Start or stop a game immediately.`)}</li>
          <li>{t(`Show or hide the leaderboard and winners.`)}</li>
        </ul>
      </div>
      <div className="modal-footer">
        <Button color="dark" data-dismiss="modal" onClick={onDismiss}>
          {t(`Close`)}
        </Button>
      </div>
    </Modal>
  )
};

export default CheatCodeModal;
