import { memo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { Button, Col } from "../App.styles";
import ChallengeIcon from "./ChallengeIcon";
import {
  ChallengeFooter,
  ChallengeThumb,
  ChallengeViewRow,
  BodyRow,
  ActionWrapper,
} from "./ChallengeView.style";
import { InstantInput } from "../common/InstantInput";

const ChallengeView = ({
  canReorder,
  id,
  onEdit,
  onReorder,
  task_type,
  position,
  count,
  ...challenge
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState(false);
  const handleOnEdit = useCallback(() => onEdit(), [onEdit])

  if (!challenge) {
    return null;
  }

  const { description, name, assets, points, trivia_points_1 } = challenge;
  const displayPoints = task_type === 5 ? trivia_points_1 : points

  const photo =
    assets?.photos.find((photo) => photo.name === "thumb" && photo.url) ||
    assets?.photos.find((photo) => photo.name === "standard" && photo.url);

  const Action = () => {
    const ActionOrder = () => (
      <InstantInput
        disabled={!canReorder}
        label={t("Order")}
        min="1"
        max={count}
        defaultValue={position}
        setValue={(value) =>
          onReorder({ id, index: parseInt(value) })
        }
        wrapperClass="input--strong"
      />
    )
    return (
      <div className="flex-grow-1 d-flex align-items-start d-md-block gap-2 gap-sm-0">
        <ActionOrder />
      </div>
    )
  }

  const ActionEdit = () => (
    <Button className="btn--flat d-block w-100" onClick={handleOnEdit}>
      <FontAwesomeIcon icon={faEdit} /> {t('Edit')}
    </Button>
  )

  return (
    <>
      <div className="p-4">
        <ChallengeViewRow>
          <Col sm="2" className="flex-column">
            <ChallengeThumb hasImage={photo?.url} error={error}>
              {photo?.url && (
                <img
                  alt={name}
                  src={photo.url}
                  onError={() => setError(true)}
                />
              )}
            </ChallengeThumb>
            <span className="text-warning d-block mt-2">{t(`${displayPoints} points`)}</span>
          </Col>
          <Col sm="10" className="col--stretch d-block d-sm-flex">
            <div className="flex flex-col flex-grow-1">
              <BodyRow className="flex-grow-1">
                <Col md="8" xl="10" className="align-items-unset">
                  <div className="flex-grow-1 d-flex flex-column justify-content-between">
                    <div>
                      <strong>{name}</strong>
                      <div dangerouslySetInnerHTML={{ __html: description }} />
                    </div>
                  </div>
                </Col>
                <Col md="4" xl="2" className="col--stretch align-items-start mt-4 mt-sm-0">
                  <Action />
                </Col>
              </BodyRow>
              <ChallengeFooter>
                <Col>
                  <ChallengeIcon id={task_type} />
                </Col>
                <Col className="justify-content-end col--stretch">
                  <ActionWrapper>
                    <ActionEdit />
                  </ActionWrapper>
                </Col>
              </ChallengeFooter>
            </div>
          </Col>
        </ChallengeViewRow>
      </div>
    </>
  );
};

export default memo(ChallengeView);