import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import {
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBCardTitle,
} from "mdb-react-ui-kit";
import { useTranslation } from "react-i18next";
import { Button, Row, Col } from "../App.styles";
import { Alert, Refresher, SidebarLayout } from "../common";
import { Input } from "../common/Input";
import { axios } from "../lib/request";
import { useGameQuery } from "../settings/Settings.state";
import ExportsPlaceholder from "./ExportsPlaceholder";
import { withPlaceholder } from "../common";

const Card = styled(MDBCard)`
  margin-bottom: 3rem;
`;

const EmailRow = styled(Row)`
  margin: 2rem 0;
`;

const ExportButtonCol = styled(Col)`
  @media (max-width: ${({ theme }) => theme.maxMobileWidth}) {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
`;

const Exports = () => {
  const { t } = useTranslation();
  const { game_id } = useParams();
  const [email, setEmail] = useState("");
  const [media, setMedia] = useState(true);
  const [exported, setExported] = useState(false);
  const [alert, setAlert] = useState("");
  const [game] = useGameQuery(game_id);
  const windowRef = useRef();

  useEffect(() => {
    if (game.dropbox_enabled && windowRef.current) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      windowRef.current.close();
      setAlert(
        t("Your dropbox is connected and submissions will start to sync within minutes.")
      );
      windowRef.current = null;
    }
  }, [game, t]);

  const exportGame = () => {
    const url = `${process.env.REACT_APP_API_EXPORT_URL}/${game_id}/export.json`;
    axios.patch(url, { email, media });
    setExported(true);
    setAlert(
      t("Exporting game is processing! You will get notified once export is ready to download.")
    );
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const getDateDifference = (dateString) => {
    const date1 = new Date(dateString);
    const currentDate = new Date();
    const diffDays = Math.ceil(
      Math.abs(currentDate - date1) / (1000 * 60 * 60 * 24)
    );

    return diffDays;
  };

  return (
    <>
      <Alert color="info">{alert}</Alert>
      <Row>
        <SidebarLayout message={t("The content of your game belongs to you and can be exported anytime.")}>
          <Card>
            <MDBCardHeader>
              <MDBCardTitle>{t('Export on Demand')}</MDBCardTitle>
            </MDBCardHeader>
            <MDBCardBody>
              <p dangerouslySetInnerHTML={{__html: t(`Have you completed your game? If so, a zip file of your
                submissions is now available for you to export. Your zip file
                will contain a CSV file referencing all submissions.`)}} />
              <p dangerouslySetInnerHTML={{__html: t(`Export files may be very large, especially if including media,
                so please be patient while we generate this file.`)}} />
              <p dangerouslySetInnerHTML={{__html: t(`Enter the email address you would like to have the export
                delivered to:`)}} />
              <EmailRow>
                <Col size="12" md="9">
                  <Input
                    help={t("Your game export will be delivered to this email address.")}
                    label={t("Email address")}
                    value={email}
                    onChange={setEmail}
                  />
                </Col>
                <ExportButtonCol size="12" md="3">
                  {!exported && (
                    <Button color="primary" onClick={exportGame}>
                      <FontAwesomeIcon icon={faDownload} /> Go
                    </Button>
                  )}
                </ExportButtonCol>
              </EmailRow>
              <Row>
                <Col>
                  <input
                    type="checkbox"
                    checked={media}
                    onChange={({ target }) => setMedia(target.checked)}
                  />
                  <div dangerouslySetInnerHTML={{__html: t('Include photo and video rich media in the export.')}} />
                </Col>
              </Row>
              {game.export_url &&
              getDateDifference(game.export_finished_at) < 30 ? (
                <Row>
                  <Col>
                    <p style={{ marginTop: "4px" }}>
                      {t('Your last successful export is available for')}{" "}
                      <a
                        href={game.export_url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <strong>{t('download')}</strong>
                      </a>
                      .
                    </p>
                  </Col>
                </Row>
              ) : (
                ""
              )}
            </MDBCardBody>
          </Card>
        </SidebarLayout>
      </Row>
      <Refresher />
    </>
  );
};

export default withPlaceholder(Exports, ExportsPlaceholder);
