import { ButtonCopy } from "../common";
import { CheatCodesContainer } from "./CheatCodes.style";

const CheatCodes = ({ cheats = [] }) => {
    return (
        <>
            <CheatCodesContainer>
                <label className="form-label">Cheat Codes</label>
                <ButtonCopy list={cheats.map(c => ({ ...c, text: c.code}))} />
            </CheatCodesContainer>
        </>
    )
}

export default CheatCodes