import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGameQuery } from "../settings/Settings.state";
import { Refresher, SidebarLayout } from "../common";
import { Row } from "../App.styles";
import ReelsPreview from "./ReelsPreview";
import ReelsForm from "./ReelsForm";
import ReelsPlaceholder from "./ReelsPlaceholder";
import { withPlaceholder } from "../common";

const Reels = () => {
  const { t } = useTranslation();
  const { game_id } = useParams();
  const [{ reels_enabled }] = useGameQuery(game_id);

  return (
    <Row>
      <SidebarLayout message={t("Game, Challenge, and Team Reels Automated")}>
        {!reels_enabled && <ReelsPreview />}
        {reels_enabled && <ReelsForm />}
      </SidebarLayout>
      <Refresher reelScreen />
    </Row>
  );
};

export default withPlaceholder(Reels, ReelsPlaceholder);
