import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckSquare, faPencilAlt } from "@fortawesome/free-solid-svg-icons"
import { useTranslation } from "react-i18next"
import { Button, ActionButtonWrapper } from "../App.styles"

const GroupCardAction = ({ editMode, onEdit, onClose }) => {
  const { t } = useTranslation()

  return (
    <ActionButtonWrapper>
      <Button
        color="primary"
        className="btn-raised"
        onClick={
            editMode
                ? onClose
                : onEdit
        }
      >
        <FontAwesomeIcon
          icon={editMode ? faCheckSquare : faPencilAlt}
        />&nbsp;{editMode ? t("Close") : t("Edit")}
      </Button>
    </ActionButtonWrapper>
  )
}

export default GroupCardAction