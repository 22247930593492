import { useState, Children, isValidElement, cloneElement } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { Button } from "../App.styles";

export const HelpButton = styled(Button)`
  border-radius: 50%;
  font-size: 1rem;
  padding: 0.5rem;
  display: flex;

  &,
  &:active,
  &:focus {
    background-color: transparent !important;
    box-shadow: none;
    color: rgb(33, 37, 41);
  }

  &:hover {
    color: ${({ theme }) => theme.primaryColor};
    background-color: rgba(153, 153, 153, 0.2);
    border-color: rgba(153, 153, 153, 0.2);
    box-shadow: none;
  }

  @media (max-width: ${({ theme }) => theme.maxMobileWidth}) {
    margin-top: 0;
    margin-right: 0 !important;
    padding-left: 0;
    padding-right: 0;

    .card-header & {
      margin-top: 0;
    }
  }
`;

const Child = ({ child, onDismiss, show }) => {
  if (isValidElement(child)) {
    return cloneElement(child, { show, onDismiss });
  }

  return child;
};

const Help = ({ children }) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <HelpButton onClick={() => setShow(true)}>
        <FontAwesomeIcon icon={faQuestionCircle} />
      </HelpButton>
      {Children.map(children, (child) => (
        <Child child={child} show={show} onDismiss={() => setShow(false)} />
      ))}
    </>
  );
};

export default Help;
