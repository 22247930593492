import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { capitalize } from "@material-ui/core";
import { Menu, MenuLabel, FieldWrapper } from "./BrandingSortMenu.style";
import { useGameBrandingDataQuery } from "../settings/Settings.state";
import SortableList from "../common/SortableList";

const reorder = (list, start, end) => {
    const result = [...list];
    const startIndex = result.findIndex(l => l.order === start)
    const endIndex = result.findIndex(l => l.order === end)
    if (!isNaN(result[startIndex]?.order) && !isNaN(result[endIndex]?.order)) {
        const previousStartIndex = result[startIndex].order
        result[startIndex].order = result[endIndex]?.order
        result[endIndex].order = previousStartIndex
    }
    const [removed] = result.splice(startIndex, 1, result[endIndex]);
    result.splice(endIndex, 1, removed);
    return result;
}

const SortMenu = ({ menu, control, onChange }) => {
    return (
        <Menu>
            <MenuLabel>{menu?.title}</MenuLabel> {control({
                defaultValue: menu?.order,
                setValue: position => onChange({
                    [menu?.key]: position,
                }) 
            })}
        </Menu>
    )
}

const BrandingSortMenu = ({ useQuery }) => {
    const { t } = useTranslation();
    const branding = useGameBrandingDataQuery();
    const [gameData, setConfig] = useQuery()

    const displayMenu = useMemo(() => {
        const menuTranslations = {
            'challenges_order': t('Challenges'),
            'stream_order': t('Stream'),
            'leaderboard_order': t('Leaderboard'),
            'reels_order': t('Reels'),
            'rewards_order': t('Rewards'),
            'join_order': t('Join'),
            'notifications_order': t('Notifications'),
            'help_order': t('Help'),
            'custom_order': t('Custom'),
        }
        return Object.entries(branding?.main_menu || {}).map(([key, obj]) => {
            const keyOrder = `${key}_order`
            return {
                key,
                keyOrder,
                order: ![null, undefined].includes(gameData?.[`${key}_order`]) 
                    ? gameData?.[keyOrder]
                    : obj?.order,
                title: obj?.title || menuTranslations[`${key}_order`] || capitalize(key),
            }
        })
    }, [branding?.main_menu, gameData, t])

    const onMove = ({ source, destination }) => {
        const newOrder = reorder(displayMenu, Number(source), Number(destination))
        setConfig(newOrder.reduce((orders, item) => {
            return {
                ...orders,
                [item?.keyOrder]: item?.order,
            }
        }, {}))
    }

    return (
        <FieldWrapper>
            <label className="form-label">{t('Menu order')}</label>
            <SortableList>
                {
                    displayMenu.map((o, key) => (
                        <SortMenu onChange={(v) => {
                            onMove({ source: o?.order, destination: v[o?.key] })
                        }} menu={o} key={key} />
                    ))
                }
            </SortableList>
        </FieldWrapper>
    )
}

export default BrandingSortMenu