import { atom, useRecoilValue } from "recoil";
import { urlSyncEffect } from "recoil-sync";
import { string } from "@recoiljs/refine";
import { unlockCodeState } from "./Monitor.state";
import { axios } from "../lib/request";
import { leaderboardUri, leaderboardQueryKey } from "../lib/refresh";
import { useQuery } from "react-query";

export const styleState = atom({
  key: "style",
  default: '"alltime|daily|weekly"',
  effects: [
    urlSyncEffect({
      refine: string(),
      history: 'push'
    })
  ],
});

export const queryState = atom({
  key: "teams/leaderboard/query",
  default: "",
});

export const fetchLeaderboard = async (
  game_id,
  style,
  term,
  unlock_code,
  per_page
) => {
  const params = { term, style, unlock_code, per_page };
  const { data } = await axios.get(leaderboardUri(game_id), { params });
  return data;
};

export const useLeaderboardDataQuery = (game_id) => {
  const query = useRecoilValue(queryState);
  const unlock_code = useRecoilValue(unlockCodeState);
  const style = useRecoilValue(styleState)
  return useQuery(
    leaderboardQueryKey(game_id, style, query),
    () => fetchLeaderboard(game_id, style, query, unlock_code, 9999),
    { suspense: false }
  );
};

export const useLeaderboardQuery = (game_id) => {
  const { data } = useLeaderboardDataQuery(game_id);
  return data?.teams || [];
};

export const useMyQuery = (game_id) => {
  const { data } = useLeaderboardDataQuery(game_id);
  return data?.my?.[0];
};
