import React, { useCallback, useState } from "react";
import statefulField from "../common/StatefulField";
import { Label, RadioLabel, RadioWrapper, RadioContent } from "./RadioGroup.style";

function getElementPosition(el) {
  try {
    var rect = el.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
  } catch {
    return {}
  }
}


const scrollElement = target => {
  try {
    setTimeout(() => {
      const offsetTop = -12
      let attrs = `#${target?.form?.id} `;
      for (const attr of target?.attributes) {
        attrs += `[${attr?.name}="${attr?.value}"]`
      }
      const el = document.querySelector(attrs)
      window.scrollTo({
        top: offsetTop + getElementPosition(el).top - document.querySelector('.sticky-top.navbar')?.clientHeight,
        behavior: 'smooth'
      })
    }, 0)
  } catch {}
}

export const RadioGroup = ({
  className,
  label,
  defaultValue,
  name,
  options,
  value,
  setValue,
  scrollToTop = false,
}) => {
  const [localValue, setLocalValue] = useState(value)
  const commit = useCallback(
    (evt) => {
      const { target } = evt
      scrollToTop && scrollElement(evt?.nativeEvent?.target)
      setLocalValue(target.value)
      setValue(target.value)
    },
    [setValue, scrollToTop]
  );

  return (
    <RadioWrapper className={`${className} form-outline`}>
      <Label className="form-label">{label}</Label>
      <br />
      {options.map((option) => {
        const checked =
          value === null || value === undefined
            ? defaultValue === option.value
            : value === option.value;
        return (
          <React.Fragment key={option.value}>
            <RadioLabel className="form-label radio__item">
              <input
                type="radio"
                name={name}
                onChange={commit}
                defaultValue={option.value}
                defaultChecked={checked}
              />{" "}
              {option.label}
            </RadioLabel>
            {
              (String(localValue) === String(option.value)) && option.children
                ? (
                  <RadioContent>
                    <div className="radio__item__body">{option.children}</div>
                  </RadioContent>
                )
                : null
            }
          </React.Fragment>
        );
      })}
    </RadioWrapper>
  );
};

export default statefulField(RadioGroup);
