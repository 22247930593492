import { useState } from "react";
import useWebSocket from "react-use-websocket";
import { INFINITE_QUERY_KEY, MAX_PER_PAGE } from "../consts/request";

export const answerQueryKey = (id) => ["answer", id];
export const tagsAutocompleteQueryKey = () => ["tags", "autocomplete"];
export const challengesUri = (game_id) =>
  `${process.env.REACT_APP_API_CHALLENGES_URL}/${game_id}.json`;
export const challengesQueryKey = (game_id, term, page, per_page) =>
  term || (page && page !== 1) || (per_page && per_page !== MAX_PER_PAGE)
    ? ["games", parseInt(game_id), "challenges", term, page, per_page]
    : ["games", parseInt(game_id), "challenges"];
export const challengeStreamUri = (challenge_id) =>
  `${process.env.REACT_APP_API_STREAM_TASK_URL}/${challenge_id}.json`;
export const dashboardUri = () => process.env.REACT_APP_API_GAMES_URL;
export const dashboardQueryKey = (filters, history, page, per_page, term) =>
  filters ||
  history ||
  (page && page !== 1) ||
  (per_page && per_page !== MAX_PER_PAGE) ||
  term
    ? ["games", filters, history, page, per_page, term]
    : ["games"];
export const gameUri = (game_id) =>
  `${process.env.REACT_APP_API_GAME_RETRIEVE_URL}/${game_id}.json`;
export const gameBrandingUri = (game_id) =>
  `https://app.socialscavenger.com/v1/games/${game_id}/grey.json`;
export const gameStreamUri = (game_id) =>
  `${process.env.REACT_APP_API_STREAM_GAME_URL}/${game_id}.json`;
export const leaderboardUri = (game_id) =>
  `${process.env.REACT_APP_API_LEADERBOARD_URL}?game_id=${game_id}`;
export const leaderboardQueryKey = (game_id, style, query) =>
  style || query
    ? ["leaderboard", game_id, style, query]
    : ["leaderboard", game_id];
export const libraryUri = (game_id) =>
  `${process.env.REACT_APP_API_LIBRARY_URL}?game_id=${game_id}`;
export const libraryQueryKey = (game_id, page, per_page, terms = []) =>
  (page && page !== 1) || (per_page && per_page !== MAX_PER_PAGE) || terms.length
    ? ["library", game_id, page, per_page].concat(terms)
    : ["library", game_id];
export const monitorUri = (game_id) =>
  `${process.env.REACT_APP_API_MONITOR_URL}?game_id=${game_id}`;

export const globalMonitorQueryKey = (game_id) => [
  "stream",
  "monitor",
  game_id,
];
export const monitorQueryKey = (game_id, task_id, team_id, page, per_page) =>
  task_id || team_id
    ? ["stream", "monitor", parseInt(game_id), task_id, team_id, page, per_page]
    : ["stream", "monitor", parseInt(game_id), page || 1, per_page || MAX_PER_PAGE];
// ? ["monitor", parseInt(game_id), task_id, team_id, page, per_page]
// : ["monitor", parseInt(game_id), page || 1, per_page || MAX_PER_PAGE];
export const monitorStreamQueryKey = (
  game_id,
  task_id,
  team_id,
  page,
  per_page
) =>
  task_id || team_id
    ? ["stream", "monitor", parseInt(game_id), task_id, team_id, page, per_page]
    : ["stream", "monitor", parseInt(game_id), page || 1, per_page || MAX_PER_PAGE];

export const playersQueryKey = (game_id) => teamsQueryKey(game_id, 1, INFINITE_QUERY_KEY);
export const personalizationsUri = (game_id) =>
  `https://app.socialscavenger.com/v1/personalizations.json?game_id=${game_id}`;
export const slideshowUri = (game_id) =>
  `${process.env.REACT_APP_API_SLIDESHOW_URL}/${game_id}/slideshow.json`;
export const rewardsUri = (game_id) =>
  `${process.env.REACT_APP_API_REWARD_RETRIEVE_URL}?game_id=${game_id}`;
export const rewardsQueryKey = (game_id, page, per_page) =>
  (page && page !== 1) || (per_page && per_page !== MAX_PER_PAGE)
    ? ["rewards", game_id, page, per_page]
    : ["rewards", game_id];
export const teamsUri = (game_id) =>
  `${process.env.REACT_APP_API_TEAM_RETRIEVE_URL}?game_id=${game_id}`;
export const teamsQueryKey = (game_id, page, per_page) =>
  (page && page !== 1) || (per_page && per_page !== MAX_PER_PAGE)
    ? ["teams", parseInt(game_id), page, per_page]
    : ["teams", parseInt(game_id)];
export const teamStreamUri = (team_id) =>
  `${process.env.REACT_APP_API_STREAM_TEAM_URL}/${team_id}.json`;

export const challengeStreamQueryKey = (game_id, challenge_id) => [
  "stream",
  game_id?.toString(),
  "challenge",
  challenge_id?.toString(),
];
export const gameStreamQueryKey = (game_id) => ["stream", game_id?.toString()];
export const teamStreamQueryKey = (game_id, team_id) => [
  "stream",
  game_id?.toString(),
  "team",
  team_id?.toString(),
];
export const groupsUri = (game_id) =>
  `${process.env.REACT_APP_API_GROUPS_RETRIEVE_URL}?game_id=${game_id}`;
export const groupsQueryKey = (game_id, page, per_page) =>
  (page && page !== 1) || (per_page && per_page !== MAX_PER_PAGE)
    ? ["groups", parseInt(game_id), page, per_page]
    : ["groups", parseInt(game_id)];
export const analyticsQueryKey = (game_id) => ["analytics", parseInt(game_id)];
const whitelabel = process.env.REACT_APP_WHITELABEL;
const useRefresh = (subscriptions) => {
  const authState = JSON.parse(window.localStorage.getItem("_auth_state"));
  const [, setError] = useState(false);

  const { lastJsonMessage } = useWebSocket("wss://ws.socialscavenger.com", {
    onError: () => setError(true),
    onOpen: () => setError(false),
    retryOnError: !!authState?.user_id,
    shouldReconnect: ({ wasClean }) => authState?.user_id && !wasClean,
    queryParams: {
      token: encodeURIComponent(authState?.token),
      whitelabel,
      subscriptions,
    },
  });

  return lastJsonMessage || { id: null };
};

export default useRefresh;
