import styled, { createGlobalStyle } from "styled-components";
import { MDBBtn, MDBContainer, MDBCol, MDBRow, MDBCard, MDBDropdownToggle } from "mdb-react-ui-kit";

export const GlobalStyle = createGlobalStyle`
  ${({ theme }) => `
  body {
    background-color: #F8F9FF;
  }

  a {
    color: ${theme.primaryColor};
  }

  .modal-title {
    font-size: 1.25rem;
  }

  .dropdown-menu {
    z-index: 1100 !important;
  }

  ${theme.breakpoints.up('lg')} {
    .form-outline .form-control~.form-label.no-ellipsis {
      max-width: none;
    }
  }

  ${theme.breakpoints.up('sm')} {
    .MuiAutocomplete-listbox .MuiAutocomplete-option {
      min-height: 36px;
    }
  }
  `}
`;

export const Container = styled(MDBContainer)`
  ${({ theme }) => `
    margin-top: ${theme.headerPadding};
    padding-left: 24px;
    padding-right: 24px;

    ${theme.breakpoints.up('sm')} {
      max-width: 96vw;
    }

    ${theme.breakpoints.up('md')} {
      max-width: calc(100vw - 245px);
    }
  `}
`;

export const Col = styled(MDBCol)`
  display: flex;
  align-items: center;

  &.col-2:not(.justify-start) {
    justify-content: flex-end;
  }

  &.col--no-grow {
    flex-grow: 0;
  }

  &.col--stretch {
    > * {
      margin: 0;

      &.row {
        margin-top: calc(var(--mdb-gutter-y)*-1);
        margin-right: calc(var(--mdb-gutter-x)/-2);
        margin-left: calc(var(--mdb-gutter-x)/-2);
      }
    }
  }

  &.col--row-wrapper {
    > * {
      margin-top: calc(var(--mdb-gutter-y) * -1);
      margin-right: calc(var(--mdb-gutter-x) / -2);
      margin-left: calc(var(--mdb-gutter-x) / -2);
    }
  }

  > * {
    margin: 0 0.5rem 0 0;

    &.row {
      margin-top: calc(var(--mdb-gutter-y)*-1);
      margin-right: calc(var(--mdb-gutter-x)/-2);
      margin-left: calc(var(--mdb-gutter-x)/-2);
    }
  }

  button {
    margin-bottom: 0;
  }

  textarea.form-control,
  select.form-control {
    border: solid #bdbdbd;
    border-width: 0 0 1px 0;
    border-radius: 0;
    padding-top: 1.5rem;

    & + .form-label {
      margin-left: -0.75rem;
    }
  }

  .form-outline,
  textarea {
    width: 100%;
  }

  .form-outline .form-control:focus ~ .form-label {
    color: ${({ theme }) => theme.primaryColor};
  }
`;

export const Row = styled(MDBRow)`
  .card-body & + & .form-outline,
  .tab-content & + & .form-outline {
    margin-top: 2rem;

    @media (max-width: ${({ theme }) => theme.maxMobileWidth}) {
      margin-top: 3rem;
    }
  }

  .card-body &:last-child .form-outline {
    margin-bottom: 2rem;

    @media (max-width: ${({ theme }) => theme.maxMobileWidth}) {
      margin-bottom: 3rem;
    }
  }

  .card-body &:last-child .form-outline {
    margin-bottom: 2rem;

    @media (max-width: ${({ theme }) => theme.maxMobileWidth}) {
      margin-bottom: 3rem;
    }
  }

  .card-body [class*="radio__item__body"] .form-outline {
    margin-bottom: 0;

    @media (max-width: ${({ theme }) => theme.maxMobileWidth}) {
      margin-bottom: 0;
    }
  }
`;

export const NavRow = styled(Row)`
  ${({ theme }) => `
    & {
      flex-grow: 1;
      flex-wrap: nowrap;
      display: none;

      ${theme.breakpoints.up('sm')} {
        display: flex;
      }
    }

    &,
    > * {
      --mdb-gutter-x: 0;
    }
  `}
`;

export const NavCol = styled(MDBCol)`
    &.nav-wrapper {
      flex-grow: 0;
    }
`;

export const MobileNav = styled.div`
  ${({ theme }) => `
    ${theme.breakpoints.up('sm')} {
      display: none;
    }
  `}
`;

export const HeaderRow = styled(Row)`
  margin-bottom: 1.625rem;
`;

export const ButtonColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonRow = styled.div`
  display: flex;
  flex-wrap: wrap;

  button {
    margin: 1rem 0.3125rem;
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: 3rem;
  text-align: right;
`;

const flatBtnStyle = ({ theme }) => `
  position: relative;
  font-size: 0.875rem;
  margin-bottom: 0.3125rem;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0;
  outline: 0;
  font-weight: 500;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  line-height: 1.5;
  border-radius: 0.125rem;
  white-space: nowrap;

  &.btn--flat {
    text-transform: none;
    box-shadow: none;
    border-radius: ${theme.rounded.sm};
  }

  &.btn--wrap {
    white-space: normal;
  }

  &.btn--flat-md {
    border-radius: ${theme.rounded.md};
  }

  a {
    color: white;
  }

  &:not(:disabled) {
    cursor: pointer;
  }

  &.btn-danger {
    background-color: ${theme.dangerColor};
  }

  &.btn-primary {
    background-color: ${theme.primaryColor};
  }

  &.btn-success {
    background-color: ${theme.successColor};
  }

  &.btn-warning {
    background-color: ${theme.warningColor};
  }

  &.btn-info {
    background-color: ${theme.infoColor};
  }

  &.btn-link {
    color: ${theme.linkButtonColor};
  }

  &.btn--narrow {
    padding: .625rem;
    min-width: 57.63px;

    ${theme.breakpoints.up('md')} {
      padding: .625rem 1rem .5rem 1rem;
    }
  }

  &.btn-outline-primary {
    color: ${theme.primaryColor};
    border-color: ${theme.primaryColor};
  }

  &.btn-outline-info {
    color: ${theme.infoColor};
    border-color: ${theme.infoColor};
  }
`

export const Button = styled(MDBBtn)`
  ${flatBtnStyle}
`;

export const DropdownToggle = styled(MDBDropdownToggle)`
  ${flatBtnStyle}
`;

export const ActionButtonWrapper = styled.div`
  text-align: right;
  margin: 1rem 0 0;

  ${Button} {
    margin: 0 0.25rem;

    &.btn-light {
      padding-left: 1rem;
      padding-right: 1rem;
      background-color: #eceff1;
      border-color: #eceff1;
    }
  }

  .modal-body {
    text-align: left;
  }
`;

export const ChallengeActionButtonWrapper = styled(MDBRow)`
  ${({ theme }) => `
  text-align: right;
  margin-top: 1rem;

  ${Button} {
    &.btn-light {
      padding-left: 1rem;
      padding-right: 1rem;
      background-color: #eceff1;
      border-color: #eceff1;
    }
  }

  .modal-body {
    text-align: left;
  }

  ${theme.breakpoints.down('sm')} {
    ${Button} {
      flex-grow: 1;
    }
  }
  `}
`;

export const Divider = styled.hr`
  border: none;
  border-bottom: 1px dotted #dddddd;
`;

export const TextareaCol = styled(Col)`
  align-items: flex-start;
  flex-direction: column;
`;

export const Card = styled(MDBCard)`
  ${({ theme }) => `
    border-radius: ${theme.rounded.md};
  `}
`;

export const CardBody = styled.div`
  .MuiInput-root {
    width: 100%;
  }
`;

export const HelpText = styled.span`
  ${({ theme, focus }) => `
    color: ${theme.darkGreyColor}90;
    font-size: 0.8rem;
    margin-left: 0;
    position: absolute;
    top: 100%;
    transition: opacity linear 0.25s;
    opacity: ${focus ? "1" : "0"};

    @media (max-width: ${theme.maxMobileWidth}) {
      line-height: 1.2;
    }
  `}
`;

export const DescText = styled.span`
  ${({ theme }) => `
    color: ${theme.darkGreyColor}90;
    font-size: 0.8rem;
    margin-left: 0;

    @media (max-width: ${theme.maxMobileWidth}) {
      line-height: 1.2;
    }
  `}
`;

export const FieldHelpText = styled.span`
  ${({ theme }) => `
    color: ${theme.darkGreyColor}90;
    margin-left: 0;
    font-size: 0.8rem;
    animation: slideIn .5s linear forwards;

    @media (max-width: ${theme.maxMobileWidth}) {
      line-height: 1.2;
    }

    @keyframes slideIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  `}
`;

export const AppContainer = styled.div`
`;