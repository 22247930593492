import styled from "styled-components";

export const ChainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;

export const ChainRow = styled.div(() => `
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    & + & {
        margin-top: 12px;
    }
`)

export const ChainParent = styled.div(({ theme }) => `
    display: flex;
    align-items: center;
    flex-grow: 1;
    gap: 12px;
    position: relative;

    > .btn {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate3d(0, -50%, 0);
        padding: 0 .625rem;
        font-size: 1.5rem;

        &.btn-link {
            color: ${theme.dangerColor};
        }
    }

    .MuiAutocomplete-popupIndicator {
        display: none;
    }
`)

export const ChainChild = styled.div(() => `
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 6px;
`)

export const ChainChildLabel = styled.label(() => `
    display: flex;

    > span {
        flex-grow: 1;
    }

    .form-check-input[type=checkbox] {
        margin-right: 0;
    }
`)