import styled from "styled-components";
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBDropdownToggle,
} from "mdb-react-ui-kit";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { Button } from "./Header.styles";

export const Navbar = styled(MDBNavbar)`
  background-color: ${({ theme }) => theme.navbarColor};
  flex-direction: column;
`;

export const NavbarBrand = styled(MDBNavbarBrand)`
  padding: 12px 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 0;

  img {
    width: auto;
    height: 40px;
  }
`;

export const DropdownToggle = styled(MDBDropdownToggle)`
  text-transform: uppercase;
  font-size: 0.75rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  white-space: nowrap;
  font-weight: 400;
  cursor: pointer;

  &&& {
    color: var(--mdb-white);
  }

  &:hover {
    opacity: 0.8;
  }
`;

export const BuilderNav = styled.ul`
  margin-top: 1rem;

  .dropdown-item {
    white-space: normal;
  }
`;

export const SideNavContainer = styled.div`
  ${({ theme }) => {
    return `
      background-color: ${theme.darkColor};
      width: 245px;
      height: 100vh;
      border-radius: 0 24px 24px 0;
      padding-left: 12px;
      padding-top: 12px;
      position: fixed;
      z-index: 11;
      transform: translate3d(-100%, 0, 0);
      transition: all .2s;
      top: 0;
      left: 0;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      ${theme.breakpoints.up('md')} {
        transform: none;
      }

      .navbar-active & {
        transform: translate3d(0, 0, 0);
      }

      .dropdown-item {
        cursor: pointer;
        color: ${theme.lightColor};

        &:hover,
        &:focus {
          background-color: transparent;
        }
      }

      .nav-item {
        > a {
          display: block;
          border-radius: 24px 0 0 24px;
          position: relative;
        }

        > a:hover {
          background-color: ${theme.lightColor};
          color: ${theme.darkColor};
          z-index: 3;

          .dropdown-item {
            color: ${theme.darkColor};
          }
        }

        > .active {
          position: relative;
          background-color: ${theme.lightColor};
          color: ${theme.darkColor};

          &:before,
          &:after {
            content: '';
            display: block;
            width: 24px;
            height: 24px;
            background-color: ${theme.lightColor};
            position: absolute;
            top: 0;
            right: 0;
            transform: translate3d(0, -24px, 0);
            pointer-events: none;
          }

          &:after {
            top: auto;
            bottom: 0;
            transform: translate3d(0, 24px, 0);
          }

          .dropdown-item {
            position: relative;
            color: ${theme.darkColor};    
      
            &:before,
            &:after {
              content: '';
              display: block;
              width: 24px;
              height: 24px;
              background-color: ${theme.darkColor};
              position: absolute;
              top: 0;
              right: 0;
              transform: translate3d(0, -24px, 0);
              border-radius: 0 0 24px 0;
              pointer-events: none;
            }

            &:after {
              top: auto;
              bottom: 0;
              transform: translate3d(0, 24px, 0);
              border-radius: 0 24px 0 0;
              z-index: 2;
            }
          }
        }
      }
    `
  }}
`

export const SideNavSpacer = styled.div`
  ${({ theme }) => {
    return `
      display: none;

      ${theme.breakpoints.up('md')} {
        display: block;
        width: 245px;
        min-height: 100vh;
      }
    `
  }}
`

export const SideNavGroup = styled.div`
  margin-bottom: 24px;
`;

export const CloseNavButton = styled(Button)`
  ${({ theme }) => `
    font-size: 1.5rem;

    .navbar-active & {
      transform: translate3d(30px, 0, 0); 
    }

    ${theme.breakpoints.up('md')} {
      display: none;
    }
  `}
`;

const IconWrapper = styled.span`
  display: inline-block;
  text-align: center;
  height: 100%;
  width: 20px;
`

export const FontAwesomeIcon = props => {
  return (
    <IconWrapper>
      <Icon {...props} />
    </IconWrapper>
  )
}

export const SignOutWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: .5rem;
`;