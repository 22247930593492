import styled from "styled-components";
import { MDBCard, MDBCardBody } from "mdb-react-ui-kit";
import { Row, Col } from "../App.styles";
import { SidebarLayout } from "../common";
import { Shimmer } from "../common/Loading.styles";

const PersonalizationRow = styled(Row)`
  .card {
    margin-bottom: 1rem;
  }

  &&& .form-outline {
    margin-bottom: 0;
  }
`;

const Personalization = () => {
  return (
    <MDBCard>
      <MDBCardBody>
        <Row>
          <Col size="5">
            <Shimmer type="title" />
          </Col>
          <Col size="5">
            <Shimmer type="title" />
          </Col>
          <Col size="2">
            <Shimmer type="title" />
          </Col>
        </Row>
      </MDBCardBody>
    </MDBCard>
  );
};

const PersonalizationsPlaceholder = () => {
  return (
    <>
      <PersonalizationRow>
        <SidebarLayout>
            <Shimmer dark count={3} />
            {
                Array.from(Array(3).keys()).map((key) => (
                <Personalization key={key} />
                ))
            }
        </SidebarLayout>
      </PersonalizationRow>
    </>
  );
};

export default PersonalizationsPlaceholder;
