const Spinner = ({ size }) => (
  <div
    className="spinner-border"
    role="status"
    style={{ height: size, width: size }}
  >
    <span className="sr-only">Loading...</span>
  </div>
);

export default Spinner;
