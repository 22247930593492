import { useTranslation } from "react-i18next";
import { QRURLModal, QRDownloadModal } from "../modals";
import { ExternalLinkButton, Input, RowInfo } from "../../common";

const whitelabel = process.env.REACT_APP_WHITELABEL;

const QRCode = ({ useQuery, game_id, id }) => {
  const { t } = useTranslation();

  return (
    <>
      <p>{t('Require the player to scan the exact QR image.')}</p>
      <RowInfo help={<QRURLModal />}>
        <Input
            label={t("URL to open")}
            name="qr_url"
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<QRDownloadModal />} hasLabel={false}>
        <ExternalLinkButton
            className="btn--flat btn--wrap mt-2"
            color="warning"
            href={`https://app.socialscavenger.com/v1/tasks/${id}/qr.png?whitelabel=${whitelabel}&game_id=${game_id}`}
            target="_blank"
          >
            {t('Download the QR code image.')}
          </ExternalLinkButton>
      </RowInfo>
    </>
  )
};

export default QRCode;
