import styled from "styled-components";
import {
  MDBCard,
  MDBCardHeader,
  MDBCardTitle,
  MDBCardBody,
} from "mdb-react-ui-kit";
import { Row, Col } from "../App.styles";
import { SidebarLayout } from "../common";
import { Shimmer } from "../common/Loading.styles";

const HeaderRow = styled(Row)`
  margin-bottom: 1rem;
`;

export const AnswerPlaceholder = () => {
    const RenderAnswer = () => (
        <MDBCard className="mb-4">
            <MDBCardHeader>
              <MDBCardTitle>
                <Row>
                    <Col size="6">
                        <Shimmer type="title" />
                    </Col>
                    <Col size="6">
                        <Shimmer type="title" count={3} />
                    </Col>
                </Row>
              </MDBCardTitle>
            </MDBCardHeader>
            <MDBCardBody>
              <Row>
                <Col size="12" lg="3" className="mb-3">
                    <Shimmer media />
                </Col>
                <Col size="12" lg="9">
                  <div className="flex-grow-1">
                    <Shimmer type="title" />
                    <Shimmer count={3} />
                  </div>
                </Col>
              </Row>
            </MDBCardBody>
        </MDBCard>
    )
    return Array.from(
        Array(3).keys()
    ).map(key => (
        <RenderAnswer key={key} />
    ))
}

const MonitorPlaceholder = () => {
  return (
    <>
      <Row>
        <SidebarLayout
          panel={
            <MDBCard>
                <MDBCardHeader>
                    <MDBCardTitle>
                        <Shimmer dark type="title" />
                    </MDBCardTitle>
                </MDBCardHeader>
                <MDBCardBody>
                    <Shimmer dark count={2} />
                </MDBCardBody>
                </MDBCard>
          }
        >
          <HeaderRow>
            <Col>
                <Shimmer type="title" count={3} />
            </Col>
          </HeaderRow>
          <AnswerPlaceholder />
        </SidebarLayout>
      </Row>
    </>
  );
};

export default MonitorPlaceholder;
