import { useEffect, useState } from "react"
import { format } from "timeago.js"

const Timeago = ({ time }) => {
    const [display, setDisplay] = useState(null)
    useEffect(() => {
        const interval = setInterval(() => {
            setDisplay(
                format(time)
            )
        }, 1000)
        return () => {
            clearInterval(interval)
        }
    }, [time])
    return display
}

export default Timeago