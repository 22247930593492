import { useTranslation } from "react-i18next";
import { NewImageUpload, RowInfo } from "../../common";
import { BackgroundModal } from "../modals";

const BackgroundPhoto = ({ useQuery }) => {
  const { t } = useTranslation();

  return (
    <>
      <p dangerouslySetInnerHTML={{__html: t(`Require the player to submit a photo. <br>An AI will remove the background and swap in the provided background.`)}} />
      <RowInfo help={<BackgroundModal />}>
        <NewImageUpload label={t("Background")} name="overlay" useQuery={useQuery} />
      </RowInfo>
    </>
  );
};

export default BackgroundPhoto;
