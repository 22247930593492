import { useQuery } from "react-query";
import axios from "axios";
import default_help_text from "./default_help.txt";

export const fetchDefaultHelpText = () => axios.get(default_help_text);

export const useDefaultHelpTextQuery = () => {
  const { data } = useQuery("defaultHelpText", fetchDefaultHelpText, {
    suspense: true,
  });
  return data.data;
};
