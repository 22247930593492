import { useTranslation } from "react-i18next";
import { Modal } from "../../common";
import { Button } from "../../App.styles";

const VisibilityModal = ({ onDismiss, show }) => {
  const { t } = useTranslation();

  return (
    <Modal onDismiss={onDismiss} show={show}>
      <div className="modal-header">
        <h4 className="modal-title">{t('Visibility')}</h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-hidden="true"
          onClick={onDismiss}
        >
          ×
        </button>
      </div>
      <div className="modal-body">
        <p>
          {t(`You can toggle whether or not you want submissions to be shared in the
          stream.`)}
        </p>
        <ul>
          <li>
            {t(`Some challenge submissions aren't very exciting to see such as QR
            codes. They can be hidden.`)}
          </li>
          <li>
            {t(`Some submissions might give away an answer or a location. You can hide
            them.`)}
          </li>
          <li>
            {t(`You can change this setting before, during and after the game. Hide
            sensitive submissions during the action but then open them up
            afterwards.`)}
          </li>
        </ul>
      </div>
      <div className="modal-footer">
        <Button type="button" color="dark" data-dismiss="modal" onClick={onDismiss}>
          {t('Close')}
        </Button>
      </div>
    </Modal>
  )
};

export default VisibilityModal;
