import styled from "styled-components";
import { MDBCollapse } from "mdb-react-ui-kit";

export const TriggerWrapper = styled.div`
    ${({ theme }) => `
        .btn--flat {
            display: block;
            width: 100%;
            margin-top: 12px;
        }

        ${theme.breakpoints.up('md')} {
            .btn--flat {
                display: inline-block;
                width: auto;
                margin-top: 0;
                margin-left: 12px;
            }
        }
    `}
`;

export const NavWrapper = styled.div`
        ${() => `
            .collapse.show {
                height: auto!important;
            }
        `}
`;

export const ChallengeCollapsible = styled(MDBCollapse)`
`;