import _axios from "axios";

export const axios = _axios.create({
  responseType: "json",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

let promise = Promise.resolve();
const mutex = async () => promise;

window.addEventListener("storage", () => {
  const refreshing = localStorage.getItem("refreshing");
  let r;

  if (refreshing === "false" && promise.pending) {
    promise.pending = false;
    promise.resolve("refresh complete");
  }

  if (refreshing === "true" && !promise.pending) {
    promise = new Promise((resolve) => {
      r = resolve;
    });

    promise.pending = true;
    promise.resolve = r;
  }
});


axios.interceptors.request.use(async (config) => {
  if (
    promise.pending &&
    !config.url.match(/refresh.json$/) &&
    !config.url.match(/maintenance.json$/)
  ) {
    await mutex();
  }

  const authState = JSON.parse(localStorage.getItem("_auth_state"));

  if (
    authState &&
    !config.url.match(/refresh.json$/) &&
    !config.url.match(/maintenance.json$/)
  ) {
    const { expiration, refresh_token } = authState;

    if (expiration < Date.now()) {
      localStorage.setItem("refreshing", true);

      promise = new Promise(async (resolve, reject) => {
        try {
          await axios.get(
            "https://app.socialscavenger.com/v1/services/maintenance.json"
          );

          const { data } = await axios.post(
            process.env.REACT_APP_API_REFRESH_URL,
            {
              refresh_token,
            }
          );
          const { lifespan, refresh_token: newRefreshToken, token } = data;

          authState.expiration = Date.now() + lifespan * 60 * 1000;
          authState.refresh_token = newRefreshToken;
          authState.token = token;

          localStorage.setItem("_auth", token);
          localStorage.setItem("_auth_state", JSON.stringify(authState));
          document.dispatchEvent(new Event("tokenRefresh"));
          resolve("refresh succeeded");
        } catch (e) {
          if (e.response.status === 401) {
            localStorage.clear();
            window.location = "/sign-in?expired";
          } else if (
            [500, 503].includes(e.response.status)
          ) {
            window.location = "/maintenance";
          } else {
            throw e;
          }
          reject("refresh failed");
        } finally {
          localStorage.setItem("refreshing", false);
          promise.pending = false;
        }
      });

      promise.pending = true;
      promise.id = expiration;
      await mutex();
    }
  }

  const tokenType = localStorage.getItem("_auth_type");
  const token = localStorage.getItem("_auth");

  if (tokenType && token) {
    config.headers.Authorization = `${tokenType} ${token}`;
  }

  config.headers["X-Whitelabel"] = process.env.REACT_APP_WHITELABEL;

  /**
   * Development mode will redirect requests to production (v1)
   * to staging environment,
   */
  const excludeStagingApi = [
    'https://app.socialscavenger.com/v1/upload.json',
    'https://app.socialscavenger.com/v1/medias/sign'
  ]
  if(
    (window.location.hostname === 'staging-builder.socialscavenger.com' ||
    process.env.NODE_ENV === 'development') &&
    !excludeStagingApi.find(url => config.url.match(url))
  ) {
    config.url = config.url.replace('v1', 'staging')
  }
  return config;
});
