import { useTranslation } from "react-i18next";
import { Modal } from "../../common";
import { Button } from "../../App.styles";

const AugmentedRealityAssetModal = ({ onDismiss, show }) => {
  const { t } = useTranslation();

  return (
    <Modal onDismiss={onDismiss} show={show}>
      <div className="modal-header">
        <h4 className="modal-title">{t('Augmented Reality Asset')}</h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-hidden="true"
          onClick={onDismiss}
        >
          ×
        </button>
      </div>
      <div className="modal-body">
        <p>
          {t(`Attach up to five augmented reality assets to this challenge. Each asset
          can have multiple models and animations inside of it.`)}
        </p>
        <ul>
          <li>
            {t(`Augmented reality content can only be loaded from Wikitude 3D Format
            files (.wt3) This is a compressed binary format for describing 3D
            content which is optimized for fast loading and handling of 3D content
            on a mobile device.`)}
          </li>
          <li>
            {t(`You still can use 3D models from your favorite 3D modeling tools but
            you'll need to convert them into the wt3 file format. The Wikitude 3D
            Encoder desktop application (Windows and Mac) encodes your 3D source
            file.`)}
          </li>
          <li>
            {t(`Asset is optional for AR chains other than the first AR in the chain.
            If not uploaded, and part of a chain, will use the asset from the
            first in the chain.`)}
          </li>
        </ul>
      </div>
      <div className="modal-footer">
        <Button type="button" color="dark" data-dismiss="modal" onClick={onDismiss}>
          {t('Close')}
        </Button>
      </div>
    </Modal>
  )
};

export default AugmentedRealityAssetModal;
