import React from "react";
import ReactFlow, { Controls, Background, ConnectionLineType, useNodesState, useEdgesState } from "reactflow";
import dagre from "dagre";
import "reactflow/dist/style.css";
import PathNode from "./PathNode";
import { FlowContainer } from "./PathFlow.style";

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const nodeWidth = 220;
const nodeHeight = 144;

const getLayoutedElements = (nodes, edges, direction = 'TB') => {
    const isHorizontal = direction === 'LR';
    dagreGraph.setGraph({ rankdir: direction, edgesep: nodeWidth });

    nodes.forEach((node) => {
        dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });

    // Higher weight edges are generally made shorter and 
    // straighter than lower weight edges.
    // It makes sure that shared edges will not be overlapped by nodes.
    const edgesWeight = edges.reduce((acc, edge) => {
        const weightId = edge.source
        return {
            ...acc,
            [weightId]: (acc[weightId] || 0) + 1,
        }
    }, {})

    edges.forEach((edge) => {
        dagreGraph.setEdge(edge.source, edge.target, { 
            // The number of ranks to keep between the source and target of the edge.
            minlen: 1.5, 
            // The weight to assign edges.
            weight: edgesWeight[edge.source],
        });
    });

    dagre.layout(dagreGraph);

    nodes.forEach((node) => {
        const nodeWithPosition = dagreGraph.node(node.id);
        node.targetPosition = isHorizontal ? 'left' : 'top';
        node.sourcePosition = isHorizontal ? 'right' : 'bottom';

        // We are shifting the dagre node position (anchor=center center) to the top left
        // so it matches the React Flow node anchor point (top left).
        node.position = {
            x: nodeWithPosition.x - nodeWidth / 2,
            y: nodeWithPosition.y - nodeHeight / 2,
        };

        return node;
    });

    return { nodes, edges };
};

const PathFlow = ({ initialNodes, initialEdges }) => {
    const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
        initialNodes,
        initialEdges
    );
    const [nodes] = useNodesState(layoutedNodes);
    const [edges] = useEdgesState(layoutedEdges);

    return (
        <FlowContainer>
            <ReactFlow
                nodeTypes={{
                    path: PathNode,
                }}
                nodes={nodes}
                edges={edges}
                connectionLineType={ConnectionLineType.SmoothStep}
                nodesConnectable={false}
                nodesDraggable={false}
                zoomOnScroll={true}
                panOnScroll={true}
                panOnDrag={true}
                zoomOnDoubleClick={false}
                onNodesChange={undefined}
                onEdgesChange={undefined}
                onConnect={undefined}
                onNodeClick={undefined}
                onNodeDragStart={undefined}
                onNodeDragStop={undefined}
                onPaneClick={undefined}
                onPaneScroll={undefined}
                onPaneContextMenu={undefined}
                defaultViewport={{ x: 0, y: 0, zoom: 1.5 }}
            >
                <Controls showInteractive={false} />
                <Background />
            </ReactFlow>
        </FlowContainer>
    );
};

export default PathFlow;
