import styled from "styled-components";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RadioGroup } from "../common/RadioGroup";
import { useGameQuery } from "../settings/Settings.state";
import { axios } from "../lib/request";
import { gameUri } from "../lib/refresh";

const SmallRadioGroup = styled(RadioGroup)`
  label {
    font-size: 0.875rem;
  }
`;

const LeaderboardToggle = () => {
  const { t } = useTranslation();
  const { game_id } = useParams();
  const [game] = useGameQuery(game_id);
  const queryClient = useQueryClient();

  const change = async (value) => {
    const params = { leaderboard_visible: value };

    await axios.put(
      `${process.env.REACT_APP_API_GAME_UPDATE_URL}/${game_id}.json`,
      params
    );

    queryClient.invalidateQueries(gameUri(game_id));
  };

  return (
    <SmallRadioGroup
      defaultValue={true}
      name="leaderboard_visible"
      options={[
        { value: true, label: t("Visible") },
        { value: false, label: t("Hidden") },
      ]}
      value={game.leaderboard_visible}
      setValue={change}
    />
  );
};

export default LeaderboardToggle;
