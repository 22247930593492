import { useState } from "react";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilm,
  faLock,
  faMapMarker,
  faLightbulb,
  faGift,
  faCalendar,
  faClock,
  faEye,
  faTags,
  faPhotoVideo,
  faLayerGroup,
  faCaretDown,
  faCaretUp,
  faWalking,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { useDecision } from "@optimizely/react-sdk";
import {
  HintsSection,
  LocationSection,
  LocksSection,
  QuantitySection,
  RewardsSection,
  TagsSection,
  TimersSection,
  TimingSection,
  VisibilitySection,
  EndSection,
  ReelsSection,
} from "./sections";
import { TriggerWrapper, NavWrapper, ChallengeCollapsible } from "./ChallengeNav.style";
import { Button } from "../App.styles";
import { ContentTab } from "../common";
import { useGameQuery } from "../settings/Settings.state";
import ArrivalSection from "./sections/ArrivalSection";

const ChallengeNav = ({ challengeId, useQuery }) => {
  const { t } = useTranslation();
  const [decisionArrival] = useDecision('arrival');
  const [showContent, setShowContent] = useState(false);
  const { game_id } = useParams();
  const [{ time_zone, tz_label }] = useGameQuery(game_id);

  return (
    <NavWrapper className="mt-4">
      <TriggerWrapper>
        <span className="d-inline-block text-warning">{t('Get more fun with optional settings')}</span>
        <Button className="btn--flat" color="info" onClick={() => setShowContent(!showContent)}>
          {t('Advanced Settings')} <FontAwesomeIcon icon={showContent ? faCaretUp : faCaretDown } />
        </Button>
      </TriggerWrapper>
      <ChallengeCollapsible show={showContent}>
        <ContentTab 
          tabs={[
            decisionArrival?.enabled && { title: <><FontAwesomeIcon icon={faWalking} /> {t('Arrival')}</>, content: <ArrivalSection useQuery={useQuery} /> },
            { title: <><FontAwesomeIcon icon={faFilm} /> {t('Reels')}</>, content: <ReelsSection useQuery={useQuery} /> },
            { title: <><FontAwesomeIcon icon={faLock} /> {t('Locks')}</>, content: <LocksSection useQuery={useQuery} /> },
            { title: <><FontAwesomeIcon icon={faMapMarker} /> {t('Location')}</>, content: <LocationSection useQuery={useQuery} challengeId={challengeId} /> },
            { title: <><FontAwesomeIcon icon={faLightbulb} /> {t('Hints')}</>, content: <HintsSection useQuery={useQuery} /> },
            { title: <><FontAwesomeIcon icon={faGift} /> {t('Rewards')}</>, content: <RewardsSection useQuery={useQuery} /> },
            { title: <><FontAwesomeIcon icon={faCalendar} /> {t('Timing')}</>, content: (
              <TimingSection
                startLabel="Start Date"
                finishLabel="Finish Date"
                startName="starts_at"
                finishName="ends_at"
                useQuery={useQuery}
                offset={time_zone}
                timezone={tz_label}
              />
            ) },
            { title: <><FontAwesomeIcon icon={faClock} /> {t('Timers')}</>, content: <TimersSection useQuery={useQuery} /> },
            { title: <><FontAwesomeIcon icon={faEye} /> {t('Visibility')}</>, content: <VisibilitySection useQuery={useQuery} /> },
            { title: <><FontAwesomeIcon icon={faTags} /> {t('Tags')}</>, content: <TagsSection id={challengeId} useQuery={useQuery} /> },
            { title: <><FontAwesomeIcon icon={faLayerGroup} /> {t('Quantity')}</>, content: <QuantitySection useQuery={useQuery} /> },
            { title: <><FontAwesomeIcon icon={faPhotoVideo} /> {t('Post Completion')}</>, content: <EndSection useQuery={useQuery} /> },
          ]}
        />
      </ChallengeCollapsible>
    </NavWrapper>
  );
};

export default ChallengeNav;
