import { useTranslation } from "react-i18next";
import { Modal } from "../../common";
import { Button } from "../../App.styles";

const HintSubtractionPointsModal = ({ onDismiss, show }) => {
  const { t } = useTranslation();

  return (
    <Modal onDismiss={onDismiss} show={show}>
      <div className="modal-header">
        <h4 className="modal-title">{t('Hint Subtraction Points')}</h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-hidden="true"
          onClick={onDismiss}
        >
          ×
        </button>
      </div>
      <div className="modal-body">
        <p>
          {t(`You can opt to deduct points for the use of hints. Administrators and
          judges can override deductions on a case by case basis in the Monitor
          section.`)}
        </p>
        <ul>
          <li dangerouslySetInnerHTML={{
            __html: t(`If you assign a hint message together with a subtraction value of
            9999 points, the app will automatically submit “Out of hints” and the
            player will be assigned 0 points. Using 9999 points for a hint is a
            way to avoid players getting stuck, as they will automatically
            complete the challenge after dismissing the hint.`)
          }} />
          <li>
            {t(`As example, if hint 1 has a subtraction of 5 and the challenge is
            worth 10 points by default, and they submit after receiving the hint,
            they will be awarded only 5 points.`)}
          </li>
          <li>
            {t(`Hint subtractions do not compound as you go - if you submit after hint
            2 only the subtraction points for hint 2 are removed. It is common to
            have subtraction values go up with each hint exposed.`)}
          </li>
        </ul>
      </div>
      <div className="modal-footer">
        <Button type="button" color="dark" data-dismiss="modal" onClick={onDismiss}>
          {t('Close')}
        </Button>
      </div>
    </Modal>
  )
};

export default HintSubtractionPointsModal;
