import styled from "styled-components";

export const PanelFooter = styled.div`
  color: #a0a0a0;
  font-size: 0.8125rem;
  margin-bottom: 24px;
  padding: 0 0 10px;
`;

const SidebarCol = styled.div`
  @media (max-width: ${({ theme }) => theme.maxMobileWidth}) {
    .card {
      margin-bottom: 2rem;
    }
  }

  .card {
    margin-bottom: 2.5rem;
  }
`;

const SidebarContent = styled.div`
  &.full-height {
    display: flex;
    flex-direction: column;
  }
`;

const SidebarLayout = ({ children, message, panel, contentClassName = '' }) => {
  return (
    <>
      <SidebarContent className={`col-12 col-xl-9 ${contentClassName}`}>
        {children}
      </SidebarContent>
      <SidebarCol className="col-12 col-xl-3">
        <>
          {
            message
              ? (
                <PanelFooter dangerouslySetInnerHTML={{__html: message}} />
              )
              : null
          }
          {panel}
        </>
      </SidebarCol>
    </>
  );
};

export default SidebarLayout;
