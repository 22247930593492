import styled from "styled-components";
import { MDBPagination, MDBPaginationItem } from "mdb-react-ui-kit";
import { Droppable } from "react-beautiful-dnd";
import { PaginationLink } from "./Pagination.styles";
import Spinner from "./Spinner";

const PaginationWrapper = styled(MDBPagination)`
  margin-top: 1.5rem;
  margin-bottom: 0;
  flex-wrap: wrap;
`;

const PaginationItem = styled(MDBPaginationItem)`
  ${({ active, theme }) =>
    active &&
    `
    ${PaginationLink}${PaginationLink} {
      background-color: ${theme.primaryColor};
    }
  `}
  ${({ active, dragging }) =>
    dragging &&
    active &&
    `
    display: none;
  `}
  ${({ dragging }) =>
    dragging &&
    `
    padding-left: 2rem;
    padding-right: 2rem;
  `}
`;

const PerPageSelect = styled.select`
  margin-left: 1rem;
`;

const Ellipses = () => <MDBPaginationItem>...</MDBPaginationItem>;

const PerPage = ({ dragging, pageSize, setPageSize }) => {
  if (dragging || !setPageSize) {
    return null;
  }

  return (
    <PaginationWrapper className="justify-content-center">
      <MDBPaginationItem key="per_page">
        <PaginationLink>
          Per page
          <PerPageSelect
            value={pageSize}
            onChange={({ target }) => setPageSize(target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
          </PerPageSelect>
        </PaginationLink>
      </MDBPaginationItem>
    </PaginationWrapper>
  );
};

const Page = ({ active, dragging, droppable, index, setPage }) => {
  if (droppable) {
    return (
      <Droppable droppableId="1">
        {({ innerRef }, { isDraggingOver }) => (
          <PaginationItem
            dragging={dragging}
            key={index}
            active={active}
            ref={innerRef}
          >
            <PaginationLink
              hover={isDraggingOver}
              onClick={() => setPage(index)}
            >
              {dragging && "Drop in page "}
              {index}
            </PaginationLink>
          </PaginationItem>
        )}
      </Droppable>
    );
  }

  return (
    <PaginationItem dragging={dragging} key={index} active={active}>
      <PaginationLink onClick={() => setPage(index)}>
        {dragging && "Drop in page "}
        {index}
      </PaginationLink>
    </PaginationItem>
  );
};

const Pagination = ({
  dragging,
  droppable,
  loading,
  page,
  pageSize,
  pages,
  setPage,
  setPageSize,
}) => {
  const end = Math.min(page + 4, pages - 1);
  const min = Math.min(end - 8, page - 4);
  const start = Math.max(min, 2);
  const length = Math.min(pages - 2, 9);
  const enumerator = Array.from({ length }, (_, i) => start + i);

  if (pages === 0) {
    return (
      <MDBPagination className="justify-content-center">
        There are no results.
      </MDBPagination>
    );
  }

  if (loading) {
    return (
      <PaginationWrapper className="justify-content-center">
        <PaginationItem active>
          <PaginationLink>
            <Spinner />
          </PaginationLink>
        </PaginationItem>
      </PaginationWrapper>
    );
  }

  if (pages === 1) {
    return (
      <PerPage
        dragging={dragging}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
    );
  }

  return (
    <>
      <PaginationWrapper className="justify-content-center">
        {!dragging && (
          <MDBPaginationItem disabled={page === 1} key="previous">
            <PaginationLink onClick={() => setPage(page - 1)}>
              Previous
            </PaginationLink>
          </MDBPaginationItem>
        )}
        <Page
          active={page === 1}
          dragging={dragging}
          droppable={droppable}
          index={1}
          setPage={() => setPage(1)}
        />
        {!enumerator.length || enumerator[0] === 2 || <Ellipses />}
        {enumerator.map((index) => (
          <Page
            active={page === index}
            dragging={dragging}
            droppable={droppable}
            index={index}
            setPage={() => setPage(index)}
            key={index}
          />
        ))}
        {!enumerator.length ||
          enumerator[enumerator.length - 1] === pages - 1 || <Ellipses />}
        {
          pages
            ? (
              <Page
                active={page === pages}
                dragging={dragging}
                droppable={droppable}
                index={pages}
                setPage={() => setPage(pages)}
              />
            )
            : null
        }
        {!dragging && (
          <MDBPaginationItem disabled={page === pages} key="next">
            <PaginationLink onClick={() => setPage(page + 1)}>
              Next
            </PaginationLink>
          </MDBPaginationItem>
        )}
      </PaginationWrapper>
      <PerPage
        dragging={dragging}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
    </>
  );
};

export default Pagination;
