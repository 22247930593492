import { useTranslation } from "react-i18next";
import { Modal } from "../../common";
import { Button } from "../../App.styles";

const TriviaModal = ({ onDismiss, show }) => {
  const { t } = useTranslation();

  return (
    <Modal onDismiss={onDismiss} show={show}>
      <div className="modal-header">
        <h4 className="modal-title">{t('Trivia Answers')}</h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-hidden="true"
          onClick={onDismiss}
        >
          ×
        </button>
      </div>
      <div className="modal-body">
        <p>
          {t(`Only answers listed here will be indicated as correct and awarded
          points.`)}
        </p>
        <ul>
          <li>
            {t(`It is best practice to list common misspellings or alternative
            wordings. Eg. One and 1, Tree and Palm Tree etc.`)}
          </li>
          <li>{t('You have 255 characters for all your answers.')}</li>
          <li>{t('Supports Personalization.')}</li>
        </ul>
      </div>
      <div className="modal-footer">
        <Button type="button" color="dark" data-dismiss="modal" onClick={onDismiss}>
          {t('Close')}
        </Button>
      </div>
    </Modal>
  )
};

export default TriviaModal;
