import styled from "styled-components";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGameQuery } from "../settings/Settings.state";
import {
  liveState,
  playingState,
  shuffleState,
  useSlideshowQuery,
  useSlideshowLoadingQuery,
  featuredState,
} from "./Slideshow.state";
import SlideshowLoader from "./SlideshowLoader";
import SlideshowControls from "./SlideshowControls";
import Slide from "./Slide";
import { Refresher, Spinner, withPlaceholder } from "../common";
import { shuffleArray } from "../lib/array";
import SlideshowPlaceholder from "./SlideshowPlaceholder";
import { Soundtrack } from "../reels/ReelsForm";

const SlideWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  justify-content: center;
  align-items: stretch;
  transition: opacity 1s linear;
  opacity ${({ active }) => (active ? "1" : "0")};
  height: 100vh;
  box-sizing: content-box;

  @media (max-width: ${({ theme }) => theme.maxMobileWidth}) {
    paddding-bottom: 56px;
  }

  img, video {
    width: 100vw;
    height: 100vh;
    object-fit: contain;
    object-position: center;
  }

  span {
    margin-bottom: 1.5rem;
  }
`;

const TitleCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    color: white;
    font-size: 4rem;
    padding: 1rem 5rem;
  }

  img,
  video {
    height: auto;
    max-height: 75%;
    max-width: 75%;
  }

  @media (max-width: ${({ theme }) => theme.maxMobileWidth}) {
    h1 {
      font-size: 2rem;
      padding-left: 1rem;
      padding-right: 1rem;
      text-align: center;
    }
  }
`;

const NoRecord = styled.span`
  ${({ theme }) => `
    color: ${theme.white};
    font-size: 1.25rem;
  `}
`;

const SlideshowWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: black;
  z-index: 9999;
`;

const SpinnerWrapper = styled.div`
  position: absolute;
`;

const isSafari =
  window.navigator.userAgent.toLocaleLowerCase().indexOf("safari") > -1 &&
  window.navigator.userAgent.toLocaleLowerCase().indexOf("chrome") === -1;

const Slideshow = () => {
  const { t } = useTranslation();
  const { game_id } = useParams();
  const [{ assets, name, soundtrack }] = useGameQuery(game_id);
  const audioRef = useRef();
  const live = useRecoilValue(liveState);
  const photo = assets.photos.find(
    (photo) => photo.name === "standard" && photo.url
  );
  const [muted, setMuted] = useState(isSafari);
  const [playing, setPlaying] = useRecoilState(playingState);
  const [featured] = useRecoilState(featuredState);
  const shuffle = useRecoilValue(shuffleState);
  const { answers: answersSource, featuredSubmissions, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useSlideshowQuery();
  const isLoading = useSlideshowLoadingQuery();
  const [prevIndex, setPrevIndex] = useState(-1);
  const [index, setIndex] = useState(-1);
  const [nextIndex, setNextIndex] = useState(0);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const delay = query.get("delay");
  const answersRef = useRef([]);
  const countRef = useRef(0);
  const currentDurationRef = useRef(0);
  const durationRef = useRef((delay || 5) * 1000);
  const tickRef = useRef();
  const timeRef = useRef(0);
  const byValidAnswers = a => {
    const vUrls = a?.assets?.videos.find(v => v.name === 'standard')?.urls
    const pUrl = a?.assets?.photos.find(p => p.name === 'standard')?.url
    return vUrls?.mp4 || vUrls.gif || pUrl;
  }
  const answers = useMemo(() => {
    const items = [...answersSource].filter(byValidAnswers)
    const slides = shuffle
      ? shuffleArray(items)
      : items
    return slides.filter(s => {
      return featured
        ? s.featured_at !== null
        : true
    })
  }, [answersSource, shuffle, featured])

  const isLoadingFeatured = useMemo(() => {
    return featured &&
      answers.length < featuredSubmissions
  }, [featured, answers.length, featuredSubmissions])

  const getNextIndex = useCallback(
    (index) => {
      return index >= countRef.current - 1
        ? -1
        : index + 1;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [answers]
  );

  const onAdvance = () => {
    if (isSafari) {
      setMuted(true);
    }

    setIndex((prevIndex) => {
      const index = getNextIndex(prevIndex);
      const nextIndex = getNextIndex(index);

      currentDurationRef.current = 0;

      if (index > -1) {
        const { duration } = answersRef.current[index];
        durationRef.current = (duration || delay || 5) * 1000;
      }

      setPrevIndex(prevIndex);
      setNextIndex(nextIndex);
      return index;
    });
  };

  useEffect(() => {
    answersRef.current = answers;
    countRef.current = answers.length;
    try {
      if (playing) {
        audioRef.current.volume = .25
        audioRef.current?.paused && audioRef.current?.play && audioRef.current.play()
      }
    } catch {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answers, playing]);

  useEffect(() => {
    if (playing && index === -1 && answers[index]) {
      setNextIndex(1);
      setIndex(0);
    }
    try {
      if (!playing) {
        audioRef.current?.pause && audioRef.current.pause()
      }
    } catch {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playing]);

  useEffect(() => {
    const onTick = () => {
      if (playing) {
        const time = Date.now();
        const delta = time - timeRef.current;
        if (
          durationRef.current > 0 &&
          currentDurationRef.current >= durationRef.current
        ) {
          onAdvance();
        }
        currentDurationRef.current += delta;
        timeRef.current = Date.now();
        tickRef.current = window.requestAnimationFrame(onTick);
      } else {
        cancelAnimationFrame(tickRef.current);
      }
    };
    tickRef.current = window.requestAnimationFrame(onTick);
    return () => cancelAnimationFrame(tickRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answers, playing]);

  useEffect(() => {
    if (index >= countRef.current) {
      setIndex(-1);
    }
  }, [answers, index]);

  useEffect(() => {
    if (!isLoading) {
      setPlaying(true);
    }
  }, [isLoading, setPlaying]);

  useEffect(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, fetchNextPage, isFetchingNextPage]);

  return (
    <SlideshowWrapper>
      <SlideWrapper active={index === -1}>
        <TitleCard>
          <h1>{name}</h1>
          {isLoading || isLoadingFeatured ? (
            <SpinnerWrapper>
              <Spinner size="1.275rem" />
            </SpinnerWrapper>
          ) : answers.length ? undefined : (
            <NoRecord>({t('no submissions')})</NoRecord>
          )}
          {photo?.url && <img src={photo?.url} alt={name} />}
        </TitleCard>
      </SlideWrapper>
      {prevIndex >= 0 && answers[prevIndex]?.id && (
        <SlideWrapper key={answers[prevIndex].id}>
          <Slide muted {...answers[prevIndex]} />
        </SlideWrapper>
      )}
      {index >= 0 && answers[index]?.id && (
        <SlideWrapper active key={answers[index].id}>
          <Slide paused={!playing} active muted={muted} {...answers[index]} />
        </SlideWrapper>
      )}
      {nextIndex >= 0 && answers.length > 0 && answers[nextIndex]?.id && (
        <SlideWrapper key={answers[nextIndex].id}>
          <Slide muted {...answers[nextIndex]} />
        </SlideWrapper>
      )}
      {!!answers.length && (
        <>
          <SlideshowControls
            onSkip={onAdvance}
            muted={muted}
            onMute={() => setMuted(true)}
            onUnmute={() => setMuted(false)}
            featuredSubmissions={featuredSubmissions}
          />
          <Soundtrack ref={audioRef} muted={muted}>
            <source
              type="audio/mp4"
              src={`https://ss-video-support.s3.amazonaws.com/reels/${soundtrack}`}
            />
          </Soundtrack>
        </>
      )}
      <Refresher
        subscriptions={
          live
            ? undefined
            : "ss.answer.accept,ss.answer.create,ss.answer.reject"
        }
      />
      <SlideshowLoader />
    </SlideshowWrapper>
  );
};

export default withPlaceholder(Slideshow, SlideshowPlaceholder);
