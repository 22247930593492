export const shuffleArray = array => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array
}

export const reorderPosition = ({ id, position }, sourceArray = [], startIndex) => {
  const array = [...sourceArray]
  const currentIndex = array.findIndex(item => item.id === id)
  const targetIndex = array.findIndex(item => item.position === position)
  const subject = array.splice(currentIndex, 1)

  if (targetIndex >= 0) {
    array.splice(targetIndex, 0, subject[0])
  }
  
  return array.map((i, index) => ({
    ...i,
    position: index + startIndex,
  }))
}