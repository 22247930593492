import { Suspense, useEffect, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { useVideoRefresher } from "../Global.state";
import { useUserQuery } from "../monitor/User.state";
import useRefresh, {
  dashboardQueryKey,
  challengesQueryKey,
  gameUri,
  leaderboardQueryKey,
  rewardsQueryKey,
  teamsQueryKey,
  groupsQueryKey,
  gameStreamQueryKey,
  personalizationsUri,
  slideshowUri,
  analyticsQueryKey,
} from "../lib/refresh";
import RefreshConflictModal from "./RefreshConflictModal";
import ReelsNotificationModal from "../reels/ReelsNotificationModal";

const streamQueryKey = () => ["stream"];

const getGameId = ({ detail, source }) => {
  if (detail?.game) {
    return detail.game.id;
  }

  switch (source) {
    case "ss.answer.accept":
    case "ss.answer.create":
    case "ss.answer.feature":
    case "ss.answer.reject":
      return detail.answer.game_id;
    case "ss.reward.create":
    case "ss.reward.delete":
    case "ss.reward.update":
      return detail.reward.game_id;
    default:
      return null;
  }
};

const refreshUris = {
  "ss.answer.accept": [streamQueryKey, leaderboardQueryKey, slideshowUri],
  "ss.answer.create": [streamQueryKey, leaderboardQueryKey, slideshowUri],
  "ss.answer.feature": [streamQueryKey, leaderboardQueryKey],
  "ss.answer.reject": [streamQueryKey, leaderboardQueryKey, slideshowUri],
  "ss.answer.update": [streamQueryKey],
  "ss.dropbox.connected": [gameUri],
  "ss.game.clone": [dashboardQueryKey],
  "ss.game.delete": [dashboardQueryKey],
  "ss.game.join": [teamsQueryKey, gameStreamQueryKey],
  "ss.game.media": [],
  "ss.game.reel": [gameUri],
  "ss.game.rescore": [leaderboardQueryKey],
  "ss.game.score": [leaderboardQueryKey],
  "ss.game.update": [dashboardQueryKey, streamQueryKey],
  "ss.reward.create": [rewardsQueryKey],
  "ss.reward.delete": [rewardsQueryKey],
  "ss.reward.update": [rewardsQueryKey],
  "ss.task.create": [challengesQueryKey, streamQueryKey],
  "ss.task.delete": [challengesQueryKey, streamQueryKey],
  "ss.task.update": [challengesQueryKey, streamQueryKey],
  "ss.team.create": [teamsQueryKey, streamQueryKey],
  "ss.team.delete": [teamsQueryKey, streamQueryKey],
  "ss.team.merge": [teamsQueryKey, streamQueryKey],
  "ss.team.photo": [teamsQueryKey, streamQueryKey],
  "ss.team.rename": [teamsQueryKey, streamQueryKey],
  "ss.team.unmerge": [teamsQueryKey, streamQueryKey],
  "ss.team.update": [teamsQueryKey, streamQueryKey],
  "ss.personalization.delete": [personalizationsUri],
  "ss.personalization.update": [personalizationsUri],
  "ss.personalization.create": [personalizationsUri],
  "ss.group.create": [groupsQueryKey],
  "ss.group.delete": [groupsQueryKey],
  "ss.group.update": [groupsQueryKey],
  "ss.analytic.teams": [analyticsQueryKey],
};

const mutations = {
  "ss.game.media": [useVideoRefresher],
};

const Mutation = ({ game_id, message, useMutation }) => {
  const mutate = useMutation(game_id);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => mutate(message), []);
  return null;
};

const RefreshUris = ({ game_id, source, shouldInvalidate = true }) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    if (game_id && game_id !== "new" && shouldInvalidate) {
      for (let uriFn of refreshUris[source] || []) {
        const key = uriFn(game_id);
        queryClient.invalidateQueries(key);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [game_id, queryClient, source]);

  return null;
};

const RefresherInstance = ({ id, reelScreen, subscriptions }) => {
  const { game_id } = useParams();
  const message = useRefresh(Object.keys(refreshUris).join(","));
  const keys = subscriptions ? subscriptions.split(",") : [];
  const [usedId, setUsedId] = useState();
  const [show, setShow] = useState(true);
  const [confirm, setConfirm] = useState(false);
  const [reel, setReel] = useState(false);
  const [conflict, setConflict] = useState(false);

  useEffect(() => {
    setShow(true);

    if (
      reelScreen &&
      ["ss.game.reel", "ss.task.reel", "ss.team.reel"].includes(
        message.source
      ) &&
      message.detail.game.id === parseInt(game_id)
    ) {
      setReel(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  const onRefresh = () => {
    setConfirm(true);
    setShow(false);
  };

  if (message.id) {
    const userId = message.detail?.user?.id;
    const gameId = getGameId(message);
    const m = mutations[message.source] || [];

    if (
      id &&
      gameId === parseInt(game_id) &&
      userId &&
      userId !== id &&
      keys.includes(message.source) &&
      refreshUris[message.source] &&
      message.id !== usedId &&
      !conflict
    ) {
      setUsedId(message.id);
      setConflict(true);
    }

    if (
      conflict &&
      keys.includes(message.source) &&
      refreshUris[message.source]
    ) {
      if (confirm) {
        window.requestAnimationFrame(() => {
          setConfirm(false);
          setConflict(false);
        });

        return (
          <>
            <RefreshUris
              game_id={game_id}
              key={message.id}
              source={message.source}
            />
            {parseInt(game_id) === gameId &&
              m.map((mutation) => (
                <Mutation
                  game_id={game_id}
                  key={message.id}
                  message={message}
                  useMutation={mutation}
                />
              ))}
            {localStorage.getItem("reels_playing") ? (
              ""
            ) : (
              <ReelsNotificationModal
                game={message.detail?.game}
                onDismiss={() => setReel(false)}
                show={reel}
                task={message.detail?.task}
                team={message.detail?.team}
              />
            )}
          </>
        );
      } else {
        return (
          <RefreshConflictModal
            show={show}
            onConfirm={onRefresh}
            onDismiss={() => {
              setShow(false);
              setConflict(false);
            }}
          />
        );
      }
    }

    if (!keys.includes(message.source)) {
      return (
        <>
          <RefreshUris
            game_id={game_id}
            key={message.id}
            source={message.source}
            shouldInvalidate={
              /**
               * Game analytics should refetch
               * only when it is coming from the same game
               */
              message.source === 'ss.analytic.teams'
                ? parseInt(gameId) === parseInt(game_id)
                : true
            }
          />
          {parseInt(game_id) === gameId &&
            m.map((mutation) => (
              <Mutation
                game_id={game_id}
                key={message.id}
                message={message}
                useMutation={mutation}
              />
            ))}
          {localStorage.getItem("reels_playing") ? (
            ""
          ) : (
            <ReelsNotificationModal
              game={message.detail?.game}
              onDismiss={() => setReel(false)}
              show={reel}
              task={message.detail?.task}
              team={message.detail?.team}
            />
          )}
        </>
      );
    }
  }

  return null;
};

const Noop = () => null;

const Refresher = ({ reelScreen, subscriptions, uri }) => {
  const interval = useRef();
  const [key, setKey] = useState();
  const { id } = useUserQuery();

  useEffect(() => {
    interval.current = setInterval(() => setKey(Date.now()), 600000);
    return () => clearInterval(interval.current);
  });

  useEffect(() => {
    const updater = () => {
      const state = JSON.parse(window.localStorage.getItem("_auth_state"));

      if (state?.expiration) {
        setKey(state.expiration);
      }
    };

    document.addEventListener("tokenRefresh", updater, false);
    window.addEventListener("storage", updater, false);
    return () => {
      document.removeEventListener("tokenRefresh", updater);
      window.removeEventListener("storage", updater);
    };
  }, []);

  return (
    <Suspense fallback={<Noop />}>
      <RefresherInstance
        id={id}
        key={key}
        reelScreen={reelScreen}
        subscriptions={subscriptions}
        uri={uri}
      />
    </Suspense>
  );
};

export default Refresher;
