import styled from "styled-components";

export const FormGroup = styled.div`
  margin: 2rem 0;
`;

export const SigninForm = styled.form`
  ${({ theme }) => `
    .btn {
      ${theme.breakpoints.down('xs')} {
        display: block;
        width: 100%;
      }
    }
  `}
`;
