import styled from "styled-components";

export const FooterWrapper = styled.footer`
  ${({ theme }) => `
    text-align: center;
    padding: 3.125rem 0;
    
    ${theme.breakpoints.up('sm')} {
      padding: 3.125rem;
    }

    p {
      width: 100%;
    }

    a {
      color: ${({ theme }) => theme.primaryColor};
    }

    .social a {
      display: inline-block;
      font-size: 30px;
      margin: 0 1rem;

      svg path {
        fill: ${({ theme }) => theme.primaryColor};
      }
    }
  `}
`;
