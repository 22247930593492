import { useTranslation } from "react-i18next";
import { Modal } from "../../common";
import { Button } from "../../App.styles";

const WatermarkModal = ({ onDismiss, show }) => {
const { t } = useTranslation();

return (
  <Modal onDismiss={onDismiss} show={show}>
    <div className="modal-header">
      <h4 className="modal-title">{t(`Watermark`)}</h4>
      <button
        type="button"
        className="close"
        data-dismiss="modal"
        aria-hidden="true"
        onClick={onDismiss}
      >
        ×
      </button>
    </div>
    <div className="modal-body">
      <p>
        {t(`Add a custom watermark to be applied to photos and videos uploaded to your game. 
        A great way to add your own logo or stamp to all the rich media submitted by your players.`)}
      </p>
      <ul>
        <li>{t(`For best results upload a PNG file format.`)}</li>
        <li>{t(`For best results upload a square or 2:1 (width:height) ration image.`)}</li>
        <li>{t(`For best results upload an image that is minimum 140 px wide by 80 pixels high, or 140px square.`)}</li>
        <li>{t(`Transparency is allowed with PNG formats.`)}</li>
      </ul>
    </div>
    <div className="modal-footer">
      <Button color="dark" data-dismiss="modal" onClick={onDismiss}>
        {t('Close')}
      </Button>
    </div>
  </Modal>
);
}

export default WatermarkModal;
