import { useCallback, useMemo } from "react";
import { MDBCard } from "mdb-react-ui-kit";
import Lottie from "react-lottie";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import animationData from "../../images/ring-loading.json";
import getJokes from "../../static/dad-jokes";
import { LoadingBody, LoadingText } from "./LoadingDisplay.style";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const LoadingDisplayAnimation = ({ percent }) => {
  // since we're loading the suspense fallback itself, no need to suspense the translation
  const { t: translate, ready } = useTranslation('default', { useSuspense: false })
  const [joke, setJoke] = useState(null)
  const t = useCallback((str) => {
    // when the translations are ready, show translated text;
    // otherwise show original version of the text
    return ready ? translate(str) : str
  }, [ready, translate])
  const jokes = useMemo(() => getJokes(t), [t])
  function render() {
    switch (true) {
      case Boolean(percent === 0):
        return <span dangerouslySetInnerHTML={{ __html: t(`Loading Your Adventure&#8230;`) }} />
      case Boolean(percent > 0 && percent < 100 && joke):
        return (
          <>
            <span key={joke.question} className="joke__question" dangerouslySetInnerHTML={{ __html: joke.question }} />
            <span key={joke.answer} className="joke__answer" dangerouslySetInnerHTML={{ __html: joke.answer }} />
          </>
        )
      case Boolean(percent === 100):
        return <span dangerouslySetInnerHTML={{ __html: t(`Ready to Go!`) }} />
      default:
        return null
    }
  }
  useEffect(() => {
    const getJoke = () => {
      const jokeIndex = Math.floor(Math.random() * jokes.length)
      setJoke(jokes[jokeIndex])
    }
    getJoke()
    const interval = setInterval(getJoke, 6000)
    return () => {
      clearInterval(interval)
    }
  }, [jokes])

  return (
    <MDBCard>
      <LoadingBody>
        <Lottie options={defaultOptions} height={400} width={400} />
        <LoadingText>
          {render()}
        </LoadingText>
      </LoadingBody>
    </MDBCard>
  );
};

export const LoadingDisplayIndicator = () => {
  const [percent, setPercent] = useState(0);
  useEffect(() => {
    const timeout = setTimeout(() => {
      // loop jokes because sometimes it loads for more than 12 seconds
      setPercent(percent => (percent < 90 ? percent + 10 : 1));
      // the full animation runs in 12s
      // 10% increment thus should be done in 12/10 seconds
    }, 1200);

    return () => {
      clearTimeout(timeout)
    }
  });
  return <LoadingDisplayAnimation percent={percent} />
}

export default LoadingDisplayIndicator
