import { useTranslation } from "react-i18next";
import { Modal } from "../../common";
import { Button } from "../../App.styles";

const BackgroundModal = ({ onDismiss, show }) => {
  const { t } = useTranslation();

  return (
    <Modal onDismiss={onDismiss} show={show}>
      <div className="modal-header">
        <h4 className="modal-title">{t('Background')}</h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-hidden="true"
          onClick={onDismiss}
        >
          ×
        </button>
      </div>
      <div className="modal-body">
        <p>
          {t(`For background challenges, you provide a fun background that is
          automatically added to the photo/video the user takes.`)}
        </p>
        <ul>
          <li>
            {t(`For both background photo and video challenges, you attach a photo to
            be placed in the background.`)}
          </li>
          <li>
            {t('Sites like')}{" "}
            <a href="https://unsplash.com/s/photos/coral-reef">Unsplash</a> {t(`are
            good places to find backgrounds.`)}
          </li>
          <li>
            {t('Files should be uploaded at the highest quality you have available.')}
          </li>
        </ul>
      </div>
      <div className="modal-footer">
        <Button type="button" color="dark" data-dismiss="modal" onClick={onDismiss}>
          {t('Close')}
        </Button>
      </div>
    </Modal>
  )
};

export default BackgroundModal;
