import styled from "styled-components";
import { MDBTable, MDBTableHead, MDBTableBody } from "mdb-react-ui-kit";
import { Col, Row } from "../App.styles";
import { DashboardControls, Th, Td } from "./Dashboard.styles";
import { Shimmer } from "../common/Loading.styles";

const TableWrapper = styled.div`
  max-width: 100vw;
  overflow: scroll;
`;

const Table = styled(MDBTable)`
  width: 100%;
`;

const DashboardCol = styled(Col)`
  flex-wrap: wrap;
`;

const CategoryCol = styled(Col)`
  justify-content: flex-end;
`;

const DashboardPlaceholderRow = () => {
  return (
    <>
        <tr>
            <Td><Shimmer dark type="title" count={2} /></Td>
            <Td><Shimmer dark type="title" /></Td>
            <Td><Shimmer dark type="title" /></Td>
            <Td><Shimmer dark type="title" /></Td>
            <Td><Shimmer dark type="title" count={5} /></Td>
            <Td><Shimmer dark type="title" count={5} /></Td>
            <Td><Shimmer dark type="title" count={5} /></Td>
            <Td><Shimmer dark type="title" /></Td>
            <Td><Shimmer dark type="title" /></Td>
        </tr>    
    </>
  );
};

const DashboardPlaceholder = () => {
    return (
        <Row>
            <DashboardControls>
            <DashboardCol size="12" md="7">
                <div className="d-flex flex-grow-1 gap-2">
                    <Shimmer dark type="title" count={2} />
                </div>
            </DashboardCol>
            <CategoryCol size="12" md="5">
                <label>Filters:</label>
                <Shimmer dark type="title" />
            </CategoryCol>
            </DashboardControls>
            <TableWrapper>
            <Table striped={true}>
                <MDBTableHead dark>
                <tr>
                    <Th>#</Th>
                    <Th>Name</Th>
                    <Th>Entry Code</Th>
                    <Th>Updated</Th>
                    <Th>Before</Th>
                    <Th>During</Th>
                    <Th>After</Th>
                    <Th>Access</Th>
                    <Th></Th>
                </tr>
                </MDBTableHead>
                <MDBTableBody>
                {
                    Array.from(
                    Array(5).keys()
                    ).map(key => (
                    <DashboardPlaceholderRow key={key} />
                    ))
                }
                </MDBTableBody>
            </Table>
            </TableWrapper>
        </Row>
    )
}

export default DashboardPlaceholder;
