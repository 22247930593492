import styled from "styled-components";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { Link, useParams } from "react-router-dom";
import { MDBInput, MDBTable, MDBTableBody } from "mdb-react-ui-kit";
import { useDebouncedCallback } from "use-debounce";
import { Row, Col } from "../App.styles";
import { useLeaderboardQuery, queryState, styleState } from "./Leaderboard.state";
import MyTeam from "./MyTeam";
import { Button } from "../App.styles";
import { axios } from "../lib/request";
import { Alert, Refresher } from "../common";
import useRefresh from "../lib/refresh";
import { useUserQuery } from "./User.state";
import PageTabs from "./PageTabs";

const PhotoCell = styled.div`
  width: 75px;
  height: 75px;
  background-color: ${({ theme }) => theme.placeholderColor};
`;

const LeaderboardTableBody = styled(MDBTableBody)`
  th,
  td {
    padding: 0.3rem;
  }
`;

const HeaderRow = styled(Row)`
  margin-bottom: 1rem;

  ${Row} {
    width: 100%;

    ${Col}:first-child {
      justify-content: center;
      align-items: flex-end;
    }

    ${Col}:last-child {
      align-items: flex-start;
    }
  }
`;

const RecalculateButton = styled(Button)`
  box-sizing: content-box;
  min-width: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
`;

const LeaderboardRow = ({
  game_id,
  id,
  name,
  photo,
  points,
  place,
  completed,
}) => (
  <tr>
    <th>{place}</th>
    <td>
      <PhotoCell>
        {photo && (
          <img
            alt={name}
            src={photo.url}
            width={photo.width}
            height={photo.height}
          />
        )}
      </PhotoCell>
    </td>
    <td>
      <Link to={`/teams/stream/${game_id}/${id}`}>{name}</Link>
    </td>
    <td>{points}</td>
    <td>{completed}</td>
  </tr>
);

const Leaderboard = () => {
  const [alert, setAlert] = useState("");
  const { game_id } = useParams();
  const leaderboard = useLeaderboardQuery(game_id);
  const [style, setStyle] = useRecoilState(styleState);
  const [query, setQuery] = useRecoilState(queryState);
  const [value, setValue] = useState("");
  const callback = useDebouncedCallback(setQuery, 500);
  const handler = ({ target }) => {
    const { value } = target;
    setValue(value);
    callback(value);
  };
  const socket = useRefresh("ss.game.score");
  const userQuery = useUserQuery();
  let [recalculateVisible, setRecalculateVisible] = useState(false);
  let [recalculateInitiated, setrecalculateInitiated] = useState(false);

  useEffect(() => {
    setValue(query);

    if (
      userQuery.game.administrator ||
      userQuery.game.coordinator ||
      userQuery.game.super_admin
    ) {
      setRecalculateVisible(true);
    }

    if (recalculateInitiated) {
      setAlert(
        "The leaderboard has been queued for recalculation. It may take a number of minutes to complete. The leaderboard will be refreshed automatically when completed."
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (socket.source) {
      if (recalculateInitiated) {
        setAlert("Leaderboard recalculated!");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  const recalculate = () => {
    axios
      .patch(
        process.env.REACT_APP_API_RECALCULATE_LEADERBOARD,
        {
          game_id: game_id,
        },
      )
      .then((res) => {
        setrecalculateInitiated(true);
        setAlert(
          "The leaderboard has been queued for recalculation. It may take a number of minutes to complete. The leaderboard will be refreshed automatically when completed."
        );
        window.scrollTo({ top: 0, behavior: "smooth" });
      })
      .catch((err) => console.error(err));
  };

  const filterTabs = [{
      active: style === 'alltime|daily|weekly',
      onClick: e => {
        e.preventDefault()
        setStyle('alltime|daily|weekly')
      },
      label: 'All Time'
  }, {
      active: style === 'daily',
      onClick: e => {
        e.preventDefault()
        setStyle('daily')
      },
      label: 'Daily'
  }, {
      active: style === 'weekly',
      onClick: e => {
        e.preventDefault()
        setStyle('weekly')
      },
      label: 'Weekly'
  }]

  return (
    <>
      <Alert color="info">{alert}</Alert>
      <HeaderRow>
        <Col size="2" className="justify-start">
          {recalculateVisible ? (
            <RecalculateButton onClick={recalculate}>
              Recalculate
            </RecalculateButton>
          ) : (
            ""
          )}
        </Col>
        <Col size="4">
          <PageTabs tabs={filterTabs}/>
        </Col>
        <Col size="2">
          <MyTeam />
        </Col>
        <Col size="4">
          <MDBInput
            label="Filter leaderboard"
            value={value}
            onChange={handler}
          />
        </Col>
      </HeaderRow>
      <Row>
        <div>
          <MDBTable striped>
            <LeaderboardTableBody>
              <tr>
                <th>Rank</th>
                <th>Photo</th>
                <th>Name</th>
                <th>Points</th>
                <th>Completed</th>
              </tr>
              {leaderboard.map((table, index) => (
                <LeaderboardRow
                  key={index}
                  {...table}
                  game_id={game_id}
                  index={index}
                  photo={table.assets.photos.find(
                    (photo) => photo.name === "thumb"
                  )}
                />
              ))}
            </LeaderboardTableBody>
          </MDBTable>
        </div>
      </Row>
      <Refresher />
    </>
  );
};

export default Leaderboard;
