import {
  MDBCard,
  MDBCardHeader,
  MDBCardTitle,
  MDBCardBody,
} from "mdb-react-ui-kit";
import { SidebarLayout } from "../common";
import { Row, Col } from "../App.styles";
import { Shimmer } from "../common/Loading.styles";

const PathPlaceholder = () => {
  return (
    <Row>
      <SidebarLayout message={<Shimmer dark type="title" count={3} />}>
        <MDBCard>
            <MDBCardHeader>
                <MDBCardTitle>
                    <Shimmer type="title" />
                </MDBCardTitle>
            </MDBCardHeader>
            <MDBCardBody>
                <Row>
                    <Col size="12" md="6">
                        <Shimmer type="title" count={3} />
                    </Col>
                    <Col size="12" md="6">
                        <Shimmer type="title" count={5} />
                    </Col>
                </Row>
                <Row>
                    <Shimmer type="title" count={5} />
                </Row>
            </MDBCardBody>
        </MDBCard>
      </SidebarLayout>
    </Row>
  );
};

export default PathPlaceholder;
