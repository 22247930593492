import {
  atom,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import { urlSyncEffect } from "recoil-sync";
import { array, number } from "@recoiljs/refine";
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { axios } from "../lib/request";
import { challengesUri, challengesQueryKey } from "../lib/refresh";
import { useItemQuery } from "../Global.state";
import { getTaskTypeName } from "./Organize.utilities";
import { INFINITE_QUERY_KEY, MAX_PER_PAGE } from "../consts/request";
import { useEffect } from "react";
import { reorderPosition } from "../lib/array";

export const deleteIdState = atom({
  key: "challenges/deleteId",
  default: 0,
});

export const pageState = atom({
  key: "challenges/page",
  default: 1,
  effects: [
    urlSyncEffect({
      refine: number(),
      history: 'push'
    })
  ],
});

export const pageSizeState = atom({
  key: "challenges/pageSize",
  default: MAX_PER_PAGE,
  effects: [
    urlSyncEffect({
      refine: number(),
      history: 'push'
    })
  ],
});

export const highlightIdState = atom({
  key: "challenges/highlightId",
  default: 0,
})

export const termState = atom({
  key: "challenges/term",
  default: "",
});

export const openTabState = atom({
  key: "open",
  default: [],
  effects: [
    urlSyncEffect({
      refine: array(number()),
      history: 'push'
    })
  ],
});

export const fetchChallenges = async (game_id, term, page, per_page) => {
  if (game_id === "new") {
    return { tasks: [] };
  }

  const params = { term, page, per_page };
  const { data } = await axios.get(challengesUri(game_id), { params });
  return data;
};

export const updateChallenge = async (game_id, id, params) => {
  const url = `${process.env.REACT_APP_API_CHALLENGE_UPDATE_URL}/${id}.json`;
  return await axios.put(url, { ...params, game_id });
};

export const useQueryKey = (game_id) => {
  const page = useRecoilValue(pageState);
  const pageSize = useRecoilValue(pageSizeState);
  const term = useRecoilValue(termState);
  return challengesQueryKey(game_id, term, page, pageSize);
};

export const useInfiniteQueryKey = (game_id) => { 
  return challengesQueryKey(game_id, INFINITE_QUERY_KEY, 1, MAX_PER_PAGE) 
} 

export const useChallengesDataQuery = (game_id) => {
  const term = useRecoilValue(termState);
  const page = useRecoilValue(pageState);
  const pageSize = useRecoilValue(pageSizeState);
  const queryKey = useQueryKey(game_id);

  return useQuery(
    queryKey,
    () => fetchChallenges(game_id, term, page, pageSize),
    { keepPreviousData: true, suspense: true }
  );
};

export const useAllChallengesQuery = () => {
  const { game_id } = useParams();

  const { 
    data,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isFetched,
  } = useInfiniteQuery(
    useInfiniteQueryKey(game_id),
    ({ pageParam }) => fetchChallenges(game_id, "", pageParam, MAX_PER_PAGE),
    {
      getNextPageParam: (lastPage, pages) => {
        const { count } = lastPage?.meta || {};
        const loaded = pages.reduce(
          (sum, page) => (sum += page?.tasks.length),
          0
        );

        if ((lastPage && (lastPage?.tasks || []).length === 0) || count <= loaded) {
          return undefined;
        }
  
        return Number(lastPage?.meta?.page || 0) + 1;
      },
    }
  );
  const tasks = (data?.pages || []).reduce((result, page) => {
    return [
      ...result,
      ...(page?.tasks || []),
    ]
  }, [])
  const nextPage = [...data?.pageParams || []].pop()

  return {
    nextPage,
    tasks,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isFetched,
  };
};

export const useInfiniteChallenges = () => {
  const {
    nextPage,
    tasks,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isFetched,
  } = useAllChallengesQuery()
  useEffect(() => {
    let loaded = false
    if (!loaded && hasNextPage) {
      fetchNextPage()
    }
    return () => {
      loaded = true
    }
  }, [
    nextPage,
    hasNextPage,
    fetchNextPage,
  ])
  return {
    isLoading: isFetchingNextPage,
    isFetched,
    tasks,
  }
}

export const useChallengesQuery = (game_id) => {
  const { data } = useChallengesDataQuery(game_id);
  return data.tasks;
};

export const useChallengesCountQuery = (game_id) => {
  const { data } = useChallengesDataQuery(game_id);
  return data.meta.count;
};

export const useChallengesFetchingQuery = (game_id) => {
  const { isFetching } = useChallengesDataQuery(game_id);
  return isFetching;
};

export const useChallengesPagesQuery = (game_id) => {
  const { data } = useChallengesDataQuery(game_id);
  return data.meta.pages;
};

export const useChallengesStatisticsQuery = ({ tasks = [] }) => {
  const statistics = {};

  for (let task of tasks) {
    const label = getTaskTypeName(task.task_type);
    const points = task.task_type === 5 ? task.trivia_points_1 : task.points;

    if (statistics[label]) {
      statistics[label].count++;
      statistics[label].points += points;
    } else {
      statistics[label] = {
        count: 1,
        label,
        points,
      };
    }
  }

  return Object.values(statistics);
};

export const useChallengeQuery = (id, config) => {
  const { game_id } = useParams();
  const { data, isFetching } = useChallengesDataQuery(game_id);
  const queryKey = useQueryKey(game_id);
  const setter = (params) => updateChallenge(game_id, id, params);

  return [...useItemQuery(data, id, "tasks", queryKey, setter, config), isFetching];
};

export const useQueryNoInvalidate = (id) => useChallengeQuery(id, { invalidate: false });
export const useQueryInvalidateInfinite = (id) => {
  const { game_id } = useParams();
  const page = useRecoilValue(pageState);
  return useChallengeQuery(id, { 
    invalidateKey: useInfiniteQueryKey(game_id), 
    refetchType: 'none',
    refetchPage: data => {
      return String(data?.meta?.page) === String(page)
    },
  })
}

export const useChallengePositionQuery = (id) => {
  const { game_id } = useParams();
  const { data } = useChallengesDataQuery(game_id);
  const page = useRecoilValue(pageState);
  const pageSize = useRecoilValue(pageSizeState);

  const offset = (page - 1) * pageSize;
  const index = data.tasks.findIndex((challenge) => challenge.id === id);

  return 1 + offset + index;
};

export const useChallengeTagsQuery = (id) => {
  const { game_id } = useParams();
  const { data } = useChallengesDataQuery(game_id);
  const { tags } = data.tasks.find((challenge) => challenge.id === id);
  return tags.map((tag) => tag.label);
};

export const updateReality = async (game_id, id, params) => {
  const url = `${process.env.REACT_APP_API_REALITY_URL}/${id}.json`;
  return await axios.put(url, { ...params, game_id });
};

export const useRealityQuery = (task_id, id) => {
  const { game_id } = useParams();
  const { data } = useChallengesDataQuery(game_id);
  const queryClient = useQueryClient();
  const queryKey = useQueryKey(game_id);
  const taskIndex = data.tasks.findIndex((task) => task.id === task_id);
  const realityIndex = data.tasks[taskIndex].realities.findIndex(
    (reality) => reality.id === id
  );

  const setReality = async (params) => {
    const { assets, ...rest } = params;

    if (Object.keys(rest).length) {
      queryClient.setQueryData(queryKey, {
        ...data,
        tasks: [
          ...data.tasks.slice(0, taskIndex),
          {
            ...data.tasks[taskIndex],
            realities: [
              ...data.tasks[taskIndex].realities.slice(0, realityIndex),
              {
                ...data.tasks[taskIndex].realities[realityIndex],
                model_url: assets?.models[0].url,
              },
              ...data.tasks[taskIndex].realities.slice(realityIndex + 1),
            ],
          },
          ...data.tasks.slice(taskIndex + 1),
        ],
      });

      await updateReality(game_id, id, rest);
    }

    if (Object.keys(rest).length) {
      queryClient.invalidateQueries(challengesQueryKey(game_id));
    }
  };

  for (let challenge of data.tasks) {
    for (let reality of challenge.realities) {
      if (reality.id === id) {
        return [
          {
            ...reality,
            assets: {
              models: [{ name: "model", url: reality.model_url || "" }],
            },
          },
          setReality,
        ];
      }
    }
  }

  return null;
};

export const useCreateMutation = () => {
  const { game_id } = useParams();
  const queryClient = useQueryClient();
  const pageSize = useRecoilValue(pageSizeState);
  const setPage = useSetRecoilState(pageState);
  const setTerm = useSetRecoilState(termState);
  const queryKey = useQueryKey(game_id);

  const { isLoading, mutateAsync } = useMutation(
    () =>
      axios.post(process.env.REACT_APP_API_CHALLENGE_CREATE_URL, {
        game_id,
      }),
    {
      onSuccess: (data) => {
        const current = queryClient.getQueryData(queryKey);
        const newTasks = [data.data, ...current.tasks]
        const newPages = Math.ceil(
          newTasks.length / pageSize
        )
        queryClient.setQueryData(queryKey, {
          ...current,
          tasks: newTasks,
          meta: {
            ...current?.meta || {},
            pages: newPages,
          },
        });
        setPage(1);
        setTerm("");
        queryClient.invalidateQueries(queryKey)
      },
    }
  );

  return {
    isCreating: isLoading,
    create: mutateAsync,
  };
};

export const useSuggestMutation = () => {
  const { game_id } = useParams();
  const queryClient = useQueryClient();
  const pageSize = useRecoilValue(pageSizeState);
  const setPage = useSetRecoilState(pageState);
  const setTerm = useSetRecoilState(termState);
  const queryKey = useQueryKey(game_id);

  const { isLoading, mutateAsync } = useMutation(
    () =>
      axios.post(process.env.REACT_APP_API_SUGGEST_CHALLENGE_URL, {
        game_id,
      }),
    {
      onSuccess: (data) => {
        console.log('success', data)
        const current = queryClient.getQueryData(queryKey);
        const newTasks = [data.data, ...current.tasks]
        const newPages = Math.ceil(
          newTasks.length / pageSize
        )
        queryClient.setQueryData(queryKey, {
          ...current,
          tasks: newTasks,
          meta: {
            ...current?.meta || {},
            pages: newPages,
          },
        });
        setPage(1);
        setTerm("");
        queryClient.invalidateQueries(queryKey)
      },
      useErrorBoundary: (error) => error.response?.status >= 500,
    },
  );

  return {
    isSuggesting: isLoading,
    suggest: mutateAsync,
  };
};

export const useDestroyMutation = () => {
  const { game_id } = useParams();
  const pageSize = useRecoilValue(pageSizeState);
  const queryClient = useQueryClient();
  const queryKey = useQueryKey(game_id);

  const { isLoading, mutate } = useMutation(
    (deleteId) =>
      axios.delete(
        `${process.env.REACT_APP_API_CHALLENGE_DELETE_URL}/${deleteId}.json?game_id=${game_id}`
      ),
    {
      onMutate: (deleteId) => {
        const data = queryClient.getQueryData(queryKey);
        const tasks = data.tasks.filter((task) => task.id !== deleteId);
        const existingPages = (data?.meta?.pages || 1)
        const newPages = Math.ceil(
          tasks.length / pageSize
        )
        queryClient.setQueryData(queryKey, { 
          ...data, 
          tasks,
          meta: {
            ...data?.meta || {},
            pages: Math.max(
              newPages, 
              existingPages - (tasks.length > 0 ? 0 : 1)
            ),
          },
        });
        queryClient.cancelQueries(queryKey);
      },
    }
  );

  return {
    isDeleting: isLoading,
    destroy: mutate,
  };
};

export const useInvalidateInfinity = () => {
  const { game_id } = useParams();
  const queryClient = useQueryClient();
  return () => queryClient.invalidateQueries(challengesQueryKey(game_id, INFINITE_QUERY_KEY, 1, MAX_PER_PAGE))
}

export const useReorderMutation = () => {
  const { game_id } = useParams();
  const queryKey = useQueryKey(game_id);
  const queryClient = useQueryClient();
  const { data } = useChallengesDataQuery(game_id);
  const page = useRecoilValue(pageState)
  const pageSize = useRecoilValue(pageSizeState)
  const startIndex = (page - 1) * pageSize + 1

  const { isLoading, mutate } = useMutation(
    ({ id, index }) =>
      axios.put(
        `${process.env.REACT_APP_API_CHALLENGE_UPDATE_URL}/${id}.json`,
        { game_id, position: index }
      ),
    {
      onMutate: ({ id, index }) => {
        const tasks = reorderPosition({ id, position: index }, data.tasks, startIndex)
        queryClient.setQueryData(queryKey, {
          ...data,
          tasks,
        })
      },
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey)
      }
    }
  );

  return { isReordering: isLoading, reorder: mutate };
};

export const useAddTagMutation = (task_id) => {
  const { game_id } = useParams();
  const queryClient = useQueryClient();
  const queryKey = useQueryKey(game_id);
  const { data } = useChallengesDataQuery(game_id);
  const index = data.tasks.findIndex((task) => task.id === task_id);

  const { mutateAsync } = useMutation(
    (label) =>
      axios.post(process.env.REACT_APP_API_TAG_URL, {
        game_id,
        label,
        task_id,
      }),
    {
      onMutate: (label) => {
        const task = {
          ...data.tasks[index],
          tags: data.tasks[index].tags.concat({ label }),
        };

        queryClient.setQueryData(queryKey, {
          ...data,
          tasks: [
            ...data.tasks.slice(0, index),
            task,
            ...data.tasks.slice(index + 1),
          ],
        });
      },
    }
  );

  return { addTag: mutateAsync };
};

export const useRemoveTagMutation = (task_id) => {
  const { game_id } = useParams();
  const queryClient = useQueryClient();
  const queryKey = useQueryKey(game_id);
  const { data } = useChallengesDataQuery(game_id);
  const index = data.tasks.findIndex((task) => task.id === task_id);
  const url = `${process.env.REACT_APP_API_CHALLENGE_UPDATE_URL}/${task_id}/tags.json`;

  const { mutateAsync } = useMutation(
    (label) =>
      axios.delete(url, {
        params: {
          game_id,
          label,
        },
      }),
    {
      onMutate: (label) => {
        const task = {
          ...data.tasks[index],
          tags: data.tasks[index].tags.filter((tag) => tag.label !== label),
        };

        queryClient.setQueryData(queryKey, {
          ...data,
          tasks: [
            ...data.tasks.slice(0, index),
            task,
            ...data.tasks.slice(index + 1),
          ],
        });
      },
    }
  );

  return { removeTag: mutateAsync };
};

export const useNavigateTaskPageQuery = ({ tasks }) => {
  const page = useRecoilValue(pageState)
  const pageSize = useRecoilValue(pageSizeState)
  const setPage = useSetRecoilState(pageState);
  return (id) => {
    const taskIndex = tasks.findIndex(task => task.id === id)
    const taskPage = 1 + Math.floor(taskIndex / pageSize)
    if (taskPage !== page) {
      setPage(taskPage)
    }
  }
}
