import styled from "styled-components";
import { MDBNavbar, MDBNavbarToggler } from "mdb-react-ui-kit";
import { Card } from "../App.styles";

export const ChallengeCard = styled(Card)`
  margin-bottom: 1.25rem;
`;

export const ChallengeNavbar = styled(MDBNavbar)`
  background-color: #eceff1;
  position: relative;

  .container-fluid {
    flex-direction: column;
  }

  .navbar-toggler {
    text-transform: uppercase;
  }

  @media (max-width: ${({ theme }) => theme.maxMobileWidth}) {
    && .nav-link {
      font-size: 1.25rem;
      text-align: center;
    }
  }

  @media (min-width: ${({ theme }) => theme.minDesktopWidth}) {
    .navbar-collapse {
      height: auto !important;
    }
  }

  .nav-link {
    font-size: 0.75rem;
    color: rgba(0, 0, 0, 0.54);
    text-transform: uppercase;
    cursor: pointer;
    padding-left: 0;

    &.active {
      color: rgba(0, 0, 0, 0.87);
    }
  }
`;

export const NavbarToggler = styled(MDBNavbarToggler)`
  ${({ show }) => show || "display: none;"}
`;
