import { useState } from "react";
import { useMutation } from "react-query";
import styled from "styled-components";
import externalAxios from "axios";
import { useTranslation } from "react-i18next";
import Spinner from "./Spinner";
import { axios } from "../lib/request";
import { Button, ButtonRow } from "../App.styles";

const PreviewImage = styled.div`
  background-color: ${({ theme }) => theme.placeholderColor};
  display: flex;
  height: 75px;
  margin-right: 1rem;
  width: ${({ width }) => width}px;
  align-items: center;
  justify-content: center;

  img {
    width: ${({ width }) => width}px;
    height: 75px;
    object-fit: contain;
  }

  ${({ error }) =>
    error &&
    `
    border: 2px solid red;

    img {
      display: none;
    }
  `}
`;

const ImageUploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  .form-label {
    font-size: 0.8rem;
  }

  ${ButtonRow} {
    margin-top: 0.75rem;
    margin-bottom: 0.25rem;

    ${Button} {
      margin-right: 0.25rem;
      max-width: 200px;
      text-overflow: ellipsis;
    }
  }
`;

const FileWrapper = styled.div`
  margin-right: 0.5rem;

  input[type="file"] {
    display: none;
  }

  .form-control {
    font-size: 0.8rem;
  }
`;

const getDescription = (type) => {
  switch (type) {
    case "video":
      return "Video";
    default:
      return "Image";
  }
};

const findThumbURL = (collection, key, type) => {
    const assets = collection.assets

    let asset = (assets?.[`${type}s`] || []).find(
        (asset) => asset.name === key && (asset.urls?.png || asset.url)
    );

    if (!asset) {
        // Branding assets are in the root level of the collection object
        asset = collection?.[type]
    }

    // Branding's tour asset url:
    return asset?.[key]?.url ||
        // Video asset url:
        asset?.urls?.png || 
        // Normal asset url:
        asset?.url || 
        // Fallback url:
        null
};

const readFileURL = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();

    reader.onload = ({ target }) => resolve(target.result);
    reader.readAsDataURL(file);
  });
};

const translateKey = (key) => {
  switch (key) {
    case "ya":
      return "correct";
    case "naw":
      return "incorrect";
    case "challengesenabled":
      return "challenges";
    case "streamenabled":
      return "stream";
    case "leaderboardenabled":
      return "leaderboard";
    case "reelsenabled":
      return "reels";
    case "rewardsenabled":
      return "rewards";
    case "joinenabled":
      return "join";
    case "notificationsenabled":
      return "notifications";
    case "helpenabled":
      return "help";
    case "customenabled":
      return "custom";
      case "overlay":
        return "sticker";
    case "tour_1":
      return 0;
    case "tour_2":
      return 1;
    case "tour_3":
      return 2;
    case "tour_4":
      return 3;
    default:
      return key;
  }
};

const ImageUpload = ({ label, name, type = "photo", useQuery, width = 75 }) => {
  const { t } = useTranslation();
  const [collection, setCollection] = useQuery();
  const key = name === "photo" ? "standard" : name;
  const thumb = findThumbURL(
    // Assets are sometimes at the root level of the collection object,
    // e.g. assets for branding
    collection,
    translateKey(key),
    type
  );
  const [placeholder, setPlaceholder] = useState(!thumb);
  const [error, setError] = useState(false);
  const [preview, setPreview] = useState(!placeholder);
  const updateTimeout = 1000;
  let timeoutRef = null

  const commitMutation = (file, fileURL) => {
    // When file payloads are all nulls
    // it clears the existing media
    const { uuid = null, extension = null } = file || {}
    // watermark does not need `media` prefix in the payload
    const payloadPrefix = ['watermark'].includes(type)
        ? `${name}`
        : `${name}_media`
    let media = (collection.assets?.[`${type}s`] || []).filter(
      (asset) => asset.name !== translateKey(key)
    );
    let assets = undefined;
    let mutationPayload = {}
  
    if (type === "tour") {
      // tour medias are arrays containing asset urls
      const index = translateKey(key);
      media = collection.tour[index];
  
      const tour = [
        ...collection.tour.slice(0, index),
        { ...media, url: fileURL },
        ...collection.tour.slice(index + 1),
      ];
  
      mutationPayload = { tour }
    } else if (media) {
      // common medias are stored in the assets object
      assets = {
        ...collection.assets,
        [`${type}s`]: [...media, { name: translateKey(key), url: fileURL }],
      };
  
      mutationPayload = { assets }
    } else {
        // branding medias are stored in the root collection object
        mutationPayload = {
            [type]: {
                url: fileURL,
            }
        }
    }
    setCollection({ 
        ...mutationPayload,
        [`${payloadPrefix}_uuid`]: uuid,
        [`${payloadPrefix}_extension`]: extension 
            ? extension.toLocaleLowerCase() 
            : extension, 
    })
  }

  const { isLoading, mutateAsync } = useMutation(async (file) => {
    const fileURL = await readFileURL(file);
    const [ext] = file.name.split(".").reverse();
    const url = `${
      process.env.REACT_APP_API_SIGN_URL
    }/${ext.toLocaleLowerCase()}`;
    const { data } = await axios.get(url);
    const options = { headers: { "Content-Type": file.type } };

    await externalAxios.put(data.signature, file, options);

    const [uuid, extension] = data.name.split(".");
    timeoutRef = setTimeout(
        commitMutation({ uuid, extension}, fileURL), 
        updateTimeout
    );
  });

  const upload = async (file) => {
    if (file) {
      try {
        setPlaceholder(false);
        setPreview(true);
        setError(false);
        await mutateAsync(file);
        clearTimeout(timeoutRef)
      } catch (e) {
        console.error(e)
      }
    }
  };

  const clear = () => {
    commitMutation(null, '');
    setPlaceholder(true);
    setPreview(false);
    setError(false);
  };

  return preview ? (
    <ImageUploadWrapper className="form-outline">
      {label && <label className="form-label">{label}</label>}
      <PreviewImage
        error={error}
        onClick={() => setPreview(false)}
        width={width}
      >
        {isLoading ? (
          <Spinner />
        ) : (
          thumb && (
            <img src={thumb} alt={label} onError={() => setError(true)} />
          )
        )}
      </PreviewImage>
    </ImageUploadWrapper>
  ) : (
    <ImageUploadWrapper className="form-outline">
      <FileWrapper>
        {label && <label className="form-label">{label}</label>}
        <input
          accept={
            type === "video"
              ? ".avi,.mkv,.mov,.mp4,.webm"
              : ".jpeg,.jpg,.png,.gif"
          }
          className="form-control"
          id={`${name}-${type}-upload`}
          type="file"
          onChange={({ target }) => upload(target.files[0])}
        />
      </FileWrapper>
      <ButtonRow>
        <Button color="info">
          <label htmlFor={`${name}-${type}-upload`}>{t('Select File')}</label>
        </Button>
        <Button color="warning" onClick={() => setPreview(true)}>
          {t('Cancel')}
        </Button>
        <Button color="danger" disabled={!!placeholder} onClick={clear}>
          {t('Delete')} {getDescription(type)}
        </Button>
      </ButtonRow>
    </ImageUploadWrapper>
  );
};

export default ImageUpload;
