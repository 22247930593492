import { HelpText } from "../App.styles";

import "froala-editor/js/froala_editor.pkgd.min.js";
import "froala-editor/js/plugins/align.min.js";
import "froala-editor/js/plugins/char_counter.min.js";
import "froala-editor/js/plugins/code_beautifier.min.js";
import "froala-editor/js/plugins/code_view.min.js";
import "froala-editor/js/plugins/colors.min.js";
import "froala-editor/js/plugins/cryptojs.min.js";
import "froala-editor/js/plugins/draggable.min.js";
import "froala-editor/js/plugins/edit_in_popup.min.js";
import "froala-editor/js/plugins/entities.min.js";
// import "froala-editor/js/plugins/file.min.js";
// import "froala-editor/js/plugins/files_manager.min.js";
import "froala-editor/js/plugins/font_family.min.js";
import "froala-editor/js/plugins/font_size.min.js";
import "froala-editor/js/plugins/fullscreen.min.js";
import "froala-editor/js/plugins/fullscreen.min.js";
import "froala-editor/js/plugins/help.min.js";
import "froala-editor/js/plugins/image.min.js";
// import "froala-editor/js/plugins/image_manager.min.js";
import "froala-editor/js/plugins/inline_class.min.js";
import "froala-editor/js/plugins/inline_style.min.js";
import "froala-editor/js/plugins/line_breaker.min.js";
import "froala-editor/js/plugins/line_height.min.js";
import "froala-editor/js/plugins/link.min.js";
import "froala-editor/js/plugins/lists.min.js";
import "froala-editor/js/plugins/paragraph_format.min.js";
import "froala-editor/js/plugins/paragraph_style.min.js";
import "froala-editor/js/plugins/print.min.js";
import "froala-editor/js/plugins/quick_insert.min.js";
import "froala-editor/js/plugins/quote.min.js";
import "froala-editor/js/plugins/save.min.js";
import "froala-editor/js/plugins/special_characters.min.js";
import "froala-editor/js/plugins/table.min.js";
// import "froala-editor/js/plugins/trim_video.min.js";
import "froala-editor/js/plugins/url.min.js";
// import "froala-editor/js/plugins/video.min.js";
import "froala-editor/js/plugins/word_paste.min.js";
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/plugins/char_counter.min.css";
import "froala-editor/css/plugins/code_view.min.css";
import "froala-editor/css/plugins/colors.min.css";
import "froala-editor/css/plugins/draggable.min.css";
// import "froala-editor/css/plugins/file.min.css";
import "froala-editor/css/plugins/fullscreen.min.css";
import "froala-editor/css/plugins/help.min.css";
import "froala-editor/css/plugins/image.min.css";
// import "froala-editor/css/plugins/image_manager.min.css";
import "froala-editor/css/plugins/line_breaker.min.css";
import "froala-editor/css/plugins/quick_insert.min.css";
import "froala-editor/css/plugins/special_characters.min.css";
import "froala-editor/css/plugins/table.min.css";
// import "froala-editor/css/plugins/video.min.css";

import styled from "styled-components";
import { useEffect, useState } from "react";
import FroalaEditor from "react-froala-wysiwyg";
import Editor from "froala-editor";
import { Col } from "../App.styles";
import { useUploadPolicyQuery } from "../Global.state";

const EditorHeader = styled.div`
  width: 100%;
  display: flex;

  .nav-item {
    width: 20%;

    .nav-link {
      text-align: center;
      padding-left: 0;
      padding-right: 0;
    }
  }

  .form-label {
    width: 60%;
    align-self: flex-end;
    margin-bottom: 0.125rem;
    font-size: 0.8rem;
  }
`;

const EditorCol = styled(Col)`
  flex-direction: column;
  margin-bottom: 1rem;
  max-width: 100%;
  position: relative;

  textarea::selection {
    background: #ccc;
  }

  .fr-wrapper {
    height: 250px;
    overflow-y: scroll;
  }

  .fr-box {
    width: 100%;
  }

  .fr-toolbar-open {
    padding-bottom: 0;
  }
`;

const RichEditor = ({ defaultValue, help, label, name, useQuery, instantUpdate = true, onUpdate = () => { }, onInitialized = () => {}, toolbarButtons, events = {} }) => {
    const uploadPolicy = useUploadPolicyQuery();
  const [collection, setCollection] = useQuery();
  const [value, setValue] = useState(null);
  const [focus, setFocus] = useState(false);

  useEffect(() => {
    if (value !== null && value !== collection[name]) {
      if (value === "<br>") {
        setCollection({ [name]: "" });
      } else {
        setCollection({ [name]: value });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, value]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setValue(collection[name]), []);

  const onFocus = () => setFocus(true);

  const blur = function (e) {
    setFocus(false);

    if (e) {
      setValue(e.target.innerHTML);
    } else if (this.codeView.isActive()) {
      const html = this.codeView.get();

      if (html !== value) {
        setValue(html);
      }
    }
  };

  Editor.DefineIcon("reset", { NAME: "reset", SVG_KEY: "remove" });
  Editor.RegisterCommand("reset", {
    title: "Reset to Default",
    focus: false,
    undo: true,
    refreshAfterCallback: false,
    callback: () => setValue(defaultValue),
  });

  const miscButtons = defaultValue
    ? [
        "reset",
        "undo",
        "redo",
        "fullscreen",
        "print",
        "getPDF",
        "spellChecker",
        "selectAll",
        "html",
        "help",
      ]
    : [
      "undo",
      "redo",
      "fullscreen",
      "print",
      "getPDF",
      "spellChecker",
      "selectAll",
      "html",
      "help",
    ];
  const defaultToolbars = {
    moreText: {
      buttons: [
        "bold",
        "italic",
        "underline",
        "strikeThrough",
        "subscript",
        "superscript",
        "fontFamily",
        "fontSize",
        "textColor",
        "backgroundColor",
        "inlineClass",
        "inlineStyle",
        "clearFormatting",
      ],
    },
    moreParagraph: {
      buttons: [
        "alignLeft",
        "alignCenter",
        "formatOLSimple",
        "alignRight",
        "alignJustify",
        "formatOL",
        "formatUL",
        "paragraphFormat",
        "paragraphStyle",
        "lineHeight",
        "outdent",
        "indent",
        "quote",
      ],
    },
    moreRich: {
      buttons: [
        "insertLink",
        "insertImage",
        "insertVideo",
        "insertTable",
        "fontAwesome",
        "specialCharacters",
        "embedly",
        "insertFile",
        "insertHR",
      ],
      buttonsVisible: 5,
    },
    moreMisc: {
      buttons: miscButtons,
      align: "right",
      buttonsVisible: defaultValue ? 3 : 2,
    },
  }

  return (
    <EditorCol className="form-outline col--stretch">
      {
        label
          ? (
            <EditorHeader>
              <label className="form-label">{label}</label>
            </EditorHeader>
          )
          : null
      }
      <FroalaEditor
        model={collection[name]}
        config={{
          attribution: false,
          enter: Editor.ENTER_BR,
          events: { 
            blur, 
            focus: onFocus, 
            initialized: onInitialized, 
            'popups.show.image.insert': function () {
              try {
                // Builder wysiwyg need to enforce embedded links to use https
                const imgLabel = this.$box[0].querySelector('label[for*=fr-image-by-url-layer-text]')
                const input = this.$box[0].querySelector('input[id*=fr-image-by-url-layer-text]')
                if (imgLabel && imgLabel.innerHTML === 'http://') {
                    imgLabel.innerHTML = 'https://'
                }
                input.addEventListener('keyup', function(e) {
                  if ((this.value || '').match(/^http:\/\//g)) {
                    this.value = this.value.replace('http://', 'https://')
                  }
                });
              } catch {}
            },
            ...events,
          },
          htmlRemoveTags: [],
          htmlExecuteScripts: false,
          imageUploadToS3: uploadPolicy,
          key: process.env.REACT_APP_FROALA_KEY,
          pasteDeniedAttrs: ["class", "id", "style"],
          pasteDeniedTags: ["div"],
          toolbarButtons: toolbarButtons
            ? toolbarButtons(defaultToolbars)
            : defaultToolbars
        }}
      />
      {help && <HelpText focus={focus}>{help}</HelpText>}
    </EditorCol>
  );
};

export default RichEditor;
