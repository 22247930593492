import { useLocation, Redirect, Route } from "react-router-dom";
import { useIsAuthenticated } from "react-auth-kit";

const PrivateRoute = ({ loginPath, ...routeProps }) => {
    const location = useLocation();
    const isAuthenticated = useIsAuthenticated()
    return isAuthenticated()
        ? <Route {...routeProps} />
        : <Redirect to={{
            pathname: loginPath, 
            state: {
                referrer:`${location.pathname}/${location.search}`,
            }
        }} />
}

export default PrivateRoute