import { useCallback } from "react";
import {
  atom,
  atomFamily,
  selector,
  useResetRecoilState,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import FacebookPixel from "react-facebook-pixel";
import { DateTime } from "luxon";
import { axios } from "../lib/request";
import { dashboardQueryKey, gameUri, gameBrandingUri } from "../lib/refresh";
import { DEFAULT_TIMEZONE } from "../consts/timezone";

export const errorState = atomFamily({
  key: "game/error",
  default: [],
});

const gameState = atom({
  key: "game",
  default: selector({
    key: "game/default",
    get: () => {
      return {
        assets: { photos: [] },
        category: "Active",
        game_type: 1,
        license_type: 1,
        photo_thumb: "",
        players: [],
        signed_legal: 1,
        tour: [],
        tz: DEFAULT_TIMEZONE,
      };
    },
  }),
});

export const fetchGame = async (game_id, game) => {
  let result = {}
  try {
    if (game_id === "new") {
      return { game };
    }
  
    const url = gameUri(game_id);
    const { data } = await axios.get(url);
    result = data
  } catch {}
  return result;
};

export const fetchBranding = async (game_id) => {
  const { data } = await axios.get(gameBrandingUri(game_id));
  return data;
};

export const fetchTimezone = async () => {
  const { data } = await axios.get(process.env.REACT_APP_API_TIMEZONE_LIST_URL);
  return data;
};

export const useTimezoneDataQuery = () => {
  const { data } = useQuery('timezone', () => fetchTimezone(), {
    suspense: true,
  });
  return data;
};

const createGame = async (params) =>
  axios.post(process.env.REACT_APP_API_GAME_CREATE_URL, params);

const updateGame = async (game_id, rawParams) => {
  let { assets, tour, ...params } = rawParams;

  if (params.start_at) {
    params = {
      ...params,
      start_at: DateTime.fromISO(params.start_at)
        .toFormat("MM/dd/yyyy hh:mm a")
        .toLocaleLowerCase(),
    };
  }

  if (params.finish_at) {
    params = {
      ...params,
      finish_at: DateTime.fromISO(params.finish_at)
        .toFormat("MM/dd/yyyy hh:mm a")
        .toLocaleLowerCase(),
    };
  }

  if (Object.keys(params).length === 0) {
    return;
  }

  return axios.put(
    `${process.env.REACT_APP_API_GAME_UPDATE_URL}/${game_id}.json`,
    params
  );
};

const updateGameBranding = async (game_id, rawParams) => {
  const { assets, tour, ...params } = rawParams;

  if (Object.keys(params).length) {
    return axios.put(
      `${process.env.REACT_APP_API_GREY_URL}/${game_id}/grey.json`,
      params
    );
  }
};

export const useGameDataQuery = (game_id) => {
  const game = useRecoilValue(gameState);
  return useQuery(gameUri(game_id), () => fetchGame(game_id, game), {
    suspense: true,
  });
};

export const useGameBrandingDataQuery = () => {
  const { game_id } = useParams();
  const { data } = useQuery(gameBrandingUri(game_id), () => fetchBranding(game_id), {
    suspense: true,
  });
  return data;
};

export const useGameQuery = (id) => {
  const params = useParams();
  const game_id = id || params.game_id;
  const { data } = useGameDataQuery(game_id);
  const [game, setGame] = useRecoilState(gameState);
  const { update } = useUpdateMutation(game_id);
  const queryClient = useQueryClient();

  const setter = useCallback(
    (params) => {
      if (game_id === "new") {
        setGame({ ...game, ...params });
        queryClient.setQueryData(gameUri("new"), {
          ...data,
          game: { ...game, ...params },
        });
      } else {
        if (params?.timer_visible) {
            params.timer_visible = params.timer_visible === 'true'
                ? 1
                : 0
        }
        update(params);
      }
    },
    [data, game_id, game, queryClient, setGame, update]
  );

  return [data?.game, setter];
};

export const useGameBrandingQuery = () => {
  const { game_id } = useParams();
  const { data } = useGameDataQuery(game_id);
  const { update } = useUpdateBrandingMutation();

  return [data?.game, update];
};

export const useCreateMutation = () => {
  const game = useRecoilValue(gameState);
  const resetGame = useResetRecoilState(gameState);
  const setError = useSetRecoilState(errorState("new"));
  const queryClient = useQueryClient();
  
  const payload = {...game}
  if (payload?.start_anytime === undefined) {
    payload.start_at = undefined
    payload.finish_at = undefined
    payload.tz = undefined
  } else {
    payload.start_at = DateTime.fromISO(payload.start_at)
      .toFormat("MM/dd/yyyy hh:mm a")
      .toLocaleLowerCase()
    payload.finish_at = DateTime.fromISO(payload.finish_at)
      .toFormat("MM/dd/yyyy hh:mm a")
      .toLocaleLowerCase()
  }

  const { isLoading, mutateAsync } = useMutation(() => createGame(payload), {
    onError: (error) => {
      setError(error.response.data);
      window.scrollTo(0, 0);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(dashboardQueryKey());
      setError([]);
      resetGame();
    },
  });

  return { isCreating: isLoading, create: mutateAsync };
};

export const useUpdateMutation = (game_id) => {
  const setError = useSetRecoilState(errorState(game_id));
  const queryClient = useQueryClient();
  const uri = gameUri(game_id);

  const { mutateAsync } = useMutation((params) => updateGame(game_id, params), {
    onError: (error) => {
      setError(error.response.data);
      window.scrollTo(0, 0);
    },
    onMutate: (params) => {
      const data = queryClient.getQueryData(uri);
      setError([]);
      queryClient.setQueryData(uri, {
        ...data,
        game: { ...data.game, ...params },
      });
    },
    onSuccess: (_, params) => {
      if (!params.assets && !params.tour) {
        queryClient.invalidateQueries(uri);
      }
    },
  });

  return { update: mutateAsync };
};

export const useUpdateBrandingMutation = () => {
  const { game_id } = useParams();
  const { data } = useGameDataQuery(game_id);
  const setError = useSetRecoilState(errorState(game_id));
  const queryClient = useQueryClient();
  const uri = gameUri(game_id);

  const { mutateAsync } = useMutation(
    (params) => updateGameBranding(game_id, params),
    {
      onError: (error) => {
        setError(error.response.data);
        window.scrollTo(0, 0);
      },
      onMutate: (params) => {
        queryClient.setQueryData(uri, {
          ...data,
          game: { ...data.game, ...params },
        });
      },
    }
  );

  return { update: mutateAsync };
};

export const usePayMutation = () => {
  const { game_id } = useParams();
  const queryClient = useQueryClient();
  const uri = gameUri(game_id);

  const { isLoading, mutateAsync } = useMutation(
    (params) =>
      axios.patch(
        `${process.env.REACT_APP_API_PAYMENT_URL}/${game_id}/teams.json`,
        params
      ),
    {
      onError: (error) => console.log("[error]", error),
      onSuccess: () => {
        queryClient.invalidateQueries(uri);
        FacebookPixel.track("Purchase");
      },
    }
  );

  return { isPaying: isLoading, pay: mutateAsync };
};

export const useBrandingPayMutation = () => {
  const { game_id } = useParams();
  const queryClient = useQueryClient();
  const uri = gameUri(game_id);

  const { isLoading, mutateAsync } = useMutation(
    (params) =>
      axios.patch(
        `${process.env.REACT_APP_API_PAY_GREY_URL}/${game_id}/branding.json`,
        params
      ),
    {
      onError: (error) => console.log("[error]", error),
      onSuccess: () => {
        queryClient.invalidateQueries(uri);
        FacebookPixel.track("Purchase");
      },
    }
  );

  return { isPaying: isLoading, pay: mutateAsync };
};

export const useReelPayMutation = () => {
  const { game_id } = useParams();
  const queryClient = useQueryClient();
  const uri = gameUri(game_id);

  const { isLoading, mutateAsync } = useMutation(
    (params) =>
      axios.patch(
        `${process.env.REACT_APP_API_PAY_REELS_URL}/${game_id}/reels.json`,
        params
      ),
    {
      onError: (error) => console.log("[error]", error),
      onSuccess: () => {
        queryClient.invalidateQueries(uri);
        FacebookPixel.track("Purchase");
      },
    }
  );

  return { isPaying: isLoading, pay: mutateAsync };
};
