import styled from "styled-components";

export const Menu = styled.div`
    ${() => `
        display: flex;
        justify-content: space-between;
        align-items: center;
    `}
`;

export const MenuLabel = styled.span`
    display: inline-block;
    margin-right: 24px;
`;

export const FieldWrapper = styled.div`
    margin-top: 24px;
`;