import styled from "styled-components";
import { useMutation, useQueryClient } from "react-query";
import { useSetRecoilState } from "recoil";
import { MDBBadge } from "mdb-react-ui-kit";
import { format } from "timeago.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useDecision } from "@optimizely/react-sdk";
import { useTranslation } from "react-i18next";
import { Button, ButtonColumn } from "../App.styles";
import { ExternalLinkButton, LinkButton, Spinner } from "../common";
import { alertState, useLiberateMutation } from "./Dashboard.state";
import { UpdatedAt, DeleteButton, Td } from "./Dashboard.styles";
import LiberateDropdown from "./LiberateDropdown";
import { axios } from "../lib/request";
import { dashboardQueryKey } from "../lib/refresh";
import usePreviewButton from "../hooks/usePreviewButton";

const HighlightId = styled.span`
  padding: 5px;
`;

const CategoryBadge = styled(MDBBadge)`
  margin-top: 1rem;
`;

const color = (category) => {
  switch (category) {
    case "Active":
    default:
      return "success";
    case "Template":
      return "warning";
    case "Draft":
      return "danger";
    case "Archive":
      return "info";
  }
};

const GameSummary = ({
  code,
  export_player_enabled,
  id,
  name,
  updated_at,
  admin,
  category,
  user,
  liberated,
  onDelete,
}) => {
  const { t } = useTranslation();
  const [decisionDonations] = useDecision('donations');
  const [decisionGroups] = useDecision('groups');
  const [decisionAnalytics] = useDecision('analytics');
  const [decisionAdventures] = useDecision('adventures')
  const queryClient = useQueryClient();
  const setAlert = useSetRecoilState(alertState);
  const { isLiberating, liberate } = useLiberateMutation(id);
  const { isLoading, mutate } = useMutation(
    () => axios.post(`${process.env.REACT_APP_API_CLONE_URL}/${id}/clone.json`),
    {
      onSettled: () => window.scrollTo({ top: 0, behavior: "smooth" }),
      onSuccess: () => {
        setAlert({
          type: "info",
          message:
            "Your private game has been created. Go ahead and fine tune it, then get your phone and get going!",
        });
        queryClient.invalidateQueries(dashboardQueryKey());
      },
    }
  );
  const onLiberate = pkg => liberate({
    params: {
      packages: pkg,
    } 
  })
  const previewBtnProps = usePreviewButton({ code })

  return (
    <tr>
      <Td>
        <HighlightId className="alert alert-success">{id}</HighlightId>
        <CategoryBadge color={color(category)}>{category}</CategoryBadge>
      </Td>
      <Td>{name}</Td>
      <Td>{code}</Td>
      <Td>
        <UpdatedAt title={updated_at}>{format(updated_at)}</UpdatedAt>
      </Td>
      <Td>
        {admin && (
          <ButtonColumn>
            <LinkButton color="primary" to={`/games/edit/${id}`}>
              {t('Settings')}
            </LinkButton>
            <LinkButton color="primary" to={`/tasks/organize/${id}`}>
              {t('Challenges')}
            </LinkButton>
            {
              decisionAdventures?.enabled
                ? (
                  <LinkButton color="primary" to={`/games/path/${id}`}>
                    {t('Path')}
                  </LinkButton>
                )
                : null
            }
            <LinkButton color="primary" to={`/teams/organize/${id}`}>
              {t('Teams')}
            </LinkButton>
            {
              decisionGroups?.enabled
                ? (
                  <LinkButton color="primary" to={`/groups/organize/${id}`}>
                    {'Groups'}
                  </LinkButton>
                )
                : null
            }
            <LinkButton color="primary" to={`/games/rewards/${id}`}>
              {t('Rewards')}
            </LinkButton>
            <LinkButton color="primary" to={`/games/grey/${id}`}>
              {t('Branding')}
            </LinkButton>
            <LinkButton color="primary" to={`/games/personalizations/${id}`}>
              {t('Personalizations')}
            </LinkButton>
            {user.super_admin && (
              <LiberateDropdown 
                onLiberate={onLiberate}
                isLiberating={isLiberating}
                liberated={liberated}
              />
            )}
            <ExternalLinkButton
              color="warning"
              {...previewBtnProps}
              href={undefined}
              target={undefined}
            >
              {t('App Preview')}
            </ExternalLinkButton>
          </ButtonColumn>
        )}
      </Td>
      <Td>
        <ButtonColumn>
          <LinkButton color="primary" to={`/games/stream/${id}`}>
            {t('Stream')}
          </LinkButton>
          <LinkButton
            color="primary"
            to={`/teams/leaderboard/${id}?style="alltime|daily|weekly"`}
          >
            {t('Leaderboard')}
          </LinkButton>
          {admin && (
            <LinkButton color="primary" to={`/answers/monitor/${id}`}>
              {t('Monitor')}
            </LinkButton>
          )}
          {
            decisionAnalytics?.enabled && admin
              ? (
                <LinkButton color="primary" to={`/games/analytics/${id}`}>
                  {t('Analytics')}
                </LinkButton>
              )
              : null
          }
        </ButtonColumn>
      </Td>
      <Td>
        <ButtonColumn>
          {(admin || export_player_enabled) && (
            <LinkButton color="primary" to={`/games/exports/${id}`}>
              {t('Exports')}
            </LinkButton>
          )}
          <LinkButton color="warning" to={`/games/slideshow/${id}`}>
            Slideshow
          </LinkButton>
          {admin && (
            <>
              <LinkButton color="warning" to={`/games/reels/${id}`}>
                {t('Reels')}
              </LinkButton>
              {
                decisionDonations?.enabled
                  ? (
                    <LinkButton color="warning" to={`/games/donate/${id}`}>
                      {t('Donations')}
                    </LinkButton>
                  )
                  : null
              }
              <Button color="danger" onClick={() => mutate(id)}>
                {isLoading ? <Spinner /> : "Clone"}
              </Button>
            </>
          )}
        </ButtonColumn>
      </Td>
      <Td>{user.access}</Td>
      <Td>
        {admin && (
          <DeleteButton color="danger" onClick={onDelete}>
            <FontAwesomeIcon icon={faTimes} />
          </DeleteButton>
        )}
      </Td>
    </tr>
  );
};

export default GameSummary;