import { useTranslation } from "react-i18next";
import { FormAutocomplete, RowInfo } from "../../common";
import { PointsModal } from "../modals";
import { useQueryInvalidateInfinite } from "../Organize.state";

const PointsSelect = ({ unlimited, trivia, ...props }) => {
  const { t } = useTranslation()
  return (
    <FormAutocomplete 
      {...props}
      options={[
  
        ...Array.from(Array(501).keys()).map(i => ({
          id: i,
          name: String(i),
        })),
        ...trivia ? [{
          id: 9999,
          name: unlimited ? t('Unlimited') : t('Use Unlimited')
        }] : []
      
      ]}
      size="small"
    />
  )
};

const HintsPointsRow = ({ useQuery }) => {
  const { t } = useTranslation();

  return (
    <div className="flex-grow-1">
      <PointsSelect
        help={t('If they submit the correct answer on try 1, they get this many points.')}
        label={t('Default points')}
        name="trivia_points_1"
        useQuery={useQuery}
        mode="clean"
        className="mb-4"
        trivia
      />
    </div>
  )
};

const PointsRow = ({ useQuery }) => {
  const { t } = useTranslation();
  
  return (
    <PointsSelect
      help={t('Choose how many points should be given by default for completion.')}
      label={t('Default points')}
      name="points"
      useQuery={useQuery}
      mode="clean"
    />
  )
};

const PointsSectionBody = ({ useQuery }) => {
  const [{ task_type }] = useQuery();

  if (parseInt(task_type) === 5) {
    return <HintsPointsRow useQuery={useQuery} />;
  }

  return <PointsRow useQuery={useQuery} />;
}

const PointsSection = ({ useQuery, id }) => {  
  const [{ task_type }] = useQuery();
  const trivia = parseInt(task_type) === 5
  const useQueryInvalidateInfinite_ = () => useQueryInvalidateInfinite(id)

  return (
    <RowInfo help={<PointsModal trivia={trivia} />}>
      <PointsSectionBody useQuery={useQueryInvalidateInfinite_} />
    </RowInfo>
  )
};

export default PointsSection;
