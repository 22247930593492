import styled from "styled-components";
import { MDBCard } from "mdb-react-ui-kit";

export const DatalistHeader = styled.div`
  ${({ theme }) => `
    display: flex;
    justify-content: flex-end;

    input {
        width: 33%;
    }

    @media (max-width: ${theme.maxMobileWidth}) {
        margin-bottom: 1rem;
    }
  `}
`;

export const DatalistCard = styled(MDBCard)`
  margin-bottom: 1.25rem;
`;

export const DatalistLi = styled.li`
  ${({ theme }) => `
    scroll-margin-top: ${theme.headerPadding};
  `}
`;