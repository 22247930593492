import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCardTitle,
} from "mdb-react-ui-kit";
import { useSignIn, useSignOut } from "react-auth-kit";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { axios } from "../lib/request";
import { dashboardQueryKey } from "../lib/refresh";
import { Button, Row } from "../App.styles";
import { FormGroup, SigninForm } from "./SignIn.styles";
import { Alert, Spinner } from "../common";
import { Input } from "../common/Input";
import { alertState } from "../dashboard/Dashboard.state";
import withDashboardRedirect from "../hoc/withDashboardRedirect";

export const SignIn = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const location = useLocation();
  const [error, setError] = useState(
    location.search.match(/expired/)
      ? t("Your session has expired and for security purposes we require you to sign in again.")
      : undefined
  );
  const history = useHistory();
  const signIn = useSignIn();
  const signOut = useSignOut();
  const queryClient = useQueryClient();
  const [{type, message}, setAlert] = useRecoilState(alertState);
  const handleOnError = error => {
    switch (error.response.status) {
      case 401:
      case 404:
        setError(
          t("We were unable to sign you in using that email address.")
        );
        break;
      case 500:
        setError(
          t("There has been a problem connecting with our platform. Please try again.")
        );
        break;
      default:
        setError(error);
    }
  }
  const { isLoading, mutate } = useMutation(
    (params) => axios.post(process.env.REACT_APP_API_AUTH_URL, params).catch(error => {
      return { error }
    }),
    {
      onError: handleOnError,
      onSuccess: ({ data, error }) => {
        if (error) {
          handleOnError(error)
          return
        }
        const { lifespan, refresh_token, token } = data;
        const tokenType = "Bearer";
        const expiration = Date.now() + lifespan * 60 * 1000;
        const authState = {
          user_id: email,
          user_type: "email",
          expiration,
          refresh_token,
          token,
        };

        if (
          signIn({
            token,
            tokenType,
            expiresIn: 1000,
            authState,
          })
        ) {
          setError(null);
          queryClient.invalidateQueries(dashboardQueryKey());
          let redirectTo = location.state?.referrer
          if (!redirectTo || redirectTo === '//') {
            redirectTo = { pathname: "/dashboard" }
          }
          history.replace(redirectTo);
        } else {
          console.error("signIn error");
        }
      },
    }
  );

  useEffect(() => {
    if (email || password) {
      setError(null);
    }
  }, [email, location, password]);

  useEffect(() => {
    if (location?.state?.resetPassword) {
      setAlert({
        type: "success",
        message: t("A new password has been sent to your email address."),
      })
    }
  }, [location?.state?.resetPassword, setAlert, t])

  useEffect(() => {
    signOut();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Row>
        <MDBCol size="12">
          <Alert color="danger">{t(error)}</Alert>
          {
            message
              ? <Alert color={type}>{message}</Alert>
              : null
          }
        </MDBCol>
      </Row>
      <Row>
        <MDBCol size="12">
          <MDBCard>
            <MDBCardHeader>
              <MDBCardTitle>
                <span dangerouslySetInnerHTML={{__html: t('Sign in')}} />
              </MDBCardTitle>
            </MDBCardHeader>
            <MDBCardBody>
              <SigninForm
                onSubmit={(e) => {
                  e.preventDefault();
                  mutate({ email, password });
                }}
              >
                <FormGroup>
                  <Input
                    className="form-control active"
                    label={t('Email')}
                    value={email}
                    onChange={(value) => setEmail(value)}
                  />
                </FormGroup>
                <FormGroup>
                  <Input
                    className="form-control active"
                    label={t('Password')}
                    type="password"
                    value={password}
                    onChange={(value) => setPassword(value)}
                  />
                </FormGroup>
                <Button color="primary" type="submit">
                  <span dangerouslySetInnerHTML={{__html: t('Sign in')}} /> {isLoading && <Spinner size="0.875rem" />}
                </Button>
                <Link to="/sign-up">
                  <Button tag="span" color="link">{t('Create Account')}</Button>
                </Link>
                <Link to="/reset-password">
                  <Button tag="span" color="link">{t('Reset Password')}</Button>
                </Link>
              </SigninForm>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </Row>
    </>
  );
};

export default withDashboardRedirect(SignIn);
