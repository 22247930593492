import styled from "styled-components";
import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { DateTime, Settings } from "luxon";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import LuxonUtils from "@date-io/luxon";
import { useTranslation } from "react-i18next";
import { Row, Col, HelpText } from "../App.styles";
import { RowInfo } from ".";
import { TimingStartModal, TimingEndModal } from "../organize/modals";
import mapTimezones from "../settings/mapTimezones";
import { TimerVisibilityModal } from "../branding/modals";
import RadioGroup from "./RadioGroup";

const ClearLink = styled.span`
  color: ${({ theme }) => theme.primaryColor};
  cursor: pointer;
`;

const RightCol = styled(Col)`
  justify-content: flex-end;
  text-align: right;
`;

const TimingSection = ({
  startLabel,
  finishLabel,
  startName,
  finishName,
  timezone,
  useQuery,
}) => {
  const { t } = useTranslation();
  const [collection, setCollection] = useQuery();

  if (mapTimezones[timezone]) {
    Settings.defaultZoneName = mapTimezones[timezone];
  }

  const { game_id } = useParams();
  const start =
    collection[startName] && DateTime.fromISO(collection[startName]);
  const finish =
    collection[finishName] && DateTime.fromISO(collection[finishName]);

  const clear = (name) => async () => {
    await setCollection({ [name]: null })
    setDefaultTimes()
  };
  const update = useCallback((name) => (date) => setCollection({ [name]: date.toISO() }), [setCollection]);

  const [startFocus, setStartFocus] = useState(false);
  const [finishFocus, setFinishFocus] = useState(false);

  const setDefaultTimes = useCallback(async () => {
    setCollection({
      [startName]: DateTime.now().plus({ hours: 1 }).toISO(),
      [finishName]: DateTime.now().plus({ hours: 1, days: 1 }).toISO(),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!start && !finish) {
      setDefaultTimes()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collection?.start_anytime])

  return (
    <MuiPickersUtilsProvider key={timezone} utils={LuxonUtils}>
      <RowInfo help={<TimingStartModal />} colClassName="datetime-picker-col">
        <>
          <label className="form-label">
            {startLabel} -{" "}
            <ClearLink onClick={clear(startName)}>{t('Clear')}</ClearLink>
          </label>
          <DateTimePicker
            value={start}
            onClose={() => setStartFocus(false)}
            onChange={update(startName)}
            onOpen={() => setStartFocus(true)}
          />
          <HelpText focus={startFocus}>
            {t('If you set a start date, challenges will be hidden until then.')}
          </HelpText>
        </>
      </RowInfo>
      <RowInfo help={<TimingEndModal />} colClassName="datetime-picker-col">
        <>
          <label className="form-label">
            {finishLabel} -{" "}
            <ClearLink onClick={clear(finishName)}>{t('Clear')}</ClearLink>
          </label>
          <DateTimePicker
            value={finish}
            onClose={() => setFinishFocus(false)}
            onChange={update(finishName)}
            onOpen={() => setFinishFocus(true)}
          />
          <HelpText focus={finishFocus}>
            {t('If you set a finish date, challenges will be hidden afterwards.')}
          </HelpText>
        </>
      </RowInfo>
      <RowInfo help={<TimerVisibilityModal />}>
        <RadioGroup
            defaultValue={true}
            label={t(`Timer Visibility`)}
            name="timer_visible"
            options={[
              { value: false, label: t(`Hidden`) },
              { value: true, label: t(`Visible`) },
            ]}
            useQuery={useQuery}
          />
      </RowInfo>
      <Row>
        <Col size="10">
          <HelpText className="position-relative top-0 mt-4 d-block" focus>
          {t('Challenges that are submitted after the end time will be considered late and points will not be awarded.')}
          <br />{t('If you change your timezone, double check these values as they will automatically adjust.')}
          </HelpText>
        </Col>
      </Row>
      {timezone && (
        <Row className="mt-4">
          <RightCol>
            <p>
              <strong>
                <em>{t('Reminder!')}</em>
              </strong>
              <br />
              <em>
              {t('Your game timezone is set to')}{" "}
                <a href={`/games/edit/${game_id}#optional`}>{timezone}</a>.
              </em>
            </p>
          </RightCol>
        </Row>
      )}
    </MuiPickersUtilsProvider>
  );
};

export default TimingSection;
