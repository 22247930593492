import { Suspense } from "react";

const Noop = () => null;

const withPlaceholder = (Component, Placeholder) => (props) => {
  return (
    <Suspense fallback={Placeholder ? <Placeholder /> : <Noop />}>
      <Component {...props} />
    </Suspense>
  );
};

export default withPlaceholder;
