import {
  MDBCard,
  MDBCardHeader,
  MDBCardTitle,
  MDBCardBody,
} from "mdb-react-ui-kit";

const Error = ({ title, message, button }) => {
  return (
    <MDBCard>
      <MDBCardHeader>
        <MDBCardTitle>{title || "Error"}</MDBCardTitle>
      </MDBCardHeader>
      <MDBCardBody>
        <p>{message}</p>
        {button}
      </MDBCardBody>
    </MDBCard>
  );
};

export default Error;
