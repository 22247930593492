import { useState, useCallback } from "react";
import styled from "styled-components";
import { useRecoilState, useRecoilValue } from "recoil";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  deleteIdState,
  notificationState,
  pageState,
  pageSizeState,
  useAchievementsQuery,
  useAchievementsFetchingQuery,
  useAchievementsPagesQuery,
  useCreateMutation,
  useDestroyMutation,
} from "./Rewards.state";
import { Button, HeaderRow, Row, Col } from "../App.styles";
import { SidebarLayout } from "../common";
import { Alert, Pagination, Refresher } from "../common";
import DeleteModal from "./DeleteModal";
import RewardList from "./RewardList";
import Reward from "./Reward";
import RewardsPlaceholder from "./RewardsPlaceholder";
import { withPlaceholder } from "../common";

const RewardHeader = styled.div`
  display: flex;
  justify-content: flex-end;

  input {
    width: 33%;
  }
`;

const Rewards = () => {
  const { t } = useTranslation();
  const { game_id } = useParams();
  const achievements = useAchievementsQuery(game_id);
  const pages = useAchievementsPagesQuery();
  const { color, message } = useRecoilValue(notificationState);
  const [deleteId, setDeleteId] = useRecoilState(deleteIdState);
  const [page, setPage] = useRecoilState(pageState);
  const [pageSize, setPageSize] = useRecoilState(pageSizeState);
  const [scrollId, setScrollId] = useState(0);
  const { create, isCreating } = useCreateMutation();
  const { destroy } = useDestroyMutation();
  const isFetching = useAchievementsFetchingQuery();

  const onCreate = useCallback(async () => {
    try {
      const { data } = await create();
      setScrollId(data.id);
    } catch (e) {
      throw e
    }
  }, [create]);

  return (
    <>
      <Row>
        <Col size="12">
          <Alert color={color}>{message}</Alert>
        </Col>
      </Row>
      <HeaderRow>
        <RewardHeader className="col-12 col-lg-9">
          <Button color="primary" onClick={onCreate} disabled={isCreating}>
            {t('Add New Reward or Clue')}
          </Button>
        </RewardHeader>
      </HeaderRow>
      <Row>
        <SidebarLayout message={t("Your game can have achievements or rewards, unlocked by completing sets of challenges or reaching certain point plateaus.")}>
          <RewardList>
            {achievements.map((row, index) => (
              <Reward
                index={index}
                key={row.id}
                {...row}
                onDelete={setDeleteId}
                game_id={game_id}
                scrollTo={row.id === "new"}
                scrollId={scrollId}
              />
            ))}
          </RewardList>
        </SidebarLayout>
      </Row>
      <Row>
        <Pagination
          loading={isFetching}
          page={page}
          pageSize={pageSize}
          pages={pages}
          setPage={setPage}
          setPageSize={setPageSize}
        />
      </Row>
      <DeleteModal
        show={!!deleteId}
        onConfirm={destroy}
        onDismiss={() => setDeleteId(0)}
      />
      <Refresher subscriptions="ss.reward.create,ss.reward.delete,ss.reward.update" />
    </>
  );
};

export default withPlaceholder(Rewards, RewardsPlaceholder);
