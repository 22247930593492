import React from "react";
import { useTranslation } from "react-i18next";
import { NumberInput } from "./NumberInput";
import { SortableListWrapper } from "./SortableList.style";

const SortableList = ({ children }) => {
    const { t } = useTranslation();
    return React.Children.map(children, (child, index) => {
        const ChildComponent = (props) => React.cloneElement(child, props)
        return (
            <SortableListWrapper key={index}>
                <ChildComponent
                    control={({ setValue, defaultValue }) => {
                        return (
                            <NumberInput
                                label={t("Order")}
                                type="number"
                                min="0"
                                max={children.length - 1}
                                setValue={setValue}
                                wrapperClass="input--strong"
                                defaultValue={String(defaultValue)}
                            />
                        )
                    }}
                />
            </SortableListWrapper>
        )
    });
};

export default SortableList;
