import { useTranslation } from "react-i18next";
import { FormAutocomplete, RowInfo } from "../../common";
import TriviaPointsModal from "../modals/TriviaPointsModal";

const PointsSelect = ({ unlimited, trivia, ...props }) => {
  const { t } = useTranslation()
  return (
    <FormAutocomplete 
      {...props}
      options={[
  
        ...Array.from(Array(501).keys()).map(i => ({
          id: i,
          name: String(i),
        })),
        ...trivia ? [{
          id: 9999,
          name: unlimited ? t('Unlimited') : t('Use Unlimited')
        }] : []
      
      ]}
      size="small"
    />
  )
};

const TriviaPointsSection = ({ useQuery }) => {
  const { t } = useTranslation();

  return (
    <div className="flex-grow-1 mt-4">
      <RowInfo help={<TriviaPointsModal text={t('If they submit the correct answer on try 2, they get this many points.')} />}>
        <PointsSelect
          label={t('Default points if correct on second guess')}
          name="trivia_points_2"
          useQuery={useQuery}
          mode="clean"
          className="mb-4"
          trivia
        />
      </RowInfo>
      <RowInfo help={<TriviaPointsModal text={t('If they submit the correct answer on try 3, they get this many points.')} />}>
        <PointsSelect
          label={t('Default points if correct on third guess')}
          name="trivia_points_3"
          useQuery={useQuery}
          mode="clean"
          className="mb-4"
          trivia
        />
      </RowInfo>
      <RowInfo help={<TriviaPointsModal text={t('If they submit the correct answer on try 4, they get this many points.')} />}>
        <PointsSelect
          label={t('Default points if correct on fourth guess')}
          name="trivia_points_4"
          useQuery={useQuery}
          mode="clean"
          className="mb-4"
          trivia
        />
      </RowInfo>
      <RowInfo help={<TriviaPointsModal text={t('If they submit the correct answer on try 5, they get this many points.')} />}>
        <PointsSelect
          label={t('Default points if correct on fifth guess')}
          name="trivia_points_5"
          useQuery={useQuery}
          mode="clean"
          className="mb-4"
          trivia
        />
      </RowInfo>
      <RowInfo help={<TriviaPointsModal text={t('If they submit the correct answer on any try past 5, they get this many points.')} />}>
        <PointsSelect
          label={t('Default points if correct during unlimited guessing')}
          name="trivia_points_unlimited"
          useQuery={useQuery}
          mode="clean"
          className="mb-4"
          trivia
          unlimited
        />
      </RowInfo>
    </div>
  )
};

export default TriviaPointsSection;
