import { useRecoilState } from "recoil";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { Button } from "../App.styles";
import { ConfirmationModal, withPlaceholder } from "../common";
import Datalist from "../datalist";
import { groupsUri } from "../lib/refresh";
import {
    deleteIdState as deleteId,
    notificationState as notification,
    pageState as page,
    pageSizeState as pageSize,
    useCreateMutation,
    useDestroyMutation,
    useUpdateMutation,
    useGroupsFetchingQuery,
    useGroupsQuery,
    useGroupsPagesQuery,
} from "./Groups.state";
import { useTeamsQuery } from "../teams/Teams.state";
import GroupView from "./GroupView";
import DatalistPlaceholder from "../datalist/DatalistPlaceholder";

const DeleteGroup = (confirmationProps) => {
    // passing t as prop instead of using useTranslation hook directly here
    // to comply with the [rules of hooks](https://reactjs.org/docs/hooks-rules.html)
    const { t, ...props } = confirmationProps
    return (
        <ConfirmationModal 
          title={t("Delete Group" )}
          body={t("Are you sure you wish to delete this group?")}
          confirmLabel={t("Yes, delete")}
          {...props}
        >
            <Button
              color="danger"
              className="btn-raised"
            >
              <FontAwesomeIcon icon={faTimes} />
            </Button>
        </ConfirmationModal>
    )
}

const Groups = () => {
    const { t } = useTranslation();
    const { game_id } = useParams();
    const [, setAlert] = useRecoilState(notification);

    const onCreateError = e => {
        if (e?.response?.status === 403) {
          setAlert({
            color: "danger",
            message: e?.response?.message || t('You must be the game creator, a game administrator, or a system super administrator.'),
          });
        } else {
          throw e;
        }
    }
    return (
        <Datalist
            dataId="group"
            sidebar={{
                message: t('Setup teams in advance or change them up during the game.'),
                panel: null,
            }}
            states={{
                deleteId,
                notification,
                page,
                pageSize,
            }}
            mutations={{
                create: useCreateMutation(),
                destroy: useDestroyMutation(),
                update: useUpdateMutation(),
            }}
            queries={{
                fetching: useGroupsFetchingQuery(),
                list: useGroupsQuery(),
                pages: useGroupsPagesQuery(),
                teams: useTeamsQuery(game_id),
            }}
            cards={{
                view: GroupView,
                edit: props => <GroupView editMode {...props} />,
            }}
            partials={{
                delete: DeleteGroup,
            }}
            errors={{
                onCreate: onCreateError,
            }}
            refresher={{
                subscriptions: 'ss.group.create,ss.group.delete,ss.group.update',
                uri: groupsUri(game_id),
            }}
        />
    )
}

export default withPlaceholder(Groups, DatalistPlaceholder)