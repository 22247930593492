import { useTranslation } from "react-i18next";
import { RowInfo } from "../../common";
import { TriviaModal } from "../modals";
import TriviaPointsSection from "../sections/TriviaPointsSection";
import Textarea from "../Textarea";

const Trivia = ({ useQuery }) => {
  const { t } = useTranslation();

  return (
    <>
      <p dangerouslySetInnerHTML={{__html: t(`Require the player to submit free text to be checked against a set of valid answers.`)}} />
      <RowInfo help={<TriviaModal />}>
        <Textarea
          label={t("Answers (list all valid answers, separated by commas)")}
          name="trivia"
          useQuery={useQuery}
        />
      </RowInfo>
      <TriviaPointsSection useQuery={useQuery} />
    </>
  )
};

export default Trivia;
