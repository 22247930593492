import styled from "styled-components";
import { darken } from "@material-ui/core";
import { MDBCardBody } from "mdb-react-ui-kit";
import { Row, Button } from "../App.styles";

export const HeadRow = styled(Row)`
  ${({ theme }) => `
    ${theme.breakpoints.down('sm')} {
        ${Button} {
            margin: 0;
            padding: 0.5rem 0.75rem;
        }
    }
  `}
`;

export const AddButton = styled.div`
    ${({ theme, disabled }) => `
        background-color: ${darken(theme.lightColor, .05)};
        display: inline-flex;
        padding: 0 15px;
        border-radius: 5px;
        min-height: 40px;
        align-items: center;
        overflow: hidden;
        gap: 20px;
        cursor: pointer;
        
        ${
            disabled ? `
                opacity: .5;
                pointer-events: none;
                cursor: not-allowed;
            ` : ''
        }

        p {
            margin-bottom: 0;
            font-weight: 500;
        }
    `}
`;

export const AddBody = styled.div`
    display: flex;
    gap: 20px;
    align-items: center;
`;

export const GroupViewBody = styled(MDBCardBody)`
    display: flex; 
    gap: 20px; 
    flex-direction: column;
`;

export const GroupViewContent = styled.div`
    display: flex; 
    gap: 20px; 
    flex-wrap: wrap;
`;