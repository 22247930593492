import { useEffect, useState } from "react";
import { FieldHelpText } from "../App.styles";
import statefulField from "./StatefulField";
import { FieldContainer, FieldWrapper } from "./InputField.style";

export const InputField = ({
  defaultValue,
  help,
  label,
  mode = 'outline',
  onChange,
  onBlurExtra,
  regex,
  value,
  setValue,
  maxLength,
  validation,
  wrapperClass = '',
  extra,
  ...rest
}) => {
  const [focus, setFocus] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const initialValue =
    rest.type === "number" && parseFloat(value) === 0
      ? defaultValue || "0.0"
      : value || defaultValue || "";
  const [error, setError] = useState("");

  useEffect(() => setInputValue(initialValue), [initialValue]);

  const setter = ({ target }) => {
    if (target?.type === "number" && (target.min || target.max)) {
      const min = target.min || 0;
      const max = target.max || target.value;
      setInputValue(Math.max(Math.min(target.value, max), min));
    } else {
      if (!regex || target.value.match(regex)) {
        setInputValue(target.value);
      }
    }

    if (onChange) {
      onChange(target.value);
    }
  };

  const commit = (e) => {
    setFocus(false);

    if (validation && setValue) {
      validation(inputValue).then((res) => {
        if ((inputValue !== "" || inputValue !== null) && res.success) {
          setValue(inputValue);
          setError("");
        } else if (inputValue === "") {
          setValue(inputValue);
          setError("");
        } else {
          setError(res.error);
        }
      });
    } else if (setValue) {
      setValue(inputValue);
      setError("");
    }
  };

  return (
    <FieldContainer>
        <FieldWrapper className={wrapperClass} length={label.length}>
            <div className={`form-${mode}`}>
                {
                mode === 'outline'
                    ? null
                    : (
                    <label className="form-label">{label}</label>
                    )
                }
                <input
                className={
                    focus || (inputValue && inputValue.toString().length)
                    ? "form-control active"
                    : "form-control"
                }
                value={inputValue}
                maxLength={maxLength}
                onBlur={(e) => {
                    if (e?.target?.value) {
                    commit(e);
                    onBlurExtra && onBlurExtra(e);
                    }
                }}
                onChange={setter}
                onFocus={() => setFocus(true)}
                onKeyDown={(e) =>
                    e.key !== "-" || rest.type !== "number" || e.preventDefault()
                }
                {...rest}
                />
                {
                mode === 'outline'
                    ? (
                    <div className="form-notch">
                        <div className="form-notch-leading"></div>
                        <div className="form-notch-middle">
                        <label className="form-label">{label}</label>
                        </div>
                        <div className="form-notch-trailing"></div>
                    </div>
                    )
                    : null
                }
            </div>
            {help && focus && <FieldHelpText>{help}</FieldHelpText>}
            <span style={{ color: "#ed5d53", fontSize: "12px", fontWeight: "bold" }}>
                {error}
            </span>
        </FieldWrapper>
        { extra }
    </FieldContainer>
  );
};

export default statefulField(InputField);
