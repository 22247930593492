import styled from "styled-components";
import { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { HelpText } from "../App.styles";
import statefulField from "./StatefulField";

export const FieldWrapper = styled.div`
  ${({ theme }) => `
    position: relative;
    width: 100%;

    &.input--strong {
      .form-outline .form-control ~ .form-notch {
        div {
          border-color: ${theme.darkGreyColor};
        }

        .form-notch-leading {
          border-radius: ${theme.rounded.sm} 0 0 ${theme.rounded.sm};
        }

        .form-notch-trailing {
          border-radius: 0 ${theme.rounded.sm} ${theme.rounded.sm} 0;
        }
      }

      .form-label {
        color: ${theme.darkGreyColor};
      }
    }

    .form-notch-middle {
      align-items: center;
      display: flex;

      label {
        font-size: 1rem;
        margin: 0;
        transform: translateY(0);
        transition: font-size linear 0.2s, transform linear 0.2s;
      }
    }

    .active + .form-notch .form-notch-middle label {
      font-size: 0.8rem;
      margin: 0 2px;
      transform: translateY(-1.15rem) translateY(0.1rem);
    }

    .form-label {
      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;

      ${theme.breakpoints.up('lg')} {
        white-space: normal;
        max-width: none;
      }
    }

    .form-clean {
      .form-label {
        font-size: .8rem;
        margin: .1rem;
      }

      .form-control {
        min-height: 39px;
      }
    }
  `}
`;

export const Input = ({
  defaultValue,
  help,
  label,
  mode = 'outline',
  onChange,
  onBlurExtra,
  regex,
  value,
  setValue,
  maxLength,
  minLength,
  validation,
  wrapperClass = '',
  ...rest
}) => {
  const { t } = useTranslation()
  const [focus, setFocus] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const initialValue = useMemo(() => {
    return rest.type === "number" && parseFloat(value) === 0
      ? defaultValue || "0.0"
      : value || defaultValue || ""
  }, [
    rest.type,
    value,
    defaultValue,
  ]);
  const [error, setError] = useState("");

  useEffect(() => {
    setInputValue(initialValue)
  }, [initialValue]);

  const setter = ({ target }) => {
    if (target?.type === "number" && (target.min || target.max)) {
      const min = target.min || 0;
      const max = target.max || target.value;
      setInputValue(Math.max(Math.min(target.value, max), min));
    } else {
      if (!regex || target.value.match(regex)) {
        setInputValue(target.value);
      }
    }

    if (onChange) {
      onChange(target.value);
    }
  };

  const commit = (e) => {
    if (e?.target?.validity?.tooShort) {
      setError(t(`Please input a minimum of ${minLength} characters.`))
      return
    }
    setFocus(false);

    if (validation && setValue) {
      validation(inputValue).then((res) => {
        if ((inputValue !== "" || inputValue !== null) && res.success) {
          setValue(inputValue);
          setError("");
        } else if (inputValue === "") {
          setValue(inputValue);
          setError("");
        } else {
          setError(res.error);
        }
      });
    } else if (setValue) {
      setValue(inputValue);
      setError("");
    }
  };

  return (
    <FieldWrapper className={wrapperClass} length={label.length}>
      <div className={`form-${mode}`}>
        {
          mode === 'outline'
            ? null
            : (
              <label className="form-label">{label}</label>
            )
        }
        <input
          className={
            focus || (inputValue && inputValue.toString().length)
              ? "form-control active"
              : "form-control"
          }
          value={inputValue}
          maxLength={maxLength}
          minLength={minLength}
          onBlur={(e) => {
            if (e?.target?.value !== value) {
              commit(e);
              onBlurExtra && onBlurExtra(e);
            }
          }}
          onChange={setter}
          onFocus={() => setFocus(true)}
          onKeyDown={(e) =>
            e.key !== "-" || rest.type !== "number" || e.preventDefault()
          }
          {...rest}
        />
        {
          mode === 'outline'
            ? (
              <div className="form-notch">
                <div className="form-notch-leading"></div>
                <div className="form-notch-middle">
                  <label className="form-label">{label}</label>
                </div>
                <div className="form-notch-trailing"></div>
              </div>
            )
            : null
        }
      </div>
      {help && <HelpText focus={focus}>{help}</HelpText>}
      <span style={{ color: "#ed5d53", fontSize: "12px", fontWeight: "bold" }}>
        {error}
      </span>
    </FieldWrapper>
  );
};

export default statefulField(Input);
