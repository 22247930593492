import styled from "styled-components";
import { useContext } from "react";
import { MDBCardBody } from "mdb-react-ui-kit";
import { useTranslation } from "react-i18next";
import { PieChart } from "../common";
import { useChallengesStatisticsQuery } from "./Organize.state";
import { Shimmer } from "../common/Loading.styles";
import { OrganizeContext } from "./Organize.context";

const CardBody = styled(MDBCardBody)`
  margin-top: 2.5rem;
  padding-bottom: 133%;
  position: relative;

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const TypeChart = () => {
  const { challenges } = useContext(OrganizeContext)
  const { isLoading: loading, tasks = [] } = challenges || {}
  const { t } = useTranslation();
  const data = useChallengesStatisticsQuery({ tasks })
  return loading
    ? (
      <>
        <Shimmer dark type="title" />
        <Shimmer dark />
      </>
    )
    : (
      <div className="card">
        <div className="card-header bg-dark text-light">
          <span className="card-title">{t('Statistics')}</span>
        </div>
        <CardBody>
          <PieChart
            data={data}
            innerRadius={25}
            label={t("Challenges")}
            outerRadius={150}
            property="count"
          />
        </CardBody>
        <CardBody>
          <PieChart
            data={data}
            innerRadius={25}
            label={t("Points")}
            outerRadius={150}
            property="points"
          />
        </CardBody>
      </div>
    )
};

export default TypeChart;