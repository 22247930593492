import { useTranslation } from "react-i18next";
import { Modal } from "../../common";
import { Button } from "../../App.styles";

const TimingStartModal = ({ onDismiss, show }) => {
  const { t } = useTranslation();

  return (
    <Modal onDismiss={onDismiss} show={show}>
      <div className="modal-header">
        <h4 className="modal-title">{t('Start')}</h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-hidden="true"
          onClick={onDismiss}
        >
          ×
        </button>
      </div>
      <div className="modal-body">
        <p>
          {t('Specify when you would like the challenge to start appearing to players.')}
        </p>
        <ul>
          <li>
            {t(`The game start time, if set, is used in determining whether or not daylight savings time applies.`)}
          </li>
          <li>
            {t(`Most commonly, challenges are not limited by start and end dates or
            times.`)}
          </li>
          <li>{t('Use timing to surprise or concentrate players.')}</li>
          <li>
            {t('Give extra points for timed challenges to really encourage completion.')}
          </li>
        </ul>
      </div>
      <div className="modal-footer">
        <Button type="button" color="dark" data-dismiss="modal" onClick={onDismiss}>
          {t('Close')}
        </Button>
      </div>
    </Modal>
  )
};

export default TimingStartModal;
