import styled from "styled-components";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBCardTitle,
} from "mdb-react-ui-kit";
import { useVirtualWindow } from "../lib/useVirtualWindow";
import { Row } from "../App.styles";
import { Alert, Refresher, SidebarLayout, withPlaceholder } from "../common";
import { useInfinityTeamStream } from "./Monitor.state";
import Answer from "./Answer";
import LeaderboardWidget from "./LeaderboardWidget";
import MediaOverlay from "./MediaOverlay";
import MonitorPlaceholder from "./MonitorPlaceholder";

export const PanelFooter = styled.div`
  color: #a0a0a0;
  font-size: 0.8125rem;
  margin-bottom: 2rem;
  padding: 10px 15px;
  margin-top: 10px;
`;

const StreamRow = styled.div.attrs(({ start, zIndex }) => ({
  style: {
    transform: `translateY(${start}px)`,
    zIndex: `${zIndex}`,
  }
}))`
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 0;
  width: 100%;
`;

const StreamCol = styled.div`
  align-items: stretch;
  flex-direction: column;
  height: ${({ totalSize }) => totalSize}px;
  position: relative;
`;

const TeamStream = () => {
  const { t } = useTranslation();
  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
  } = useInfinityTeamStream();

  const answers = data ? data.pages.flatMap((page) => page.answers) : [];
  const game = data?.pages[0].game;
  const [overlayId, setOverlayId] = useState();
  const parentRef = useRef();
  const windowRef = useRef(window);

  const rowVirtualizer = useVirtualWindow({
    estimateSize: useCallback(() => 450, []),
    overscan: 5,
    paddingStart: 80,
    parentRef,
    size: answers.length,
    windowRef: windowRef,
  });

  useEffect(
    () => window.requestAnimationFrame(() => window.scrollTo(0, 0)),
    []
  );

  useEffect(() => {
    const [lastItem] = [...rowVirtualizer.virtualItems].reverse();

    if (!lastItem) {
      return;
    }

    if (
      lastItem.index === answers.length - 1 &&
      hasNextPage &&
      !isFetchingNextPage
    ) {
      fetchNextPage();
    }
  }, [
    hasNextPage,
    fetchNextPage,
    answers.length,
    isFetchingNextPage,
    rowVirtualizer.virtualItems,
  ]);

  if (status === "error") {
    throw error;
  }

  if (status === "loading") {
    return <MonitorPlaceholder />;
  }

  const team_name = answers.length ? answers[0].team.name : "";

  return (
    <>
      <Row>
        <SidebarLayout
          panel={(
            <>
              <MDBCard>
                <MDBCardHeader>
                  <MDBCardTitle>{t('Submissions')}</MDBCardTitle>
                  <MDBCardBody>
                    <p dangerouslySetInnerHTML={{__html: t(`Your completed challenges are submitted instantly to a live
                      stream and categorized for easy viewing. The live stream is
                      available on Web and inside the app.`)}} />
                  </MDBCardBody>
                </MDBCardHeader>
              </MDBCard>
              <LeaderboardWidget />
            </>
          )}
        >
          <StreamCol
            size="12"
            lg="9"
            ref={parentRef}
            totalSize={rowVirtualizer.totalSize}
          >
            {team_name !== "" ? (
              <>
                <Alert color="info">
                  {t('Real Time Team Activity for')} {team_name}
                </Alert>
              </>
            ) : (
              ""
            )}
            {rowVirtualizer.virtualItems.length ? (
              rowVirtualizer.virtualItems.map((virtualRow) => {
                return (
                  <StreamRow
                    ref={virtualRow.measureRef}
                    start={virtualRow.start}
                    zIndex={answers.length - virtualRow.index}
                  >
                    <Answer
                      {...answers[virtualRow.index]}
                      game={game}
                      key={virtualRow.index}
                      onFullscreen={setOverlayId}
                    />
                  </StreamRow>
                );
              })
            ) : (
              <Alert color="warning">{t('There are no submissions to review.')}</Alert>
            )}
          </StreamCol>
        </SidebarLayout>
      </Row>
      {overlayId && (
        <MediaOverlay
          {...answers.find((answer) => answer.id === overlayId)}
          onClick={() => setOverlayId(undefined)}
        />
      )}
      <Refresher />
    </>
  );
};

export default withPlaceholder(TeamStream, MonitorPlaceholder);
