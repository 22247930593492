import { MDBCard } from "mdb-react-ui-kit";
import styled from "styled-components";
import { Col, Row } from "../App.styles";

export const MainRow = styled(Row)`
    align-items: flex-start;
    flex-wrap: nowrap;
    flex-direction: column;
`;

export const CardContainer = styled(Col)`
    flex-direction: column;
    align-items: flex-start;
`;

export const Card = styled(MDBCard)`
    margin-bottom: 2.5rem;
    width: 100%;
`;