import { atom, atomFamily } from "recoil";
import { useCallback } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { axios } from "./lib/request";
import { useQueryKey } from "./organize/Organize.state";

export const refreshState = atomFamily({
  key: "global/refresh",
  default: (uri) => false,
});

export const useVideoRefresher = (game_id) => {
  const queryClient = useQueryClient();
  const queryKey = useQueryKey(game_id);

  const { mutate } = useMutation((message) => {
    const { uuid } = message.detail.media;
    const { id } = message.detail.media.relation;
    const data = queryClient.getQueryData(queryKey);

    if (!data) {
      return;
    }

    const taskIndex = data.tasks.findIndex((task) => task.id === id);

    if (taskIndex === -1) {
      return;
    }

    const defaultTask = {
      placeholders: [],
      assets: {
        videos: [],
      },
    }
    const task = (data.tasks || [])[taskIndex] || defaultTask;

    task.placeholders = task.placeholders || defaultTask.placeholders
    task.assets = task.assets || defaultTask.assets
    const placeholderIndex = task.placeholders.findIndex(
      (placeholder) => placeholder.uuid === uuid
    );
    const { key } = task.placeholders?.[placeholderIndex] || {};
    const videoIndex = task.assets.videos.findIndex(
      (video) => video.name === key
    );
    const video = (task.assets?.videos || [])[videoIndex] || {};

    queryClient.setQueryData(queryKey, {
      ...data,
      tasks: [
        ...data.tasks.slice(0, taskIndex),
        {
          ...data.tasks[taskIndex],
          assets: {
            ...task.assets,
            videos: [
              ...task.assets.videos.slice(0, videoIndex),
              {
                ...video,
                urls: {
                  ...video?.urls,
                  png: `https://ss-assets.socialscavenger.com/images/${uuid}-00001.png`,
                },
              },
              ...task.assets.videos.slice(videoIndex + 1),
            ],
          },
        },
        ...data.tasks.slice(taskIndex + 1),
      ],
    });
  });
  return mutate;
};

export const videoQueue = atom({
  key: "global/videoQueue",
  default: [],
});

export const useItemQuery = (data, id, name, queryKey, setter, config = {}) => {
  const { invalidate = true, invalidateKey, ...invalidationConfig } = config
  const queryClient = useQueryClient();
  const collection = name ? data[name] : data;
  const index = collection.findIndex((item) => item.id === id);
  const item = collection[index];

  const setItem = useCallback(
    async (params) => {
      queryClient.cancelQueries(queryKey);
      queryClient.setQueryData(queryKey, {
        ...data,
        [name]: [
          ...collection.slice(0, index),
          { ...item, ...params },
          ...collection.slice(index + 1),
        ],
      });

      const { assets, placeholders, tour, ...rest } = params;

      if (Object.keys(rest).length) {
        await setter(rest);
      }
      
      const shouldInvalidate = (!assets && !placeholders && !tour) && invalidate 
      if (shouldInvalidate) { 
        queryClient.invalidateQueries((invalidateKey || queryKey), { ...invalidationConfig }); 
      } 
    },
    [collection, data, index, item, name, queryClient, queryKey, setter, invalidate, invalidateKey, invalidationConfig]
  );

  return [item, setItem];
};

export const fetchS3Policy = async () => {
  const url = "https://app.socialscavenger.com/v1/upload.json";
  const { data } = await axios.get(url);
  return data;
};

export const useUploadPolicyQuery = () => {
  const { data } = useQuery("uploadPolicy", () => fetchS3Policy(), {
    suspense: true,
  });
  return data;
};
