import styled from "styled-components";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { DateTime, Settings } from "luxon";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import LuxonUtils from "@date-io/luxon";
import { useTranslation } from "react-i18next";
import { Row, Col, HelpText } from "../App.styles";
import { RowInfo } from ".";
import { TimingStartModal, TimingEndModal } from "../organize/modals";
import mapTimezones from "../settings/mapTimezones";

const ClearLink = styled.span`
  color: ${({ theme }) => theme.primaryColor};
  cursor: pointer;
`;

const RightCol = styled(Col)`
  justify-content: flex-end;
  text-align: right;
`;

const TimingSectionNoDefault = ({
  startLabel,
  finishLabel,
  startName,
  finishName,
  timezone,
  useQuery,
}) => {
  const { t } = useTranslation();
  const [collection, setCollection] = useQuery();

  if (mapTimezones[timezone]) {
    Settings.defaultZoneName = mapTimezones[timezone];
  }

  const { game_id } = useParams();
  const start =
    collection[startName] && DateTime.fromISO(collection[startName]);
  const finish =
    collection[finishName] && DateTime.fromISO(collection[finishName]);

  const clear = (name) => () => setCollection({ [name]: null });
  const update = (name) => (date) => setCollection({ [name]: date.toISO() });

  const [startFocus, setStartFocus] = useState(false);
  const [finishFocus, setFinishFocus] = useState(false);

  return (
    <MuiPickersUtilsProvider key={timezone} utils={LuxonUtils}>
      <RowInfo help={<TimingStartModal />} colClassName="datetime-picker-col">
        <>
          <label className="form-label">
            {startLabel} -{" "}
            <ClearLink onClick={clear(startName)}>{t('Clear')}</ClearLink>
          </label>
          <DateTimePicker
            value={start}
            onClose={() => setStartFocus(false)}
            onChange={update(startName)}
            onOpen={() => setStartFocus(true)}
          />
          <HelpText focus={startFocus}>
            {t('If you set a start date, challenges will be hidden until then.')}
          </HelpText>
        </>
      </RowInfo>
      <RowInfo help={<TimingEndModal />} colClassName="datetime-picker-col">
        <>
          <label className="form-label">
            {finishLabel} -{" "}
            <ClearLink onClick={clear(finishName)}>{t('Clear')}</ClearLink>
          </label>
          <DateTimePicker
            value={finish}
            onClose={() => setFinishFocus(false)}
            onChange={update(finishName)}
            onOpen={() => setFinishFocus(true)}
          />
          <HelpText focus={finishFocus}>
            {t('If you set a finish date, challenges will be hidden afterwards.')}
          </HelpText>
        </>
      </RowInfo>
      <Row>
        <Col size="10">
          <HelpText className="position-relative top-0 mt-4 d-block" focus>
          {t('Challenges that are submitted after the end time will be considered late and points will not be awarded.')}
          <br />{t('If you change your timezone, double check these values as they will automatically adjust.')}
          </HelpText>
        </Col>
      </Row>
      {timezone && (
        <Row className="mt-4">
          <RightCol>
            <p>
              <strong>{t('Reminder!')}</strong>
              <br />
              {t('Your game timezone is set to')}{" "}
              <a className="text-warning" href={`/games/edit/${game_id}#optional`}>{timezone}</a>.
            </p>
          </RightCol>
        </Row>
      )}
    </MuiPickersUtilsProvider>
  );
};

export default TimingSectionNoDefault;
