import styled from "styled-components";
import { Button } from "../App.styles";

export const IconWrapper = styled.span`
    display: inline-block;
    margin-left: .5rem;
`;

export const List = styled.ul`
width: 100%;
display: flex;
flex-wrap: wrap;

li {
  margin-right: 1rem;
  margin-bottom: 1rem;

  svg {
    margin-left: 1rem;
  }

  @media (max-width: ${({ theme }) => theme.maxMobileWidth}) {
    margin-right: 0;
  }
}

@media (max-width: ${({ theme }) => theme.maxMobileWidth}) {
  ${Button} {
    overflow-wrap: anywhere;
    text-align: left;
    white-space: normal;
    word-break: normal;
  }
}
`;

export const TooltipParagraph = styled.p`
    padding-top: 0.5rem;
    font-size: 0.875rem;
    line-height: 1.15rem;
    text-align: center;

    svg {
    margin-left: 0.625rem;
    }
`;