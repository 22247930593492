import { useState } from "react";
import { useMutation } from "react-query";
import styled from "styled-components";
import externalAxios from "axios";
import { useTranslation } from "react-i18next";
import Spinner from "./Spinner";
import { axios } from "../lib/request";
import { Button, ButtonRow } from "../App.styles";

const AssetUploadWrapper = styled.div`
  ${ButtonRow} {
    margin-top: 0.75rem;
    margin-bottom: 0.25rem;

    ${Button} {
      margin-right: 0.25rem;
      max-width: 200px;
      text-overflow: ellipsis;
    }
  }
`;

const ModelUploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  .form-label {
    font-size: 0.8rem;
  }

  ${ButtonRow} {
    margin-top: 0.75rem;
    margin-bottom: 0.25rem;

    ${Button} {
      margin-right: 0.25rem;
      max-width: 200px;
      text-overflow: ellipsis;
    }
  }
`;

const FileWrapper = styled.div`
  margin-right: 0.5rem;

  input[type="file"] {
    display: none;
  }

  .form-control {
    font-size: 0.8rem;
  }
`;

const UploadBtn = styled.label`
  ${({ theme }) => `
  display: inline-block;

  span.disabled {
    background-color: ${theme.disabledColor};
    cursor: not-allowed;
    color: ${theme.darkGreyColor};
  }
  `}
`;

const ModelUpload = ({ label, name, type = "photo", useQuery, width = 75 }) => {
  const { t } = useTranslation()
  const [collection, setCollection] = useQuery();
  const [file, setFile] = useState(null);
  const { model_url } = collection;
  const [placeholder, setPlaceholder] = useState(!model_url);
  const [, setError] = useState(false);
  const [preview, setPreview] = useState(!placeholder);

  const { isLoading, mutateAsync } = useMutation(async () => {
    const [ext] = file.type
      ? file.type.split("/").reverse()
      : file.name.split(".").reverse();

    const url = `${process.env.REACT_APP_API_SIGN_URL}/${ext}`;
    const { data } = await axios.get(url);
    const options = { headers: { "Content-Type": file.type } };

    await externalAxios.put(data.signature, file, options);

    const [uuid, extension] = data.name.split(".");

    return await setCollection({
      model_media_uuid: uuid,
      model_media_extension: extension.toLocaleLowerCase(),
    });
  });

  const upload = async () => {
    if (file) {
      setPlaceholder(false);
      setPreview(true);
      setError(false);
      await mutateAsync();
      setFile(null);
    }
  };

  const clear = () => {
    setCollection({
      model_media_uuid: null,
      model_media_extension: null,
    });
    setPlaceholder(true);
    setPreview(false);
    setError(false);
  };

  const disabled = !file || isLoading;

  return preview ? (
    <AssetUploadWrapper className="form-outline">
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {model_url ? (
            <a href={model_url} title="Click to download...">
              Asset
            </a>
          ) : (
            <span onClick={() => setPreview(false)}>No asset</span>
          )}{" "}
          <span onClick={() => setPreview(false)} title="Click to change...">
            attached. Tap/click to change.
          </span>
        </>
      )}
    </AssetUploadWrapper>
  ) : (
    <ModelUploadWrapper className="form-outline">
      <FileWrapper>
        {label && <label className="form-label">{label}</label>}
        <input
          className="form-control"
          id={`${name}-${type}-upload`}
          type="file"
          accept=".wt3"
          onChange={({ target }) => setFile(target.files[0])}
        />
      </FileWrapper>
      <ButtonRow>
        <UploadBtn htmlFor={`${name}-${type}-upload`}>
          <Button tag="span" className="btn--flat" color="info">
            {t('Select File')}
          </Button>
        </UploadBtn>
        <UploadBtn>
          <Button tag="span" className="btn--flat" onClick={upload} disabled={disabled}>
            {t('Upload')} {file ? file.name : undefined}
          </Button>
        </UploadBtn>
        <UploadBtn>
          <Button tag="span" className="btn--flat" color="warning" onClick={() => setPreview(true)}>
            {t('Cancel')}
          </Button>
        </UploadBtn>
        <UploadBtn>
          <Button tag="span" className="btn--flat" color="danger" disabled={!!placeholder} onClick={clear}>
            {t('Delete AR Model')}
          </Button>
        </UploadBtn>
      </ButtonRow>
    </ModelUploadWrapper>
  );
};

export default ModelUpload;
