import { useState } from "react";
import { useMutation } from "react-query";
import styled from "styled-components";
import externalAxios from "axios";
import { useTranslation } from "react-i18next";
import Spinner from "./Spinner";
import { axios } from "../lib/request";
import { Button, ButtonRow } from "../App.styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "@material-ui/core";

const PreviewImage = styled.div`
  background-color: ${({ theme }) => theme.placeholderColor};
  display: flex;
  height: 75px;
  margin-right: 1rem;
  margin-bottom: .5rem;
  width: ${({ width }) => width}px;
  align-items: center;
  justify-content: center;

  img {
    width: ${({ width }) => width}px;
    height: 75px;
    object-fit: contain;
  }

  ${({ error }) =>
    error &&
    `
    border: 2px solid red;

    img {
      display: none;
    }
  `}
`;

const ImageUploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  .form-label {
    font-size: 0.8rem;
  }

  ${ButtonRow} {
    margin-top: 0.75rem;
    margin-bottom: 0.25rem;

    ${Button} {
      margin-right: 0.25rem;
      max-width: 200px;
      text-overflow: ellipsis;
    }
  }
`;

const FileWrapper = styled.div`
  margin-right: 0.5rem;

  input[type="file"] {
    display: none;
  }

  .form-control {
    font-size: 0.8rem;
  }
`;

const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  button {
    margin-bottom: 0.3125rem;
    padding: .625rem 1rem;
  }
`;

const UploadBtn = styled.label`
  display: inline-block;
`;

const findThumbURL = (assets, key, tour, type) => {
  if (type === "tour" && tour) {
    if (tour[key]) {
      return tour[key].url;
    }
  } else if (assets) {
    if (type === "video") {
      const asset = assets.videos.find(
        (asset) => asset.name === key && asset.urls?.png
      );

      if (asset) {
        return asset.urls.png;
      }
    } else {
      const asset = assets[`${type}s`].find(
        (asset) => asset.name === key && asset.url
      );

      if (asset) {
        return asset.url;
      }
    }
  }

  return null;
};

const readFileURL = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();

    reader.onload = ({ target }) => resolve(target.result);
    reader.readAsDataURL(file);
  });
};

const translateKey = (key) => {
  switch (key) {
    case "ya":
      return "correct";
    case "naw":
      return "incorrect";
    case "challengesenabled":
      return "challenges";
    case "streamenabled":
      return "stream";
    case "leaderboardenabled":
      return "leaderboard";
    case "reelsenabled":
      return "reels";
    case "rewardsenabled":
      return "rewards";
    case "joinenabled":
      return "join";
    case "notificationsenabled":
      return "notifications";
    case "helpenabled":
      return "help";
    case "customenabled":
      return "custom";
      case "overlay":
        return "sticker";
    case "tour_1":
      return 0;
    case "tour_2":
      return 1;
    case "tour_3":
      return 2;
    case "tour_4":
      return 3;
    default:
      return key;
  }
};

const NewImageUpload = ({ label, name, type = "photo", useQuery, width = 75 }) => {
  const { t } = useTranslation();
  const [collection, setCollection] = useQuery({ invalidate: false });
  const { id } = collection
  const keyMap = {
    photo: 'standard',
    arrival_image: 'arrival'
  }
  const key = keyMap[name] || name;
  const thumb = findThumbURL(
    collection.assets,
    translateKey(key),
    collection.tour,
    type
  );
  const [placeholder, setPlaceholder] = useState(!thumb);
  const [error, setError] = useState(false);
  const [preview, setPreview] = useState(!placeholder);
  const updateTimeout = 1000;
  let timeoutRef = null

  const { isLoading, mutateAsync } = useMutation(async (file) => {
    const fileURL = await readFileURL(file);

    const [ext] = file.name.split(".").reverse();
    let assets = undefined;

    if (type === "tour") {
      const index = translateKey(key);
      const media = collection.tour[index];

      const tour = [
        ...collection.tour.slice(0, index),
        { ...media, url: fileURL },
        ...collection.tour.slice(index + 1),
      ];

      timeoutRef = setTimeout(() => setCollection({ tour }), updateTimeout);
    } else {
      const media = collection.assets[`${type}s`].filter(
        (asset) => asset.name !== translateKey(key)
      );

      assets = {
        ...collection.assets,
        [`${type}s`]: [...media, { name: translateKey(key), url: fileURL }],
      };

      timeoutRef = setTimeout(() => setCollection({ assets }), updateTimeout);
    }

    const url = `${
      process.env.REACT_APP_API_SIGN_URL
    }/${ext.toLocaleLowerCase()}`;
    const { data } = await axios.get(url);
    const options = { headers: { "Content-Type": file.type } };

    await externalAxios.put(data.signature, file, options);

    const [uuid, extension] = data.name.split(".");

    return await setCollection({
      assets,
      [`${name}_media_uuid`]: uuid,
      [`${name}_media_extension`]: extension.toLocaleLowerCase(),
    });
  });

  const upload = async (file) => {
    if (file) {
      setPlaceholder(false);
      setPreview(true);
      setError(false);
      await mutateAsync(file);
      clearTimeout(timeoutRef)
    }
  };

  const clear = () => {
    if (type === "tour") {
      const index = translateKey(key);
      const media = collection.tour[index];

      const tour = [
        ...collection.tour.slice(0, index),
        { ...media, url: "" },
        ...collection.tour.slice(index + 1),
      ];

      setCollection({
        tour,
        [`${name}_media_uuid`]: null,
        [`${name}_media_extension`]: null,
      });
    } else {
      const media = collection.assets[`${type}s`].filter(
        (asset) => asset.name !== translateKey(key)
      );

      const assets = {
        ...collection.assets,
        [`${type}s`]: [...media, { name: translateKey(key), url: "" }],
      };

      setCollection({
        assets,
        [`${name}_media_uuid`]: null,
        [`${name}_media_extension`]: null,
      });
    }

    setPlaceholder(true);
    setPreview(false);
    setError(false);
  };

  const fileId = `${name}-${type}-${id}-upload`

  return (
    <ImageUploadWrapper className="form-outline">
      {label && <label className="form-label">{label}</label>}
      {
        preview
          ? (
            <PreviewImage
              error={error}
              width={width}
            >
              {isLoading ? (
                <Spinner />
              ) : (
                thumb && (
                  <img src={thumb} alt={label} onError={() => setError(true)} />
                )
              )}
            </PreviewImage>
          )
          : null
      }
      <ActionWrapper>
        <UploadBtn htmlFor={fileId}>
          <FileWrapper>
            <input
              accept={
                type === "video"
                  ? ".avi,.mkv,.mov,.mp4,.webm"
                  : ".jpeg,.jpg,.png,.gif"
              }
              className="form-control"
              id={fileId}
              type="file"
              onChange={(evt) => {
                console.log(evt)
                const { target } = evt
                upload(target.files[0])
              }}
            />
          </FileWrapper>
          <Button tag="span" color="warning" className="btn--flat">
            {t('Select File')}
          </Button>
        </UploadBtn>
        {
          preview
            ? (
              <Tooltip title={t(`Delete image`)}>
                <Button onClick={clear} disabled={!!placeholder} color="danger" className="btn--flat">
                  <FontAwesomeIcon icon={faTimes} />
                </Button>
              </Tooltip>
            )
            : null
        }
      </ActionWrapper>
    </ImageUploadWrapper>
  );
};

export default NewImageUpload;
