import { useEffect, useRef } from "react";
import { HighlightListItem } from "./HighlightList.style";

const HighlightList = ({ scrollId, id, children }) => {
  const ref = useRef();

  useEffect(() => {
    const highlightCard = async () => {
      const highlighted = document.querySelectorAll('.newly-added')
      await Promise.all((Array.from(highlighted) || []).map(h => {
        return h.classList.remove('newly-added')
      }))
      if (scrollId === id && scrollId > 0) {
        ref.current.scrollIntoView();
        ref.current.classList.add('newly-added');
      }
    }
    highlightCard()
  }, [id, scrollId]);

  return (
    <HighlightListItem ref={ref}>
      {children}
    </HighlightListItem>
  );
};

export default HighlightList;