import { useTranslation } from "react-i18next";
import { Modal } from "../../common";
import { Button } from "../../App.styles";

const LegalModal = ({ onDismiss, show }) => {
  const { t } = useTranslation();

  return (
    <Modal onDismiss={onDismiss} show={show}>
      <div className="modal-header">
        <h4 className="modal-title">{t(`Legal`)}</h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-hidden="true"
          onClick={onDismiss}
        >
          ×
        </button>
      </div>
      <div className="modal-body">
        <p>
          {t(`All players are required to have read, understood and agreed to our
          Terms of Use which describes the terms of game play.`)}
        </p>
        <ul>
          <li dangerouslySetInnerHTML={{__html: t(`It's pretty standard stuff, but we do need to protect ourselves.`)}} />
        </ul>
      </div>
      <div className="modal-footer">
        <Button color="dark" data-dismiss="modal" onClick={onDismiss}>
          {t(`Close`)}
        </Button>
      </div>
    </Modal>
  )
};

export default LegalModal;
