import Modal from "./Modal";
import { Button } from "../App.styles";

const RefreshConflictModal = ({ onConfirm, onDismiss, show }) => (
  <Modal onDismiss={onDismiss} show={show}>
    <div className="modal-header">
      <h4 className="modal-title">Change Detected</h4>
      <button
        type="button"
        className="close"
        data-dismiss="modal"
        aria-hidden="true"
        onClick={onDismiss}
      >
        ×
      </button>
    </div>
    <div className="modal-body">
      Another user has edited this page. If you make a change, it may be
      overwritten. If you refresh, you will see the latest version.
    </div>
    <div className="modal-footer">
      <Button color="dark" data-dismiss="modal" onClick={onDismiss}>
        Ignore
      </Button>
      <Button color="primary" onClick={onConfirm}>
        Refresh
      </Button>
    </div>
  </Modal>
);

export default RefreshConflictModal;
