import { MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FooterWrapper } from "./Footer.styles";

const Footer = () => {
  const { t } = useTranslation();
  const year = new Date().getFullYear();

  return (
    <FooterWrapper>
      <MDBContainer>
        <MDBRow>
          <p>
            Outback Team Building & Training &copy; {year}
            <br />
            <Link to="/privacy" dangerouslySetInnerHTML={{__html: t("Privacy Policy")}} />
            &nbsp;&amp;&nbsp;
            <Link to="/terms" dangerouslySetInnerHTML={{__html: t('Terms of Use')}} />
          </p>
        </MDBRow>
      </MDBContainer>
    </FooterWrapper>
  );
};

export default Footer;
