import styled from "styled-components";
import { darken } from "@material-ui/core";

export const RadioWrapper = styled.div`
${({ theme }) => `
    &.radio--block {
        .radio__item {
            background: ${theme.lightColor};
            display: block;
            width: 100%;
            padding: 8px 24px;
            border-radius: ${theme.rounded.sm};
            cursor: pointer;

            &:hover {
                background: ${darken(theme.lightColor, .015)};
            }
        }
    }
`}
`;

export const Label = styled.label`
  font-size: 0.8rem;
`;

export const RadioLabel = styled.label`
  margin-right: 1rem;
`;

export const RadioContent = styled.div`
  ${({ theme }) => `
    background: ${theme.lightColor};
    margin-bottom: 1rem;
    margin-top: -.8rem;
    padding: 8px 24px 12px;
    border-radius: 0 0 ${theme.rounded.sm} ${theme.rounded.sm};

    .radio__item__body {
      border-top: 1px solid ${theme.darkGreyColor}50;
      padding-top: 8px;
    }
  `}
`;