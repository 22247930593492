import styled from "styled-components";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useTranslation } from "react-i18next";
import { ExternalLinkButton, Spinner } from "../common";
import {
  useBrandingPayMutation,
  useGameQuery,
} from "../settings/Settings.state";
import { Button, Row, Col } from "../App.styles";
import brand from "../images/brand.png";
import theme from "../themes";
import usePayment from "../lib/usePayment";

const PaymentForm = styled.form`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
  justify-content: center;

  .col-lg-4 {
    justify-content: flex-end;
  }

  .StripeElement {
    width: 100%;
  }

  @media (max-width: ${({ theme }) => theme.maxMobileWidth}) {
    flex-direction: column;
    margin-top: 1rem;
  }

  ${Col} {
    margin-bottom: 1.5rem;
  }
`;

const PaymentCol = styled(Col)`
  border-bottom: 1px solid #bdbdbd;
  margin-right: 0.75rem;
  padding-bottom: 0.75rem;
`;

const stripeStyles = {
  style: {
    base: {
      fontSize: "16px",
    },
  },
};

const SamplesLink = styled.a`
  color: white;
  text-decoration: underline;
`;

const BrandingCol = styled(Col)`
  ${({ theme }) => `
    background-color: #539cc9;
    color: white;
    flex-direction: column;
    margin: 0;
    padding: 30px;

    ${theme.breakpoints.up('md')} {
      margin: 0 15px;
    }

    h2 {
      margin-bottom: 0.5rem;
    }

    p {
      margin-bottom: 1rem;
    }

    a.btn {
      margin-left: 1rem;
    }

    img {
      margin: 30px 0;
      max-width: 100%;
    }

    img + p {
      margin-top: 1rem;
    }
  `}
`;

const PaymentButton = styled(Button)`
  margin-left: 0.5rem;
`;

const BrandingPreview = ({ setErrorMessage }) => {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const { game_id } = useParams();
  const [game] = useGameQuery(game_id);
  const [show, setShow] = useState(false);
  const [paid, setPaid] = useState(false);
  const [success, setSuccess] = useState(!!game.grey_enabled);
  const { isPaying, pay } = useBrandingPayMutation();
  const { doPayment } = usePayment();

  useEffect(() => setSuccess(!!game.grey_enabled), [game.grey_enabled]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      console.error("[Branding] Payment Error:", error);
      setErrorMessage(error.message);
      window.scrollTo(0, 0);
      return;
    }

    const { id: stripe_id } = paymentMethod;
    const params = { stripe_id, cost: 299 };
    doPayment({
      action: () => pay(params),
      onSuccess: () => setPaid(true),
      onError: setErrorMessage,
    })
  };

  return (
    <>
      <Row>
        <BrandingCol>
          <h2>{t('Your brand. Our platform.')}</h2>
          <p>
            {t(`Immerse your players deeper with fully branded and configurable
            games`)}
          </p>
          <img alt="branding examples" src={brand} />
          <p>{t('Unlock your branding today')}</p>
          <p>
            $ 299 USD
            {success ? (
              <ExternalLinkButton
                color="success"
                href={theme.contactURL}
                target="_blank"
              >
                {t('Thank You')}
              </ExternalLinkButton>
            ) : (
              !show && (
                <PaymentButton color="danger" onClick={() => setShow(true)}>
                  {t('Pay Now')}
                </PaymentButton>
              )
            )}
          </p>
          {success && (
            <p>
              {t('Download')}{" "}
              <SamplesLink href="https://ss-assets.socialscavenger.com/grey_label_sample.zip">
                {t('samples')}
              </SamplesLink>
              .
            </p>
          )}
        </BrandingCol>
      </Row>
      <Row>
        {show && !success && (
          <PaymentForm onSubmit={handleSubmit}>
            <PaymentCol size="12" md="8">
              <CardElement options={stripeStyles} />
            </PaymentCol>
            <Col size="12" md="3" lg="2">
              <Button type="submit" color="danger">
                {t('Pay Now')} {isPaying && <Spinner size="0.875rem" />}
              </Button>
            </Col>
          </PaymentForm>
        )}
      </Row>
      {paid ? (
        <img
          alt=""
          src={`${process.env.REACT_APP_CONVERSION_PAY}&value=29900&currency_code=${process.env.REACT_APP_CURRENCY_CODE}`}
          style={{ width: "1px", height: "1px" }}
        />
      ) : (
        <img
          alt=""
          src={process.env.REACT_APP_CONVERSION_BUILD}
          style={{ width: "1px", height: "1px" }}
        />
      )}
    </>
  );
};

export default BrandingPreview;
