import styled from "styled-components";
import { useState } from "react";
import { HelpText } from "../App.styles";
import statefulField from "../common/StatefulField";

const FloatingHelp = styled(HelpText)`
  @media (min-width: 1024px) {
    top: 0;
    right: 0;
    
    &.realign {
      transform: translate3d(0, -.5rem, 0);
    }
  }
`;

const FieldWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const FormSelect = ({
  children,
  defaultValue,
  mode = 'outline',
  help,
  helpClass = '',
  label,
  value,
  setValue,
  style,
}) => {
  const [focus, setFocus] = useState(false);
  const setter = ({ target }) => setValue(target.value);

  return (
    <FieldWrapper className={`form-${mode}`}>
      {mode === 'outline' ? null : <label className="form-label">{label}</label>}
      <select
        className="form-control active"
        value={value || parseInt(value) === 0 ? value : defaultValue}
        onBlur={() => setFocus(false)}
        onChange={setter}
        onFocus={() => setFocus(true)}
        style={style}
      >
        {children}
      </select>
      {mode === 'outline' ? <label className="form-label">{label}</label> : null}
      {help && <FloatingHelp className={helpClass} focus={focus}>{help}</FloatingHelp>}
    </FieldWrapper>
  );
};

export default statefulField(FormSelect);
