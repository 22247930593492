import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { axios } from "../lib/request";
import { personalizationsUri } from "../lib/refresh";

const useQueryKey = () => {
  const { game_id } = useParams();
  return personalizationsUri(game_id);
};

const create = async (params) => {
  const { data } = await axios.post(
    "https://app.socialscavenger.com/v1/personalizations.json",
    params
  );
  return data;
};

export const fetchPersonalizations = async (uri) => {
  const { data } = await axios.get(uri);
  return data;
};

const update = async (id, game_id, key, value) => {
  const { data } = await axios.put(
    `https://app.socialscavenger.com/v1/personalizations/${id}.json`,
    {
      game_id,
      key,
      value,
    }
  );
  return data;
};

const destroy = async (id, game_id) => {
  const { data } = await axios.delete(
    `https://app.socialscavenger.com/v1/personalizations/${id}.json?game_id=${game_id}`
  );
  return data;
};

export const usePersonalizationsQuery = () => {
  const queryKey = useQueryKey();
  const {
    data: { personalizations },
  } = useQuery(queryKey, () => fetchPersonalizations(queryKey), {
    suspense: true,
  });
  return personalizations;
};

export const usePersonalizationQuery = (id) => {
  const { game_id } = useParams();
  const personalizations = usePersonalizationsQuery();
  const personalization = personalizations.find((p) => p.id === id);
  const { update } = useUpdateMutation();
  const setter = ({ key, value }) => update(id, game_id, key, value);

  return [personalization, setter];
};

export const useCreateMutation = () => {
  const { game_id } = useParams();
  const queryClient = useQueryClient();
  const queryKey = useQueryKey();

  const { isLoading, mutateAsync } = useMutation(
    ({ key, value }) => create({ game_id, key, value }),
    {
      onMutate: (params) => {
        const data = queryClient.getQueryData(queryKey);

        queryClient.setQueryData(queryKey, {
          ...data,
          personalizations: (data?.personalizations || []).concat(params),
        });
      },
      onSettled: () => queryClient.invalidateQueries(queryKey),
    }
  );

  return { isCreating: isLoading, create: mutateAsync };
};

export const useUpdateMutation = (id) => {
  const { game_id } = useParams();
  const queryClient = useQueryClient();
  const queryKey = useQueryKey();

  const { isLoading, mutateAsync } = useMutation(
    ({ key, value }) => update(id, game_id, key, value),
    {
      onMutate: (params) => {
        const data = queryClient.getQueryData(queryKey);
        const index = data.personalizations.findIndex((p) => p.id === id);

        queryClient.setQueryData(queryKey, {
          ...data,
          personalizations: [
            ...data.personalizations.slice(0, index),
            params,
            ...data.personalizations.slice(index + 1),
          ],
        });
      },
      onSettled: () => queryClient.invalidateQueries(queryKey),
    }
  );

  return { isUpdating: isLoading, update: mutateAsync };
};

export const useDestroyMutation = (id) => {
  const { game_id } = useParams();
  const queryClient = useQueryClient();
  const queryKey = useQueryKey();

  const { isLoading, mutateAsync } = useMutation(() => destroy(id, game_id), {
    onMutate: () => {
      const data = queryClient.getQueryData(queryKey);

      queryClient.setQueryData(queryKey, {
        ...data,
        personalizations: data.personalizations.filter((p) => p.id !== id),
      });
    },
    onSuccess: () => queryClient.invalidateQueries(queryKey),
  });

  return { isDeleting: isLoading, destroy: mutateAsync };
};
