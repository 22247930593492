import { useTranslation } from "react-i18next";
import { Modal } from "../../common";
import { Button } from "../../App.styles";

const HintsModal = ({ onDismiss, show }) => {
  const { t } = useTranslation();
  return (
    <Modal onDismiss={onDismiss} show={show}>
      <div className="modal-header">
        <h4 className="modal-title">{t('Hints')}</h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-hidden="true"
          onClick={onDismiss}
        >
          ×
        </button>
      </div>
      <div className="modal-body">
        <p>
          {t('Hints are helpers for your players that may or may not cost them a penalty.')}
        </p>
        <ul>
          <li>
            {t(`If you enable hints, a button will appear to the player. Each time the
            player taps it, they get the next hint as defined below. If they then
            answer correctly, they get the points available for the challenge
            minus the current hint subtraction value.`)}
          </li>
        </ul>
      </div>
      <div className="modal-footer">
        <Button type="button" color="dark" data-dismiss="modal" onClick={onDismiss}>
          {t('Close')}
        </Button>
      </div>
    </Modal>
  )
};

export default HintsModal;
