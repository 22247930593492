import styled from "styled-components";
import { useSetRecoilState } from "recoil";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { MDBInput } from "mdb-react-ui-kit";
import { useTranslation } from "react-i18next";
import {
  ActionButtonWrapper,
  Button,
  Row,
  Col,
  TextareaCol,
} from "../../App.styles";
import { notificationState } from "../Teams.state";
import { axios } from "../../lib/request";

const ButtonWrapper = styled(ActionButtonWrapper)`
  width: 100%;
`;

const Blast = () => {
  const { t } = useTranslation();
  const { game_id } = useParams();
  const SUBJECT = t("Game update!");
  const MESSAGE = t("Thanks for playing.");
  const setNotification = useSetRecoilState(notificationState);
  const [method, setMethod] = useState("");
  const [subject, setSubject] = useState(SUBJECT);
  const [message, setMessage] = useState(MESSAGE);
  const setter =
    (setValue) =>
    ({ target }) =>
      setValue(target.value);

  const send = async () => {
    const { status } = await axios.post(
      `${process.env.REACT_APP_API_USER_BLAST_URL}/${game_id}/blast.json`,
      {
        method,
        subject,
        message,
      }
    );

    if (status === 200) {
      setSubject(SUBJECT);
      setMessage(MESSAGE);
      setNotification({
        color: "success",
        message: t("Your blast has been sent."),
      });

      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  return (
    <div className="card">
      <div className="card-header bg-dark text-light">
        <span className="card-title">{t('Communication Blast')}</span>
      </div>
      <div className="card-body">
        <Row>
          <Col>
            <div className="form-outline">
              <select
                className="form-control active"
                value={method}
                onChange={setter(setMethod)}
              >
                <option value={0}>{t('Push Notification')}</option>
                <option value={1}>{t('Email')}</option>
                <option value={2}>{t('Chat (as Administrator)')}</option>
                <option value={3}>{t('Chat (as System)')}</option>
              </select>
              <label className="form-label">{t('Method')}</label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <MDBInput
              label={t("Subject")}
              value={subject}
              onChange={setter(setSubject)}
            />
          </Col>
        </Row>
        <Row>
          <TextareaCol>
            <label className="form-label">{t('Message')}</label>
            <textarea value={message} onChange={setter(setMessage)}></textarea>
          </TextareaCol>
        </Row>
        <Row>
          <Col>
            <ButtonWrapper>
              <Button color="danger" onClick={send}>
                {t('Send Message')}
              </Button>
            </ButtonWrapper>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Blast;