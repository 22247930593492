import { atom, useRecoilState, useRecoilValue } from "recoil";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { axios } from "../lib/request";
import { rewardsQueryKey } from "../lib/refresh";
import { useItemQuery } from "../Global.state";
import { MAX_PER_PAGE } from "../consts/request";

export const deleteIdState = atom({
  key: "rewards/deleteId",
  default: 0,
});

export const notificationState = atom({
  key: "rewards/notification",
  default: {},
});

export const pageState = atom({
  key: "rewards/page",
  default: 1,
});

export const pageSizeState = atom({
  key: "rewards/pageSize",
  default: MAX_PER_PAGE,
});

export const fetchAchievements = async (game_id, page, per_page) => {
  const params = { game_id, page, per_page };
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_REWARD_RETRIEVE_URL}/${game_id}.json`,
    { params }
  );
  return data;
};

export const updateAchievement = async (game_id, id, params) => {
  const url = `${process.env.REACT_APP_API_REWARD_UPDATE_URL}/${id}.json`;
  return await axios.put(url, { ...params, game_id });
};

const useQueryKey = () => {
  const { game_id } = useParams();
  const page = useRecoilValue(pageState);
  const per_page = useRecoilValue(pageSizeState);
  return rewardsQueryKey(game_id, page, per_page);
};

export const useAchievementsDataQuery = (game_id) => {
  const page = useRecoilValue(pageState);
  const pageSize = useRecoilValue(pageSizeState);

  return useQuery(
    rewardsQueryKey(game_id, page, pageSize),
    () => fetchAchievements(game_id, page, pageSize),
    {
      suspense: true,
    }
  );
};

export const useAchievementsQuery = () => {
  const { game_id } = useParams();
  const { data } = useAchievementsDataQuery(game_id);
  return data.achievements;
};

export const useAchievementsFetchingQuery = () => {
  const { game_id } = useParams();
  const { isFetching } = useAchievementsDataQuery(game_id);
  return isFetching;
};

export const useAchievementsPagesQuery = () => {
  const { game_id } = useParams();
  const { data } = useAchievementsDataQuery(game_id);
  return data.meta.pages;
};

export const useAchievementQuery = (id) => {
  const { game_id } = useParams();
  const { data, isFetching } = useAchievementsDataQuery(game_id);
  const queryKey = useQueryKey();

  return [
    ...useItemQuery(data, id, "achievements", queryKey, (params) =>
      updateAchievement(game_id, id, params)
    ),
    isFetching,
  ];
};

export const useCreateMutation = () => {
  const { game_id } = useParams();
  const { data } = useAchievementsDataQuery(game_id);
  const queryClient = useQueryClient();
  const queryKey = useQueryKey();

  const { isLoading, mutateAsync } = useMutation(
    () =>
      axios.post(process.env.REACT_APP_API_REWARD_CREATE_URL, {
        game_id,
      }),
    {
      onMutate: () => {
        queryClient.setQueryData(queryKey, {
          ...data,
          achievements: [
            ...data.achievements,
            { id: "new", title: "Untitled" },
          ],
        });
      },
      onSuccess: () => queryClient.invalidateQueries(rewardsQueryKey(game_id)),
    }
  );

  return { isCreating: isLoading, create: mutateAsync };
};

export const useDestroyMutation = () => {
  const { game_id } = useParams();
  const { data } = useAchievementsDataQuery(game_id);
  const [deleteId, setDeleteId] = useRecoilState(deleteIdState);
  const queryClient = useQueryClient();
  const queryKey = useQueryKey();

  const { isLoading, mutateAsync } = useMutation(
    () =>
      axios.delete(
        `${process.env.REACT_APP_API_REWARD_DELETE_URL}/${deleteId}.json?game_id=${game_id}`
      ),
    {
      onMutate: () => {
        const achievements = data.achievements.filter(
          (achievement) => achievement.id !== deleteId
        );

        queryClient.setQueryData(queryKey, {
          ...data,
          achievements,
        });

        setDeleteId(0);
      },
      onSuccess: () => queryClient.invalidateQueries(rewardsQueryKey(game_id)),
    }
  );

  return { isDeleting: isLoading, destroy: mutateAsync };
};
