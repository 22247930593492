import { useTranslation } from "react-i18next";
import { Input } from "../../common";
import { HelpText } from "../../App.styles";
import { NewImageUpload, RowInfo } from "../../common";
import {
  RewardsTitleModal,
  RewardsDescriptionModal,
  RewardsFileModal,
} from "../modals";

const RewardsSection = ({ useQuery }) => {
  const { t } = useTranslation();

  return (
    <>
      <HelpText className="position-relative top-0 mb-4 d-block" focus>
        {t(`If you wish to provide a reward or clue specifically for this challenge, add it here. After the player succesfully completes the challenge, this will be added to their rewards and clues section.`)}
      
        <p className="mt-2">
        {t(`A reward can be a coupon with a barcode, an illustration, 
        a graphical clue, or any number of creative things.
        Titles are limited to 255 characters.
        Supports Personalization.`)}
        </p>
      </HelpText>
      <RowInfo help={<RewardsTitleModal />}>
        <Input
            help={t('Give your reward or clue a title.')}
            label={t('Title')}
            name="reward_title"
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<RewardsDescriptionModal />}>
        <Input
            help={t('This is the main text for your reward or clue.')}
            label={t('Description')}
            name="reward_description"
            useQuery={useQuery}
          />
      </RowInfo>
      <RowInfo help={<RewardsFileModal />}>
        <NewImageUpload name="reward" label={t('Image')} useQuery={useQuery} />
      </RowInfo>
    </>
  )
};

export default RewardsSection;
