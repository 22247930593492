import { useTranslation } from "react-i18next";
import { NewImageUpload, NewVideoUpload, RowInfo } from "../../common";
import { MediaResponseModal } from "../modals";

const EndSection = ({ useQuery }) => {
  const { t } = useTranslation();
  const taskType = useQuery()[0].task_type;
  const taskTypeVal = parseInt(taskType) === 5 || parseInt(taskType) === 6 ? "trivia" : "other";

  return (
    <>
      <RowInfo help={<MediaResponseModal type={taskTypeVal} />}>
        <NewImageUpload
            label={`${t('Image given when')} ${
              taskTypeVal === "trivia" ? t("correct") : t("completed")
            }`}
            name="correct"
            useQuery={useQuery}
          />
      </RowInfo>
      {taskTypeVal !== "other" ? (
        <RowInfo help={<MediaResponseModal type={taskTypeVal} />}>
          <NewImageUpload
              label={`${t('Image given when')} ${
                taskTypeVal === "trivia" ? t("incorrect") : t("completed")
              }`}
              name="incorrect"
              useQuery={useQuery}
            />
        </RowInfo>
      ) : (
        ""
      )}
      <RowInfo help={<MediaResponseModal type={taskTypeVal} />}>
        <NewVideoUpload
          label={`${t('Video given when')} ${
            taskTypeVal === "trivia" ? t("correct") : t("completed")
          }`}
          name="ya"
          useQuery={useQuery}
        />
      </RowInfo>
      {taskTypeVal !== "other" ? (
        <RowInfo help={<MediaResponseModal type={taskTypeVal} />}>
          <NewVideoUpload
            label={`${t('Video given when')} ${
              taskTypeVal === "trivia" ? t("incorrect") : t("completed")
            }`}
            name="naw"
            useQuery={useQuery}
          />
        </RowInfo>
      ) : (
        ""
      )}
    </>
  );
};

export default EndSection;
