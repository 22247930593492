import { useState, useEffect, useCallback } from "react";
import { useMutation } from "react-query";
import styled from "styled-components";
import externalAxios from "axios";
import { useTranslation } from "react-i18next";
import Spinner from "./Spinner";
import { axios } from "../lib/request";
import { Button, ButtonRow } from "../App.styles";

const AssetUploadWrapper = styled.div`
    margin-bottom: 0.5rem;

    ${Button} {
    margin-right: 0.25rem;
    max-width: 200px;
    text-overflow: ellipsis;
    }
`;

const ModelUploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  .form-label {
    font-size: 0.8rem;
  }

  ${ButtonRow} {
    margin-top: 0.75rem;
    margin-bottom: 0.25rem;

    ${Button} {
      margin-right: 0.25rem;
      max-width: 200px;
      text-overflow: ellipsis;
    }
  }
`;

const FileWrapper = styled.div`
  margin-right: 0.5rem;

  input[type="file"] {
    display: none;
  }

  .form-control {
    font-size: 0.8rem;
  }
`;

const UploadBtn = styled.label`
  display: inline-block;
`;

const NewModelUpload = ({ label, name, type = "photo", useQuery }) => {
    const { t } = useTranslation();
    const [collection, setCollection] = useQuery();
    const [file, setFile] = useState(null);
    const { model_url } = collection;
    const [placeholder, setPlaceholder] = useState(!model_url);
    const [, setError] = useState(false);
    const [preview, setPreview] = useState(!placeholder);

    const { isLoading, mutateAsync } = useMutation(async () => {
        const [ext] = file.type
            ? file.type.split("/").reverse()
            : file.name.split(".").reverse();

        const url = `${process.env.REACT_APP_API_SIGN_URL}/${ext}`;
        const { data } = await axios.get(url);
        const options = { headers: { "Content-Type": file.type } };

        await externalAxios.put(data.signature, file, options);

        const [uuid, extension] = data.name.split(".");

        return await setCollection({
            model_media_uuid: uuid,
            model_media_extension: extension.toLocaleLowerCase(),
        });
    });

    const upload = useCallback(async () => {
        if (file) {
            setPlaceholder(false);
            setPreview(true);
            setError(false);
            await mutateAsync();
            setFile(null);
        }
    }, [file, setPlaceholder, setPreview, setError, mutateAsync, setFile]);

    useEffect(() => {
        upload()
    }, [file, upload])

    return (
        <ModelUploadWrapper className="form-outline">
            <FileWrapper>
                {label && <label className="form-label">{label}</label>}
                {
                    preview ? (
                        <AssetUploadWrapper>
                            {isLoading ? (
                                <Spinner />
                            ) : (
                                <>
                                    {model_url ? (
                                        <a href={model_url} title={t("Click to download...")}>
                                            <span className="text-warning">{t('Asset')}</span>
                                        </a>
                                    ) : (
                                        <span onClick={() => setPreview(false)}>{t('No asset')}</span>
                                    )}{" "}
                                    <span onClick={() => setPreview(false)} title={t("Click to change...")}>
                                        {t('attached. Tap/click to change.')}
                                    </span>
                                </>
                            )}
                        </AssetUploadWrapper>
                    ) : null
                }
                <input
                    className="form-control"
                    id={`${name}-${type}-upload`}
                    type="file"
                    onChange={({ target }) => setFile(target.files[0])}
                />
            </FileWrapper>
            <div>
                <UploadBtn htmlFor={`${name}-${type}-upload`}>
                <Button tag="span" color="warning" className="btn--flat">
                    {t('Upload File')}
                </Button>
                </UploadBtn>
            </div>
        </ModelUploadWrapper>
    );
};

export default NewModelUpload;
