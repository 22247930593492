import { useState } from "react";
import {
  MDBCard,
  MDBCardHeader,
  MDBCardTitle,
  MDBCardBody,
} from "mdb-react-ui-kit";
import { useTranslation } from "react-i18next";
import { useGameBrandingQuery } from "../settings/Settings.state";
import { Alert, Refresher, SidebarLayout } from "../common";
import { Row } from "../App.styles";
import BrandingPreview from "./BrandingPreview";
import BrandingForm from "./BrandingForm";
import BrandingPlaceholder from "./BrandingPlaceholder";
import { withPlaceholder } from "../common";

const Branding = () => {
  const { t } = useTranslation();
  const useQuery = () => useGameBrandingQuery();
  const [{ grey_enabled }] = useQuery();
  const [errorMessage, setErrorMessage] = useState("");

  return (
    <>
      <Row>
        <SidebarLayout message={t("These settings apply to your game You can change them anytime, even during the game.")}>
          <Row>
            <Alert color="danger">{errorMessage}</Alert>
          </Row>
          <MDBCard>
            <MDBCardHeader>
              <MDBCardTitle>{t('Game Branding')}</MDBCardTitle>
            </MDBCardHeader>
            <MDBCardBody>
              <BrandingPreview setErrorMessage={setErrorMessage} />
              {grey_enabled && <BrandingForm useQuery={useQuery} />}
            </MDBCardBody>
          </MDBCard>
        </SidebarLayout>
        <Refresher subscriptions="ss.game.update" />
      </Row>
    </>
  );
};

export default withPlaceholder(Branding, BrandingPlaceholder);
