const getJokes = t => [
    {
        "question": t("Why do bees have sticky hair?"),
        "answer": t("Because they use a honeycomb")
    },
    {
        "question": t("Why is Peter Pan always flying?"),
        "answer": t("Because he Neverlands.")
    },
    {
        "question": t("How do celebrities stay cool?"),
        "answer": t("They have many fans.")
    },
    {
        "question": t("When does a joke become a 'dad joke?'"),
        "answer": t("When it becomes apparent.")
    },
    {
        "question": t("Why are spiders so smart?"),
        "answer": t("They can find everything on the web.")
    },
    {
        "question": t("What did the ocean say to the beach?"),
        "answer": t("Nothing, it just waved.")
    },
    {
        "question": t("Why do seagulls fly over the ocean?"),
        "answer": t("Because if they flew over the bay, we'd call them bagels.")
    },
    {
        "question": t("Where do math teachers go on vacation?"),
        "answer": t("Times Square.")
    },
    {
        "question": t("What's a robot's favorite snack?"),
        "answer": t("Computer chips.")
    },
    {
        "question": t("Why are piggy banks so wise?"),
        "answer": t("They are filled with common cents.")
    },
    {
        "question": t("Which bear is the most condescending?"),
        "answer": t("A pan-duh!")
    },
    {
        "question": t("Why are elevator jokes so classic and good?"),
        "answer": t("They work on many levels.")
    },
    {
        "question": t("Why are Sunday's the second saddest days of the week?"),
        "answer": t("Because the day before is a sadder day.")
    },
    {
        "question": t("What does a house wear?"),
        "answer": t("Address.")
    },
    {
        "question": t("What do sprinters eat before a race?"),
        "answer": t("Nothing, they fast.")
    },
    {
        "question": t("What vegetable is kind to everyone?"),
        "answer": t("The sweet potato.")
    },
    {
        "question": t("Why don't phones ever go hungry?"),
        "answer": t("They have plenty of apps to choose from.")
    },
    {
        "question": t("Why do melons have weddings?"),
        "answer": t("Because they cantaloupe!")
    },
    {
        "question": t("What music do mummies like?"),
        "answer": t("Wrap music.")
    },
    {
        "question": t("Why couldn't the bicycle stand up by itself?"),
        "answer": t("It was two tired!")
    },
    {
        "question": t("What does a nosey pepper do?"),
        "answer": t("It gets jalapeño business.")
    },
    {
        "question": t("What is brown and sticky?"),
        "answer": t("A stick.")
    },
    {
        "question": t("What kind of drink can be bitter and sweet?"),
        "answer": t("Reali-tea.")
    },
    {
        "question": t("Why did the coach go to the bank?"),
        "answer": t("To get his quarterback.")
    },
    {
        "question": t("What kind of car does a sheep like to drive?"),
        "answer": t("A Lamborghini.")
    },
    {
        "question": t("Why shouldn't you write with a broken pencil?"),
        "answer": t("Because it's pointless.")
    },
    {
        "question": t("What kind of bird is always getting hurt?"),
        "answer": t("The owl.")
    },
    {
        "question": t("Why couldn't the couple get married at the library?"),
        "answer": t("It was all booked up.")
    },
    {
        "question": t("What animals are the best to call if you get locked out of your house"),
        "answer": t("Monkeys.")
    }
]

export default getJokes