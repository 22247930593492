import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { axios } from "../lib/request";

export const fetchUser = async (game_id) => {
  let user = {}
  try {
    const url = process.env.REACT_APP_API_WHOAMI_URL;
    const params = game_id === "new" ? {} : { game_id };
    const { data } = await axios.get(url, { params });
    user = data
  } catch (e) {}
  return user;
};

export const useUserDataQuery = (game_id) => {
  return useQuery(["whoami", game_id], () => fetchUser(game_id), {
    suspense: true,
  });
};

export const useUserQuery = () => {
  const { game_id } = useParams();
  const { data } = useUserDataQuery(game_id);

  return data;
};