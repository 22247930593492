import { useState } from "react";
import { useMutation } from "react-query";
import { Link, useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import {
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBCardHeader,
  MDBCardTitle,
} from "mdb-react-ui-kit";
import FacebookPixel from "react-facebook-pixel";
import { useTranslation } from "react-i18next";
import { Button, Row } from "../App.styles";
import { Alert, Spinner } from "../common";
import { verifyUser, createUser } from "./SignUp.state";
import withDashboardRedirect from "../hoc/withDashboardRedirect";

const FormGroup = styled.div`
  margin: 2rem 0;
`;

export const SignUp = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [error, setError] = useState(null);
  const history = useHistory();
  const location = useLocation();

  const { isLoading, mutate } = useMutation(
    createUser,
    {
      onError: ({ response }) => {
        switch (response.status) {
          case 409:
            setError(t("Please provide a valid email address."));
            break;
          case 412:
            setError(t("Your password and confirmation do not match."));
            break;
          case 500:
            setError(
              t("There has been a problem connecting with our platform. Please try again.")
            );
            break;
          default:
            setError(t("You must provide all the fields."));
            break;
        }
      },
      onSuccess: () => {
        FacebookPixel.track("CompleteRegistration");
        history.replace(location.state?.from || { pathname: "/dashboard" });
      },
    }
  );

  const submit = async () => {
    const userExists = await verifyUser(email)
    if (userExists) {
      setError(
        <span>
          {
            t(`
              There is already an account associated with this email address. 
              If you have previously played a game with us, your password will be the same as the one you used to login to the game. 
              Forgot your password? No problem,
            `)
          }
          <Link to="/reset-password">{t('click here to reset your password.')}</Link>
        </span>
      )
      return
    }
    mutate({
      name,
      email,
      password,
      password_confirmation: passwordConfirmation,
    });
  };

  return (
    <>
      <Row>
        <MDBCol size="12">
          <Alert color="danger">{error}</Alert>
        </MDBCol>
      </Row>
      <Row>
        <MDBCol size="12">
          <MDBCard>
            <MDBCardHeader>
              <MDBCardTitle>
                <span dangerouslySetInnerHTML={{__html: t('Sign up')}} />
              </MDBCardTitle>
            </MDBCardHeader>
            <MDBCardBody>
              <form
                data-testid="signup-form"
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    submit(email, password);
                  }
                }}
                onSubmit={(e) => {
                  e.preventDefault();
                  submit(email, password);
                }}
              >
                <FormGroup>
                  <MDBInput
                    label={t("Full Name")}
                    value={name}
                    onChange={({ target }) => setName(target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <MDBInput
                    data-testid="signup-email"
                    label={t("Email address")}
                    value={email}
                    onChange={({ target }) => setEmail(target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <MDBInput
                    label={t("Password")}
                    type="password"
                    value={password}
                    onChange={({ target }) => setPassword(target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <MDBInput
                    label={t("Confirm Password")}
                    type="password"
                    value={passwordConfirmation}
                    onChange={({ target }) =>
                      setPasswordConfirmation(target.value)
                    }
                  />
                </FormGroup>
                <Link to="/sign-in">
                  <Button color="link">
                    <span dangerouslySetInnerHTML={{__html: t('Sign in')}} /> {isLoading && <Spinner size="0.875rem" />}
                  </Button>
                </Link>
                <Button color="primary" type="submit">
                  {t('Create Account')}
                </Button>
              </form>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </Row>
    </>
  );
};

export default withDashboardRedirect(SignUp);
