import { ModalBackdrop, ModalDialog, ModalWrapper } from "./Modal.styles";

const Modal = ({ children, onDismiss, show }) => {
  return (
    <>
      <ModalBackdrop
        className={`modal-backdrop fade ${show ? "show" : ""}`}
        onClick={onDismiss}
      ></ModalBackdrop>
      <ModalWrapper
        className={`modal fade ${show ? "show" : ""}`}
        role="dialog"
        aria-modal="true"
        onClick={onDismiss}
      >
        <ModalDialog
          className="modal-dialog"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="modal-content">{children}</div>
        </ModalDialog>
      </ModalWrapper>
    </>
  );
};

export default Modal;
