import { useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Row } from "../App.styles";
import { challengesUri } from "../lib/refresh";
import {
  Refresher,
  SidebarLayout,
  withPlaceholder,
} from "../common";
import {
  CustomStickers,
  PurchaseAGame,
  VideoHighlightReels,
} from "../common/sidebar-cards";
import PathPlaceholder from "./PathPlaceholder";
import TypeChart from "../organize/TypeChart";
import { OrganizeContext, OrganizeContextProvider } from "../organize/Organize.context";
import { MDBCardBody, MDBCardHeader, MDBCardTitle } from "mdb-react-ui-kit";
import { Shimmer } from "../common/Loading.styles";
import { Card, CardContainer, MainRow } from "./Path.style";
import PathFlow from "./PathFlow";

const Panel = () => {
  const random = useRef();

  if (!random.current) {
    random.current = Math.floor(Math.random() * 3);
  }

  switch (random.current) {
    case 0:
    default:
      return <CustomStickers />;
    case 1:
      return <PurchaseAGame />;
    case 2:
      return <VideoHighlightReels />;
  }
};

const PathPreview = () => {
  const { challenges } = useContext(OrganizeContext)
  const { isLoading, isFetched, tasks = [] } = challenges || {}
  const position = { x: 0, y: 0 };
  const edgeType = 'smoothstep';
  const nodes = [];
  const edges = [];
  tasks.filter(t => !!t.unlock_by_task_ids || !!t.unlock_by_task_id).forEach(task => {
    const { id, name, task_type } = task
    const composePath = (unlockId, pathsString = '') => {
      const indexInNode = nodes.findIndex(n => String(n.id) === String(unlockId))
      const node = tasks.find(t => String(t.id) === String(unlockId))
      if (indexInNode !== -1) {
        nodes.splice(indexInNode, 1, {
          ...nodes[indexInNode],
          data: {
            ...nodes[indexInNode].data,
            pathsString,
          }
        })
      } else if (node) {
        nodes.push({
          id: String(node.id),
          type: 'path',
          data: {
            name: node.name,
            task_type: node.task_type,
            description: node.description,
            multiplechoice: node.multiplechoice,
            trivia: node.trivia,
            pathsString,
          },
          position,
        })
      }

      const edgeTarget = tasks.find(t => t.id === id)
      edges.push({
        id: `${id}_${unlockId}`,
        source: String(unlockId),
        target: String(id),
        label: edgeTarget?.name,
        type: edgeType,
        markerEnd: { 
          type: 'arrowclosed',
          color: '#000',
        },
        style: {
          stroke: '#000',
          strokeWidth: 2,
        },
      })
    }
    nodes.push({
      id: String(id),
      type: 'path',
      data: {
        name,
        task_type,
      },
      position,
    })
    if (task.unlock_by_task_ids) {
      task.unlock_by_task_ids.split(',').forEach(u => {
        const [unlockId, pathsString] = u.split(';')
        composePath(unlockId, pathsString)
      })
    } else if (task.unlock_by_task_id) {
      const unlockId = task.unlock_by_task_id
      composePath(unlockId)
    }
  })

  return isLoading || !isFetched
    ? (
      <>
        <Shimmer type="title" />
        <Shimmer />
      </>
    )
    : edges?.length > 0
      ? <PathFlow initialNodes={nodes} initialEdges={edges} />
      : 'Paths are not available'
}

const Path = () => {
  const { game_id } = useParams();
  const { t } = useTranslation();

  return (
    <OrganizeContextProvider>
      <Row>
        <SidebarLayout
          message={t("Use this diagram to review your game. <br>To change the Path, please return to the Challenge Panel")}
          panel={
            <>
              <Panel />
              <TypeChart />
            </>
          }
          contentClassName="full-height"
        >
          <MainRow>
            <CardContainer>
              <Card className="flex-grow-1">
                <MDBCardHeader>
                  <MDBCardTitle>{t('Path')}</MDBCardTitle>
                </MDBCardHeader>
                <MDBCardBody>
                  <PathPreview />
                </MDBCardBody>
              </Card>
            </CardContainer>
          </MainRow>
        </SidebarLayout>
      </Row>
      <Refresher
        subscriptions="ss.task.create,ss.task.delete,ss.task.update"
        uri={challengesUri(game_id)}
      />
    </OrganizeContextProvider>
  );
};

export default withPlaceholder(Path, PathPlaceholder);
