import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TextareaCol } from "../../App.styles";
import { useGameQuery } from "../../settings/Settings.state";
import theme from "../../themes";

const Invitations = () => {
  const { t } = useTranslation();
  const { game_id } = useParams();
  const [gameQueryState] = useGameQuery(game_id);
  const { code, name } = gameQueryState || {}
  const message = `${t(`I'm taking part in a fun new app called Social Scavenger. Visit`)} ${theme.playerUrl} ${t(`to join in! After you've installed the app on your phone, make sure to join the game`)} "${name}" ${t(`using the secret unlock code`)} "${code}".`;

  return (
    <div className="card">
      <div className="card-header bg-dark text-light">
        <span className="card-title">{t('Invitations')}</span>
      </div>
      <div className="card-body">
        <p dangerouslySetInnerHTML={{__html: t(`Email anyone and invite them to join the game. Copy and paste the text
          below and customize it however you'd like.`)}} />
        <TextareaCol>
          <label className="form-label">{t('Email Copy')}</label>
          <textarea rows={10} value={message} onChange={() => {}}></textarea>
        </TextareaCol>
      </div>
    </div>
  );
};

export default Invitations;
