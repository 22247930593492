import { useTranslation } from "react-i18next";
import { Modal } from "../../common";
import { Button } from "../../App.styles";

const ChallengeListModal = ({ onDismiss, show }) => {
  const { t } = useTranslation();

  return (
    <Modal onDismiss={onDismiss} show={show}>
      <div className="modal-header">
        <h4 className="modal-title">{t('Challenge List')}</h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-hidden="true"
          onClick={onDismiss}
        >
          ×
        </button>
      </div>
      <div className="modal-body">
        <p>
          {t(`Challenges can be displayed to everyone as per the order set in the
          builder or they can be shuffled into a unique order for each team.`)}
        </p>
        <ul>
          <li>
            {t(`Ordered mode means all teams are likely to start by trying to complete
            the same first challenge in the list.`)}
          </li>
          <li>
            {t(`Shuffled mode means teams will be likely to distribute themselves
            naturally as they work the list from top to bottom. If you are worried
            about everyone going to the same challenge at the same time, use the
            Shuffled mode.`)}
          </li>
          <li>
            {t(`If your game has a specific path or uses locks, consider using Ordered
            mode.`)}
          </li>
        </ul>
      </div>
      <div className="modal-footer">
        <Button color="dark" data-dismiss="modal" onClick={onDismiss}>
          {t('Close')}
        </Button>
      </div>
    </Modal>
  )
};

export default ChallengeListModal;
