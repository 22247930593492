export const errorMessages = {
    400: 'Your purchase could not be completed. A bad request was made.',
    401: 'Your purchase could not be completed. Your card is unauthorized.',
    402: 'Your purchase could not be completed. Your card was declined.',
    403: 'Your purchase could not be completed. You must be the game creator, a game administrator, or a system super administrator.',
    404: 'Your purchase could not be completed. The game associated with the purchase was not found.',
    406: 'Your purchase could not be completed. Authentication with our payment provider Stripe was found to be invalid.',
    500: 'Your purchase could not be completed. A server error has occurred.',
    501: 'Your purchase could not be completed. Our payment provider Stripe reported invalid parameters.',
    502: 'Your purchase could not be completed. Our payment provider Stripe rated limited the transaction.',
    503: 'Your purchase could not be completed. Our payment provider Stripe reported a generic error.',
    504: 'Your purchase could not be completed. The network connectivity to our payment provider Stripe was down.',
}

export const usePayment = () => {
    const doPayment = async ({ action, onSuccess, onError }) => {
        try {
            await action()
            onSuccess()
        } catch (e) {
            if (errorMessages[e.response?.status]) {
              onError(errorMessages[e.response.status]);
            }
        }
        window.scrollTo(0, 0);
    }

    return {
        doPayment,
    }
}

export default usePayment