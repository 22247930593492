import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useMyQuery } from "./Leaderboard.state";
import { Row, Col } from "../App.styles";

const HeaderCol = styled(Col)`
  width: 50%;
  flex-direction: column;

  img {
    max-width: 100%;
  }

  @media (max-width: ${({ theme }) => theme.maxMobileWidth}) {
    width: 40%;
  }
`;

const MyTeam = () => {
  const { game_id, style } = useParams();
  const myTeam = useMyQuery(game_id, style);

  if (!myTeam) {
    return null;
  }

  const { assets, name, points, points_place, completed } = myTeam;
  const photo = assets?.photos?.find((photo) => photo.name === "thumb");

  if (!name) {
    return null;
  }

  return (
    <Row>
      <HeaderCol>{photo && <img src={photo.url} alt={name} />}</HeaderCol>
      <HeaderCol>
        <strong>{name}</strong>
        <span>Rank: {points_place}</span>
        <span>Points: {points}</span>
        <span>Completed: {completed}</span>
      </HeaderCol>
    </Row>
  );
};

export default MyTeam;
