import styled from "styled-components";
import { useState } from "react";
import { MDBCardHeader, MDBCardBody, MDBCardTitle } from "mdb-react-ui-kit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { ActionButtonWrapper, Button, Col, Divider, Row } from "../App.styles";
import { useAchievementQuery } from "./Rewards.state";

const RewardThumb = styled.div`
  background-color: ${({ theme }) => theme.placeholderColor};
  height: 75px;
  margin-right: 1rem;
  width: 75px;

  img {
    height: 75px;
    width: 75px;
    object-fit: cover;
    object-position: center;
  }

  ${({ error }) =>
    error &&
    `
    border: 2px solid red;

    img {
      display: none;
    }
  `};
`;

const TeamView = ({ id, onEdit }) => {
  const { t } = useTranslation();
  const [{ assets, title, description }] = useAchievementQuery(id);
  const [error, setError] = useState(false);
  const photo = assets?.photos.find(
    (photo) => photo.name === "standard" && photo.url
  );

  return (
    <>
      <MDBCardHeader>
        <Row>
          <Col>
            <MDBCardTitle>{title}</MDBCardTitle>
          </Col>
        </Row>
      </MDBCardHeader>
      <MDBCardBody>
        <Row>
          <Col size="5" md="2">
            <RewardThumb error={error}>
              {photo?.url && (
                <img
                  alt={title}
                  src={photo?.url}
                  onError={() => setError(true)}
                />
              )}
            </RewardThumb>
          </Col>
          <Col
            size="7"
            md="10"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </Row>
        <Divider />
        <ActionButtonWrapper>
          <Button color="primary" className="btn-raised" onClick={onEdit}>
            <FontAwesomeIcon icon={faPencilAlt} /> {t('Edit')}
          </Button>
        </ActionButtonWrapper>
      </MDBCardBody>
    </>
  );
};

export default TeamView;
