import { useTranslation } from "react-i18next";
import { Modal } from "../../common";
import { Button } from "../../App.styles";

const GameModeModal = ({ onDismiss, show }) => {
  const { t } = useTranslation();

  return (
    <Modal onDismiss={onDismiss} show={show}>
      <div className="modal-header">
        <h4 className="modal-title">{t(`Game Mode`)}</h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-hidden="true"
          onClick={onDismiss}
        >
          ×
        </button>
      </div>
      <div className="modal-body">
        <p dangerouslySetInnerHTML={{__html: t(`If you select 'Scheduled', you will define a start time and end time and
          all players must start and finish at those times. If you select 'Start
          Anytime', you will define how long each player or team has to complete
          the game but they can start anytime. If you select 'Stopped', you will
          hide all challenges and automatically reject any new submissions.`)}} />
        <ul>
          <li dangerouslySetInnerHTML={{__html: t(`If you want all your players active at the same time choose
            'Scheduled'.`)}} />
          <li dangerouslySetInnerHTML={{__html: t(`If you wish to allow players to play your game at anytime but still be
            challenged with a time limit select 'Start Anytime'.`)}} />
          <li dangerouslySetInnerHTML={{__html: t(`If you wish to allow players to play anytime and have no restrictions
            on time select 'Start Anytime'.`)}} />
          <li>
            {t(`Scheduling alone will not stop people from submitting early and seeing
            submissions in the stream. To stop early or late submissions from
            being automatically accepted and displayed, use the moderation
            settings.`)}
          </li>
        </ul>
      </div>
      <div className="modal-footer">
        <Button color="dark" data-dismiss="modal" onClick={onDismiss}>
          {t(`Close`)}
        </Button>
      </div>
    </Modal>
  )
};

export default GameModeModal;
