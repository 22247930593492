import Error from "./Error";
import LinkButton from "./LinkButton";

const Maintenance = () => {
  return (
    <Error
      title="Site Maintenance"
      message="The builder is currently undergoing maintenance. Please try again in a couple of hours. Thanks for your patience."
      button={
        <LinkButton color="primary" to="/">
          Refresh
        </LinkButton>
      }
    />
  );
};

export default Maintenance;
