export const radiusOptions = [{
    id: '0',
    name: '',
}, {
    id: '5',
    name: '5m/15ft',
}, {
    id: '10',
    name: '10m/32ft',
}, {
    id: '15',
    name: '15m/50ft',
}, {
    id: '25',
    name: '25m/82ft'
}, {
    id: '30',
    name: '30m/100ft',
}, {
    id: '50',
    name: '50m/165ft',
}, {
    id: '100',
    name: '100m/330ft'
}, {
    id: '1000',
    name: '1km/0.6mi'
}]