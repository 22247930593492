import { Row, Col } from "../App.styles";
import { Shimmer } from "../common/Loading.styles";

const AnalyticsPlaceholder = () => {
  return (
    <>
        <Row className="mb-3">
            <Col size="12" lg="6">
                <Shimmer media className="media-full mb-3" />
            </Col>
            <Col size="12" lg="6">
                <Shimmer media className="media-full mb-3" />
            </Col>
        </Row>
        <Row className="mb-3">
            <Col size="12" lg="6">
                <Shimmer media className="media-full mb-3" />
            </Col>
            <Col size="12" lg="6">
                <Shimmer media className="media-full mb-3" />
            </Col>
        </Row>
    </>
  );
};

export default AnalyticsPlaceholder;
