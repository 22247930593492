import { useMemo } from "react";
import styled from "styled-components";
import {
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBDropdownLink,
} from "mdb-react-ui-kit";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { Spinner } from "../common";
import { DropdownToggle } from "../App.styles";

export const LiberateDropdownWrapper = styled(MDBDropdown)`
  button {
    width: 100%;
    position: relative;
    font-size: 0.875rem;
    margin-bottom: 0.3125rem;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 0;
    outline: 0;
    font-weight: 500;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    line-height: 1.5;
    border-radius: 0.125rem;
    white-space: nowrap;
  }
`;

export const LiberateItemIcon = styled.span(({ theme }) => `
  color: ${theme.successColor};
  display: inline-block;
  margin-left: 6px;
`)

export const LiberateItemList = styled(MDBDropdownItem)(({ disabled }) => `
  cursor: pointer;

  ${disabled ? `
    opacity: 0.5;
    pointer-events: none;
  ` : ''}
`)

const LiberateDropdown = ({ className, onLiberate, isLiberating, liberated }) => {
  const { t } = useTranslation()
  const liberateOptions = useMemo(() => ({
    team: t('Teams'),
    branding: t('Branding'),
    library: t('Library'),
  }), [t])

  // map non-standard liberation keys
  const optionMap = useMemo(() => ({
    teams: 'team'
  }), [])
  const allPackages = Object.keys(liberateOptions).join(',')
  const liberatedKeys = useMemo(() => Object.entries(liberated)
    .filter(([, value]) => value === true)
    .map(([key]) => optionMap[key] || key), [liberated, optionMap])
  const allLiberated = useMemo(() => Object.values(liberated).every(status => status === true), [liberated])
  const someLiberated = useMemo(() => Object.values(liberated).some(status => status === true), [liberated])

  const btnColor = useMemo(() => {
    switch (true) {
      case allLiberated:
        return 'success'
      case someLiberated:
        return 'warning'
      default:
        return 'danger'
    }
  }, [allLiberated, someLiberated])

  const options = useMemo(() => {
    return Object.entries(liberateOptions).map(([value, label], index) => ({
      value,
      label,
      index,
      checked: liberatedKeys.includes(value),
    }))
  }, [liberateOptions, liberatedKeys])
  
  return (
    <LiberateDropdownWrapper className={className}>
      <DropdownToggle color={btnColor}>
        {t('Liberate')} { isLiberating ? <Spinner size="0.875rem" /> : null }
      </DropdownToggle>
      <MDBDropdownMenu>
        <MDBDropdownItem onClick={() => onLiberate(allPackages)} data-testid="liberate-action-all">
          <MDBDropdownLink>{t('Liberate All')}</MDBDropdownLink>
        </MDBDropdownItem>
        <div className="dropdown-divider"></div>
        {
          options.map(({ value, label, index, checked }) => {
            return (
              <LiberateItemList disabled={checked} key={index} onClick={() => onLiberate(value)} data-testid={`liberate-action-${value}`}>
                <MDBDropdownLink>
                  <span dangerouslySetInnerHTML={{__html: `${t('Liberate')} ${label}`}} />
                  <LiberateItemIcon>
                    {
                      checked
                        ? <FontAwesomeIcon icon={faCheck} />
                        : null
                    }
                  </LiberateItemIcon>
                </MDBDropdownLink>
              </LiberateItemList>
            )
          })
        }
      </MDBDropdownMenu>
    </LiberateDropdownWrapper>
  );
};

export default LiberateDropdown;
