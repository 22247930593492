import styled from "styled-components";
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsContent,
  MDBTabsPane,
} from "mdb-react-ui-kit";
import { SidebarLayout } from "../common";
import { Row, Col } from "../App.styles";
import { Shimmer } from "../common/Loading.styles";

const Tabs = styled(MDBTabs)`
  background-color: #eceff1;

  .nav-link {
    &.active {
      border-color: ${({ theme }) => theme.primaryColor};
      color: ${({ theme }) => theme.primaryColor};
    }
  }

  @media (max-width: ${({ theme }) => theme.maxMobileWidth}) {
    .nav-item {
      flex-grow: 1;
    }

    .nav-link {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      text-align: center;
    }
  }
`;

const TabsContent = styled(MDBTabsContent)`
  margin-bottom: 2.5rem;
`;

const TabsItem = styled(MDBTabsItem)`
  min-width: 200px;
`;

const SettingsPlaceholder = () => {
  return (
    <>
      <Row>
        <SidebarLayout>
          <Tabs className="mb-3">
            <TabsItem>
                <Shimmer type="cover" />
            </TabsItem>
          </Tabs>
          <TabsContent>
            <MDBTabsPane show={true}>
                <Row>
                    <Col size="10">
                        <Shimmer type="title" />
                    </Col>
                </Row>
                <Row>
                    <Col size="12">
                        <Shimmer />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col size="10">
                        <Shimmer media />
                    </Col>
                </Row>
                <Row>
                    <Col size="10">
                        <Shimmer type="title" />
                    </Col>
                </Row>
                <Row>
                    <Col size="10">
                        <Shimmer type="title" />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Shimmer type="title" />
                    </Col>
                </Row>
            </MDBTabsPane>
          </TabsContent>
        </SidebarLayout>
      </Row>
    </>
  );
};

export default SettingsPlaceholder;
