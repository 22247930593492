import { atom, useRecoilState } from "recoil";
import { gameStreamQueryKey } from "../lib/refresh";
import { fetchGameStream } from "../monitor/Monitor.state";
import { useInfiniteQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

export const featuredState = atom({
  key: "games/slideshow/settings/featured",
  default: false,
});

export const liveState = atom({
  key: "games/slideshow/settings/live",
  default: true,
});

export const notificationsState = atom({
  key: "games/slideshow/notifications",
  default: [],
});

export const playingState = atom({
  key: "games/slideshow/settings/playing",
  default: false,
});

export const shuffleState = atom({
  key: "games/slideshow/settings/shuffle",
  default: 0,
});

export const fetchSlideshow =
  (game_id) =>
  ({ pageParam }) =>
    fetchGameStream(game_id, undefined, pageParam);

export const useSlideshowDataQuery = (game_id) => {
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
    useInfiniteQuery(gameStreamQueryKey(game_id), fetchSlideshow(game_id), {
      getNextPageParam: (lastPage = {}, pages = []) => {
        const ids = (lastPage?.answers || []).map(({ id }) => parseInt(id));
        const { count } = lastPage;
        const loaded = pages.reduce(
          (sum, page) => (sum += page?.answers.length),
          0
        );

        if ((lastPage && (lastPage?.answers || []).length === 0) || count <= loaded) {
          return undefined;
        }

        return Math.min(...ids);
      },
      suspense: true,
    });

  const types = [1, 2, 3, 9, 10];
  const answers = (data.pages || [])
    .flatMap((page) => page?.answers)
    .filter((item = {}) => {
      const { sub_type, type } = item
      return type === 2 && sub_type === 2 ? false : types.includes(type);
    });
  // counts of featured submission is attached as `featured` property.
  // need to extract from 1 sample to get the information
  const featuredSubmissions =  (data.pages || [])?.[0]?.featured
  return { answers, featuredSubmissions, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading };
};

export const useSlideshowQuery = () => {
  const { game_id } = useParams();
  return useSlideshowDataQuery(game_id);
};

export const useSlideshowLoadingQuery = () => {
  const { game_id } = useParams();
  const { isLoading } = useSlideshowDataQuery(game_id);

  return isLoading;
};

export const useInvalidateSlideshowMutation = () => {
  const { game_id } = useParams();
  const queryClient = useQueryClient();
  const { mutate } = useMutation(() =>
    queryClient.invalidateQueries(gameStreamQueryKey(game_id))
  );
  return mutate;
};

export const useShowNotification = () => {
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const showNotification = (message) =>
    setNotifications([...notifications, message]);

  const { mutate } = useMutation(({ source }) => {
    switch (source) {
      case "ss.answer.accept":
        showNotification("Submission accepted.");
        break;
      case "ss.answer.reject":
        showNotification("Submission rejected.");
        break;
      case "ss.answer.create":
        showNotification("New submission added.");
        break;
      default:
    }
  });

  return mutate;
};
