import { useTranslation } from "react-i18next";
import { Modal } from "../../common";
import { Button } from "../../App.styles";

const AugmentedRealityAnimationModal = ({ onDismiss, show }) => {
  const { t } = useTranslation();

  return (
    <Modal onDismiss={onDismiss} show={show}>
      <div className="modal-header">
        <h4 className="modal-title">{t('Animation')}</h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-hidden="true"
          onClick={onDismiss}
        >
          ×
        </button>
      </div>
      <div className="modal-body">
        <p>
          {t(`Each augmented reality model or file may contain any number of
          animations. Each animation is labelled and may be referenced by the
          unique label.`)}
        </p>
        <ul>
          <li>
            {t(`Enter the specific animation label you wish to have appear within this
            challenge.`)}
          </li>
          <li>
            {t(`If you wish to enable multiple animations from a single model or file,
            then upload the file multiple times, referencing different animations
            each time.`)}
          </li>
        </ul>
      </div>
      <div className="modal-footer">
        <Button type="button" color="dark" data-dismiss="modal" onClick={onDismiss}>
          {t('Close')}
        </Button>
      </div>
    </Modal>
  )
};

export default AugmentedRealityAnimationModal;
