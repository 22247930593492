import { useTranslation } from "react-i18next";
import { useRealityQuery } from "../Organize.state";
import { FormSelect, RowInfo } from "../../common";
import { VideoLengthModal, VideoSharingModal } from "../modals";
import AugmentedRealityCard from "./AugmentedRealityCard";

const AugmentedRealityVideo = ({ realities, task_id, useQuery }) => {
  const { t } = useTranslation();

  return (
    <>
      <p dangerouslySetInnerHTML={{__html: t(`Require the player to upload a video including an AR model.`)}} />
      <RowInfo help={<VideoLengthModal />}>
        <FormSelect
            label={t("Video length")}
            name="video_length"
            useQuery={useQuery}
          >
            <option value="1">1s</option>
            <option value="2">2s</option>
            <option value="3">3s</option>
            <option value="5">5s</option>
            <option value="7">7s</option>
            <option value="10">10s</option>
            <option value="20">20s</option>
            <option value="30">30s</option>
            <option value="45">45s</option>
            <option value="60">60s</option>
          </FormSelect>
      </RowInfo>
      <RowInfo help={<VideoSharingModal />}>
        <FormSelect label={t("Shared as")} name="task_subtype" useQuery={useQuery}>
            <option value="0">{t('standard video')}</option>
            <option value="1">{t('animated gif')}</option>
            <option value="2">{t('audio only')}</option>
          </FormSelect>
      </RowInfo>
      {realities.map(({ id }) => {
        const useQuery = () => useRealityQuery(task_id, id);
        return <AugmentedRealityCard key={id} useQuery={useQuery} />;
      })}
    </>
  );
};

export default AugmentedRealityVideo;
