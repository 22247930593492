import styled from "styled-components";
import { useEffect, useState } from "react";
import { ColorPicker as Color, createColor } from "material-ui-color";
import { HelpText } from "../App.styles";
import statefulField from "./StatefulField";

const palette = {
  "#000000": "rgb(0, 0, 0)",
  "#434343": "rgb(67, 67, 67)",
  "#666666": "rgb(102, 102, 102)",
  "#CCCCCC": "rgb(204, 204, 204)",
  "#D9D9D9": "rgb(217, 217, 217)",
  "#FFFFFF": "rgb(255, 255, 255)",
  "#980000": "rgb(152, 0, 0)",
  "#FF0000": "rgb(255, 0, 0)",
  "#FF9900": "rgb(255, 153, 0)",
  "#FFFF00": "rgb(255, 255, 0)",
  "#00FF00": "rgb(0, 255, 0)",
  "#00FFFF": "rgb(0, 255, 255)",
  "#4A86E8": "rgb(74, 134, 232)",
  "#0000FF": "rgb(0, 0, 255)",
  "#9900FF": "rgb(153, 0, 255)",
  "#FF00FF": "rgb(255, 0, 255)",
  "#E6B8AF": "rgb(230, 184, 175)",
  "#F4CCCC": "rgb(244, 204, 204)",
  "#FCE5CD": "rgb(252, 229, 205)",
  "#FFF2CC": "rgb(255, 242, 204)",
  "#D9EAD3": "rgb(217, 234, 211)",
  "#D0E0E3": "rgb(208, 224, 227)",
  "#C9DAF8": "rgb(201, 218, 248)",
  "#CFE2F3": "rgb(207, 226, 243)",
  "#D9D2E9": "rgb(217, 210, 233)",
  "#EAD1DC": "rgb(234, 209, 220)",
  "#DD7E6B": "rgb(221, 126, 107)",
  "#EA9999": "rgb(234, 153, 153)",
  "#F9CB9C": "rgb(249, 203, 156)",
  "#FFE599": "rgb(255, 229, 153)",
  "#B6D7A8": "rgb(182, 215, 168)",
  "#A2C4C9": "rgb(162, 196, 201)",
  "#A4C2F4": "rgb(164, 194, 244)",
  "#9FC5E8": "rgb(159, 197, 232)",
  "#B4A7D6": "rgb(180, 167, 214)",
  "#D5A6BD": "rgb(213, 166, 189)",
  "#CC4125": "rgb(204, 65, 37)",
  "#E06666": "rgb(224, 102, 102)",
  "#F6B26B": "rgb(246, 178, 107)",
  "#FFD966": "rgb(255, 217, 102)",
  "#93C47D": "rgb(147, 196, 125)",
  "#76A5AF": "rgb(118, 165, 175)",
  "#6D9EEB": "rgb(109, 158, 235)",
  "#6FA8DC": "rgb(111, 168, 220)",
  "#8E7CC3": "rgb(142, 124, 195)",
  "#C27BA0": "rgb(194, 123, 160)",
  "#A61C00": "rgb(166, 28, 0)",
  "#CC0000": "rgb(204, 0, 0)",
  "#E69138": "rgb(230, 145, 56)",
  "#F1C232": "rgb(241, 194, 50)",
  "#6AA84F": "rgb(106, 168, 79)",
  "#45818E": "rgb(69, 129, 142)",
  "#3C78D8": "rgb(60, 120, 216)",
  "#3D85C6": "rgb(61, 133, 198)",
  "#674EA7": "rgb(103, 78, 167)",
  "#A64D79": "rgb(166, 77, 121)",
  "#5B0F00": "rgb(91, 15, 0)",
  "#660000": "rgb(102, 0, 0)",
  "#783F04": "rgb(120, 63, 4)",
  "#7F6000": "rgb(127, 96, 0)",
  "#274E13": "rgb(39, 78, 19)",
  "#0C343D": "rgb(12, 52, 61)",
  "#1C4587": "rgb(28, 69, 135)",
  "#073763": "rgb(7, 55, 99)",
  "#20124D": "rgb(32, 18, 77)",
  "#4C1130": "rgb(76, 17, 48)",
  "#3478B9": "#3478b9",
  "#454641": "#454641",
};

const FieldWrapper = styled.div`
  position: relative;
  width: 100%;

  .form-label {
    font-size: 0.8rem;
  }

  .ColorPicker-MuiButtonBase-root,
  .ColorPicker-MuiButtonBase-root div {
    width: 3rem;
    height: 3rem;
  }
`;

export const ColorPicker = ({ defaultValue, label, setValue, value }) => {
  const [focus, setFocus] = useState(false);
  const [color, setColor] = useState();

  useEffect(() => {
    const c = createColor(value ? `#${value}` : defaultValue);

    if (c.hex !== color?.hex) {
      setColor(c);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //const isHexColor = hex => typeof hex === 'string' && hex.length === 6 && !isNaN(Number('0x' + hex))

  const onChange = (c) => {
    const { hex } = c;

    setColor(c);
    setValue(hex);
  };

  return (
    <FieldWrapper className="form-outline">
      <label className="form-label">{label}</label>
      <Color
        defaultValue={defaultValue}
        hideTextfield
        onChange={onChange}
        onOpen={(open) => setFocus(open)}
        palette={palette}
        value={color}
      />
      <HelpText focus={focus}>Select a color.</HelpText>
    </FieldWrapper>
  );
};

export default statefulField(ColorPicker);
