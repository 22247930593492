import { useEffect, useRef } from "react";

const hasVideo = ({ urls }) =>
  Object.values(urls).reduce((result, url) => result || !!url, false);

const Slide = ({ active, assets, muted, paused }) => {
  const videoRef = useRef();
  const photo = assets.photos.find(
    (photo) => photo.name === "standard" && photo.url
  );
  const video = assets.videos.find(
    (video) => video.name === "standard" && hasVideo(video)
  );

  useEffect(() => {
    if (active) {
      if (videoRef.current) {
        videoRef.current.currentTime = 0;
      }
    }
  }, [active]);

  useEffect(() => {
    const onPause = () => {
      try {
        if (paused && !videoRef.current.paused) {
          videoRef?.current?.pause && videoRef.current.pause()
        } else {
          videoRef?.current?.play && videoRef.current.play()
        }
      } catch {}
    }
    onPause()
  }, [paused]);

  if (video) {
    if (video.urls.gif) {
      return <img src={video.urls.gif} alt="" />;
    }

    return (
      <video
        autoPlay
        muted={muted}
        playsInline
        preload="auto"
        height="100%"
        poster={video.urls.png}
        ref={videoRef}
      >
        <source src={video.urls.mp4} type="video/mp4" />
        {video.urls.webm && <source src={video.urls.webm} type="video/webm" />}
      </video>
    );
  }

  return photo?.url ? <img src={photo?.url} alt="" /> : null;
};

export default Slide;
