import { useState } from "react";
import { useMutation } from "react-query";
import styled from "styled-components";
import externalAxios from "axios";
import { useTranslation } from "react-i18next";
import Spinner from "./Spinner";
import { axios } from "../lib/request";
import { Button, ButtonRow } from "../App.styles";
import { Tooltip } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const PreviewImage = styled.div`
  background-color: ${({ theme }) => theme.placeholderColor};
  display: flex;
  height: 75px;
  margin-right: 1rem;
  margin-bottom: .5rem;
  width: ${({ width }) => width}px;
  align-items: center;
  justify-content: center;

  img {
    width: ${({ width }) => width}px;
    height: 75px;
    object-fit: contain;
  }

  ${({ error }) =>
    error &&
    `
    border: 2px solid red;

    img {
      display: none;
    }
  `}
`;

const ImageUploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  .form-label {
    font-size: 0.8rem;
  }

  ${ButtonRow} {
    margin-top: 0.75rem;
    margin-bottom: 0.25rem;

    ${Button} {
      margin-right: 0.25rem;
      max-width: 200px;
      text-overflow: ellipsis;
    }
  }
`;

const FileWrapper = styled.div`
  margin-right: 0.5rem;

  input[type="file"] {
    display: none;
  }

  .form-control {
    font-size: 0.8rem;
  }
`;

const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  button {
    margin-bottom: 0.3125rem;
    padding: .625rem 1rem;
  }
`;

const UploadBtn = styled.label`
  display: inline-block;
`;

const findThumbURL = (assets, key) => {
  const asset = assets.videos.find(
    (asset) => asset.name === key && asset.urls?.png
  );

  if (asset) {
    return asset.urls.png;
  }

  return null;
};

const translateKey = (key) => {
  switch (key) {
    case "ya":
      return "correct";
    case "naw":
      return "incorrect";
    case "arrival_video":
      return "arrival";
    default:
      return key;
  }
};

const NewVideoUpload = ({ label, name, useQuery, width = 75 }) => {
  const { t } = useTranslation();
  const [{ assets, placeholders, id }, setCollection] = useQuery();
  const key = translateKey(name);
  const thumb = findThumbURL(assets, key);
  const [placeholder, setPlaceholder] = useState(!thumb);
  const [error, setError] = useState(false);
  const [preview, setPreview] = useState(!placeholder);

  const { isLoading, mutateAsync } = useMutation(async (file) => {
    const [ext] = file.name.split(".").reverse();

    const url = `${
      process.env.REACT_APP_API_SIGN_URL
    }/${ext.toLocaleLowerCase()}`;
    const { data } = await axios.get(url);
    const options = { headers: { "Content-Type": file.type } };

    await externalAxios.put(data.signature, file, options);

    const [uuid, extension] = data.name.split(".");

    return await setCollection({
      placeholders: [...(placeholders || []), { key, uuid }],
      [`${name}_media_uuid`]: uuid,
      [`${name}_media_extension`]: extension.toLocaleLowerCase(),
    });
  });

  const upload = async (file) => {
    if (file) {
      setPlaceholder(false);
      setPreview(true);
      setError(false);
      await mutateAsync(file);
    }
  };

  const clear = () => {
    const media = assets.videos.filter((asset) => asset.name !== key);

    setCollection({
      assets: {
        ...assets,
        videos: [...media, { name: key, urls: {} }],
      },
      [`${name}_media_uuid`]: null,
      [`${name}_media_extension`]: null,
    });

    setPlaceholder(true);
    setPreview(false);
    setError(false);
  };

  const fileId = `${name}-video-${id}-upload`

  return (
    <ImageUploadWrapper className="form-outline">
        {label && <label className="form-label">{label}</label>}
        {
            preview
                ? (
                    <PreviewImage
                        error={error}
                        onClick={() => setPreview(false)}
                        width={width}
                    >
                        {isLoading ? (
                        <Spinner />
                        ) : (
                        thumb && (
                            <img src={thumb} alt={label} onError={() => setError(true)} />
                        )
                        )}
                    </PreviewImage>
                )
                : null
        }
        <ActionWrapper>
          <UploadBtn htmlFor={fileId}>
            <FileWrapper>
              <input
                accept=".avi,.mkv,.mov,.mp4,.webm"
                className="form-control"
                id={fileId}
                type="file"
                onChange={({ target }) => upload(target.files[0])}
              />
            </FileWrapper>
            <Button tag="span" color="warning" className="btn--flat">
              {t('Select File')}
            </Button>
          </UploadBtn>
          {
            preview
              ? (
                <Tooltip title={t(`Delete video`)}>
                  <Button onClick={clear} color="danger" className="btn--flat">
                    <FontAwesomeIcon icon={faTimes} />
                  </Button>
                </Tooltip>
              )
              : null
          }
        </ActionWrapper>
    </ImageUploadWrapper>
  );
};

export default NewVideoUpload;
