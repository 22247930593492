import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import {
  Navbar,
  NavbarBrand,
  Container,
  Button,
  BrandWrapper,
} from "./Header.styles";
import { FontAwesomeIcon } from "./SideNav.styles";
import logo from "../images/logo.png";
import { useState } from "react";

const Header = () => {
  const [isNavbarActive, setIsNavbarActive] = useState(false)

  const toggleNavbar = () => {
    if (isNavbarActive) {
      document.body.classList.remove('navbar-active')
    } else {
      document.body.classList.add('navbar-active')
    }
    setIsNavbarActive(!isNavbarActive)
  }

  return (
      <Navbar sticky>
          <Container>
            <BrandWrapper>
              <Button color="link" onClick={toggleNavbar}>
                  {
                    isNavbarActive
                      ? <FontAwesomeIcon icon={faTimes} />
                      : <FontAwesomeIcon icon={faBars} />
                  }
              </Button>
              <NavbarBrand tag="div">
                  <Link to="/">
                      <img src={logo} alt="Social Scavenger" />
                  </Link>
              </NavbarBrand>
            </BrandWrapper>
        </Container>
    </Navbar>
  )
}

export default Header