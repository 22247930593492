import styled from "styled-components";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { MDBCardHeader, MDBCardBody } from "mdb-react-ui-kit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { ActionButtonWrapper, Button, Row, Col, Divider } from "../App.styles";
import { ImageUpload, Input, Spinner } from "../common";
import { Input as RawInput } from "../common/Input";
import PlayerList from "./PlayerList";
import { teamsQueryKey } from "../lib/refresh";
import { useTeamQuery, useUnassignedQuery } from "./Teams.state";
import { axios } from "../lib/request";

const ButtonCol = styled(Col)`
  justify-content: flex-end;

  @media (max-width: ${({ theme }) => theme.maxMobileWidth}) {
    ${Button} {
      padding-left: 0.25rem;
      padding-right: 0.25rem;
      white-space: normal;
    }
  }
`;

const HeadRow = styled(Row)`
  @media (max-width: ${({ theme }) => theme.maxMobileWidth}) {
    ${Button} {
      margin: 0;
      padding: 0.5rem 0.75rem;
    }
  }
`;

const PlayerRow = styled(Row)`
  @media (max-width: ${({ theme }) => theme.maxMobileWidth}) {
    max-width: 100%;
  }
`;

const TeamEdit = ({ id, onClose, onDelete }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const { game_id } = useParams();
  const queryClient = useQueryClient();
  const queryKey = teamsQueryKey(game_id);
  const [team,,isFetching] = useTeamQuery(game_id, id);
  const unassigned = useUnassignedQuery(game_id);
  const { users } = team;
  const useQuery = () => useTeamQuery(game_id, id);

  const { isLoading, mutate } = useMutation(
    (email) => {
      const url = `${process.env.REACT_APP_API_PLAYER_URL}/${id}/player.json`;
      return axios.post(url, {
        game_id,
        email,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
        setEmail("");
      },
    }
  );

  const addPlayer = async (e) => {
    e.preventDefault();
    mutate(email);
  };

  const addUnassignedPlayer = async (email) => {
    if (email) {
      mutate(email);
    }
  };

  const removePlayer = async (player_id) => {
    const url = `${process.env.REACT_APP_API_PLAYER_URL}/${id}/player.json`;
    await axios.delete(url, { params: { game_id, player_id } });
    queryClient.invalidateQueries(queryKey);
  };

  return (
    <>
      <MDBCardHeader>
        <HeadRow>
          <Col size="10" md="10">
            <Input
              help={t("This is the name others will see you as.")}
              name="name"
              label={t("Headline")}
              useQuery={useQuery}
            />
          </Col>
          <Col size="2" md="2">
            <Button
              color="danger"
              className="btn-raised"
              onClick={() => onDelete(id)}
            >
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          </Col>
        </HeadRow>
      </MDBCardHeader>
      <MDBCardBody>
        <PlayerRow>
          <Col size="6">
            <ImageUpload
              name="photo"
              label={t("Image")}
              model="teams"
              useQuery={useQuery}
            />
          </Col>
          <Col size="6">
            <PlayerList onRemove={removePlayer} users={users} />
          </Col>
        </PlayerRow>
        <Divider />
        <form className="row" onSubmit={addPlayer}>
          <Col size="8" md="9">
            <RawInput
              help={t("Enter an email address for a player who has not yet joined.")}
              label={t("Email address")}
              onChange={setEmail}
              value={email}
            />
            <span className="bmd-help"></span>
          </Col>
          <ButtonCol size="4" md="3">
            <Button color="light" disabled={!email} type="submit">
              {isLoading || isFetching ? <Spinner /> : t("Save Email")}
            </Button>
          </ButtonCol>
        </form>
        <Row>
          <Col>
            {!!unassigned.length && (
              <div className="form-outline">
                <select
                  className="form-control active"
                  onChange={({ target }) => addUnassignedPlayer(target.value)}
                >
                  <option value="">{t('Add an unassigned player')}</option>
                  {unassigned.map(({ id, email, name }) => (
                    <option key={id} value={email}>
                      {name || email}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </Col>
        </Row>
        <ActionButtonWrapper>
          <Button color="primary" className="btn-raised" onClick={onClose}>
            <FontAwesomeIcon icon={faCheckSquare} /> {t('Close')}
          </Button>
        </ActionButtonWrapper>
      </MDBCardBody>
    </>
  );
};

export default TeamEdit;
