import { useTranslation } from "react-i18next";
import { RowInfo } from "../../common";
import { MultipleChoiceAnswersModal } from "../modals";
import Textarea from "../Textarea";

const MultipleChoice = ({ useQuery }) => {
  const { t } = useTranslation();

  return (
    <>
      <p dangerouslySetInnerHTML={{__html: t(`Present buttons with answers and ask players to select one.`)}} />
      <RowInfo help={<MultipleChoiceAnswersModal />}>
        <Textarea
            label={t("Answers (list all answers, separated by commas, with the correct one first, maximum of 5)")}
            labelClassName="no-ellipsis"
            name="multiplechoice"
            useQuery={useQuery}
          />
      </RowInfo>
    </>
  );
}

export default MultipleChoice;
