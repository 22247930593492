import styled from "styled-components";
import { useEffect, useState } from "react";
import { HelpText } from "../App.styles";
import statefulField from "./StatefulField";

export const FieldWrapper = styled.div`
  ${({ theme }) => `
    position: relative;
    width: 100%;
    padding-right: 22px;

    &.input--strong {
      .form-outline .form-control ~ .form-notch {
        div {
          border-color: ${theme.darkGreyColor};
        }

        .form-notch-leading {
          border-radius: ${theme.rounded.sm} 0 0 ${theme.rounded.sm};
        }

        .form-notch-trailing {
          border-radius: 0 ${theme.rounded.sm} ${theme.rounded.sm} 0;
        }
      }

      .form-label {
        color: ${theme.darkGreyColor};
      }
    }

    .form-notch-middle {
      align-items: center;
      display: flex;

      label {
        font-size: 1rem;
        margin: 0;
        transform: translateY(0);
        transition: font-size linear 0.2s, transform linear 0.2s;
      }
    }

    .active + .form-notch .form-notch-middle label {
      font-size: 0.8rem;
      margin: 0 2px;
      transform: translateY(-1.15rem) translateY(0.1rem);
    }

    .form-label {
      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;

      ${theme.breakpoints.up('lg')} {
        white-space: normal;
        max-width: none;
      }
    }

    .form-clean {
      .form-label {
        font-size: .8rem;
        margin: .1rem;
      }

      .form-control {
        min-height: 39px;
      }
    }
  `}
`;

const FieldSpinner = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate3d(0, -50%, 0);
`;

const Spinner = styled.div`
  ${({ theme }) => `
    cursor: pointer;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    padding: 4px 3px;
    margin: 2px 0;
    height: 14px;
    background: ${theme.placeholderColor};

    &:hover {
      background: ${theme.cardColor};
    }
  `}
`

const SpinnerArrow = styled.div`
    ${({ theme, up, down }) => `
      display: block;
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;

      ${up && `
        border-top: 6px solid transparent;
        border-bottom: 6px solid ${theme.darkGreyColor};
        margin-top: -6px;
      `}

      ${down && `
        border-bottom: 6px solid transparent;
        border-top: 6px solid ${theme.darkGreyColor};
        margin-top: 1px;
      `}
    `}
`;

export const InstantInput = ({
  defaultValue,
  help,
  label,
  mode = 'outline',
  onChange,
  onBlurExtra,
  regex,
  value,
  setValue,
  maxLength,
  validation,
  wrapperClass = '',
  ...rest
}) => {
  const [focus, setFocus] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const initialValue =
    rest.type === "number" && parseFloat(value) === 0
      ? defaultValue || "0.0"
      : value || defaultValue || "";
  const [error, setError] = useState("");

  useEffect(() => setInputValue(initialValue), [initialValue]);

  const setter = ({ target }) => {
    if (isNaN(target.value)) {
      return
    }
    if ((target.min || target.max)) {
      const min = target.min || 0;
      const max = target.max || target.value;
      setInputValue(Math.max(Math.min(target.value, max), min));
    } else {
      if (!regex || target.value.match(regex)) {
        setInputValue(target.value);
      }
    }

    if (onChange) {
      onChange(target.value);
    }
  };

  const increase = () => {
    const e = {
      target: {
        ...rest,
        value: inputValue + 1,
      }
    }
    setter(e)
    commit(e)
  }

  const decrease = () => {
    const e = {
      target: {
        ...rest,
        value: inputValue - 1,
      }
    }
    setter(e)
    commit(e)
  }

  const commit = (e) => {
    setFocus(false);
    const commitValue = e?.target?.value || inputValue

    if (validation && setValue) {
      validation(commitValue).then((res) => {
        if ((commitValue !== "" || commitValue !== null) && res.success) {
          setValue(commitValue);
          setError("");
        } else if (commitValue === "") {
          setValue(commitValue);
          setError("");
        } else {
          setError(res.error);
        }
      });
    } else if (setValue) {
      setValue(commitValue);
      setError("");
    }
  };

  return (
    <FieldWrapper className={wrapperClass} length={label.length}>
      <div className={`form-${mode}`}>
        {
          mode === 'outline'
            ? null
            : (
              <label className="form-label">{label}</label>
            )
        }
        <input
          className={
            focus || (inputValue && inputValue.toString().length)
              ? "form-control active"
              : "form-control"
          }
          value={inputValue}
          maxLength={maxLength}
          onBlur={(e) => {
            if (e?.target?.value) {
              commit(e);
              onBlurExtra && onBlurExtra(e);
            }
          }}
          onChange={setter}
          onFocus={() => setFocus(true)}
          onKeyDown={(e) =>
            e.key !== "-" || rest.type !== "number" || e.preventDefault()
          }
          type="text"
          {...rest}
        />
        {
          mode === 'outline'
            ? (
              <div className="form-notch">
                <div className="form-notch-leading"></div>
                <div className="form-notch-middle">
                  <label className="form-label">{label}</label>
                </div>
                <div className="form-notch-trailing"></div>
              </div>
            )
            : null
        }
      </div>
      {
        <>
          <FieldSpinner>
            <Spinner onClick={decrease}>
              <SpinnerArrow up />
            </Spinner>
            <Spinner onClick={increase}>
              <SpinnerArrow down />
            </Spinner>
          </FieldSpinner>
        </>
      }
      {help && <HelpText focus={focus}>{help}</HelpText>}
      <span style={{ color: "#ed5d53", fontSize: "12px", fontWeight: "bold" }}>
        {error}
      </span>
    </FieldWrapper>
  );
};

export default statefulField(InstantInput);
