import { useTranslation } from "react-i18next";
import { Modal } from "../../common";
import { Button } from "../../App.styles";

const GameImageModal = ({ onDismiss, show }) => {
  const { t } = useTranslation();

  return (
    <Modal onDismiss={onDismiss} show={show}>
      <div className="modal-header">
        <h4 className="modal-title">{t(`Game Image`)}</h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-hidden="true"
          onClick={onDismiss}
        >
          ×
        </button>
      </div>
      <div className="modal-body">
        <p>{t(`Give your game an image. Click the thumbnail to upload a new image.`)}</p>
        <ul>
          <li>
            {t(`Game images are visible when users join your game on their mobile
            device.`)}
          </li>
          <li>
            {t(`The best game images are optimized for display on the phone at 800 by
            800 pixels in size.`)}
          </li>
        </ul>
      </div>
      <div className="modal-footer">
        <Button color="dark" data-dismiss="modal" onClick={onDismiss}>
          {t(`Close`)}
        </Button>
      </div>
    </Modal>
  )
};

export default GameImageModal;
