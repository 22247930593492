import { useTranslation } from "react-i18next";
import { useRealityQuery } from "../Organize.state";
import AugmentedRealityCard from "./AugmentedRealityCard";

const AugmentedRealityPhoto = ({ realities, task_id }) => {
  const { t } = useTranslation();

  return (
    <>
      <p dangerouslySetInnerHTML={{__html: t(`Require the player to upload a photo including an AR model.`)}} />
      {realities.map(({ id }) => {
        const useRealityQueryInstance = () => useRealityQuery(task_id, id);
        return (
          <AugmentedRealityCard key={id} useQuery={useRealityQueryInstance} />
        );
      })}
    </>
  );
};

export default AugmentedRealityPhoto;
