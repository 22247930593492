import styled from "styled-components";
import { useState } from "react";
import { MDBInput } from "mdb-react-ui-kit";
import { useSetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { ActionButtonWrapper, Button, Row, Col } from "../../App.styles";
import { axios } from "../../lib/request";
import { notificationState } from "../Teams.state";
import { useUserQuery } from "../../monitor/User.state";
import { useEffect } from "react";

const ButtonWrapper = styled(ActionButtonWrapper)`
  width: 100%;
`;

const SuperAdmin = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const setNotification = useSetRecoilState(notificationState);
  const userQuery = useUserQuery();
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  useEffect(() => {
    if (userQuery.super_admin || userQuery.game.super_admin) {
      setIsMenuVisible(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addAdmin = () => {
    if (email === "") {
      alert(t("Email field must be filled in!"));
    } else {
      axios
        .post(`${process.env.REACT_APP_API_ADD_SUPER_ADMIN_URL}`, {
          email: email,
        })
        .then((res) => {
          setNotification({
            color: "success",
            message: t("Sucessfully added a new system administrator!"),
          });
        })
        .catch((err) => {
          if (err.response.status === 403) {
            setNotification({
              color: "warning",
              message: t("You must be a Super Admin to process this request."),
            });
          } else if (err.response.status === 404) {
            setNotification({
              color: "warning",
              message: t("The player cannot be found."),
            });
          } else if (err.response.status === 422) {
            setNotification({
              color: "warning",
              message: t("The player update cannot be processed."),
            });
          }
        });

      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  if (isMenuVisible) {
    return (
      <div className="card">
        <div className="card-header bg-dark text-light">
          <span className="card-title">{t('Add Super Admin')}</span>
        </div>
        <div className="card-body">
          <Row>
            <Col>
              <MDBInput
                label={t("Email")}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <ButtonWrapper>
                <Button color="danger" onClick={addAdmin}>
                  {t('Add')}
                </Button>
              </ButtonWrapper>
            </Col>
          </Row>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export default SuperAdmin;
