import GameSummary from "./GameSummary";

const MyGame = ({ onDelete, row }) => {
  const admin = row.user.access === "Created" || row.user.access === "Managing";
  return (
    <GameSummary {...row} admin={admin} onDelete={() => onDelete(row.id)} />
  );
};

const MyGames = ({ rows, onDelete }) => (
  <>
    {rows.map((row) => (
      <MyGame key={row.id} row={row} onDelete={onDelete} />
    ))}
  </>
);

export default MyGames;
