import { useTranslation } from "react-i18next";
import { Modal } from "../../common";
import { Button } from "../../App.styles";

const StickerModal = ({ onDismiss, show }) => {
  const { t } = useTranslation();

  return (
    <Modal onDismiss={onDismiss} show={show}>
      <div className="modal-header">
        <h4 className="modal-title">{t('Sticker')}</h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-hidden="true"
          onClick={onDismiss}
        >
          ×
        </button>
      </div>
      <div className="modal-body">
        <p>
          {t('For sticker challenges, you provide a transparent overlay')} (
          <a
            href="https://ss-production.s3.amazonaws.com/images/full.png"
            target="_blank"
            rel="noreferrer"
          >
            {t('fullscreen example')}
          </a>
          ,{" "}
          <a
            href="https://ss-production.s3.amazonaws.com/images/floating.png"
            target="_blank"
            rel="noreferrer"
          >
            {t('floating example')}
          </a>
          , <a href="http://blog.socialscavenger.com/ar-stickers/">{t('and more')}</a>.)
          {t('that is automatically added to the photo the user takes.')}
        </p>
        <ul>
          <li>
            {t(`You can use any graphics application you want that allows you to
            export transparent PNG files.`)}
          </li>
          <li>
            {t(`Not good at Photoshop? Stickers can be made for you at an additional
            cost.`)}
          </li>
          <li>
            {t(`Files should be created as 645px wide by 860px high images for
            standard quality and 1290px by 1720px images for high quality.`)}
          </li>
          <li>{t('Always orient your sticker image for portrait mode.')}</li>
        </ul>
      </div>
      <div className="modal-footer">
        <Button type="button" color="dark" data-dismiss="modal" onClick={onDismiss}>
          {t('Close')}
        </Button>
      </div>
    </Modal>
  )
};

export default StickerModal;
