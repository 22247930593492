import styled from "styled-components";

export const ModalBackdrop = styled.div`
  &:not(.show) {
    pointer-events: none;
  }
`;

export const ModalWrapper = styled.div`
  display: block;

  &:not(.show) {
    top: -9999rem;
  }
`;

export const ModalDialog = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 2rem;
  margin: 0;
  width: 100%;
  max-width: 500px;

  .modal.fade & {
    transform: translate(-50%, -100%);
  }

  .modal.show & {
    transform: translate(-50%, -50%);
  }
`;
