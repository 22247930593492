import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import { axios } from "./request";
import { defaults } from "./object";
import { addQueryString } from "./url";

const requestWithAxios = async (options, url, payload, callback) => {
  const urlParams = new URLSearchParams((new URL(url)).search);
  const entries = Object.fromEntries(urlParams.entries());
  const headers = defaults(
    {},
    typeof options.customHeaders === 'function' 
      ? options.customHeaders() 
      : options.customHeaders
  );
  const bundle = {
    bundle: 'builder',
  }
  if (!entries?.to || entries?.to === 'disabled' || (entries?.to || '').match(/^en[-]?\w*$/)) {
    callback(null, {
      status: 200,
      data: {
        [entries?.to]: payload?.origin,
      },
    })
    return
  }
  if (options.queryStringParams) {
    url = addQueryString(url, options.queryStringParams);
  }
  if (payload) headers['Content-Type'] = 'application/json';
  const config = defaults({
    url,
    method: payload ? 'post' : 'get',
    data: payload ? options.stringify({
      ...payload,
      ...entries,
      ...bundle,
    }) : undefined,
    params: payload ? undefined : { ...bundle },
    headers: headers,
    ...typeof options.requestOptions === 'function' 
      ? options.requestOptions(payload) 
      : options.requestOptions,
  })
  const onLoaded = function (response) {
    try {
      const { status, statusText, data } = response
      if (!response.status === 200) return callback(statusText || 'Error', {
        status: status
      });
      if (payload) {
        const translation = {
          [data?.origin]: [data?.translation],
        }
        i18n.addResource(i18n.language, 'translation', data?.origin, data?.translation)
        i18n.reloadResources().then(function(){
          callback(null, {
            status,
            data: translation,
          })
        })
      } else {
        callback(null, {
          status,
          data: (data?.translations || []).reduce((res, tr) => {
            return {
              ...res,
              [tr?.origin]: tr?.translation,
            }
          }, {}),
        })
      }
    } catch (e) {
      console.error(e)
    }
  }
  const response = await axios(config);
  onLoaded(response);
};

i18n
  .use(detector)
  .use(backend)
  .use(initReactI18next)
  .init({
    fallbackLng: false,
    saveMissing: true,
    updateMissing: true,
    load: 'languageOnly',
    debug: process.env.REACT_APP_TRANSLATE_DEBUG === 'true',
    backend: {
      loadPath: `${process.env.REACT_APP_TRANSLATE_URL}?to={{lng}}`,
      addPath: `${process.env.REACT_APP_TRANSLATE_URL}?to={{lng}}`,
      allowMultiLoading: false,
      parsePayload: function(namespace, key) {
        return {
            origin: key,
        }
      },
      requestOptions: {
        mode: 'cors',
        credentials: 'same-origin',
        cache: 'default',
      },
      crossDomain: true,
      withCredentials: true,
      overrideMimeType: false,
      customHeaders: {
        bundle: "builder",
      },
      queryStringParams: {},
      request: (...args) => {
        try {
          requestWithAxios(...args)
        } catch (e) {
          console.error(e)
        }
      },
    },
});

export default i18n;