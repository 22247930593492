import styled from "styled-components";

export const HighlightListItem = styled.li`
  ${({ theme }) => `
    scroll-margin-top: ${theme.headerPadding};

    @keyframes highlight {
      0% {
        background: #fff;
      }
      50% {
        background: #fff4cb;
        box-shadow: 0 0 5px #fff4cb;
      }
      100% {
        background: #fff;
        box-shadow: none;
      }
    }

    &.newly-added {
      .card {
        animation-duration: 1.5s;
        animation-name: highlight;
        animation-timing-function: linear;
      }
    }
  `}
`;