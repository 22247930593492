import styled from "styled-components";

export const NodeBody = styled.div`
    padding: 10px;
    border-radius: 20px;
    min-width: 220px;
    width: 220px;
    font-size: 11px;
    color: #222;
    text-align: left;
    border-width: 1px;
    border-style: solid;
    border-color: #1a192b;
    background-color: white;    

    > .text-info {
        margin-top: 4px;
        margin-bottom: 8px;
    }
`;

export const Chips = styled.div`
    display: flex;
    margin-top: 12px;

    .badge {
        font-weight: normal;
        background-color: transparent!important;
        color: #000;

        &.bg-danger {
            border: 1px solid #DF20CC;
        }

        &.bg-success {
            border: 1px solid #21DE93;
        }

        + .badge {
            margin-left: 4px;
        }
    }
`