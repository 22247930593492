import { useCallback, useState } from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  deleteIdState,
  notificationState,
  pageState,
  pageSizeState,
  useCreateMutation,
  useDestroyMutation,
  useTeamsFetchingQuery,
  useTeamsPagesQuery,
  useTeamsQuery,
  useInfinitePlayers,
} from "./Teams.state";
import { Button, HeaderRow, Row, Col } from "../App.styles";
import { Pagination, SidebarLayout, Spinner } from "../common";
import { Alert, Refresher } from "../common";
import DeleteModal from "./DeleteModal";
import TeamList from "./TeamList";
import Team from "./Team";
import {
  Administrators,
  Banned,
  Blast,
  Coordinators,
  Invitations,
} from "./sidebar-cards";
import TeamsPlaceholder from "./TeamsPlaceholder";
import { withPlaceholder } from "../common";

const TeamHeader = styled.div`
  display: flex;
  justify-content: flex-end;

  input {
    width: 33%;
  }

  @media (max-width: ${({ theme }) => theme.maxMobileWidth}) {
    margin-bottom: 1rem;
  }
`;

const Teams = () => {
  const { t } = useTranslation();
  const { game_id } = useParams();
  const teams = useTeamsQuery(game_id);
  const pages = useTeamsPagesQuery();
  const { players, administrators, coordinators, banned, isLoading: isLoadingPlayers } = useInfinitePlayers();
  const [{ color, message }, setAlert] = useRecoilState(notificationState);
  const [deleteId, setDeleteId] = useRecoilState(deleteIdState);
  const [page, setPage] = useRecoilState(pageState);
  const [pageSize, setPageSizeState] = useRecoilState(pageSizeState);
  const [scrollId, setScrollId] = useState(0);

  const { isCreating, create } = useCreateMutation();
  const { isDeleting, destroy } = useDestroyMutation();
  const isFetching = useTeamsFetchingQuery();
  const setPageSize = pageSize => {
    setPageSizeState(Number(pageSize))
  }
  const onCreate = useCallback(async () => {
    const updatePage = (currentPage) => {
      if (Number(currentPage) !== Number(page)) {
        setPage(currentPage)
      }
    }
    try {
      const { data } = await create();
      updatePage(data?.meta?.page)
      setScrollId(data.team.id);
    } catch (e) {
      if (e.response.status === 402) {
        setAlert({
          color: "danger",
          message:
            t("By default your game can accommodate 1 team. In order to allow more teams to participate, you will need to purchase a license."),
        });
      } else {
        throw e;
      }
    }
  }, [create, setAlert, page, setPage, t]);

  return (
    <>
      <Row>
        <Col size="12">
          <Alert color={color}>{message}</Alert>
        </Col>
      </Row>
      <HeaderRow>
        <TeamHeader className="col-12 col-lg-9">
          <Button color="primary" onClick={onCreate} disabled={isCreating}>
            {t('Add New Team')} {isCreating && <Spinner size="0.875rem" />}
          </Button>
        </TeamHeader>
      </HeaderRow>
      <Row>
        <SidebarLayout
          message={t("Setup teams in advance or change them up during the game.")}
          panel={(
            <>
              <Banned options={players} selected={banned} loading={isLoadingPlayers} />
              <Administrators options={players} selected={administrators} loading={isLoadingPlayers} />
              <Coordinators options={players} selected={coordinators} loading={isLoadingPlayers} />
              <Invitations />
              <Blast />
            </>
          )}
        >
          <TeamList>
            {teams.map((row, index) => (
              <Team
                index={index}
                key={row.id}
                {...row}
                onDelete={setDeleteId}
                game_id={game_id}
                scrollId={scrollId}
              />
            ))}
          </TeamList>
        </SidebarLayout>
      </Row>
      <Row>
        <Pagination
          loading={isFetching}
          page={page}
          pageSize={pageSize}
          pages={pages}
          setPage={setPage}
          setPageSize={setPageSize}
        />
      </Row>
      <DeleteModal
        loading={isDeleting}
        show={!!deleteId}
        onConfirm={destroy}
        onDismiss={() => setDeleteId(0)}
      />
      <Refresher subscriptions="ss.team.create,ss.team.delete,ss.team.update,ss.admin.create,ss.admin.delete,ss.coordinator.create,ss.coordinator.delete,ss.user.ban,ss.user.unban" />
    </>
  );
};

export default withPlaceholder(Teams, TeamsPlaceholder);
