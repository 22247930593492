import { useTranslation } from "react-i18next";
import { FormAutocompleteMultiple } from "../../common/FormAutocomplete";
import {
  useAddAdministratorMutation,
  useRemoveAdministratorMutation,
} from "../Teams.state";
import { useUserQuery } from "../../monitor/User.state";
import { CardBody } from "../../App.styles";
import { Shimmer } from "../../common/Loading.styles";

const Administrators = props => {
  const { options: players, selected: administrators, loading } = props
  const { t } = useTranslation();
  const { addAdministrator } = useAddAdministratorMutation();
  const { removeAdministrator } = useRemoveAdministratorMutation();
  const user = useUserQuery();

  const handleChange = async ({ target }) => {
    const { value } = target;

    for (let player of value.filter(
      (v) => !administrators.find((a) => a.id === v.id)
    )) {
      await addAdministrator(player);
    }

    for (let player of administrators.filter(
      (a) => !value.find((v) => a.id === v.id)
    )) {
      await removeAdministrator(player);
    }
  };

  return (
    <CardBody className="card">
      <div className="card-header bg-dark text-light">
        <span className="card-title">{t('Administrators')}</span>
      </div>
      <div className="card-body">
        <p dangerouslySetInnerHTML={{__html: t(`Want someone else to help manage? Add them here to give them full
          access.`)}} />
          {
            loading
            ? (
              <>
                <Shimmer dark type="title" />
                <Shimmer dark />
              </>
            ) 
            : (
              <FormAutocompleteMultiple
                options={players}
                mapLabel="email"
                disableClearable={true}
                size="small"
                placeholder={t("Select people...")}
                onChange={(e, value) => {
                  handleChange({
                    target: {
                      value,
                    }
                  })
                }}
                value={
                  user.game.creator && !administrators.find((a) => a.id === user.id)
                    ? administrators.concat(user)
                    : administrators
                }
                stateful={false}
              />
            )
          }
      </div>
    </CardBody>
  );
};

export default Administrators;
