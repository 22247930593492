import { getTimeZones } from "@vvo/tzdb";
import timezoneConversions from "./timezone-conversions.json";
import timezoneCompatibility from "./timezone-compatibility.json";

const mapTimezones = {
    ...timezoneConversions,
    ...timezoneCompatibility,
}

// new options are in a format of e.g. { "id": "Pacific Time (US & Canada)", "name": "Pacific Time (US & Canada) (GMT-07:00)" }
// retrieved from API/timezones.json.
const timezonesTable = getTimeZones()
export const backwardCompatibleTimezone = value => ({ id }) => {
    const maybeOldTimezone = timezoneCompatibility[value]
    const groupFallback = timezonesTable.find(({ group }) => group.includes(maybeOldTimezone))?.name
    const oldToNewValue = Object.entries(timezoneConversions).find(([, v]) => {
        return [
            maybeOldTimezone,
            groupFallback,
            value,
        ].includes(v)
    })?.[0]
    return (oldToNewValue || value) === id
}

export default mapTimezones