import { Card } from "@material-ui/core";
import { MDBCardBody, MDBCardHeader, MDBCardTitle } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const TermsAndCondition = () => {
  const { t } = useTranslation()
  return (
    <Card>
      <MDBCardHeader>
        <MDBCardTitle><span dangerouslySetInnerHTML={{__html: t(`Terms of Use`)}} /></MDBCardTitle>
      </MDBCardHeader>
      <MDBCardBody>
        <p dangerouslySetInnerHTML={{__html: t(`These Terms of Use apply to the websites and mobile applications owned
          and operated by Mandown Group Inc. (doing business as and referred to
          herein as “Social Scavenger”). The websites and mobile applications
          owned and operated by Social Scavenger are referred to herein
          collectively as the “Service”. Social Scavenger is a Canadian company
          with a registered office at 147 Liberty St. West, Toronto, Ontario,
          M6K 3G3, Canada. When used herein the terms “we”, “us”, and “our”
          refer to Social Scavenger.`)}} />

        <p>
          <span dangerouslySetInnerHTML={{__html: t(`By accessing and using the Service you agree to be bound by these
          Terms of Use. Specific rules may apply to certain sections or elements
          of the Service, such as a specific mobile application, and you hereby
          agree to be bound by such rules in addition to these Terms of Use.
          Your access and use of the Service is also subject to our`)}} />{" "}
          <Link to="/privacy">{t(`Privacy Policy`)}</Link>.{" "}
        </p>

        <p dangerouslySetInnerHTML={{__html: t(`We reserve the right, at any time, to modify, alter, or update these
          Terms of Use. We will notify you of material changes by a notice on
          socialscavenger.com prior to such material change becoming effective.
          Your continued use of the Service following such notification shall
          constitute your acceptance of the revised Terms of Use. If you do not
          agree to any such changes you must immediately cease using the
          Service. We encourage you to periodically review this page for the
          latest information on our Terms of Use.`)}} />

        <p>
          <strong dangerouslySetInnerHTML={{__html: t(`Registration`)}} />
        </p>
        <p>
          <span dangerouslySetInnerHTML={{__html: t(`You agree to provide true, accurate, current and complete information
          about yourself as prompted by any registration, application or
          enrolment form utilized by the Service. You must maintain and promptly
          update the information you provide to keep it true, accurate, current
          and complete.`)}} />
          <br />
          <span dangerouslySetInnerHTML={{__html: t(`You represent and warrant that you are at least 18 years old or that
          if you are under 18 years old, your use of the Service is with the
          consent of your parent or legal guardian.`)}} />
          <br />
          <span dangerouslySetInnerHTML={{__html: t(`You may register for the Service only through email or social channels
          made available on sign in. When you register through a third party
          service your login credentials will be used to open and access your
          account with us.`)}} />
          <br />
          <span dangerouslySetInnerHTML={{__html: t(`You are responsible for keeping your social login credentials
          confidential. We are entitled to act on all instructions received by
          anyone using your account and are not responsible for any transaction
          made without your authorization.`)}} />
          <br />
          <span dangerouslySetInnerHTML={{__html: t(`You consent to receive communications from us electronically. We will
          communicate with you by e-mail. You agree that all agreements,
          notices, disclosures and other communications that we provide to you
          electronically satisfy any legal requirement that such communications
          be in writing.`)}} />
        </p>

        <p>
          <strong dangerouslySetInnerHTML={{__html: t(`Your Submissions`)}} />
        </p>
        <p>
          <span dangerouslySetInnerHTML={{__html: t(`For purposes of these Terms of Use, the term “Content” includes,
          without limitation, any location information, videos, audio clips,
          comments, information, data, text, photographs, software, scripts,
          graphics, and interactive features generated, provided, or otherwise
          made accessible by on or through the Service. Content added, created,
          uploaded, submitted, distributed, or posted to the Service by you is
          collectively referred to as, your “submissions”. We may use your
          submissions in a number of different ways in connection with the
          Service, including but not limited to, publicly displaying them,
          reformatting them, incorporating them into marketing materials,
          advertisements and other works, creating derivative works from them,
          promoting them, distributing them, and allowing other users to do the
          same in connection with their own websites, media platforms, and
          applications (“Third Party Media”). By making submissions through the
          Service, you hereby grant and agree to grant to us a worldwide,
          non-exclusive, royalty-free, fully paid and transferable license to
          use, copy, edit, modify, reproduce, distribute, sublicense, prepare
          derivative works of, display, perform, and otherwise fully exploit
          your submissions in connection with the Service and our business,
          including without limitation for promoting and redistributing part or
          all of the the Service in any media formats and through any media
          channels (including, without limitation, third party websites and
          feeds). You also hereby grant and agree to grant to each user of the
          Service, including Third Party Media, a non-exclusive license to
          access your submissions, and to use, edit, modify, reproduce,
          distribute, prepare derivative works of, display and perform such
          submissions in connection with their use of the Service and Third
          Party Media.`)}} />
          <br />
          <span dangerouslySetInnerHTML={{__html: t(`You represent and warrant that you have all rights to grant such
          license to us without infringement or violation of any third party
          rights, including without limitation, any privacy rights, publicity
          rights, copyrights, contract rights, or any other intellectual
          property or proprietary rights.`)}} />
          <br />
          <span dangerouslySetInnerHTML={{__html: t(`You understand that all information publicly posted or privately
          transmitted through the Service is the sole responsibility of the
          person from which such Content originated; that we will not be liable
          for any errors or omissions in any Content; and that we cannot
          guarantee the identity of any other users with whom you may interact
          in the course of using the Service.`)}} />
        </p>

        <p>
          <strong dangerouslySetInnerHTML={{__html: t(`Prohibited Conduct`)}} />
        </p>
        <p dangerouslySetInnerHTML={{__html: t(`You shall not upload, download, post, submit or otherwise distribute
          or facilitate distribution of any Content on or through the Service
          that:`)}} />
        <ul style={{ listStyle: "disc" }}>
          <li dangerouslySetInnerHTML={{__html: t(`infringes any patent, trademark, trade secret, copyright, right of
            publicity or other right of any other person or entity or violates
            any law or contractual duty;`)}} />
          <li dangerouslySetInnerHTML={{__html: t(`is unlawful, threatening, abusive, harassing, defamatory, libelous,
            deceptive, fraudulent, invasive of another's privacy, tortious,
            obscene, vulgar, pornographic, offensive, profane, contains or
            depicts nudity, contains or depicts sexual activity, promotes
            bigotry, discrimination or violence, or is otherwise inappropriate
            as determined by us in our sole discretion;`)}} />
          <li dangerouslySetInnerHTML={{__html: t(`constitutes unauthorized or unsolicited advertising, junk or bulk
            e-mail;`)}} />
          <li dangerouslySetInnerHTML={{__html: t(`involves commercial activities (whether or not for profit) and/or
            sales without our prior written consent such as contests,
            sweepstakes, barter, advertising, or pyramid schemes;`)}} />
          <li dangerouslySetInnerHTML={{__html: t(`contains software viruses or any other computer codes, files, worms,
            logic bombs or programs that are designed or intended to disrupt,
            disable, damage, limit or interfere with the proper function of any
            software, hardware or telecommunications equipment or to damage or
            obtain unauthorized access to any system, data, password or other
            information;`)}} />
          <li dangerouslySetInnerHTML={{__html: t(`impersonates any entity;`)}} />
          <li dangerouslySetInnerHTML={{__html: t(`includes anyone's identification documents or sensitive financial
            information; and`)}} />
          <li dangerouslySetInnerHTML={{__html: t(`breaches our Privacy Policy and/or any of the other policies and
            rules incorporated herein.`)}} />
        </ul>
        <p dangerouslySetInnerHTML={{__html: t(`You shall not:`)}} />
        <ul style={{ listStyle: "disc" }}>
          <li dangerouslySetInnerHTML={{__html: t(`take any action that imposes or may impose (as determined by us in
            our sole discretion) an unreasonable or disproportionately large
            load on our infrastructure;`)}} />
          <li dangerouslySetInnerHTML={{__html: t(`interfere or attempt to interfere with the proper working of the
            Service or any activities conducted on the Service;`)}} />
          <li dangerouslySetInnerHTML={{__html: t(`bypass any measures we may use to prevent or restrict access to the
            Service (or other accounts, computer systems or networks connected
            to the Service);`)}} />
          <li dangerouslySetInnerHTML={{__html: t(`run any form of auto-responder or “spam” on the Service;`)}} />
          <li dangerouslySetInnerHTML={{__html: t(`use manual or automated software, devices, or other processes to
            “crawl” or “spider” any page of the Service;`)}} />
          <li dangerouslySetInnerHTML={{__html: t(`harvest or scrape any Content from the Service;`)}} />
          <li dangerouslySetInnerHTML={{__html: t(`modify, adapt, appropriate, reproduce, distribute, translate, create
            derivative works or adaptations of, publicly display, republish,
            repurpose, sell, trade, or in any way exploit the Service or Content
            located on the Service;`)}} />
          <li dangerouslySetInnerHTML={{__html: t(`decipher, decompile, disassemble, reverse engineer or otherwise
            attempt to derive any source code or underlying ideas or algorithms
            of any part of the Service (including without limitation any
            application or widget);`)}} />
          <li dangerouslySetInnerHTML={{__html: t(`copy, rent, lease, distribute, or otherwise transfer any of the
            rights that you receive hereunder; and`)}} />
          <li dangerouslySetInnerHTML={{__html: t(`otherwise take any action in violation of our guidelines and
            policies.`)}} />
        </ul>

        <p>
          <strong dangerouslySetInnerHTML={{__html: t(`Ownership and Intellectual Property`)}} />
        </p>
        <p>
          <span dangerouslySetInnerHTML={{__html: t(`The Service and all Content displayed or otherwise accessible through
          the Service, other than your submissions, are owned or licensed by us
          and are protected under Canadian and foreign copyright laws and
          referred to herein as our Content.`)}} />
          <br />
          <span dangerouslySetInnerHTML={{__html: t(`You are not granted any right, title, interest or other license in or
          to our Content. You agree not to reproduce, make derivative works of,
          retransmit, distribute, sell, publish, communicate, broadcast or
          otherwise make available any of our Content, including without
          limitation, by caching, framing, deep-linking or similar means,
          without our prior written consent.`)}} />
          <br />
          <span dangerouslySetInnerHTML={{__html: t(`Social Scavenger is a trademark of Mandown Group Inc. Other names,
          words, titles, phrases, logos, designs, graphics, icons and trademarks
          displayed on or through the Service may be registered or unregistered
          trademarks of third parties. Any use of any trademarks appearing on or
          through the Service without the express written consent of its owner
          of strictly prohibited.`)}} />
        </p>

        <p>
          <strong dangerouslySetInnerHTML={{__html: t(`Termination or Suspension of Service`)}} />
        </p>
        <p dangerouslySetInnerHTML={{__html: t(`We may at any time, without notice, in our sole discretion and without
          cause, terminate and/or suspend your right to access or use the
          Service (or any portion thereof), including for reason of your breach
          of these Terms of Use.`)}} />

        <p>
          <strong dangerouslySetInnerHTML={{__html: t(`Assumption of Risk and Release`)}} />
        </p>
        <p dangerouslySetInnerHTML={{__html: t(`YOU ACKNOWLEDGE AND AGREE THAT YOUR USE OF THE SERVICE IS ENTIRELY AT
          YOUR OWN RISK. THE GAMES PROVIDED BY US THROUGH THE SERVICE CONTAIN
          CERTAIN CHALLENGES THAT YOU MUST COMPLETE IN ORDER TO PARTICIPATE. IN
          SOME CASES THE CHALLENGES ARE SUBMITTED BY USERS AND THIRD PARTIES AND
          WE DO NOT EXERCISE EDITORIAL CONTROL OVER THEIR SUBMISSIONS. BY
          PARTICIPATING IN A GAME THAT WE OFFER, YOU ACCEPT ALL RISKS AND
          RESPONSIBILITIES ASSOCIATED WITH COMPLETING ANY CHALLENGE. DO NOT
          PARTICIPATE IN ANY CHALLENGE THAT MAY IN ANY WAY CREATE A RISK OF
          INJURY, DEATH OR PROPERTY DAMAGE. YOU HEREBY RELEASE, DISCHARGE AND
          COVENANT NOT TO SUE SOCIAL SCAVENGER, OR ANY OF ITS DIRECTORS,
          OFFICERS, EMPLOYEES, AGENTS, REPRESENTATIVES, CONTRACTORS OR
          SUPPLIERS, IN CONNECTION WITH ANY INJURY TO THE PERSON, PROPERTY
          DAMAGE OR DEATH OF ANY PERSON ARISING OUT OF OR RELATED TO YOUR
          PARTICIPATION IN A GAME OR CHALLENGE OFFERED BY SOCIAL SCAVENGER.`)}} />

        <p>
          <strong dangerouslySetInnerHTML={{__html: t(`Disclaimer`)}} />
        </p>
        <p dangerouslySetInnerHTML={{__html: t(`THE SERVICE AND ALL CONTENT MADE AVAILABLE THROUGH THE SERVICE IS
          PROVIDED “AS IS” WITHOUT WARRANTY, REPRESENTATION, CONDITION OR
          GUARANTEE OF ANY KIND, EITHER EXPRESSED OR IMPLIED, INCLUDING BUT NOT
          LIMITED TO ANY IMPLIED WARRANTIES, REPRESENTATIONS, CONDITIONS OR
          GUARANTEES OF QUALITY, MERCHANTABILITY, MERCHANTABLE QUALITY, FITNESS
          FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT, ALL OF WHICH ARE
          DISCLAIMED TO THE FULLEST EXTENT PERMITTED BY LAW.`)}} />
        <p>
          <strong dangerouslySetInnerHTML={{__html: t(`Limitation of Liability`)}} />
        </p>
        <p dangerouslySetInnerHTML={{__html: t(`IN NO EVENT SHALL SOCIAL SCAVENGER, OR ANY OF ITS DIRECTORS, OFFICERS,
          EMPLOYEES, AGENTS, REPRESENTATIVES, CONTRACTORS OR SUPPLIERS, BE
          LIABLE TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE,
          OR CONSEQUENTIAL DAMAGES WHATSOEVER RESULTING FROM (I) ANY ERRORS,
          MISTAKES, OR INACCURACIES OF CONTENT, (II) ANY UNAUTHORIZED ACCESS TO
          OR USE OF OUR SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION STORED
          THEREIN, (III) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR
          FROM OUR SERVICE, (IV) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE,
          WHICH MAY BE TRANSMITTED TO OR THROUGH OUR SERVICE BY ANY THIRD PARTY,
          AND/OR (V) YOUR USE OF ANY CONTENT POSTED, EMAILED, TRANSMITTED, OR
          OTHERWISE MADE AVAILABLE VIA THE SERVICE, WHETHER SUCH CLAIM IS BASED
          ON WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY, AND WHETHER OR
          NOT WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE
          FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT
          PERMITTED BY APPLICABLE LAW.`)}} />

        <p>
          <strong dangerouslySetInnerHTML={{__html: t(`Indemnification`)}} />
        </p>
        <p dangerouslySetInnerHTML={{__html: t(`To the extent permitted by applicable law, you agree to defend,
          indemnify and hold harmless Social Scavenger, its officers, directors,
          employees and agents, from and against any and all claims, damages,
          obligations, losses, liabilities, costs or debt, and expenses
          (including but not limited to attorney's fees) arising from: (i) your
          use of and access to the Service; (ii) your violation of any term of
          these Terms of Use; (iii) your violation of any third party right,
          including without limitation any copyright, property, or privacy
          right; or (iv) any claim that Content caused damage to a third party.
          This defense and indemnification obligation will survive these Terms
          of Use and your use of the Service.`)}} />

        <p>
          <strong dangerouslySetInnerHTML={{__html: t(`Third-Party Sites`)}} />
        </p>
        <p dangerouslySetInnerHTML={{__html: t(`Any third-party websites linked to or from the Service may have
          different terms and conditions. We have no control over such
          third-party websites. You should carefully review these other
          websites' terms and conditions in order to determine how each website
          may use any personal information you provide. These Terms of Use are
          no longer applicable when you leave our Service by way of link. If you
          decide to access or use any linked website or content, materials,
          software, goods or services from a linked website, you do so entirely
          at your own risk.`)}} />

        <p>
          <strong dangerouslySetInnerHTML={{__html: t(`Governing law and Disputes`)}} />
        </p>
        <p dangerouslySetInnerHTML={{__html: t(`These Terms of Use and your use of the Service is governed exclusively
          by the laws of the Province of Ontario, Canada notwithstanding any
          principles of conflicts of law. To the extent permitted by applicable
          law, any claim, dispute or controversy between you and us shall be
          determined by final and binding arbitration, with no right of appeal,
          even on questions of law. Such arbitration will be conducted in
          Toronto, Ontario, Canada in the English language pursuant to the
          National Arbitration Rules of the ADR Institute of Canada. If the
          foregoing arbitration is not permitted by applicable law or any court
          with jurisdiction, you irrevocably agree that all claims, disputes or
          controversies shall be settled exclusively by the courts of the
          Province of Ontario.`)}} />

        <p>
          <strong dangerouslySetInnerHTML={{__html: t(`Entire Agreement, Waiver and Severability`)}} />
        </p>
        <p dangerouslySetInnerHTML={{__html: t(`This Agreement, together with any applicable transaction request and
          confirmation, constitute the entire agreement between you and us with
          respect to your use of the Service and supersede all prior or
          contemporaneous agreements or understandings, if any, whether written
          or oral, relating to such subject matter. No modification, amendment
          or waiver of these Terms of Use shall be effective or binding unless
          made in writing and signed by us. If any of the provisions of this
          Agreement shall be deemed invalid, void, or for any reason
          unenforceable, that provision shall be deemed severable and shall not
          affect the validity and enforceability of any remaining provision.`)}} />

        <p dangerouslySetInnerHTML={{__html: t(`These Terms of Use were last updated on July 1st, 2019`)}} />

        <p dangerouslySetInnerHTML={{__html: t(`All rights reserved. Copyright © 2022. Unauthorized distribution,
          transmission or republication strictly prohibited.`)}} />
      </MDBCardBody>
    </Card>
  );
};

export default TermsAndCondition;
