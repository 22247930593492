import React, { useEffect, useState } from "react";
import {
  MDBCardHeader,
  MDBCardTitle,
} from "mdb-react-ui-kit";
import { Select, MenuItem } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Input } from "../common/Input";
import { Spinner } from "../common";
import { Col } from "../App.styles";
import TeamChip from "./TeamChip";
import GroupCardAction from "./GroupCardAction";
import { HeadRow, AddButton, AddBody, GroupViewBody, GroupViewContent } from "./GroupView.style";

const AddToTeam = ({ toggles, onToggle, loading, children }) => {
  return (
    <>
      {
        toggles.addToTeam
          ? <AddBody>{children}</AddBody>
          : null
      }
      <AddButton disabled={loading} onClick={() => onToggle('addToTeam')}>
        {
          loading
            ? <Spinner size={20} />
            : <p>{toggles.addToTeam ? "-" : "+"}</p>
        }
      </AddButton>
    </>
  );
};

export default function GroupView(props) {
  const { t } = useTranslation()
  const { 
    id: groupId, 
    label: name, 
    teams, 
    onEdit, 
    onClose, 
    onToggle,
    toggles,
    editMode, 
    deleteSection, 
    mutations, 
    queries, 
  } = props
  const { game_id } = useParams();
  const teamsQuery = queries.teams;
  const [teamDifference, setTeamDifference] = useState([]);
  const [groupName, setGroupName] = useState(name);
  const { isFetching } = queries.fetching
  const { update, isUpdating } = mutations.update;
  const { destroy, isDestroying } = mutations.destroy;

  const updateGroupName = params => {
    update(params)
  }

  useEffect(() => {
    let difference = teamsQuery.filter(
      (team) => !teams.some((team2) => team2.id === team.id)
    );
    setTeamDifference(difference);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teams]);

  const addTeamToGroup = (teamId) => {
    onToggle('addToTeam')
    update({
      groupId,
      team_id: teamId,
      teams: [
        ...teams,
        teamsQuery.find(({ id }) => id === teamId),
      ],
    })
  };

  const removeTeamFromGroup = (id) => {
    destroy({
      groupId,
      team_id: id,
      game_id: game_id,
    })
  };

  return (
    <>
      <>
        <MDBCardHeader>
          <HeadRow>
            {!editMode ? (
              <Col>
                <MDBCardTitle>{groupName}</MDBCardTitle>
              </Col>
            ) : (
              <>
                <Col size="9" sm="10">
                  <Input
                    name="name"
                    label={t("Group Name")}
                    value={groupName}
                    onChange={(e) => setGroupName(e)}
                    onBlurExtra={() => updateGroupName({
                      groupId,
                      label: groupName,
                    })}
                  />
                </Col>
                <Col size="3" sm="2">
                  { teams.length > 0 ? null : deleteSection }
                </Col>
              </>
            )}
          </HeadRow>
        </MDBCardHeader>
        <GroupViewBody>
          <GroupViewContent>
            {teams.map((team, index) => {
              return (
                <TeamChip
                  key={index}
                  thumbnail={team.assets.photos[0].url}
                  name={team.name}
                  editMode={editMode}
                  onDelete={() => removeTeamFromGroup(team.id)}
                  loading={isDestroying || isFetching}
                />
              );
            })}
            {
              teamDifference.length > 0 && editMode ? (
                <AddToTeam
                  toggles={toggles}
                  onToggle={onToggle}
                  loading={isUpdating || isFetching}
                >
                  <div>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label={t("Age")}
                      style={{ width: "200px" }}
                      value=""
                      onChange={(e) => addTeamToGroup(e.target.value)}
                    >
                      {teamDifference.map((team, index) => {
                        return (
                          <MenuItem key={index} value={team.id}>
                            {team.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </div>
                </AddToTeam>
              ) : null
            }
          </GroupViewContent>
          <GroupCardAction editMode={editMode} onEdit={onEdit} onClose={onClose} />
        </GroupViewBody>
      </>
    </>
  );
}
