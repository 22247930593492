import styled from "styled-components";

export const InfoWrapper = styled.div(() => `
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
`)

export const InfoItem = styled.div(() => `
    display: flex;
    flex-direction: column;
    align-items: center;
`)