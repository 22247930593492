import { useTranslation } from "react-i18next";
import { NewVideoUpload, RowInfo } from "../../common";
import { BackgroundModal } from "../modals";

const BackgroundVideo = ({ useQuery }) => {
  const { t } = useTranslation();

  return (
    <>
      <p dangerouslySetInnerHTML={{__html: t(`Require the player to submit a video. <br>An AI will remove the background and swap in the provided background.`)}} />
      <RowInfo help={<BackgroundModal />}>
        <NewVideoUpload
            label={t("Background Video")}
            name="overlay"
            useQuery={useQuery}
          />
      </RowInfo>
    </>
  );
};

export default BackgroundVideo;
