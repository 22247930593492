import { useTranslation } from "react-i18next";
import { Modal } from "../../common";
import { Button } from "../../App.styles";

const TimeToCompleteModal = ({ onDismiss, show }) => {
  const { t } = useTranslation();

  return (
    <Modal onDismiss={onDismiss} show={show}>
      <div className="modal-header">
        <h4 className="modal-title">{t(`Time to Complete`)}</h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-hidden="true"
          onClick={onDismiss}
        >
          ×
        </button>
      </div>
      <div className="modal-body">
        <p>
          {t(`If you want your game to be played as a race, and yet have someone join at any time, 
          specify how much time each team should be given to complete their challenges. 
          The timer for each team starts ticking when they complete their first challenge.`)}
        </p>
        <ul>
          <li>
            {t(`Time to complete is always optional. If you leave this field blank, players can 
            start anytime within the scheduled start and finish times and have no time limits as to 
            when their game ends. If it is set, it works together with the scheduled start and finish 
            times to further limit the time each team has to finish.`)}
          </li>
          <li dangerouslySetInnerHTML={{__html: t(`Common settings are '' (blank, meaning no 
          restrictions), '2 hours' or '1 day'.`)}} />
          <li dangerouslySetInnerHTML={{__html: t(`If this value exceeds the time between the 
          scheduled start and finish then the schedule is the limiting factor.`)}} />
          <li dangerouslySetInnerHTML={{__html: t(`If this value is shorter than the time between 
          the scheduled start and finish then this is the limiting factor.`)}} />
        </ul>
      </div>
      <div className="modal-footer">
        <Button color="dark" data-dismiss="modal" onClick={onDismiss}>
          {t(`Close`)}
        </Button>
      </div>
    </Modal>
  )
};

export default TimeToCompleteModal;
