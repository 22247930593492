import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { atom, useRecoilValue } from "recoil";
import FacebookPixel from "react-facebook-pixel";
import { axios } from "../lib/request";
import {
  challengesQueryKey,
  gameUri,
  libraryQueryKey,
  libraryUri,
  tagsAutocompleteQueryKey,
} from "../lib/refresh";
import { MAX_PER_PAGE } from "../consts/request";

export const pageState = atom({
  key: "tasks/library/page",
  default: 1,
});

export const pageSizeState = atom({
  key: "tasks/library/pageSize",
  default: MAX_PER_PAGE,
});

export const termsState = atom({
  key: "tasks/library/terms",
  default: [],
});

export const fetchLibrary = async (game_id, page, per_page, terms = []) => {
  if (terms.length === 0) {
    return { pages: 1, tasks: [] };
  }

  const { data } = await axios.get(libraryUri(game_id), {
    params: { page, per_page, terms },
  });
  return data;
};

export const fetchTags = async () => {
  const { data } = await axios.get(process.env.REACT_APP_API_TAGS_URL);
  return data.map((tag) => tag.label);
};

export const useLibraryDataQuery = () => {
  const { game_id } = useParams();
  const page = useRecoilValue(pageState);
  const pageSize = useRecoilValue(pageSizeState);
  const terms = useRecoilValue(termsState);

  return useQuery(
    libraryQueryKey(game_id, page, pageSize, terms),
    () => fetchLibrary(game_id, page, pageSize, terms),
    { suspense: true }
  );
};

export const useLibraryQuery = () => {
  const { game_id } = useParams();
  const { data } = useLibraryDataQuery(game_id);
  return data.tasks;
};

export const useLibraryFetchingQuery = () => {
  const { game_id } = useParams();
  const { isFetching } = useLibraryDataQuery(game_id);
  return isFetching;
};

export const useLibraryPagesQuery = () => {
  const { game_id } = useParams();
  const { data } = useLibraryDataQuery(game_id);
  return data.meta ? data.meta.pages : data.pages;
};

export const useChallengeQuery = (id) => {
  const { game_id } = useParams();
  const { data } = useLibraryDataQuery(game_id);
  return data.tasks.find((challenge) => challenge.id === id);
};

export const useAutocompleteQuery = () => {
  const { data } = useQuery(tagsAutocompleteQueryKey(), fetchTags, {
    suspense: false,
  });
  return data;
};

export const usePayMutation = () => {
  const { game_id } = useParams();
  const queryClient = useQueryClient();
  const uri = gameUri(game_id);

  const { isLoading, mutateAsync } = useMutation(
    (stripe_id) =>
      axios.patch(
        `${process.env.REACT_APP_API_LIBRARY_PAY_URL}/${game_id}/library.json`,
        { stripe_id, cost: 299 }
      ),
    {
      onError: (error) => console.log("[error]", error),
      onSuccess: () => {
        queryClient.invalidateQueries(uri);
        FacebookPixel.track("Purchase");
      },
    }
  );

  return { isPaying: isLoading, pay: mutateAsync };
};

export const useAddMutation = (task_id) => {
  const { game_id } = useParams();
  const queryClient = useQueryClient();

  return useMutation(
    () =>
      axios.post(
        `${process.env.REACT_APP_API_LIBRARY_ADD_URL}/${task_id}/copy/${game_id}.json`
      ),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(challengesQueryKey(game_id)),
    }
  );
};
