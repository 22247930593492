import styled from "styled-components";
import {
  MDBDropdownToggle,
  MDBDropdownItem,
  MDBDropdown,
  MDBDropdownLink,
  MDBDropdownMenu,
} from "mdb-react-ui-kit";

const btnHeight = '36px';
export const DropdownToggle = styled(MDBDropdownToggle)`
  ${({ theme }) => `
    white-space: nowrap;
    font-weight: 400;
    cursor: pointer;
    display: flex;
    align-items: center;  

    &&& {
      color: var(--mdb-white);
    }

    &:after {
      display: none;
    }

    .dropdown-item {
      width: auto;
      color: ${theme.darkColor};
      background: ${theme.white};
      border-radius: ${theme.rounded.md};
      text-transform: uppercase;

      &:hover,
      &:focus {
        background-color: ${theme.white};
      }
    }
  `}
`;

export const DropdownLink = styled(MDBDropdownLink)`
  display: flex;
  align-items: center;
  height: ${btnHeight};
  padding: .5rem;
  text-transform: uppercase;

  svg {
    display: inline-block;
    margin-right: .5rem;
  }
`;

export const DropdownItem = styled(MDBDropdownItem)`
  cursor: pointer;
  display: flex;
  align-items: center;
  text-transform: uppercase;

  a {
    color: ${({ theme }) => theme.linkButtonColor};
  }

  &:not(:hover) {
    color: var(--mdb-light);
  }

  svg {
    display: inline-block;
    margin-right: .5rem;
  }
`;

export const Dropdown = styled(MDBDropdown)`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding-right: 1rem;

  .dropdown-toggle {
    padding-bottom: 0;
  }
`;

export const DropdownMenu = styled(MDBDropdownMenu)`
  min-width: 0;
  max-height: 300px;
  overflow-y: auto;
  box-shadow: none;
  margin-bottom: -${btnHeight};

  &[data-popper-placement="bottom-start"] {
    margin-bottom: 0;
    margin-top: -${btnHeight};
  }
`;

export const BrandWrapper = styled.div`
  display: flex;
  align-items: center;
`;