import { useParams } from "react-router-dom";
import { useDecision } from "@optimizely/react-sdk";
import { useTranslation } from "react-i18next";
import { RadioGroup } from "../../common";
import {
  Photo,
  Video,
  Trivia,
  MultipleChoice,
  StickerPhoto,
  Text,
  QRCode,
  AugmentedRealityPhoto,
  AugmentedRealityVideo,
  BackgroundPhoto,
  BackgroundVideo,
  SplitPath,
} from "../task-types";
import { useQueryNoInvalidate, useQueryInvalidateInfinite } from "../Organize.state";

const TypeSection = ({ useQuery, id: challengeId }) => {
  const { t } = useTranslation();
  const { game_id } = useParams();
  const [{ id, realities, task_type }] = useQuery();
  const useQueryNoInvalidate_ = () => useQueryNoInvalidate(challengeId)
  const useQueryInvalidateInfinite_ = () => useQueryInvalidateInfinite(challengeId)
  const [decisionAr] = useDecision('ar')

  return (
    <form id={`form-${id}`} onSubmit={e => e.preventDefault()}>
      <RadioGroup
        className="radio--block"
        defaultValue={task_type}
        label="Type of Completion"
        name="task_type"
        options={[
          { value: 1, label: t("Photo"), children: <Photo useQuery={useQueryNoInvalidate_} /> },
          { value: 2, label: t("Video"), children: <Video useQuery={useQueryNoInvalidate_} /> },
          { value: 5, label: t("Trivia"), children: <Trivia useQuery={useQueryNoInvalidate_} /> },
          { value: 6, label: t("Multiple Choice"), children: <MultipleChoice useQuery={useQueryNoInvalidate_} /> },
          { value: 3, label: t("Sticker + Photo"), children: <StickerPhoto useQuery={useQueryNoInvalidate_} /> },
          { value: 0, label: t("Text"), children: <Text useQuery={useQueryNoInvalidate_} /> },
          { value: 4, label: t("QR Code"), children: <QRCode useQuery={useQueryNoInvalidate_} game_id={game_id} id={id} /> },
          ...decisionAr?.enabled
            ? [
              { value: 7, label: "Augmented Reality + Photo", children: (
                <AugmentedRealityPhoto
                  realities={realities}
                  task_id={id}
                  useQuery={useQueryNoInvalidate_}
                />
              ) },
              { value: 8, label: "Augmented Reality + Video", children: (
                <AugmentedRealityVideo
                  realities={realities}
                  task_id={id}
                  useQuery={useQueryNoInvalidate_}
                />
              ) },
            ] : [],
          { value: 9, label: t("Background + Photo"), children: <BackgroundPhoto useQuery={useQueryNoInvalidate_} /> },
          { value: 10, label: t("Background + Video"), children: <BackgroundVideo useQuery={useQueryNoInvalidate_} /> },
          { value: 11, label: t("Split Path"), children: <SplitPath useQuery={useQueryInvalidateInfinite_} /> },
        ]}
        useQuery={useQueryInvalidateInfinite_}
        scrollToTop
      />
    </form>
  );
};

export default TypeSection;