import { atom, useRecoilState, useRecoilValue } from "recoil";
import { urlSyncEffect } from "recoil-sync";
import { number } from "@recoiljs/refine";
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { axios } from "../lib/request";
import { teamsQueryKey } from "../lib/refresh";
import { useItemQuery } from "../Global.state";
import { INFINITE_QUERY_KEY, MAX_PER_PAGE } from "../consts/request";
import { useEffect } from "react";

export const deleteIdState = atom({
  key: "teams/deleteId",
  default: 0,
});

export const notificationState = atom({
  key: "notification",
  default: {},
});

export const pageState = atom({
  key: "teams/page",
  default: 1,
  effects: [
    urlSyncEffect({
      refine: number(),
      history: 'push'
    })
  ],
});

export const pageSizeState = atom({
  key: "teams/pageSize",
  default: MAX_PER_PAGE,
  effects: [
    urlSyncEffect({
      refine: number(),
      history: 'push'
    })
  ],
});

export const fetchTeams = async (game_id, page, per_page) => {
  if (game_id === "new") {
    return { teams: [] };
  }

  const response = await axios({
    url: process.env.REACT_APP_API_TEAM_RETRIEVE_URL,
    params: { game_id, page, per_page },
  });

  return response.data;
};

export const fetchPlayers = async (game_id, page = 1, per_page = MAX_PER_PAGE) => {
  return fetchTeams(game_id, page, per_page);
};

export const updateTeam = async (game_id, id, params) => {
  const url = `${process.env.REACT_APP_API_TEAM_UPDATE_URL}/${id}.json`;
  return await axios.put(url, { ...params, game_id });
};

export const useTeamsDataQuery = (game_id) => {
  const page = useRecoilValue(pageState);
  const pageSize = useRecoilValue(pageSizeState);
  const queryKey = useQueryKey(game_id);

  return useQuery(queryKey, () => fetchTeams(game_id, page, pageSize), {
    suspense: true,
  });
};

export const useAllTeamsQuery = () => {
  const { game_id } = useParams();

  const { data } = useQuery(
    teamsQueryKey(game_id, 1, 'allteams'),
    () => fetchTeams(game_id, 1, MAX_PER_PAGE),
    { suspense: true }
  );

  return data.teams;
};

const useQueryKey = (game_id) => {
  const page = useRecoilValue(pageState);
  const pageSize = useRecoilValue(pageSizeState);
  return teamsQueryKey(game_id, page, pageSize);
};

export const useTeamsQuery = (game_id) => {
  const { data } = useTeamsDataQuery(game_id);
  return data.teams;
};

export const useTeamQuery = (game_id, id) => {
  const { data, isFetching } = useTeamsDataQuery(game_id);
  const queryKey = useQueryKey(game_id);

  return [
    ...useItemQuery(data, id, "teams", queryKey, (params) =>
      updateTeam(game_id, id, params)
    ),
    isFetching,
  ];
};

export const useAllPlayersQuery = () => {
  const { game_id } = useParams();

  const { 
    data,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    teamsQueryKey(game_id, 1, INFINITE_QUERY_KEY),
    ({ pageParam }) => fetchPlayers(game_id, pageParam, MAX_PER_PAGE),
    {
      getNextPageParam: (lastPage, pages) => {
        const { count } = lastPage || {};
        const loaded = pages.reduce(
          (sum, page) => (sum += page?.teams.length),
          0
        );

        if ((lastPage && (lastPage?.teams || []).length === 0) || count <= loaded) {
          return undefined;
        }
  
        return Number(lastPage?.page || 0) + 1;
      },
      staleTime: Infinity
    }
  );
  
  const playerData = (data?.pages || []).reduce((result, page) => {
    return {
      teams: [
        ...result.teams || [],
        ...page.teams || [],
      ],
      unassigned_users: [
        ...result.unassigned_users || [],
        ...page.unassigned_users || [],
      ].filter(user => {
        return !result.unassigned_users.find(u => u.id === user.id)
      })
    }
  }, {
    teams: [],
    unassigned_users: [],
  })
  const nextPage = [...data?.pageParams || []].pop()

  return {
    nextPage,
    playerData,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  };
};

export const useInfinitePlayers = () => {
  const {
    nextPage,
    playerData: data,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useAllPlayersQuery()
  useEffect(() => {
    let loaded = false
    if (!loaded && hasNextPage) {
      fetchNextPage()
    }
    return () => {
      loaded = true
    }
  }, [
    nextPage,
    hasNextPage,
    fetchNextPage,
  ])
  const players = data.unassigned_users.slice();

  for (let team of data.teams) {
    for (let player of team.users) {
      if (!players.find((p) => p.id === player.id)) {
        if (player.email === undefined) {
          player.email = ''
        }
        players.push(player);
      }
    }
  }

  const result = players.sort((a, b) => {
    const aName = a.name || a.email;
    const bName = b.name || b.email;

    if (aName === bName) {
      return 0;
    }

    return aName < bName ? -1 : 1;
  });

  return {
    isLoading: isFetchingNextPage,
    players: result,
    teams: data.teams,
    administrators: result.filter((player) => player.game.administrator),
    banned: result.filter((player) => player.game.banned),
    coordinators: result.filter((player) => player.game.coordinator),
  }
}

export const useTeamsFetchingQuery = () => {
  const { game_id } = useParams();
  const { isFetching } = useTeamsDataQuery(game_id);
  return isFetching;
};

export const useTeamsPagesQuery = () => {
  const { game_id } = useParams();
  const { data } = useTeamsDataQuery(game_id);
  return data.pages;
};

export const useUnassignedQuery = (game_id) => {
  const { data } = useTeamsDataQuery(game_id);
  return data.unassigned_users;
};

export const useTeamPhotoThumbQuery = (id) => {
  const { game_id } = useParams();
  const [team] = useTeamQuery(game_id, id);
  const photo = team.assets.photos.find(
    (photo) => photo.name === "standard" && photo.url
  );

  return photo?.url;
};

export const useCreateMutation = () => {
  const { game_id } = useParams();
  const queryClient = useQueryClient();
  const queryKey = useQueryKey(game_id);
  const { data } = useTeamsDataQuery(game_id);
  const pageSize = useRecoilValue(pageSizeState);

  const { isLoading, mutateAsync } = useMutation(
    () => axios.post(process.env.REACT_APP_API_TEAM_CREATE_URL, { game_id, per_page: pageSize }),
    {
      onSuccess: ({ data: team }) => {
        const teams = [team.team, ...data.teams];
        const uri = teamsQueryKey(game_id);

        queryClient.invalidateQueries(uri);

        teams.sort((a, b) => {
          const first = a.name.toLocaleLowerCase();
          const second = b.name.toLocaleLowerCase();

          if (first === second) {
            return 0;
          }

          if (first < second) {
            return -1;
          }

          return 1;
        });

        queryClient.setQueryData(queryKey, {
          ...data,
          teams,
        });
      },
    }
  );

  return { isCreating: isLoading, create: mutateAsync };
};

export const useDestroyMutation = () => {
  const { game_id } = useParams();
  const [deleteId, setDeleteId] = useRecoilState(deleteIdState);
  const queryClient = useQueryClient();
  const queryKey = useQueryKey(game_id);

  const { isLoading, mutate } = useMutation(
    () =>
      axios.delete(
        `${process.env.REACT_APP_API_TEAM_DELETE_URL}/${deleteId}.json?game_id=${game_id}`
      ),
    {
      onSettled: () => setDeleteId(0),
      onSuccess: () => queryClient.invalidateQueries(teamsQueryKey(game_id)),
      onMutate: () => {
        const data = queryClient.getQueryData(queryKey);
        const teams = data.teams.filter((team) => team.id !== deleteId);

        queryClient.setQueryData(queryKey, { ...data, teams });
      },
    }
  );

  return { isDeleting: isLoading, destroy: mutate };
};

const updateUser = (playerData, id, params) => {
  const data = (playerData?.pages || []).reduce((result, page) => {
    return {
      teams: [
        ...result.teams || [],
        ...page.teams || [],
      ],
      unassigned_users: [
        ...result.unassigned_users || [],
        ...page.unassigned_users || [],
      ].filter(user => {
        return !result.unassigned_users.find(u => u.id === user.id)
      })
    }
  }, {
    teams: [],
    unassigned_users: [],
  })
  const userById = (user) => user.id === id
  const teamByUserId = (team) => team.users.find(userById)
  const pageIndex = playerData.pages.findIndex((page) => 
    page.teams.find(teamByUserId)
  );
  const teamIndex = data.teams.findIndex(teamByUserId);
  const userIndex = (data.teams[teamIndex]?.users || []).findIndex(userById);
  const team = data.teams[teamIndex] || { users: [] };
  const user = team.users[userIndex] || {};
  const game = { ...user.game, ...params };

  const users = [
    ...team.users.slice(0, userIndex),
    { ...user, game },
    ...team.users.slice(userIndex + 1),
  ];

  const teams = [
    ...data.teams.slice(0, teamIndex),
    { ...team, users },
    ...data.teams.slice(teamIndex + 1),
  ];

  playerData.pages = playerData.pages.map((page, index) => {
    if (pageIndex === index) {
      return {
        ...page,
        teams,
      }
    }
    return page
  })

  return playerData
};

export const useAddAdministratorMutation = () => {
  const { game_id } = useParams();
  const queryClient = useQueryClient();
  const key = teamsQueryKey(game_id, 1, INFINITE_QUERY_KEY);

  const { mutateAsync } = useMutation(
    ({ id }) =>
      axios.post(
        `${process.env.REACT_APP_API_GAME_ACCESS_URL}/${game_id}/administrator.json?player_id=${id}`
      ),
    {
      onMutate: ({ id }) => {
        queryClient.cancelQueries(key);
        queryClient.setQueryData(
          key,
          updateUser(queryClient.getQueryData(key), id, { administrator: true })
        );
      },
      onSuccess: () => queryClient.invalidateQueries(key),
    }
  );

  return { addAdministrator: mutateAsync };
};

export const useRemoveAdministratorMutation = () => {
  const { game_id } = useParams();
  const queryClient = useQueryClient();
  const key = teamsQueryKey(game_id, 1, INFINITE_QUERY_KEY);;

  const { mutateAsync } = useMutation(
    ({ id }) =>
      axios.delete(
        `${process.env.REACT_APP_API_GAME_ACCESS_URL}/${game_id}/administrator.json?player_id=${id}`
      ),
    {
      onMutate: ({ id }) => {
        queryClient.cancelQueries(key);
        queryClient.setQueryData(
          key,
          updateUser(queryClient.getQueryData(key), id, {
            administrator: false,
          })
        );
      },
      onSuccess: () => queryClient.invalidateQueries(key),
    }
  );

  return { removeAdministrator: mutateAsync };
};

export const useAddBanMutation = () => {
  const { game_id } = useParams();
  const queryClient = useQueryClient();
  const key = teamsQueryKey(game_id, 1, INFINITE_QUERY_KEY);;

  const { mutateAsync } = useMutation(
    ({ id }) =>
      axios.post(
        `${process.env.REACT_APP_API_GAME_ACCESS_URL}/${game_id}/ban.json?player_id=${id}`
      ),
    {
      onMutate: ({ id }) => {
        queryClient.cancelQueries(key);
        queryClient.setQueryData(
          key,
          updateUser(queryClient.getQueryData(key), id, { banned: true })
        );
      },
      onSuccess: () => queryClient.invalidateQueries(key),
    }
  );

  return { addBan: mutateAsync };
};

export const useRemoveBanMutation = () => {
  const { game_id } = useParams();
  const queryClient = useQueryClient();
  const key = teamsQueryKey(game_id, 1, INFINITE_QUERY_KEY);;

  const { mutateAsync } = useMutation(
    ({ id }) =>
      axios.delete(
        `${process.env.REACT_APP_API_GAME_ACCESS_URL}/${game_id}/ban.json?player_id=${id}`
      ),
    {
      onMutate: ({ id }) => {
        queryClient.cancelQueries(key);
        queryClient.setQueryData(
          key,
          updateUser(queryClient.getQueryData(key), id, { banned: false })
        );
      },
      onSuccess: () => queryClient.invalidateQueries(key),
    }
  );

  return { removeBan: mutateAsync };
};

export const useAddCoordinatorMutation = () => {
  const { game_id } = useParams();
  const queryClient = useQueryClient();
  const key = teamsQueryKey(game_id, 1, INFINITE_QUERY_KEY);;

  const { mutateAsync } = useMutation(
    ({ id }) =>
      axios.post(
        `${process.env.REACT_APP_API_GAME_ACCESS_URL}/${game_id}/coordinator.json?player_id=${id}`
      ),
    {
      onMutate: ({ id }) => {
        queryClient.cancelQueries(key);
        queryClient.setQueryData(
          key,
          updateUser(queryClient.getQueryData(key), id, { coordinator: true })
        );
      },
      onSuccess: () => queryClient.invalidateQueries(key),
    }
  );

  return { addCoordinator: mutateAsync };
};

export const useRemoveCoordinatorMutation = () => {
  const { game_id } = useParams();
  const queryClient = useQueryClient();
  const key = teamsQueryKey(game_id, 1, INFINITE_QUERY_KEY);;

  const { mutateAsync } = useMutation(
    ({ id }) =>
      axios.delete(
        `${process.env.REACT_APP_API_GAME_ACCESS_URL}/${game_id}/coordinator.json?player_id=${id}`
      ),
    {
      onMutate: ({ id }) => {
        queryClient.cancelQueries(key);
        queryClient.setQueryData(
          key,
          updateUser(queryClient.getQueryData(key), id, { coordinator: false })
        );
      },
      onSuccess: () => queryClient.invalidateQueries(key),
    }
  );

  return { removeCoordinator: mutateAsync };
};
