import styled from "styled-components";
import { Button, Col } from "../App.styles";
import { LinkButton } from "../common";

export const Td = styled.td`
  font-size: 1rem;
  font-weight: 400;
`;

export const Th = styled.th`
  white-space: nowrap;
`;

export const UpdatedAt = styled.abbr`
  cursor: help;
`;

export const Break = styled.div`
  flex-basis: 100%;
  height: 0;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.minDesktopWidth}) {
    display: none;
  }
`;

export const FilterButton = styled(Button)`
  ${({ active }) => {
    if (!active) {
      return `
        border-color: #999 !important;
        color: #999 !important;
      `;
    }
  }}

  font-size: 0.8125rem;
  margin: 0 0.25rem;
  opacity: ${({ active }) => (active ? "1" : "0.5")};
  padding: 0.4rem 0.5rem;

  &:hover {
    background-color: rgba(153, 153, 153, 0.2);
  }
`;

export const FilterButtons = styled(Col)`
  align-items: center;
  flex-wrap: wrap;
`;

export const NewButton = styled(LinkButton)`
`;

export const DashboardControls = styled.div`
  ${({ theme }) => `
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: 1.625rem;

    ${theme.breakpoints.up('lg')} {
      align-items: center;
    }

    input {
      width: 50%;
    }

    ${Button} {
      margin: 0.25rem;

      ${theme.breakpoints.down('xs')} {
        padding-left: 0.625rem;
        padding-right: 0.625rem;
      }
    }

    ${FilterButtons} {
      flex-grow: 1;
      display: flex;
      justify-content: flex-end;
    }

    ${NewButton} {
      margin: 0 0 0;

      ${theme.breakpoints.up('md')} {
        margin: 0;
      }
    }
  `}
`;

export const DeleteButton = styled(Button)`
  padding: 0.5rem 1rem;
`;

export const SpinnerCell = styled.td`
  text-align: center;

  && {
    background-color: white;
  }
`;
