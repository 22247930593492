import { Helmet } from "react-helmet-async";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Audio = styled.audio`
  margin-bottom: 1rem;
`;

const Image = styled.img(({ theme }) => `
    cursor: pointer;
    flex-grow: 1;
    text-align: center;
    object-fit: cover;
    max-height: 384px;
    width: 100%;
    height: 100%;
    ${theme.breakpoints.up('md')} {
        margin-bottom: -10px;
    }
`);

const AnswerMedia = ({ onLoad, audio, photo, video }) => {
  const { t } = useTranslation();
  if (video?.urls.mp4) {
    return (
      <>
        <Helmet>
          <meta property="og:video" content={video.urls.mp4} />
          <meta property="og:video:type" content="video/mp4" />
          <meta property="og:video:secure_url" content={video.urls.mp4} />
          <meta property="og:video:width" content="100%" />
          <meta property="og:video:height" content="264" />
        </Helmet>
        <video
          controls
          width="100%"
          height="264"
          onLoad={onLoad}
          playsInline
          poster={video.urls.png}
          preload="metadata"
          data-testid="answer-media-video"
        >
          <source src={video.urls.mp4} type="video/mp4" preload="metadata" />
          {video.urls.webm && (
            <source src={video.urls.webm} type="video/webm" preload="metadata" />
          )}
        </video>
      </>
    );
  } else if (video?.urls.gif) {
    return (
      <>
        <Helmet>
          <meta property="og:image" content={video.urls.gif} />
        </Helmet>
        <Image alt="" onLoad={onLoad} src={video.urls.gif} data-testid="answer-media-gif" />
      </>
    );
  }

  if (audio?.url) {
    return (
      <>
        <Helmet>
          <meta property="og:audio" content={audio.url} />
        </Helmet>
        <Audio controls data-testid="answer-media-audio">
          <source src={audio.url} type="audio/mp4" />
          {t(`Your browser does not support the audio element.`)}
        </Audio>
      </>
    );
  }

  if (photo) {
    return (
      <>
        <Helmet>
          <meta property="og:image" content={photo.url} />
        </Helmet>
        <Image
          alt="media"
          onLoad={onLoad}
          src={photo.url}
          onClick={() => window.open(photo.url)}
          data-testid="answer-media-image"
        />
      </>
    );
  }

  return <span onLoad={onLoad} />;
};

export default AnswerMedia;
