import styled from "styled-components";
import { useRecoilState } from "recoil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBroadcastTower,
  faVolumeMute,
  faVolumeUp,
  faPause,
  faPlay,
  faFastForward,
  faRandom,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { Button } from "../App.styles";
import {
  featuredState,
  liveState,
  playingState,
  shuffleState,
} from "./Slideshow.state";

const SlideshowControlWrapper = styled.div`
  position: absolute;
  z-index: 10000;

  button {
    margin: 0 0.5rem;
  }

  @media (max-width: ${({ theme }) => theme.maxMobileWidth}) {
    display: flex;
    ${({ top }) => top && `top: 0;`};
    ${({ right }) => right && `right: 0;`};
    ${({ bottom }) => bottom && `bottom: 0;`};
    ${({ left }) => left && `left: 0;`};

    ${({ left }) => left && `width: 66.6%;`};
    ${({ right }) =>
      right &&
      `
      justify-content: flex-end;
      width: 33.3%;
    `};

    button {
      border-radius: 0;
      box-shadow: none;
      flex-grow: 1;
      height: 3.5rem;
      margin: 0;

      &:active,
      &:focus,
      &:hover {
        box-shadow: none;
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.minDesktopWidth}) {
    ${({ top }) => top && `top: ${top};`};
    ${({ right }) => right && `right: ${right};`};
    ${({ bottom }) => bottom && `bottom: ${bottom};`};
    ${({ left }) => left && `left: ${left};`};
  }
`;

const SlideshowControls = ({ muted, onSkip, onMute, onUnmute, featuredSubmissions }) => {
  const [featured, setFeatured] = useRecoilState(featuredState);
  const [live, setLive] = useRecoilState(liveState);
  const [playing, setPlaying] = useRecoilState(playingState);
  const [shuffle, setShuffle] = useRecoilState(shuffleState);

  return (
    <>
      <SlideshowControlWrapper bottom="1.5rem" left="1.5rem">
        {live ? (
          <Button
            color="warning"
            title="Turn off live update feed"
            onClick={() => setLive(false)}
          >
            <FontAwesomeIcon icon={faBroadcastTower} />
          </Button>
        ) : (
          <Button
            color="light"
            title="Turn on live update feed"
            onClick={() => setLive(true)}
          >
            <FontAwesomeIcon icon={faBroadcastTower} />
          </Button>
        )}
        {shuffle ? (
          <Button
            color="danger"
            title="Turn off shuffle"
            onClick={() => setShuffle(false)}
          >
            <FontAwesomeIcon icon={faRandom} />
          </Button>
        ) : (
          <Button
            color="light"
            title="Turn on shuffle"
            onClick={() => setShuffle(true)}
          >
            <FontAwesomeIcon icon={faRandom} />
          </Button>
        )}
        {muted ? (
          <Button
            color="danger"
            title="Unmute"
            onClick={() => onUnmute()}
          >
            <FontAwesomeIcon icon={faVolumeMute} />
          </Button>
        ) : (
          <Button color="info" title="Mute" onClick={() => onMute()}>
            <FontAwesomeIcon icon={faVolumeUp} />
          </Button>
        )}
        {
          featuredSubmissions > 0
            ? featured ? (
                <Button
                  color="danger"
                  title="Show all submissions"
                  onClick={() => setFeatured(false)}
                >
                  <FontAwesomeIcon icon={faStar} />
                </Button>
              ) : (
                <Button
                  color="dark"
                  title="Show featured submissions only"
                  onClick={() => setFeatured(true)}
                >
                  <FontAwesomeIcon icon={faStar} />
                </Button>
              )
            : null
        }
      </SlideshowControlWrapper>
      <SlideshowControlWrapper bottom="1.5rem" right="1.5rem">
        {playing ? (
          <Button
            color="danger"
            title="Pause"
            onClick={() => setPlaying(false)}
          >
            <FontAwesomeIcon icon={faPause} />
          </Button>
        ) : (
          <Button color="success" title="Play" onClick={() => setPlaying(true)}>
            <FontAwesomeIcon icon={faPlay} />
          </Button>
        )}
        <Button color="info" title="Next submission" onClick={onSkip}>
          <FontAwesomeIcon icon={faFastForward} />
        </Button>
      </SlideshowControlWrapper>
    </>
  );
};

export default SlideshowControls;
