import styled from "styled-components";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  MDBCard,
  MDBCardHeader,
  MDBCardTitle,
  MDBCardBody,
} from "mdb-react-ui-kit";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { axios } from "../lib/request";
import usePayment from "../lib/usePayment";
import { Button, Row } from "../App.styles";
import { Alert, Refresher } from "../common";
import { Input } from "../common/Input";
import { FormSelect } from "../common/FormSelect";
import { useGameQuery } from "../settings/Settings.state";

const stripeStyles = {
  style: {
    base: {
      fontSize: "16px",
    },
  },
};

const CardRow = styled(Row)`
  margin: 1rem 0 2rem;
`;

const Donation = () => {
  const { t } = useTranslation();
  const { game_id } = useParams();
  const stripe = useStripe();
  const elements = useElements();
  const [email, setEmail] = useState("");
  const [amount, setAmount] = useState("20");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const gameQuery = useGameQuery();
  const { doPayment } = usePayment();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSuccess(false);
    setError(false);

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      setError(error?.message);
      console.error("[Donation] Payment Error: ", error);
      return;
    }

    const { id: stripe_id } = paymentMethod;
    const params = { email, stripe_id, cost: amount };
    const url = `${process.env.REACT_APP_API_DONATE_URL}/${game_id}/donations.json`;
    doPayment({
      action: () => axios.patch(url, params),
      onSuccess: () => setSuccess(true),
      onError: setError,
    });
  };

  return (
    <>
      {error && <Alert color="danger">{error}</Alert>}
      <Row>
        <MDBCard>
          <MDBCardHeader>
            <MDBCardTitle>
              {success
                ? t("Thank you for your donation!")
                : `Donate to ${gameQuery[0].name}`}
            </MDBCardTitle>
          </MDBCardHeader>
          <MDBCardBody>
            <form onSubmit={handleSubmit}>
              <Row>
                <Input label={t("Enter Email:")} value={email} setValue={setEmail} />
              </Row>
              <Row>
                <FormSelect
                  label={t("Your Contribution:")}
                  value={amount}
                  setValue={setAmount}
                  style={{ border: "1px solid #bdbdbd", marginTop: "15px" }}
                >
                  <option value="10">
                    $10.00 { t(`(Suggested - thank you for playing)`) }
                  </option>
                  <option value="20" selected="selected">
                    $20.00 { t(`(You are too kind)`) }
                  </option>
                  <option value="50">
                    $50.00 { t(`(You had an epic journey I see!)`) }
                  </option>
                  <option value="100">
                    $100.00 { t(`(We’re rechecking your highlight reel!)`) }
                  </option>
                </FormSelect>
              </Row>
              <CardRow
                style={{
                  borderBottom: "1px solid #bdbdbd",
                  paddingBottom: "10px",
                  marginTop: "25px",
                }}
              >
                <CardElement options={stripeStyles} />
              </CardRow>
              <Row>
                {success ? (
                  <Button
                    color="success"
                    style={{ width: "200px", marginLeft: "10px" }}
                  >
                    <span dangerouslySetInnerHTML={{__html: t(`Thank you.`)}} />
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    type="submit"
                    style={{ width: "200px", marginLeft: "10px" }}
                  >
                    <span dangerouslySetInnerHTML={{__html: t(`Pay Now`)}} />
                  </Button>
                )}
              </Row>
            </form>
          </MDBCardBody>
        </MDBCard>
      </Row>
      <Refresher />
    </>
  );
};

export default Donation;
