import { useTranslation } from "react-i18next";
import { Modal } from "../../common";
import { Button } from "../../App.styles";

const NotificationsEnabledModal = ({ onDismiss, show }) => {
const { t } = useTranslation();

return (
  <Modal onDismiss={onDismiss} show={show}>
    <div className="modal-header">
      <h4 className="modal-title">{t(`Chat`)}</h4>
      <button
        type="button"
        className="close"
        data-dismiss="modal"
        aria-hidden="true"
        onClick={onDismiss}
      >
        ×
      </button>
    </div>
    <div className="modal-body">
      <p>
        {t(`Give your grey label an icon. Click the thumbnail to upload a new image.`)}
      </p>
      <ul>
        <li>
          {t(`The best icon images are optimized for display on the phone at 165w by
          165h pixels in size.`)}
        </li>
        <li>{t(`The background is white.`)}</li>
      </ul>
    </div>
    <div className="modal-footer">
      <Button color="dark" data-dismiss="modal" onClick={onDismiss}>
        {t('Close')}
      </Button>
    </div>
  </Modal>
);
}

export default NotificationsEnabledModal;
