import { useTranslation } from "react-i18next";
import { Modal } from "../../common";
import { Button } from "../../App.styles";

const LockByModal = ({ onDismiss, show }) => {
  const { t } = useTranslation();

  return (
    <Modal onDismiss={onDismiss} show={show}>
      <div className="modal-header">
        <h4 className="modal-title">{t('Lock By')}</h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-hidden="true"
          onClick={onDismiss}
        >
          ×
        </button>
      </div>
      <div className="modal-body">
        <p>{t('You can lock a challenge, and hide it too.')}</p>
        <ul>
          <li>
            {t('You can lock a challenge such that it requires another challenge.')}
          </li>
          <li>
            {t(`You can lock it to require being within a small radius of the location
            you set (Must select a location before using this feature).`)}
          </li>
          <li>
            {t(`While not listed here, you can lock it to require being within a start
            and end time as well.`)}
          </li>
          <li>
            {t(`While not listed here, QR code challenges are another way of locking
            completion to a particular location(s).`)}
          </li>
        </ul>
      </div>
      <div className="modal-footer">
        <Button type="button" color="dark" data-dismiss="modal" onClick={onDismiss}>
          {t('Close')}
        </Button>
      </div>
    </Modal>
  )
};

export default LockByModal;
