import styled from "styled-components";
import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { GoogleMap, Marker } from "@react-google-maps/api";
import externalAxios from "axios";
import { useTranslation } from "react-i18next";
import { Row, Col, Button, HelpText } from "../../App.styles";
import { RowInfo } from "../../common";
import { Input } from "../../common/Input";
import { LocationModal } from "../modals";
import { useAnswersQuery } from "../../monitor/Monitor.state";
import { OrganizeContext } from "../Organize.context";
import { useQueryInvalidateInfinite } from "../Organize.state";

const key = process.env.REACT_APP_GOOGLE_API_KEY;

const Form = styled.form`
  ${({ theme }) => `
    display: flex;
    width: 100%;

    ${Button} {
      box-sizing: content-box;
      margin-left: 0.5rem;
    }

    .form-outline {
      margin-bottom: 0 !important;
    }

    ${theme.breakpoints.up('md')} {
      ${Button} {
        min-width: 3.5rem;
      }
    }

    ${theme.breakpoints.up('lg')} {
      ${Button} {
        min-width: 5rem;
      }
    }
  `}
`;

const ButtonCol = styled(Col)`
  ${({ theme }) => `
    ${theme.breakpoints.down('sm')} {
      margin-top: 2rem;
      margin-bottom: 2rem;

      ${Button}:first-child {
        margin-left: 0;
      }
    }
  `}
`;

const MapRow = styled(Col)`
  margin-top: 1rem;

  @media (max-width: ${({ theme }) => theme.maxMobileWidth}) {
    margin-top: 0;
  }
`;

const LocationSection = ({ useQuery, challengeId }) => {
  const { t } = useTranslation();

  const useQueryInvalidateInfinite_ = () => useQueryInvalidateInfinite(challengeId)
  const [collection, setCollection] = useQueryInvalidateInfinite_();
  const { address, latitude, longitude } = collection;
  const { game_id } = useParams();
  const defaultLoc = { lat: 43.6426, lng: -79.3871 };
  const [clearLoc, setClearLoc] = useState({ lat: 43.6426, lng: -79.3871 });
  const [addressState, setAddressState] = useState();
  const [center, setCenter] = useState();
  const { challenges: challengesContext } = useContext(OrganizeContext);
  const { tasks: challenges = [] } = challengesContext || {};
  const submissions = useAnswersQuery(game_id);
  const [markerVisible, setMarkerVisible] = useState(false);

  useEffect(() => {
    if (!address) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const location = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setLocation(location.lat, location.lng);
        },
        (err) => {
          const cIndex = challenges.findIndex(
            (x) => x.latitude !== null && x.longitude !== null
          );
          const sIndex = submissions.findIndex(
            (x) => x.latitude !== null && x.longitude !== null
          );
          if (cIndex !== -1) {
            setLocation(
              challenges[cIndex].latitude,
              challenges[cIndex].longitude
            );
          } else if (cIndex === -1 && sIndex !== -1) {
            setLocation(
              submissions[sIndex].latitude,
              submissions[sIndex].longitude
            );
          } else {
            setLocation(defaultLoc.lat, defaultLoc.lng);
          }
        }
      );
    } else {
      setAddressState(address);
      setLocation(latitude, longitude);
      setMarkerVisible(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setLocation = (latitude, longitude) => {
    setClearLoc({ lat: latitude, lng: longitude });
    setCenter({ lat: latitude, lng: longitude });
  };

  const update = (latitude, longitude, address = "") => {
    if (address === "" && latitude) {
      address = latitude + "," + longitude;
    }

    const params = { address, latitude, longitude };
    if (latitude === null && longitude === null) {
      params.arrival_location_radius = 0
    }
    setCollection(params);
    setAddressState(address);
  };

  const closeInfoWindow = () => {
    const close = document.querySelector('.gm-ui-hover-effect[aria-label=Close]')
    if (close) {
      close.click()
    }
  }

  const search = async (e) => {
    e.preventDefault();
    closeInfoWindow();

    if (addressState === "") {
      clear();
      return;
    }

    const coordinates = addressState
      ? addressState.match(/^([^,]+),([^,]+)$/)
      : null;

    if (coordinates) {
      const latitude = parseFloat(coordinates[1]);
      const longitude = parseFloat(coordinates[2]);

      if (!isNaN(latitude) && !isNaN(longitude)) {
        setCollection({ address: coordinates[0], latitude, longitude });
        setLocation(latitude, longitude);
        setMarkerVisible(true);
        return;
      }
    }

    const { data, status } = await externalAxios({
      url: "https://maps.googleapis.com/maps/api/geocode/json",
      params: { address: addressState, key },
    });

    if (status === 200) {
      if (data.results[0]) {
        update(
          data.results[0].geometry.location.lat,
          data.results[0].geometry.location.lng,
          addressState
        );
        setLocation(data.results[0].geometry.location.lat, data.results[0].geometry.location.lng);
        setMarkerVisible(true);
      } else {
        alert(t("Address not found! Make sure you spelled it correctly."));
      }
    }
  };

  const clear = () => {
    update(null, null, "");
    setLocation(clearLoc);
    setMarkerVisible(false);
  };

  return (
    <>
      <RowInfo help={<LocationModal />}>
        <Form onSubmit={search}>
          <Row>
            <Col size="12" lg="6">
              <Input
                help={t('Locations can be suggestions or requirements.')}
                label={t('Completion Location')}
                onChange={setAddressState}
                value={addressState}
              />
            </Col>
            <ButtonCol size="12" lg="6">
              <Button className="btn--flat" color="dark" type="submit">
                {t('Search')}
              </Button>
              <Button className="btn--flat" color="warning" onClick={clear}>
                {t('Clear')}
              </Button>
            </ButtonCol>
          </Row>
        </Form>
      </RowInfo>
      <MapRow>
        <Col size="12">
          <GoogleMap
            mapContainerStyle={{ width: "100%", height: "300px" }}
            center={center}
            zoom={15}
            onClick={({ latLng }) => {
              setMarkerVisible(true)
              update(latLng.lat(), latLng.lng());
              setLocation(latLng.lat(), latLng.lng());
            }}
          >
            {markerVisible ? (
              <Marker
                position={center}
                draggable
                onDragEnd={({ latLng }) => {
                  update(latLng.lat(), latLng.lng())
                  setLocation(latLng.lat(), latLng.lng())
                }}
              />
            ) : (
              ""
            )}
          </GoogleMap>
        </Col>
      </MapRow>
      <HelpText className="position-relative top-0 mt-4 d-block" focus>Locations can be informative or restrictive. <br />If you do not lock to the location, the player can use the map for guidance but may submit from anywhere.</HelpText>
    </>
  );
};

export default LocationSection;
