import { useTranslation } from "react-i18next";
import { Modal } from "../../common";
import { Button } from "../../App.styles";

const ChallengesVisibilityModal = ({ onDismiss, show }) => {
  const { t } = useTranslation();

  return (
  <Modal onDismiss={onDismiss} show={show}>
    <div className="modal-header">
      <h4 className="modal-title">{t(`Challenges Visibility`)}</h4>
      <button
        type="button"
        className="close"
        data-dismiss="modal"
        aria-hidden="true"
        onClick={onDismiss}
      >
        ×
      </button>
    </div>
    <div className="modal-body">
      <p>
        {t(`Challenges Visibility can be shown or hidden throughout the game. If set
        to Visible, it will show up in the apps. If Hidden, it will disappear
        and be unavailable. Any new joiners will use the current settings.`)}
      </p>
    </div>
    <div className="modal-footer">
      <Button color="dark" data-dismiss="modal" onClick={onDismiss}>
        {t('Close')}
      </Button>
    </div>
  </Modal>
  );
}

export default ChallengesVisibilityModal;
