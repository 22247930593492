import { useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, HeaderRow, Row, Col } from "../App.styles";
import {
  Pagination,
  Refresher,
  Search,
  SidebarLayout,
  Spinner,
  withPlaceholder,
} from "../common";
import { ChallengeHeader } from "./Organize.styles";
import {
  pageState,
  pageSizeState,
  termState,
  useChallengesQuery,
  useChallengesCountQuery,
  useChallengesFetchingQuery,
  useChallengesPagesQuery,
  useCreateMutation,
  useReorderMutation,
  useDestroyMutation,
  useInvalidateInfinity,
  highlightIdState,
  useSuggestMutation,
} from "./Organize.state";
import ChallengeList from "./ChallengeList";
import Challenge from "./Challenge";
import TypeChart from "./TypeChart";
import {
  CustomStickers,
  VideoHighlightReels,
} from "../common/sidebar-cards";
import OrganizePlaceholder from "./OrganizePlaceholder";
import HighlightList from "../common/HighlightList";
import { OrganizeContextProvider } from "./Organize.context";
import Minimap from "./Minimap";
import { useDecision } from "@optimizely/react-sdk";

const Panel = () => {
  const random = useRef();

  if (!random.current) {
    random.current = Math.floor(Math.random() * 2);
  }

  switch (random.current) {
    case 0:
    default:
      return <CustomStickers />;
    case 1:
      return <VideoHighlightReels />;
  }
};

const Organize = () => {
  const { game_id } = useParams();
  const { t } = useTranslation();
  const [openId, setOpenId] = useState();
  const [highlightId, setHighlightId] = useRecoilState(highlightIdState);
  const [page, setPage] = useRecoilState(pageState);
  const [pageSize, _setPageSize] = useRecoilState(pageSizeState);
  const [term, setTerm] = useRecoilState(termState);
  const count = useChallengesCountQuery(game_id);
  const data = useChallengesQuery(game_id);
  const pages = useChallengesPagesQuery(game_id);
  const { isCreating, create } = useCreateMutation();
  const { isSuggesting, suggest } = useSuggestMutation();
  const { isDeleting, destroy } = useDestroyMutation();
  const { isReordering, reorder } = useReorderMutation();
  const isFetching = useChallengesFetchingQuery(game_id);
  const afterDestroy = useInvalidateInfinity()
  const [decisionAi] = useDecision('ai');

  const setPageSize = (per_page) => {
    const newPages = Math.ceil(count / per_page);

    if (page >= newPages) {
      setPage(newPages);
    }

    _setPageSize(Number(per_page));
  };

  const onCreate = async () => {
    const {
      data: { id },
    } = await create();
    setOpenId(id);
    setHighlightId(id);
  };

  const onSuggest = async () => {
    const {
      data: { id },
    } = await suggest();
    setOpenId(id);
    setHighlightId(id);
  };

  const search = (term) => {
    setPage(1);
    setTerm(term);
  };

  return (
    <OrganizeContextProvider>
      <Row>
        <Col size="12" xl="9" className="col--stretch">
          <HeaderRow className="flex-grow-1 mb-2">
            {
              decisionAi?.enabled
                ? (
                  <>
                    <div className="col-12 col-lg-6 mb-2 mb-lg-0">
                      <Search className="form-control--flat form-control--flat-sm m-0" onChange={search} value={term} />
                    </div>
                    <ChallengeHeader className="col-12 col-lg-6 mb-2 d-block d-sm-flex gap-2">
                      <Button data-testid="challenge__add-new" size="lg" className="btn--flat btn--flat-sm d-block flex-grow-1" color="primary" onClick={onCreate} disabled={isCreating || isSuggesting}>
                        {t('Add Challenge')} {isCreating && <Spinner size="0.875rem" />}
                      </Button>
                      <Button data-testid="challenge__suggest-new" size="lg" className="btn--flat btn--flat-sm d-block flex-grow-1 ml-2" color={data.length > 3 ? 'success' : 'danger'} onClick={onSuggest} disabled={isSuggesting || isCreating}>
                        {t('Suggest Challenge')} {isSuggesting && <Spinner size="0.875rem" />}
                      </Button>
                    </ChallengeHeader>
                  </>
                )
                : (
                  <>
                    <div className="col-12 col-lg-8 mb-2 mb-lg-0">
                      <Search className="form-control--flat form-control--flat-sm m-0" onChange={search} value={term} />
                    </div>
                    <ChallengeHeader className="col-12 col-lg-4 mb-2 d-block d-sm-flex">
                      <Button data-testid="challenge__add-new" size="lg" className="btn--flat btn--flat-sm d-block w-100" color="primary" onClick={onCreate} disabled={isCreating}>
                        {t('Add New Challenge')} {isCreating && <Spinner size="0.875rem" />}
                      </Button>
                    </ChallengeHeader>
                  </>
                )
            }
          </HeaderRow>
        </Col>
      </Row>
      <Row>
        <SidebarLayout
          message={t("Configure the challenges you would like teams to complete.")}
          panel={
            <>
              <Panel />
              <Minimap />
              <TypeChart />
            </>
          }
        >
          <ChallengeList>
            {data.map((row, index) => (
              <HighlightList key={row.id} scrollId={highlightId} id={row.id}>
                <div
                  key={row.id}
                  index={index}
                >
                  <Challenge
                    canReorder={!term}
                    index={index}
                    {...row}
                    clearOpenId={() => setOpenId(undefined)}
                    onReorder={reorder}
                    onDelete={async () => {
                      await destroy(row.id)
                      afterDestroy()
                    }}
                    isDeleting={isDeleting}
                    openId={openId}
                    game_id={game_id}
                    count={count}
                  />
                </div>
              </HighlightList>
            ))}
          </ChallengeList>
          <Row>
            <Pagination
              loading={isReordering || isFetching || isDeleting}
              page={page}
              pageSize={pageSize}
              pages={pages}
              setPage={setPage}
              setPageSize={setPageSize}
            />
          </Row>
        </SidebarLayout>
      </Row>
      <Refresher />
    </OrganizeContextProvider>
  );
};

export default withPlaceholder(Organize, OrganizePlaceholder);