import styled from "styled-components";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const Input = styled(TextField)`
  outline: none;

  .MuiInput-root:before,
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 0;
  }
`;

const TagsList = styled.ul`
  display: flex;
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  box-sizing: border-box;
  margin: 0;
  padding: 1px 5px;
  width: 100%;

  input {
    border: 0;
    outline: 0;
    width: 100%;
  }

  li {
    list-style-type: none;
    margin: 2px 5px 2px 0;
    padding: 0.25em 4px 0.25em 0;
  }
`;

const InputItem = styled.li`
  flex-grow: 1;
`;

const Label = styled.label`
  font-size: 0.8rem;
`;

const TagList = ({ freeSolo, onAdd, onRemove, value, options }) => {
  const { t } = useTranslation();
  const commit = (_, tags) => {
    if (onAdd) {
      for (let option of tags) {
        if (!value.includes(option)) {
          onAdd(option.trim());
        }
      }
    }

    if (onRemove) {
      for (let option of value) {
        if (!tags.includes(option)) {
          onRemove(option.trim());
        }
      }
    }
  };

  return (
    <>
      <Label className="form-label">{t('Search by tag')}</Label>
      <TagsList>
        <InputItem>
          <Autocomplete
            multiple
            onChange={commit}
            options={options}
            renderInput={(props) => <Input {...props} />}
            freeSolo={freeSolo}
            size="small"
            value={value}
          />
        </InputItem>
      </TagsList>
    </>
  );
};

export default TagList;
