import styled from "styled-components";

const Label = styled.label`
  font-size: 0.8rem;
`;

const Checkbox = ({ label, name, useQuery, text, ...props }) => {
  const [collection, setCollection] = useQuery();

  const commit = ({ target }) =>
    setCollection({ ...collection, [name]: target.checked ? 1 : 0 });

  return (
    <div className="form-outline">
      <Label className="form-label">{label}</Label>
      <br />
      <input
        type="checkbox"
        name={name}
        onChange={commit}
        checked={!!collection[name]}
        {...props}
      />{" "}
      {text}
    </div>
  );
};

export default Checkbox;
