import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBCardHeader,
  MDBCardTitle,
} from "mdb-react-ui-kit";
import { useTranslation } from "react-i18next";
import { axios } from "../lib/request";
import { Button, Row } from "../App.styles";
import { Alert } from "../common";
import withDashboardRedirect from "../hoc/withDashboardRedirect";

const FormGroup = styled.div`
  margin: 2rem 0;
`;

export const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const history = useHistory();
  const { t } = useTranslation();

  const submit = async () => {
    try {
      await axios.patch(process.env.REACT_APP_API_RESET_PASSWORD_URL, {
        email,
      });

      setError(null);
      history.push({
        pathname: '/sign-in',
        state: {
          resetPassword: true,
        },
      });
    } catch (error) {
      if (error.response.status === 404) {
        setError(t("We don't recognize that email address."));
        setSuccess(null);
      } else {
        setError(
          t("There has been a problem connecting with our platform. Please try again.")
        );
        setSuccess(null);
      }
    }
  };

  useEffect(() => {
    setError(null);
    setSuccess(null);
  }, [email]);

  return (
    <>
      <Row>
        <MDBCol size="12">
          <Alert color={success ? "success" : "danger"}>
            {success || error}
          </Alert>
        </MDBCol>
      </Row>
      <Row>
        <MDBCol size="12">
          <MDBCard>
            <MDBCardHeader>
              <MDBCardTitle>{t('Reset Password')}</MDBCardTitle>
            </MDBCardHeader>
            <MDBCardBody>
              <p 
                dangerouslySetInnerHTML={{
                  __html: t(`Enter the email address associated with your account and we'll send you a new password.`)
                }} 
              />
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  submit();
                }}
              >
                <FormGroup>
                  <MDBInput
                    label={t("Email")}
                    value={email}
                    onChange={({ target }) => setEmail(target.value)}
                  />
                </FormGroup>
                {
                  success
                    ? null
                    : (
                      <>
                        <Button color="primary" type="submit">
                          {t('Reset Password')}
                        </Button>
                        <Link to="/sign-in">
                          <Button color="link">{t('Cancel')}</Button>
                        </Link>
                      </>
                    )
                }
              </form>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </Row>
    </>
  );
};

export default withDashboardRedirect(ResetPassword);
