import { useTranslation } from "react-i18next";
import { Modal } from "../../common";
import { Button } from "../../App.styles";

const RewardsFileModal = ({ onDismiss, show }) => {
  const { t } = useTranslation();

  return (
    <Modal onDismiss={onDismiss} show={show}>
      <div className="modal-header">
        <h4 className="modal-title">{t('Rewards and Clues')}</h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-hidden="true"
          onClick={onDismiss}
        >
          ×
        </button>
      </div>
      <div className="modal-body">
        <p>
          {t(`Give your challenge a bonus reward or clue image. Click the thumbnail to
          upload a new image.`)}
        </p>
        <ul>
          <li>
            {t(`Challenge reward or clue images are displayed as thumbnails in the
            rewards and clues section as well as fullscreen when selected.`)}
          </li>
          <li>
            {t(`The best images are optimized for display on the phone, square, and at
            least 800 by 800 pixels in size.`)}
          </li>
          <li>
            {t(`Images could be coupons with barcodes, additional QR codes, artwork or
            any other visual.`)}
          </li>
        </ul>
      </div>
      <div className="modal-footer">
        <Button type="button" color="dark" data-dismiss="modal" onClick={onDismiss}>
          {t('Close')}
        </Button>
      </div>
    </Modal>
  )
};

export default RewardsFileModal;
