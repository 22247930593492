import { useMemo } from "react";

const statefulField =
  (Component) =>
  ({ useQuery, ...rest }) => {
    const [collection, setCollection] = useQuery();

    const setValue = (value) => {
      const hasValue = ![null, undefined].includes(value)
      if ((hasValue || collection[rest.name]) && value !== collection[rest.name]) {
        setCollection({ [rest.name]: value });
      }
    };

    // For text inputs we set value as empty string if null.
    // This is so that on blur events it won't trigger API update
    // due to the value is treated as being updated (i.e. by comparing null with "")
    const fallbackValue = useMemo(() => ({
      Input: "",
      Textarea: "",
    }), [])

    const defaultValue = useMemo(() => {
      const currentValue = collection[rest.name]
      const hasValue = ![null, undefined].includes(collection[rest.name])
      const fallback = ['Input', 'Textarea'].includes(Component.name)
        ? fallbackValue[Component.name]
        : currentValue
      return hasValue ? currentValue : fallback
    }, [
      collection,
      rest.name,
      fallbackValue,
    ])

    return (
      <Component {...rest} value={defaultValue} setValue={setValue} />
    );
  };

export default statefulField;
