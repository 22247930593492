import styled from "styled-components";
import { useEffect, useState } from "react";
import { MDBCard, MDBCardBody } from "mdb-react-ui-kit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import {
  usePersonalizationQuery,
  usePersonalizationsQuery,
  useCreateMutation,
  useDestroyMutation,
  useUpdateMutation,
} from "./Personalizations.state";
import { Button, Row, Col } from "../App.styles";
import { Alert, Refresher, SidebarLayout, Spinner } from "../common";
import { Input } from "../common/Input";
import PersonalizationsPlaceholder from "./PersonalizationsPlaceholder";
import { withPlaceholder } from "../common";

const PersonalizationRow = styled(Row)`
  .card {
    margin-bottom: 1rem;
  }

  &&& .form-outline {
    margin-bottom: 0;
  }
`;

const Personalization = ({ id }) => {
  const { t } = useTranslation();
  const { isCreating, create } = useCreateMutation();
  const { update } = useUpdateMutation(id);
  const { destroy } = useDestroyMutation(id);
  const [collection] = usePersonalizationQuery(id);
  const [key, setKey] = useState("");
  const [value, setValue] = useState("");

  useEffect(() => {
    if (id !== "new") {
      setKey(collection?.key);
      setValue(collection.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collection]);

  const onSetKey = (key) => {
    setKey(key);

    if (id && id !== "new" && key !== collection?.key) {
      update({ key, value });
    }
  };

  const onSetValue = (value) => {
    setValue(value);

    if (id && id !== "new" && value !== collection.value) {
      update({ key, value });
    }
  };

  const onCreate = async (key, value) => {
    setKey("");
    setValue("");
    await create({ key, value });
  };

  return (
    <MDBCard>
      <MDBCardBody>
        <Row>
          <Col className="col--stretch" md={5} size={4}>
            <Input
              help={t("This is the placeholder that will be replaced by the value text.")}
              name="key"
              label={t("Key")}
              regex={/^[a-z]*$/}
              value={key}
              setValue={onSetKey}
            />
          </Col>
          <Col className="col--stretch" md={5} size={4}>
            <Input
              help={t("This is the text that will replace %{key}.")}
              name="value"
              label={t("Value")}
              value={value}
              setValue={onSetValue}
            />
          </Col>
          <Col className="col--stretch" md={2} size={4}>
            {id === "new" ? (
              <Button
                color="success"
                className="btn-raised btn--narrow"
                onClick={() => onCreate(key, value)}
              >
                {isCreating ? <Spinner size="0.875" /> : t("Add")}
              </Button>
            ) : (
              <Button
                color="danger"
                className="btn-raised"
                disabled={!id}
                onClick={destroy}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Button>
            )}
          </Col>
        </Row>
      </MDBCardBody>
    </MDBCard>
  );
};

const Personalizations = () => {
  const { t } = useTranslation();
  const personalizations = usePersonalizationsQuery();

  return (
    <>
      <PersonalizationRow>
        <SidebarLayout>
          <Row>
            <Col className="col--stretch mb-4">
              <Alert color="info">
                {t(`You can substitute text in challenge and game definitions.
                Configure your substitutions below. Whenever you want to do a
                substitution, use the syntax %&#123;key&#125;. If you're wondering
                if a challenge or game attribute supports the personalization
                syntax, check the help.`)}
              </Alert>
            </Col>
          </Row>
          {personalizations.map(({ id }, key) => (
            <Personalization id={id} key={key} />
          ))}
          <Personalization id="new" />
        </SidebarLayout>
      </PersonalizationRow>
      <Refresher />
    </>
  );
};

export default withPlaceholder(Personalizations, PersonalizationsPlaceholder);
