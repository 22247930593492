import { useEffect, useState } from "react";
import styled from "styled-components";
import statefulField from "./StatefulField";

const SwitchContainer = styled.div`
  display: flex;
  flex-direction: column;

  .form-label {
    font-size: 0.8rem;
  }
`;

const SwitchWrapper = styled.div`
  border: 1px solid #ccc;
  color: #fff;
  font-size: 0.8125rem;
  font-weight: 800;
  font-family: Helvetica, Verdana, Arial, sans-serif;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  height: 35px;
  width: 120px;
  border-radius: 25px;

  .inset {
    background-color: ${({ status }) => (status ? "#51a351" : "#da4f49")};
    background-image: ${({ status }) =>
      status
        ? "linear-gradient(top, #62c462, #51a351)"
        : "linear-gradient(top, #ee5f5b, #bd362f)"};
    border-color: ${({ status }) =>
      status ? "#51a351 #51a351 #387038" : "#bd362f #bd362f #802420"};
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    background-image: none;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15),
      0 1px 2px rgba(0, 0, 0, 0.05);
  }

  .inset .control {
    float: ${({ status }) => (status ? "right" : "left")};
  }

  ${({ labels, status }) =>
    status
      ? `
        .inset .control:before {
          content: "${labels.on}";
          position: relative;
          left: -110%;
          top: 20%;
          white-space: nowrap;
          overflow: hidden;
          display: block;
          text-overflow: ellipsis;
        }
      `
      : `
        .inset .control:after {
          content: "${labels.off}";
          position: relative;
          right: -120%;
          top: 20%;
          white-space: nowrap;
          overflow: hidden;
          display: block;
          text-overflow: ellipsis;
        }
      `}

  .inset {
    width: 100%;
    height: 100%;
    border-radius: 20px;
  }

  .inset .control {
    background-color: #000;
    width: 40%;
    height: 100%;
    border-radius: 20px;
    background-color: #f5f5f5;
    *background-color: #e6e6e6;
    background-image: linear-gradient(top, #ffffff, #e6e6e6);
    background-repeat: repeat-x;
  }

  .inset .control:hover {
    cursor: pointer;
  }

  &.disabled {
    color: #ccc;
  }

  &.disabled .inset {
    background-color: #f5f5f5;
    *background-color: #e6e6e6;
    background-image: linear-gradient(top, #ffffff, #e6e6e6);
    background-repeat: repeat-x;
  }

  &.disabled .control {
    cursor: default;
  }
`;

export const Switch = ({
  label,
  labels,
  on,
  off,
  value,
  setValue,
  onChange,
}) => {
  const [status, setStatus] = useState(value === on);

  useEffect(() => setStatus(value === on), [on, value]);

  const change = () => {
    if (setValue) {
      const newValue = status ? off : on
      const newStatus = !status
      setValue(newValue);
      setStatus(newStatus);
      onChange && onChange(newStatus);
    }
  };

  return (
    <SwitchContainer className="form-outline">
      <label className="form-label">{label}</label>
      <SwitchWrapper labels={labels} status={status} onClick={change}>
        <div className="inset">
          <div className="control"></div>
        </div>
      </SwitchWrapper>
    </SwitchContainer>
  );
};

export default statefulField(Switch);
