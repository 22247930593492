import { MDBCard, MDBCardHeader, MDBCardBody, MDBCardTitle } from "mdb-react-ui-kit";
import { HeaderRow, Row, Col, Divider, CardBody } from "../App.styles";
import { SidebarLayout } from "../common";
import Datalist from "./Datalist";
import { DatalistHeader } from "./Datalist.style";
import { Shimmer } from "../common/Loading.styles";

const DatalistView = () => {
  return (
    <MDBCard className="mb-3">
      <MDBCardHeader>
        <MDBCardTitle>
            <Shimmer type="title"/>
        </MDBCardTitle>
      </MDBCardHeader>
      <MDBCardBody>
        <Row>
          <Col size="5" md="2">
            <Shimmer media/>
          </Col>
          <Col size="7" md="10">
            <div className="flex-grow-1">
              <Shimmer type="title" count={3}/>
            </div>
          </Col>
        </Row>
        <Divider />
        <Row className="flex-grow-1 d-flex justify-content-end">
          <Col md={3}>
            <Shimmer type="title"/>
          </Col>
        </Row>
      </MDBCardBody>
    </MDBCard>
  );
};

const Panel = () => (
  <>
    <CardBody className="card p-4">
        <Shimmer dark type="title"/>
        <Shimmer dark />
    </CardBody>
  </>
);

const DatalistPlaceholder = () => {
  return (
    <>
      <HeaderRow>
        <DatalistHeader className="col-12 col-lg-9">
            <Row className="flex-grow-1 d-flex justify-content-end">
              <Col md={3}>
                <Shimmer dark type="title"/>
              </Col>
            </Row>
        </DatalistHeader>
      </HeaderRow>
      <Row>
        <SidebarLayout
          panel={<Panel />}
        >
          <Datalist>
            {
                Array.from(
                    Array(3).keys()
                ).map(key => (
                    <DatalistView key={key} />
                ))
            }
          </Datalist>
        </SidebarLayout>
      </Row>
    </>
  );
};

export default DatalistPlaceholder;
