import { useState } from "react";
import styled from "styled-components";
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBCardHeader,
  MDBCardBody,
} from "mdb-react-ui-kit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCamera,
  faCheckSquare,
  faTimes,
  faTrophy,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { ActionButtonWrapper, Button, Row, Col, Divider } from "../App.styles";
import { ImageUpload, Input, RichEditor, RowInfo } from "../common";
import { useAchievementQuery } from "./Rewards.state";
import TypeSection from "./TypeSection";
import PointsSection from "./PointsSection";
import { DescriptionModal, ImageModal, TitleModal } from "./modals";

const Navbar = styled(MDBNavbar)`
  background-color: #eceff1;
  margin: 2rem 0;

  .container-fluid {
    justify-content: flex-start;
  }

  .nav-link {
    font-size: 0.75rem;
    color: rgba(0, 0, 0, 0.54);
    text-transform: uppercase;
    cursor: pointer;
    padding-left: 0;

    &.active {
      color: rgba(0, 0, 0, 0.87);
    }
  }
`;

const HeadRow = styled(Row)`
  @media (max-width: ${({ theme }) => theme.maxMobileWidth}) {
    ${Button} {
      margin: 0;
      padding: 0.5rem 0.75rem;
    }
  }
`;

const RewardEdit = ({ id, onClose, onDelete }) => {
  const { t } = useTranslation();
  const [section, setSection] = useState("type");
  const useQuery = () => useAchievementQuery(id);

  return (
    <>
      <MDBCardHeader>
        <HeadRow>
          <Col size="10">
            <RowInfo help={<TitleModal />}>
              <Input
                help={t("This is the title or headline for your reward or clue.")}
                name="title"
                label={t("Title")}
                useQuery={useQuery}
              />
            </RowInfo>
          </Col>
          <Col size="2">
            <Button
              color="danger"
              className="btn-raised"
              onClick={() => onDelete(id)}
            >
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          </Col>
        </HeadRow>
      </MDBCardHeader>
      <MDBCardBody>
        <RowInfo help={<ImageModal />}>
          <ImageUpload
                name="photo"
                label={t("Image")}
                model="rewards"
                useQuery={useQuery}
              />
        </RowInfo>
        <RowInfo help={<DescriptionModal />}>
          <RichEditor
                help={t("This is the main text for your reward or clue.")}
                label={t("Description")}
                name="description"
                useQuery={useQuery}
              />
        </RowInfo>
        <Navbar expand="lg">
          <MDBContainer fluid>
            <MDBNavbarItem>
              <MDBNavbarLink
                active={section === "type"}
                onClick={() => setSection("type")}
              >
                {t('Type')} <FontAwesomeIcon icon={faCamera} />
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink
                active={section === "points"}
                onClick={() => setSection("points")}
              >
                {t('Points')} <FontAwesomeIcon icon={faTrophy} />
              </MDBNavbarLink>
            </MDBNavbarItem>
          </MDBContainer>
        </Navbar>
        {(() => {
          switch (section) {
            default:
              return <TypeSection useQuery={useQuery} />;
            case "points":
              return <PointsSection useQuery={useQuery} />;
          }
        })()}
        <Divider />
        <ActionButtonWrapper>
          <Button color="primary" className="btn-raised" onClick={onClose}>
            <FontAwesomeIcon icon={faCheckSquare} /> {t('Close')}
          </Button>
        </ActionButtonWrapper>
      </MDBCardBody>
    </>
  );
};

export default RewardEdit;
