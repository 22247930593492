import { useTranslation } from "react-i18next";
import { FormSelect, Input, RowInfo } from "../common";
import { GrantsModal, MessagesModal, QualifyModal } from "./modals";

const TypeSection = ({ useQuery }) => {
  const { t } = useTranslation();

  return (
    <>
      <RowInfo help={<GrantsModal />}>
        <FormSelect
            help={t("Choose how many players should receive the award.")}
            label={t("Grant to")}
            name="first"
            useQuery={useQuery}
          >
            <option>{t('unlimited')}</option>
            <option value={1}>1</option>
            <option value={2}>2</option>
            <option value={3}>3</option>
            <option value={4}>4</option>
            <option value={5}>5</option>
            <option value={6}>6</option>
            <option value={7}>7</option>
            <option value={8}>8</option>
            <option value={9}>9</option>
            <option value={10}>10</option>
            <option value={11}>11</option>
            <option value={12}>12</option>
            <option value={13}>13</option>
            <option value={14}>14</option>
            <option value={15}>15</option>
            <option value={16}>16</option>
            <option value={17}>17</option>
            <option value={18}>18</option>
            <option value={19}>19</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={99}>99</option>
            <option value={200}>200</option>
            <option value={300}>300</option>
            <option value={400}>400</option>
            <option value={500}>500</option>
          </FormSelect>
      </RowInfo>
      <RowInfo help={<QualifyModal />}>
        <FormSelect
            help={t("The requirements for the award.")}
            label={t("Qualify with")}
            name="trig"
            useQuery={useQuery}
          >
            <option>{t('unlimited')}</option>
            <option value={1}>1</option>
            <option value={2}>2</option>
            <option value={3}>3</option>
            <option value={4}>4</option>
            <option value={5}>5</option>
            <option value={6}>6</option>
            <option value={7}>7</option>
            <option value={8}>8</option>
            <option value={9}>9</option>
            <option value={10}>10</option>
            <option value={11}>11</option>
            <option value={12}>12</option>
            <option value={13}>13</option>
            <option value={14}>14</option>
            <option value={15}>15</option>
            <option value={16}>16</option>
            <option value={17}>17</option>
            <option value={18}>18</option>
            <option value={19}>19</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={99}>99</option>
            <option value={200}>200</option>
            <option value={300}>300</option>
            <option value={400}>400</option>
            <option value={500}>500</option>
          </FormSelect>
          <FormSelect
            help={t("The requirements for the award.")}
            name="style"
            useQuery={useQuery}
          >
            <option value={12}>{t('Background + Photo')}</option>
            <option value={9}>{t('Multiple Choice')}</option>
            <option value={3}>{t('Photo')}</option>
            <option value={7}>{t('QR Code')}</option>
            <option value={14}>{t('Split Path')}</option>
            <option value={5}>{t('Sticker Photo')}</option>
            <option value={1}>{t('Submissions')}</option>
            <option value={6}>{t('Text')}</option>
            <option value={8}>{t('Trivia')}</option>
            <option value={4}>{t('Video')}</option>
          </FormSelect>
      </RowInfo>
      <RowInfo help={<MessagesModal />}>
        <Input
            help={t("Add a special note when the award is made.")}
            label={t("When awarded, send them this message")}
            name="push"
            useQuery={useQuery}
          />
      </RowInfo>
    </>
  );
};

export default TypeSection;
