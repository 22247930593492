import styled from "styled-components";
import { useContext, useMemo, useState } from "react";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { GoogleMap, Marker, MarkerClusterer } from "@react-google-maps/api";
import { MDBCardBody } from "mdb-react-ui-kit";
import { Col } from "../App.styles";
import { highlightIdState, useNavigateTaskPageQuery } from "./Organize.state";
import { Shimmer } from "../common/Loading.styles";
import { OrganizeContext } from "./Organize.context";

const MapRow = styled(Col)`
  margin-top: 0;

  @media (max-width: ${({ theme }) => theme.maxMobileWidth}) {
    margin-top: 0;
  }
`;

const CardBody = styled(MDBCardBody)`
  padding: 0;
  margin: 0;
  position: relative;

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const Minimap = () => {
  const { t } = useTranslation()
  const { challenges } = useContext(OrganizeContext)
  const { isLoading, tasks = [] } = challenges || {}
  const [, setHighlightId] = useRecoilState(highlightIdState)
  const [currentCenter, setCurrentCenter] = useState()
  const setTaskPage = useNavigateTaskPageQuery({ tasks })
  const markers = useMemo(() => {
    return tasks.filter(t => t.latitude && t.longitude).map(({ id, latitude, longitude }) => ({
      id,
      lat: latitude,
      lng: longitude,
    }))
  }, [tasks])
  const center = useMemo(() => {
    const defaultLoc = { lat: 43.6426, lng: -79.3871 };
    return currentCenter || markers[0] || defaultLoc
  }, [markers, currentCenter])
  const onMarkerClick = async (location) => {
    setCurrentCenter(location)
    
    await setTaskPage(location.id)
    setHighlightId(location.id)
  }

  return isLoading
    ? (
      <>
        <Shimmer dark type="title" />
        <Shimmer dark />
      </>
    )
    : markers.length > 0
      ? (
        <div className="card">
          <div className="card-header bg-dark text-light">
            <span className="card-title">{t('Map')}</span>
          </div>
          <CardBody>
            <MapRow>
              <Col size="12" className="col--stretch">
                <GoogleMap
                  mapContainerStyle={{ width: "100%", height: "300px" }}
                  zoom={3}
                  center={center}
                >
                  <MarkerClusterer averageCenter>
                    {(clusterer) =>
                      markers.map((location) => (
                        <Marker 
                          key={`${location.id}`} 
                          position={location} 
                          clusterer={clusterer} 
                          onClick={() => onMarkerClick(location)}
                        />
                      ))
                    }
                  </MarkerClusterer>
                </GoogleMap>
              </Col>
            </MapRow>
          </CardBody>
        </div>
      )
      : null;
};

export default Minimap;
