import styled from "styled-components";
import { useEffect, useState } from "react";
import { Tooltip } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimesCircle,
  faClipboard,
  faClipboardCheck,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { Button } from "../App.styles";

const List = styled.ul`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  li {
    margin-right: 1rem;
    margin-bottom: 1rem;

    svg {
      margin-left: 1rem;
    }

    @media (max-width: ${({ theme }) => theme.maxMobileWidth}) {
      margin-right: 0;
    }
  }

  @media (max-width: ${({ theme }) => theme.maxMobileWidth}) {
    ${Button} {
      overflow-wrap: anywhere;
      text-align: left;
      white-space: normal;
      word-break: normal;
    }
  }
`;

const TooltipParagraph = styled.p`
  padding-top: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.15rem;
  text-align: center;

  svg {
    margin-left: 0.625rem;
  }
`;

const PlayerTooltip = ({ password, code }) => {
  const { t } = useTranslation();
  const [passwordCopied, setPasswordCopied] = useState(false);
  const [codeCopied, setCodeCopied] = useState(false);
  const [qsCopied, setQSCopied] = useState(false);

  const copyPassword = () => {
    navigator.clipboard.writeText(password);
    setCodeCopied(false);
    setPasswordCopied(true);
  };

  const copyCode = () => {
    navigator.clipboard.readText();
    navigator.clipboard.writeText(code);
    setCodeCopied(true);
    setPasswordCopied(false);
  };

  const copyQSUrl = () => {
    navigator.clipboard.readText();
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_PREVIEW_URL}/#/signin?code=${code}`
    );
    setQSCopied(true);
  };

  useEffect(() => {
    const copy = async () => {
      try {
        const clipboard = await navigator.clipboard.readText();

        if (clipboard === password) {
          setPasswordCopied(true);
        }

        if (clipboard === code) {
          setCodeCopied(true);
        }
      } catch (error) {
        console.warn(t("Silly clipboard thing threw an exception"), error);
      }
    };

    copy();
  }, [code, password, t]);

  const passwordIcon = passwordCopied ? faClipboardCheck : faClipboard;
  const codeIcon = codeCopied ? faClipboardCheck : faClipboard;
  const passwordHint = passwordCopied
    ? t("Copied to clipboard")
    : t("Copy to clipboard");
  const codeHint = codeCopied ? t("Copied to clipboard") : t("Copy to clipboard");

  return (
    <TooltipParagraph>
      {password ? (
        <>
          {t('Password is')} {password}.
          <FontAwesomeIcon
            icon={passwordIcon}
            onClick={copyPassword}
            title={passwordHint}
          />
          <br />
        </>
      ) : (
        <>
          {t('Password is set by user and encrypted.')}
          <br />
        </>
      )}
      {t('Player can access game with their unique unlock code')} {code}.
      <FontAwesomeIcon icon={codeIcon} onClick={copyCode} title={codeHint} />
      <br />
      <br />
      {t('Personal Link')}:{" "}
      <a
        href={`${process.env.REACT_APP_PREVIEW_URL}/#/signin?code=${code}`}
        target="_blank"
        rel="noreferrer"
        style={{ color: "white" }}
      >
        {process.env.REACT_APP_PREVIEW_URL}/#/signin?code={code}
      </a>
      <FontAwesomeIcon
        icon={qsCopied ? faClipboardCheck : faClipboard}
        onClick={copyQSUrl}
        title={qsCopied ? t("Copied to clipboard") : t("Copy to clipboard")}
      />
    </TooltipParagraph>
  );
};

const Player = ({ children, title }) => {
  const [open, setOpen] = useState(false);
  const [fixed, setFixed] = useState(false);

  return (
    <Tooltip
      arrow
      interactive
      onClick={() => setFixed(!fixed)}
      onClose={() => {
        if (!fixed) {
          setOpen(false);
        }
      }}
      onOpen={() => setOpen(true)}
      open={open}
      title={title}
    >
      {children}
    </Tooltip>
  );
};

const PlayerList = ({ onRemove, users }) => {
  return (
    <List className="list-unstyled list-inline">
      {users.map(({ id, email, game, name, password_generated }, key) => (
        <Player
          key={key}
          title={
            <PlayerTooltip password={password_generated} code={game.code} />
          }
        >
          <li key={id}>
            <Button color="light">
              {name === "Anonymous" || name === ""
                ? email
                : `${email} (${name})` || email}
              {onRemove && (
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  onClick={() => onRemove(id)}
                />
              )}
            </Button>
          </li>
        </Player>
      ))}
    </List>
  );
};

export default PlayerList;
